import {
  BugReport, KeyboardArrowDownRounded, KeyboardArrowUpRounded,
} from '@mui/icons-material'
import {
  Card,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { RotationAnimation } from '@xylabs/react-animation'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { DarkModeIconButtonForColorScheme } from '@xylabs/react-invertible-theme'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

// import { socialLinksData } from './SocialData.js'

export interface BottomMenuAreaProps {
  backgroundColor?: string
  openDebug?: () => void
}

export const BottomMenuArea: React.FC<BottomMenuAreaProps> = ({ openDebug, backgroundColor }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)
  // const logo = XyoOSLogoWhite

  // Toggle expand/collapse
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card
      sx={{
        position: 'relative',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundColor: backgroundColor,
        overflow: 'hidden',
      }}
      elevation={4}
    >
      {/* Collapsed View */}
      <FlexCol paddingY={1} alignItems="stretch">
        <Stack
          paddingX={2}
          justifyContent="space-between"
          flexDirection="row"
          gap={0.5}
          onClick={handleExpandClick}
          sx={{ textTransform: 'none', cursor: 'pointer' }}
        >
          <Typography gutterBottom={false}>More</Typography>
          {expanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </Stack>
      </FlexCol>
      {/* Expanded Content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ paddingBottom: 3 }}>
        <Divider flexItem></Divider>
        <FlexCol alignItems="center">
          <Toolbar sx={{ paddingX: 1.5 }}>
            {/* <Tooltip title="Debug Node View">
              <IconButton onClick={openDebug}>
                <BugReport />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Toggle Light/Dark Mode">
              <span>
                <DarkModeIconButtonForColorScheme defaultLightModeColor="warning" />
              </span>
            </Tooltip>
          </Toolbar>
          {/* <FlexRow flexWrap="wrap">
            {socialLinksData.map((social, index) => (
              <RotationAnimation key={index} rotation={20}>
                <IconButton
                  sx={{ color: '#fff' }}
                  href={social.link}
                  target="_blank"

                >
                  {social.icon}
                </IconButton>
              </RotationAnimation>
            ))}
          </FlexRow> */}
          <FlexRow padding={1} justifyContent="center" gap={0.5}>
            <Typography variant="body2" color={theme.palette.text.primary}>
              Powered By
            </Typography>
            {/* <img
              style={{ color: theme.palette.text.primary, fill: theme.palette.text.primary }}
              src={logo}
              width="auto"
              height="20px"
            /> */}
          </FlexRow>
          <Typography variant="caption" color={theme.palette.text.primary}>
            Copyright © 2024 XY Labs, Inc.
          </Typography>
          <Stack gap={0.5} flexDirection="row">
            <LinkEx color="inherit" target="_blank" href="https://xylabs.com/privacy/">
              <Typography variant="caption">Privacy</Typography>
            </LinkEx>
            {' '}
            •
            {' '}
            <LinkEx color="inherit" target="_blank" href="https://xylabs.com/terms/">
              <Typography variant="caption">Terms</Typography>
            </LinkEx>
            {' '}
            •
            {' '}
            <LinkEx color="inherit" target="_blank" href="https://xyo.network/">
              <Typography variant="caption">XYO Website</Typography>
            </LinkEx>
          </Stack>
        </FlexCol>
      </Collapse>
    </Card>
  )
}
