/* eslint-disable @stylistic/max-len */
export type Pillar = {
  desc: string
  title: string
}

export const ThreePillarsData: Pillar[] = [
  {
    desc: "Data isn't sterile. It's filled with memories, emotions, and stories. Dataism helps you understand the deeply personal connections behind your data.",
    title: 'Human-Centric',
  },
  {
    desc: "Your data belongs to you. Dataism empowers you to decide what you share and who has access, whether it's public, private, or anonymized.",
    title: 'Control Over Data',
  },
  {
    desc: 'Everyone uses data differently. With Dataism, learn how to turn your data into something that benefits you and those around you.',
    title: 'Personalized Experiences',
  },
]
