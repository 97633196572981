import { CssBaseline } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

import {
  AuthProvider, SettingsLoader, UserProvider,
} from '../contexts/index.ts'
import { AppThemeBody } from './theme/index.ts'

export const App: React.FC = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <SettingsLoader>
          <Helmet defaultTitle="Dataism.org" titleTemplate="%s" />
          <CssBaseline />
          <AppThemeBody />
        </SettingsLoader>
      </UserProvider>
    </AuthProvider>
  )
}
