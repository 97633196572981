/* eslint-disable @stylistic/max-len */
import type { PersonaName } from './NameData.tsx'

export type Persona = {
  description: string
  favoriteDataUses: string
  goal: string
  name: PersonaName
  strengths: string
  subname: string
  summary: string
  weaknesses: string
}

export const DataistPersonaData: Persona[] = [
  {
    name: 'nova',
    subname: 'The Explorer',
    description: 'Nova is endlessly curious and loves learning about new tools and technologies. They absorb information quickly and enjoy exploring a wide range of topics.',
    strengths: "At your strongest, you're quick to grasp new concepts and love diving into different topics. You can absorb information rapidly, and your breadth of knowledge across many subjects makes you a versatile learner. You also have a unique ability to connect seemingly unrelated ideas, allowing you to approach problems from fresh perspectives. This curiosity makes you a natural problem solver, often surprising others with your insights.",
    weaknesses: "When you're struggling, you might hesitate to implement what you've learned. You could be indecisive, fearing mistakes, which sometimes prevents you from gaining practical experience. You may also find yourself overwhelmed by the sheer amount of information you consume, making it difficult to know where to start. This can lead to a sense of paralysis, where you're learning but not applying.",
    favoriteDataUses: 'Storing knowledge and skills for future use, learning about data systems, but often keeping it to themselves.',
    goal: 'Help Nova gain confidence in applying their knowledge through small, low-risk experiments.',
    summary: 'Endlessly curious, occasionally indecisive',
  },
  {
    name: 'vex',
    subname: 'The Pioneer',
    description: 'Vex is bold, adventurous, and always eager to try out the latest tech innovations. They thrive on being at the forefront of new trends and ideas.',
    strengths: "When you're feeling confident, you're bold and adventurous, always ready to try out the latest technology. You're ahead of the curve and often know about trends before they happen. You thrive on being the first to discover new ways to use data, finding innovative solutions where others see challenges. Your natural excitement for the unknown makes you an inspirational figure to others, encouraging them to take risks alongside you.",
    weaknesses: 'You may struggle with impulsiveness, jumping into new technologies without fully understanding them. This could lead to occasional mistakes or setbacks, which sometimes frustrates you. You might also overlook potential risks in your excitement to try new things, which can lead to hasty decisions. Learning to temper your enthusiasm with caution can help you avoid unnecessary pitfalls.',
    favoriteDataUses: 'Eager to implement new technologies to solve problems, test hypotheses, or create something innovative. Enjoys sharing their findings with others.',
    goal: 'Encourage Vex to balance their adventurous spirit with caution, making more informed decisions while exploring new tech.',
    summary: 'Bold, adventurous, impulsive',
  },
  {
    name: 'rook',
    subname: 'The Architect',
    description: 'Rook is highly organized and excels at building structured, reliable systems for managing data. They enjoy creating frameworks that can be used long-term.',
    strengths: "At your best, you're highly organized and excel at building reliable systems. You love creating structured frameworks that stand the test of time, ensuring that everything has a place and purpose. Your attention to detail is impeccable, and you take pride in creating something that will be useful for years to come. Others often rely on you to keep projects grounded and well-organized, making you an invaluable team member.",
    weaknesses: "If you're feeling overwhelmed, you might get bogged down in the details, making it hard for you to adapt when things don't go as planned. Your desire for control can sometimes lead to rigidity, preventing flexibility. You may also struggle with perfectionism, finding it difficult to move forward unless everything is just right, which can delay progress. Learning to let go of minor details could help you achieve greater balance.",
    favoriteDataUses: 'Designing intricate data storage systems, creating workflows and frameworks for efficient management.',
    goal: 'Help Rook embrace flexibility and encourage them to step outside their rigid systems when necessary.',
    summary: 'Highly organized, occasionally rigid',
  },
  {
    name: 'lux',
    subname: 'The Visionary',
    description: 'Lux has the ability to see the big picture and think long-term. They excel at understanding the future potential of data and how it can shape new solutions.',
    strengths: "When you're in your element, you have a remarkable ability to see the bigger picture and think long-term. You're forward-thinking, strategic, and can easily identify how data can shape future solutions. Your insights often help others see possibilities they hadn't considered, making you a natural leader in forward-thinking discussions. You have a talent for recognizing trends and aligning them with broader goals, which can lead to groundbreaking innovations.",
    weaknesses: 'When things get challenging, you may overlook the present details in favor of future planning. You could miss the small, necessary steps that will lead to the realization of your visionary goals. At times, this can make you seem disconnected from immediate concerns, causing frustration among those who work more in the present. Finding a balance between visionary thinking and practical action will help you move from ideas to execution.',
    favoriteDataUses: 'Applying data for future problem-solving, crafting long-term strategies, and focusing on broader societal or global challenges.',
    goal: 'Help Lux to focus on present-day actions that will lead to their visionary outcomes, balancing the future with practical steps.',
    summary: 'Visionary, future-focused, often overlooks details',
  },
  {
    name: 'aegis',
    subname: 'The Protector',
    description: 'Aegis is highly protective of their data, with a deep understanding of privacy and security measures. They are vigilant about ensuring data is safe and properly managed.',
    strengths: "At your core, you're incredibly vigilant about safeguarding data. You're excellent at understanding privacy settings, encryption, and security protocols, always keeping information safe. You approach data management with a sense of responsibility, ensuring that nothing is left vulnerable. Others trust you to secure sensitive information, and you take pride in being the gatekeeper who ensures everything is protected.",
    weaknesses: "When you're feeling cautious, you may resist sharing data out of fear of breaches or misuse. This caution can sometimes hold you back from exploring new ideas or innovating. You might also find it difficult to trust others with data, leading to isolation or missed opportunities for collaboration. Learning to open up while maintaining strong security protocols can help you strike a better balance between protection and progress.",
    favoriteDataUses: 'Securely storing and protecting data, ensuring everything is private and encrypted. Prefers tightly controlled access to their information.',
    goal: 'Teach Aegis to balance security with openness, encouraging them to use their protective instincts while still exploring collaborative or innovative data uses.',
    summary: 'Highly protective, cautious about change',
  },
  {
    name: 'echo',
    subname: 'The Tinkerer',
    description: 'Echo is a hands-on experimenter, always tweaking, customizing, and playing with different data systems to make them fit their needs. Great at troubleshooting and creative problem-solving.',
    strengths: "At your best, you're highly inventive and thrive on customizing solutions to fit your needs. You're great at troubleshooting and love the challenge of making systems work better through creative thinking. You have a natural ability to think outside the box, often coming up with unique solutions that others wouldn’t have considered. Your hands-on approach makes you a master of personalization, turning even mundane tasks into creative projects.",
    weaknesses: "When you're struggling, you might have trouble finishing what you start, constantly tweaking and experimenting without settling on a final solution. This can make it hard to complete projects, as you're always searching for ways to improve them. You may also get frustrated when things don't work as expected, leading to burnout or loss of motivation. Learning when to stop tinkering and finalize your work can help you channel your creativity more productively.",
    favoriteDataUses: 'Loves to modify, personalize, and experiment with data systems, turning simple tasks into creative projects.',
    goal: "Help Echo learn when it's time to finalize their work, encouraging them to apply their creative energy toward productive outcomes.",
    summary: 'Inventive, hands-on, struggles to finalize work',
  },
  {
    name: 'myca',
    subname: 'The Collaborator',
    description: 'Myca excels at bringing people together through data, seeing the potential for collaboration and shared success. They thrive in team environments and enjoy building community-driven projects.',
    strengths: "When you're feeling empowered, you're an empathetic and social connector. You excel at using data to create relationships and build community-driven projects, thriving in team-based environments. You see the potential in collaboration and know how to bring the best out of others, creating an atmosphere of mutual success. Your natural empathy allows you to understand different perspectives, making you a key player in team dynamics.",
    weaknesses: 'You may struggle with balancing collective goals and personal privacy. Sometimes, you focus so much on others that you forget to prioritize your own data or personal objectives. This can lead to burnout or feelings of being unappreciated. Finding the balance between helping others and taking care of your own needs will help you sustain long-term success in both personal and professional projects.',
    favoriteDataUses: 'Connecting people, sharing resources, and fostering collaboration through data. Loves working on open-source or community-driven initiatives.',
    goal: 'Help Myca strike a balance between collaboration and personal data management, teaching them how to protect their data while contributing to a larger cause.',
    summary: 'Empathetic, collaborative, sometimes forgets self',
  },
  {
    name: 'cass',
    subname: 'The Skeptic',
    description: 'Cass is cautious, preferring to stick with familiar systems rather than diving into new technologies. They tend to distrust change, especially when it comes to data management, privacy, and new tech trends.',
    strengths: "At your strongest, you're excellent at identifying risks and potential flaws in new tools or systems. You're great at asking the tough questions and ensuring every decision is well-vetted before proceeding. Your skepticism often saves you from potential disasters, as you're not easily swayed by hype or untested trends. Others look to you for a reality check, knowing you'll provide a thoughtful, measured perspective.",
    weaknesses: "If you're feeling hesitant, your cautious nature could cause you to miss opportunities for growth and innovation. You might resist change, even when it's beneficial, leading to stagnation or fear of new technologies. This can result in frustration as the world moves forward without you. Learning to take calculated risks could help you adapt while still maintaining your thoughtful approach to decision-making.",
    favoriteDataUses: 'Cass prefers simple, traditional systems that have been proven to work. They focus on maintaining stable systems and are reluctant to try new methods unless absolutely necessary.',
    goal: 'Help Cass overcome their fear of change by introducing new tools in a controlled, low-risk environment. Show them that gradual, well-considered changes can be both safe and beneficial.',
    summary: 'Cautious, prefers status quo, resistant to change',
  },
]
