import { RotationAnimation } from '@xylabs/react-animation'
import type { CSSProperties } from 'react'
import React from 'react'

import { DataismIcon } from '../../../img/index.ts'

export interface RotatingLogoProps {
  logoStyle?: CSSProperties
  rotation?: number
}
export const RotatingLogo: React.FC<RotatingLogoProps> = ({ rotation = 20, logoStyle }) => {
  return (
    <RotationAnimation rotation={rotation}>
      <DataismIcon style={logoStyle} />
    </RotationAnimation>
  )
}
