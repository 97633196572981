import { Slide } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports, import-x/no-internal-modules
import { TransitionProps } from '@mui/material/transitions'
import React, { forwardRef } from 'react'

export const SlideTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />
})
