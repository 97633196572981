import { createSvgIcon } from '@mui/material'
import React from 'react'

export const VexIcon = createSvgIcon(
  <svg id="VexIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" stroke="currentColor" fill="none">
    <line x1="594.88" y1="510.8" x2="690.72" y2="289.2" />
    <line x1="584.64" y1="509.6" x2="700.91" y2="298.02" />
    <line x1="574.84" y1="507.48" x2="710.51" y2="307.77" />
    <line x1="565.6" y1="504.47" x2="719.46" y2="318.41" />
    <line x1="556.99" y1="500.62" x2="727.66" y2="329.87" />
    <line x1="549.07" y1="496.02" x2="735.05" y2="342.08" />
    <line x1="541.91" y1="490.72" x2="741.54" y2="354.99" />
    <line x1="535.57" y1="484.82" x2="747.09" y2="368.47" />
    <line x1="530.11" y1="478.4" x2="751.66" y2="382.48" />
    <line x1="525.59" y1="471.54" x2="755.17" y2="396.89" />
    <line x1="522.01" y1="464.34" x2="757.61" y2="411.63" />
    <line x1="519.43" y1="456.9" x2="758.94" y2="426.6" />
    <line x1="517.85" y1="449.31" x2="759.15" y2="441.68" />
    <line x1="517.28" y1="441.68" x2="758.22" y2="456.81" />
    <line x1="517.72" y1="434.11" x2="756.18" y2="471.83" />
    <line x1="519.17" y1="426.68" x2="753.02" y2="486.68" />
    <line x1="521.59" y1="419.51" x2="748.74" y2="501.26" />
    <line x1="524.97" y1="412.7" x2="743.42" y2="515.46" />
    <line x1="529.25" y1="406.31" x2="737.06" y2="529.18" />
    <line x1="534.4" y1="400.46" x2="729.74" y2="542.33" />
    <line x1="540.37" y1="395.21" x2="721.47" y2="554.85" />
    <line x1="547.07" y1="390.68" x2="712.35" y2="566.64" />
    <line x1="554.45" y1="386.87" x2="702.45" y2="577.62" />
    <line x1="562.43" y1="383.92" x2="691.82" y2="587.75" />
    <line x1="570.93" y1="381.84" x2="680.57" y2="596.93" />
    <line x1="579.87" y1="380.69" x2="668.77" y2="605.15" />
    <line x1="589.13" y1="380.52" x2="656.52" y2="612.35" />
    <line x1="598.65" y1="381.35" x2="643.92" y2="618.5" />
    <line x1="608.31" y1="383.21" x2="631.08" y2="623.56" />
    <line x1="618.02" y1="386.11" x2="618.06" y2="627.54" />
    <line x1="627.68" y1="390.05" x2="604.99" y2="630.42" />
    <line x1="637.18" y1="395.05" x2="591.98" y2="632.21" />
    <line x1="646.42" y1="401.05" x2="579.12" y2="632.91" />
    <line x1="655.31" y1="408.06" x2="566.49" y2="632.55" />
    <line x1="663.76" y1="416.04" x2="554.21" y2="631.18" />
    <line x1="671.69" y1="424.93" x2="542.36" y2="628.81" />
    <line x1="678.97" y1="434.72" x2="531.04" y2="625.5" />
    <line x1="685.55" y1="445.3" x2="520.32" y2="621.33" />
    <line x1="691.35" y1="456.64" x2="510.29" y2="616.33" />
    <line x1="696.29" y1="468.66" x2="501" y2="610.6" />
    <line x1="700.32" y1="481.25" x2="492.54" y2="604.19" />
    <line x1="703.38" y1="494.38" x2="484.95" y2="597.21" />
    <line x1="705.41" y1="507.91" x2="478.29" y2="589.73" />
    <line x1="706.4" y1="521.79" x2="472.58" y2="581.87" />
    <line x1="706.3" y1="535.88" x2="467.86" y2="573.68" />
    <line x1="705.1" y1="550.1" x2="464.15" y2="565.3" />
    <line x1="702.78" y1="564.35" x2="461.48" y2="556.81" />
    <line x1="699.34" y1="578.54" x2="459.83" y2="548.31" />
    <line x1="694.8" y1="592.54" x2="459.19" y2="539.91" />
    <line x1="689.17" y1="606.27" x2="459.56" y2="531.69" />
    <line x1="682.47" y1="619.62" x2="460.89" y2="523.78" />
    <line x1="674.75" y1="632.51" x2="463.18" y2="516.23" />
    <line x1="666.05" y1="644.85" x2="466.36" y2="509.17" />
    <line x1="656.42" y1="656.53" x2="470.38" y2="502.66" />
    <line x1="645.94" y1="667.49" x2="475.2" y2="496.8" />
    <line x1="634.64" y1="677.65" x2="480.73" y2="491.65" />
    <line x1="622.63" y1="686.93" x2="486.91" y2="487.26" />
    <line x1="609.97" y1="695.28" x2="493.64" y2="483.72" />
    <line x1="596.77" y1="702.65" x2="500.85" y2="481.09" />
    <line x1="583.1" y1="708.98" x2="508.47" y2="479.38" />
    <line x1="569.06" y1="714.25" x2="516.36" y2="478.65" />
    <line x1="554.75" y1="718.43" x2="524.47" y2="478.9" />
    <line x1="540.28" y1="721.49" x2="532.67" y2="480.17" />
    <line x1="525.74" y1="723.44" x2="540.87" y2="482.48" />
    <line x1="511.23" y1="724.26" x2="548.97" y2="485.79" />
    <line x1="496.87" y1="723.96" x2="556.86" y2="490.11" />
    <line x1="482.73" y1="722.59" x2="564.47" y2="495.42" />
    <line x1="468.93" y1="720.16" x2="571.67" y2="501.68" />
    <line x1="455.54" y1="716.71" x2="578.4" y2="508.87" />
    <line x1="442.68" y1="712.27" x2="584.54" y2="516.93" />
    <line x1="430.43" y1="706.93" x2="590.04" y2="525.8" />
    <line x1="418.84" y1="700.71" x2="594.8" y2="535.41" />
    <line x1="408.02" y1="693.72" x2="598.75" y2="545.71" />
    <line x1="398.02" y1="686" x2="601.82" y2="556.61" />
    <line x1="388.89" y1="677.68" x2="603.97" y2="568.03" />
    <line x1="380.69" y1="668.8" x2="605.13" y2="579.88" />
    <line x1="373.47" y1="659.47" x2="605.28" y2="592.06" />
    <line x1="367.26" y1="649.79" x2="604.38" y2="604.51" />
    <line x1="362.08" y1="639.85" x2="602.41" y2="617.08" />
    <line x1="357.95" y1="629.75" x2="599.36" y2="629.71" />
    <line x1="354.85" y1="619.59" x2="595.2" y2="642.28" />
    <line x1="352.83" y1="609.49" x2="589.97" y2="654.68" />
    <line x1="351.81" y1="599.52" x2="583.65" y2="666.84" />
    <line x1="351.83" y1="589.79" x2="576.3" y2="678.63" />
    <line x1="352.83" y1="580.41" x2="567.93" y2="689.98" />
    <line x1="354.76" y1="571.45" x2="558.61" y2="700.78" />
    <line x1="357.57" y1="563" x2="548.36" y2="710.95" />
    <line x1="361.24" y1="555.16" x2="537.24" y2="720.4" />
    <line x1="365.66" y1="547.98" x2="525.34" y2="729.06" />
    <line x1="370.78" y1="541.56" x2="512.71" y2="736.85" />
    <line x1="376.52" y1="535.94" x2="499.45" y2="743.72" />
    <line x1="382.8" y1="531.18" x2="485.62" y2="749.62" />
    <line x1="389.52" y1="527.34" x2="471.34" y2="754.48" />
    <line x1="396.6" y1="524.44" x2="456.68" y2="758.28" />
    <line x1="403.94" y1="522.52" x2="441.75" y2="760.97" />
    <line x1="411.45" y1="521.59" x2="426.65" y2="762.55" />
    <line x1="419.02" y1="521.69" x2="411.47" y2="763" />
    <line x1="426.54" y1="522.78" x2="396.32" y2="762.32" />
    <line x1="433.94" y1="524.89" x2="381.31" y2="760.51" />
    <line x1="441.1" y1="527.97" x2="366.54" y2="757.6" />
    <line x1="447.92" y1="532.03" x2="352.08" y2="753.62" />
    <line x1="454.32" y1="536.99" x2="338.05" y2="748.58" />
    <line x1="460.2" y1="542.85" x2="324.54" y2="742.56" />
    <line x1="465.49" y1="549.52" x2="311.63" y2="735.58" />
    <line x1="470.09" y1="556.97" x2="299.41" y2="727.71" />
    <line x1="473.95" y1="565.1" x2="287.95" y2="719.04" />
    <line x1="476.97" y1="573.87" x2="277.32" y2="709.62" />
    <line x1="479.13" y1="583.18" x2="267.58" y2="699.53" />
    <line x1="480.35" y1="592.94" x2="258.8" y2="688.87" />
    <line x1="480.58" y1="603.08" x2="250.99" y2="677.72" />
    <line x1="479.82" y1="613.47" x2="244.22" y2="666.19" />
    <line x1="478" y1="624.06" x2="238.5" y2="654.35" />
    <line x1="475.14" y1="634.7" x2="233.86" y2="642.34" />
    <line x1="471.23" y1="645.33" x2="230.29" y2="630.22" />
    <line x1="466.26" y1="655.84" x2="227.81" y2="618.11" />
    <line x1="460.24" y1="666.12" x2="226.4" y2="606.11" />
    <line x1="453.19" y1="676.07" x2="226.04" y2="594.33" />
    <line x1="445.16" y1="685.6" x2="226.71" y2="582.84" />
    <line x1="436.17" y1="694.62" x2="228.35" y2="571.75" />
    <line x1="426.28" y1="703.03" x2="230.94" y2="561.15" />
    <line x1="415.53" y1="710.76" x2="234.41" y2="551.12" />
    <line x1="404" y1="717.72" x2="238.71" y2="541.74" />
    <line x1="391.76" y1="723.84" x2="243.76" y2="533.09" />
    <line x1="378.87" y1="729.06" x2="249.48" y2="525.23" />
    <line x1="365.44" y1="733.32" x2="255.8" y2="518.22" />
    <line x1="351.54" y1="736.56" x2="262.63" y2="512.1" />
    <line x1="337.27" y1="738.77" x2="269.87" y2="506.94" />
    <line x1="322.72" y1="739.88" x2="277.45" y2="502.73" />
    <line x1="308" y1="739.89" x2="285.25" y2="499.53" />
    <line x1="293.2" y1="738.77" x2="293.17" y2="497.34" />
    <line x1="278.43" y1="736.54" x2="301.1" y2="496.17" />
    <line x1="263.78" y1="733.18" x2="308.98" y2="496.02" />
    <line x1="249.37" y1="728.71" x2="316.68" y2="496.85" />
    <line x1="235.28" y1="723.17" x2="324.12" y2="498.67" />
    <line x1="221.62" y1="716.57" x2="331.18" y2="501.44" />
    <line x1="208.48" y1="708.98" x2="337.8" y2="505.11" />
    <line x1="195.94" y1="700.42" x2="343.86" y2="509.63" />
    <line x1="184.09" y1="690.97" x2="349.32" y2="514.94" />
    <line x1="173" y1="680.67" x2="354.06" y2="520.98" />
    <line x1="162.75" y1="669.64" x2="358.03" y2="527.7" />
    <line x1="153.4" y1="657.92" x2="361.17" y2="534.98" />
    <line x1="145" y1="645.6" x2="363.43" y2="542.76" />
    <line x1="137.62" y1="632.77" x2="364.75" y2="550.95" />
    <line x1="131.28" y1="619.55" x2="365.11" y2="559.47" />
    <line x1="126.01" y1="606" x2="364.45" y2="568.2" />
    <line x1="121.85" y1="592.24" x2="362.78" y2="577.04" />
    <line x1="118.78" y1="578.37" x2="360.08" y2="585.92" />
    <line x1="116.84" y1="564.49" x2="356.35" y2="594.71" />
    <line x1="115.99" y1="550.7" x2="351.59" y2="603.32" />
    <line x1="116.23" y1="537.09" x2="345.83" y2="611.66" />
    <line x1="117.53" y1="523.77" x2="339.11" y2="619.62" />
    <line x1="119.85" y1="510.84" x2="331.42" y2="627.11" />
    <line x1="123.17" y1="498.39" x2="322.85" y2="634.05" />
    <line x1="127.4" y1="486.49" x2="313.44" y2="640.35" />
    <line x1="132.5" y1="475.23" x2="303.24" y2="645.93" />
    <line x1="138.41" y1="464.7" x2="292.34" y2="650.7" />
    <line x1="145.06" y1="454.95" x2="280.78" y2="654.61" />
    <line x1="152.34" y1="446.05" x2="268.68" y2="657.6" />
    <line x1="160.2" y1="438.05" x2="256.11" y2="659.61" />
    <line x1="168.52" y1="431" x2="243.15" y2="660.6" />
    <line x1="177.22" y1="424.92" x2="229.92" y2="660.53" />
    <line x1="186.21" y1="419.86" x2="216.5" y2="659.39" />
    <line x1="195.37" y1="415.83" x2="203" y2="657.14" />
    <line x1="204.62" y1="412.83" x2="189.51" y2="653.8" />
    <line x1="213.86" y1="410.88" x2="176.14" y2="649.34" />
    <line x1="222.98" y1="409.95" x2="162.99" y2="643.81" />
    <line x1="231.89" y1="410.02" x2="150.14" y2="637.19" />
    <line x1="240.47" y1="411.07" x2="137.72" y2="629.54" />
    <line x1="248.66" y1="413.06" x2="125.8" y2="620.9" />
    <line x1="256.36" y1="415.94" x2="114.49" y2="611.3" />
    <line x1="263.48" y1="419.68" x2="103.85" y2="600.81" />
    <line x1="269.94" y1="424.17" x2="93.97" y2="589.48" />
    <line x1="275.67" y1="429.39" x2="84.94" y2="577.4" />
    <line x1="280.61" y1="435.23" x2="76.81" y2="564.62" />
    <line x1="284.7" y1="441.61" x2="69.63" y2="551.26" />
    <line x1="287.91" y1="448.47" x2="63.47" y2="537.38" />
    <line x1="290.16" y1="455.7" x2="58.35" y2="523.09" />
    <line x1="291.43" y1="463.19" x2="54.31" y2="508.46" />
    <line x1="291.73" y1="470.88" x2="51.38" y2="493.63" />
    <line x1="291" y1="478.63" x2="49.59" y2="478.67" />
    <line x1="289.26" y1="486.37" x2="48.91" y2="463.7" />
    <line x1="286.51" y1="494.01" x2="49.36" y2="448.8" />
    <line x1="282.76" y1="501.4" x2="50.92" y2="434.08" />
    <line x1="278.05" y1="508.49" x2="53.57" y2="419.65" />
    <line x1="272.38" y1="515.17" x2="57.26" y2="405.61" />
    <line x1="265.84" y1="521.35" x2="61.98" y2="392.02" />
    <line x1="258.44" y1="526.95" x2="67.66" y2="378.99" />
    <line x1="250.25" y1="531.86" x2="74.25" y2="366.62" />
    <line x1="241.33" y1="536.04" x2="81.65" y2="354.96" />
    <line x1="231.77" y1="539.39" x2="89.85" y2="344.1" />
    <line x1="221.65" y1="541.88" x2="98.73" y2="334.09" />
    <line x1="211.03" y1="543.43" x2="108.21" y2="324.99" />
    <line x1="200.02" y1="544" x2="118.21" y2="316.86" />
    <line x1="188.71" y1="543.57" x2="128.63" y2="309.73" />
    <line x1="177.18" y1="542.08" x2="139.37" y2="303.62" />
    <line x1="165.55" y1="539.53" x2="150.35" y2="298.57" />
    <line x1="153.91" y1="535.91" x2="161.45" y2="294.59" />
    <line x1="142.35" y1="531.21" x2="172.57" y2="291.67" />
    <line x1="131.01" y1="525.43" x2="183.63" y2="289.81" />
    <line x1="119.95" y1="518.64" x2="194.51" y2="289" />
    <line x1="109.28" y1="510.8" x2="205.12" y2="289.2" />
    <line x1="99.09" y1="501.98" x2="215.36" y2="290.4" />
    <line x1="89.49" y1="492.23" x2="225.16" y2="292.52" />
    <line x1="80.54" y1="481.59" x2="234.4" y2="295.53" />
    <line x1="72.34" y1="470.13" x2="243.01" y2="299.38" />
    <line x1="64.95" y1="457.92" x2="250.93" y2="303.98" />
    <line x1="58.46" y1="445.01" x2="258.09" y2="309.28" />
    <line x1="52.91" y1="431.53" x2="264.43" y2="315.18" />
    <line x1="48.34" y1="417.52" x2="269.89" y2="321.6" />
    <line x1="44.83" y1="403.11" x2="274.41" y2="328.46" />
    <line x1="42.39" y1="388.37" x2="277.99" y2="335.66" />
    <line x1="41.06" y1="373.4" x2="280.57" y2="343.1" />
    <line x1="40.85" y1="358.32" x2="282.15" y2="350.69" />
    <line x1="41.78" y1="343.19" x2="282.72" y2="358.32" />
    <line x1="43.82" y1="328.17" x2="282.28" y2="365.89" />
    <line x1="46.98" y1="313.31" x2="280.83" y2="373.32" />
    <line x1="51.26" y1="298.74" x2="278.41" y2="380.49" />
    <line x1="56.58" y1="284.54" x2="275.03" y2="387.3" />
    <line x1="62.94" y1="270.82" x2="270.75" y2="393.69" />
    <line x1="70.26" y1="257.67" x2="265.6" y2="399.54" />
    <line x1="78.53" y1="245.15" x2="259.63" y2="404.79" />
    <line x1="87.65" y1="233.36" x2="252.93" y2="409.32" />
    <line x1="97.55" y1="222.38" x2="245.55" y2="413.13" />
    <line x1="108.18" y1="212.25" x2="237.57" y2="416.08" />
    <line x1="119.43" y1="203.07" x2="229.07" y2="418.16" />
    <line x1="131.23" y1="194.85" x2="220.13" y2="419.31" />
    <line x1="143.48" y1="187.65" x2="210.87" y2="419.48" />
    <line x1="156.08" y1="181.5" x2="201.35" y2="418.65" />
    <line x1="168.92" y1="176.44" x2="191.69" y2="416.79" />
    <line x1="181.94" y1="172.46" x2="181.98" y2="413.89" />
    <line x1="195.01" y1="169.58" x2="172.32" y2="409.95" />
    <line x1="208.02" y1="167.79" x2="162.82" y2="404.95" />
    <line x1="220.88" y1="167.09" x2="153.58" y2="398.95" />
    <line x1="233.51" y1="167.45" x2="144.69" y2="391.94" />
    <line x1="245.79" y1="168.82" x2="136.24" y2="383.96" />
    <line x1="257.64" y1="171.19" x2="128.31" y2="375.07" />
    <line x1="268.96" y1="174.5" x2="121.03" y2="365.28" />
    <line x1="279.68" y1="178.67" x2="114.45" y2="354.7" />
    <line x1="289.71" y1="183.67" x2="108.65" y2="343.36" />
    <line x1="299" y1="189.4" x2="103.71" y2="331.34" />
    <line x1="307.46" y1="195.81" x2="99.68" y2="318.75" />
    <line x1="315.05" y1="202.79" x2="96.62" y2="305.62" />
    <line x1="321.71" y1="210.27" x2="94.59" y2="292.08" />
    <line x1="327.42" y1="218.13" x2="93.6" y2="278.21" />
    <line x1="332.14" y1="226.32" x2="93.7" y2="264.12" />
    <line x1="335.85" y1="234.7" x2="94.9" y2="249.9" />
    <line x1="338.52" y1="243.19" x2="97.22" y2="235.64" />
    <line x1="340.17" y1="251.69" x2="100.66" y2="221.46" />
    <line x1="340.81" y1="260.09" x2="105.2" y2="207.46" />
    <line x1="340.44" y1="268.31" x2="110.83" y2="193.73" />
    <line x1="339.11" y1="276.22" x2="117.53" y2="180.38" />
    <line x1="336.82" y1="283.77" x2="125.25" y2="167.49" />
    <line x1="333.64" y1="290.83" x2="133.95" y2="155.15" />
    <line x1="329.62" y1="297.34" x2="143.58" y2="143.47" />
    <line x1="324.8" y1="303.2" x2="154.06" y2="132.51" />
    <line x1="319.27" y1="308.35" x2="165.36" y2="122.35" />
    <line x1="313.09" y1="312.74" x2="177.37" y2="113.07" />
    <line x1="306.36" y1="316.28" x2="190.03" y2="104.72" />
    <line x1="299.15" y1="318.91" x2="203.23" y2="97.35" />
    <line x1="291.53" y1="320.62" x2="216.9" y2="91.02" />
    <line x1="283.64" y1="321.35" x2="230.94" y2="85.75" />
    <line x1="275.53" y1="321.1" x2="245.25" y2="81.57" />
    <line x1="267.33" y1="319.83" x2="259.72" y2="78.51" />
    <line x1="259.13" y1="317.52" x2="274.26" y2="76.56" />
    <line x1="251.03" y1="314.21" x2="288.77" y2="75.74" />
    <line x1="243.14" y1="309.89" x2="303.13" y2="76.04" />
    <line x1="235.53" y1="304.58" x2="317.27" y2="77.41" />
    <line x1="228.33" y1="298.32" x2="331.07" y2="79.84" />
    <line x1="221.6" y1="291.13" x2="344.46" y2="83.29" />
    <line x1="215.46" y1="283.07" x2="357.32" y2="87.73" />
    <line x1="209.96" y1="274.2" x2="369.59" y2="93.07" />
    <line x1="205.21" y1="264.59" x2="381.16" y2="99.29" />
    <line x1="201.25" y1="254.29" x2="391.98" y2="106.28" />
    <line x1="198.18" y1="243.39" x2="401.98" y2="114" />
    <line x1="196.03" y1="231.97" x2="411.11" y2="122.32" />
    <line x1="194.87" y1="220.12" x2="419.31" y2="131.2" />
    <line x1="194.72" y1="207.94" x2="426.53" y2="140.53" />
    <line x1="195.62" y1="195.49" x2="432.74" y2="150.21" />
    <line x1="197.59" y1="182.92" x2="437.92" y2="160.15" />
    <line x1="200.64" y1="170.29" x2="442.05" y2="170.25" />
    <line x1="204.8" y1="157.72" x2="445.15" y2="180.41" />
    <line x1="210.03" y1="145.32" x2="447.17" y2="190.51" />
    <line x1="216.35" y1="133.16" x2="448.19" y2="200.48" />
    <line x1="223.7" y1="121.37" x2="448.17" y2="210.21" />
    <line x1="232.07" y1="110.02" x2="447.17" y2="219.59" />
    <line x1="241.39" y1="99.22" x2="445.24" y2="228.55" />
    <line x1="251.64" y1="89.05" x2="442.43" y2="237" />
    <line x1="262.76" y1="79.6" x2="438.76" y2="244.84" />
    <line x1="274.66" y1="70.94" x2="434.34" y2="252.02" />
    <line x1="287.29" y1="63.15" x2="429.22" y2="258.44" />
    <line x1="300.56" y1="56.28" x2="423.48" y2="264.06" />
    <line x1="314.38" y1="50.38" x2="417.2" y2="268.82" />
    <line x1="328.66" y1="45.52" x2="410.48" y2="272.66" />
    <line x1="343.32" y1="41.72" x2="403.4" y2="275.56" />
    <line x1="358.25" y1="39.03" x2="396.05" y2="277.48" />
    <line x1="373.35" y1="37.45" x2="388.55" y2="278.41" />
    <line x1="388.53" y1="37" x2="380.98" y2="278.31" />
    <line x1="403.68" y1="37.68" x2="373.46" y2="277.22" />
    <line x1="418.69" y1="39.49" x2="366.06" y2="275.11" />
    <line x1="433.46" y1="42.4" x2="358.9" y2="272.03" />
    <line x1="447.92" y1="46.38" x2="352.08" y2="267.97" />
    <line x1="461.95" y1="51.42" x2="345.68" y2="263.01" />
    <line x1="475.46" y1="57.44" x2="339.8" y2="257.15" />
    <line x1="488.37" y1="64.42" x2="334.51" y2="250.48" />
    <line x1="500.59" y1="72.29" x2="329.91" y2="243.03" />
    <line x1="512.05" y1="80.96" x2="326.05" y2="234.9" />
    <line x1="522.68" y1="90.38" x2="323.03" y2="226.13" />
    <line x1="532.42" y1="100.47" x2="320.88" y2="216.82" />
    <line x1="541.2" y1="111.13" x2="319.65" y2="207.06" />
    <line x1="549.01" y1="122.28" x2="319.42" y2="196.92" />
    <line x1="555.78" y1="133.81" x2="320.18" y2="186.53" />
    <line x1="561.5" y1="145.65" x2="322" y2="175.94" />
    <line x1="566.14" y1="157.66" x2="324.86" y2="165.3" />
    <line x1="569.71" y1="169.78" x2="328.77" y2="154.67" />
    <line x1="572.19" y1="181.89" x2="333.74" y2="144.16" />
    <line x1="573.6" y1="193.89" x2="339.76" y2="133.88" />
    <line x1="573.96" y1="205.67" x2="346.81" y2="123.93" />
    <line x1="573.29" y1="217.16" x2="354.84" y2="114.4" />
    <line x1="571.65" y1="228.25" x2="363.83" y2="105.38" />
    <line x1="569.06" y1="238.85" x2="373.72" y2="96.97" />
    <line x1="565.59" y1="248.88" x2="384.47" y2="89.24" />
    <line x1="561.29" y1="258.26" x2="396" y2="82.28" />
    <line x1="556.24" y1="266.91" x2="408.24" y2="76.16" />
    <line x1="550.52" y1="274.77" x2="421.13" y2="70.94" />
    <line x1="544.2" y1="281.78" x2="434.56" y2="66.68" />
    <line x1="537.37" y1="287.9" x2="448.46" y2="63.44" />
    <line x1="530.13" y1="293.06" x2="462.73" y2="61.23" />
    <line x1="522.55" y1="297.27" x2="477.28" y2="60.12" />
    <line x1="514.75" y1="300.47" x2="492" y2="60.11" />
    <line x1="506.83" y1="302.66" x2="506.8" y2="61.23" />
    <line x1="498.9" y1="303.83" x2="521.57" y2="63.46" />
    <line x1="491.02" y1="303.98" x2="536.22" y2="66.82" />
    <line x1="483.32" y1="303.15" x2="550.63" y2="71.29" />
    <line x1="475.88" y1="301.33" x2="564.72" y2="76.83" />
    <line x1="468.82" y1="298.56" x2="578.38" y2="83.43" />
    <line x1="462.2" y1="294.89" x2="591.52" y2="91.02" />
    <line x1="456.14" y1="290.38" x2="604.06" y2="99.58" />
    <line x1="450.68" y1="285.06" x2="615.91" y2="109.03" />
    <line x1="445.94" y1="279.02" x2="627" y2="119.33" />
    <line x1="441.97" y1="272.3" x2="637.25" y2="130.36" />
    <line x1="438.83" y1="265.02" x2="646.6" y2="142.08" />
    <line x1="436.57" y1="257.24" x2="655" y2="154.4" />
    <line x1="435.25" y1="249.05" x2="662.38" y2="167.22" />
    <line x1="434.89" y1="240.53" x2="668.72" y2="180.45" />
    <line x1="435.55" y1="231.8" x2="673.99" y2="194" />
    <line x1="437.22" y1="222.96" x2="678.15" y2="207.76" />
    <line x1="439.92" y1="214.08" x2="681.22" y2="221.63" />
    <line x1="443.65" y1="205.3" x2="683.16" y2="235.51" />
    <line x1="448.41" y1="196.68" x2="684.01" y2="249.31" />
    <line x1="454.17" y1="188.34" x2="683.77" y2="262.91" />
    <line x1="460.89" y1="180.38" x2="682.47" y2="276.23" />
    <line x1="468.58" y1="172.89" x2="680.15" y2="289.16" />
    <line x1="477.15" y1="165.95" x2="676.83" y2="301.61" />
    <line x1="486.56" y1="159.65" x2="672.6" y2="313.51" />
    <line x1="496.76" y1="154.07" x2="667.5" y2="324.77" />
    <line x1="507.66" y1="149.3" x2="661.59" y2="335.3" />
    <line x1="519.22" y1="145.39" x2="654.94" y2="345.05" />
    <line x1="531.32" y1="142.4" x2="647.66" y2="353.95" />
    <line x1="543.89" y1="140.39" x2="639.8" y2="361.95" />
    <line x1="556.85" y1="139.4" x2="631.48" y2="369" />
    <line x1="570.08" y1="139.47" x2="622.78" y2="375.08" />
    <line x1="583.5" y1="140.61" x2="613.79" y2="380.14" />
    <line x1="597" y1="142.86" x2="604.63" y2="384.17" />
    <line x1="610.49" y1="146.2" x2="595.38" y2="387.17" />
    <line x1="623.86" y1="150.66" x2="586.14" y2="389.12" />
    <line x1="637.01" y1="156.19" x2="577.02" y2="390.05" />
    <line x1="649.86" y1="162.81" x2="568.11" y2="389.98" />
    <line x1="662.28" y1="170.46" x2="559.53" y2="388.93" />
    <line x1="674.2" y1="179.1" x2="551.34" y2="386.94" />
    <line x1="685.51" y1="188.7" x2="543.64" y2="384.06" />
    <line x1="696.15" y1="199.19" x2="536.52" y2="380.32" />
    <line x1="706.03" y1="210.52" x2="530.06" y2="375.83" />
    <line x1="715.06" y1="222.6" x2="524.33" y2="370.61" />
    <line x1="723.19" y1="235.38" x2="519.39" y2="364.77" />
    <line x1="730.37" y1="248.74" x2="515.3" y2="358.39" />
    <line x1="736.53" y1="262.62" x2="512.09" y2="351.53" />
    <line x1="741.65" y1="276.91" x2="509.84" y2="344.3" />
    <line x1="745.69" y1="291.54" x2="508.57" y2="336.81" />
    <line x1="748.62" y1="306.37" x2="508.27" y2="329.12" />
    <line x1="750.41" y1="321.33" x2="509" y2="321.37" />
    <line x1="751.09" y1="336.31" x2="510.74" y2="313.63" />
    <line x1="750.64" y1="351.2" x2="513.49" y2="305.99" />
    <line x1="749.08" y1="365.92" x2="517.24" y2="298.6" />
    <line x1="746.43" y1="380.35" x2="521.95" y2="291.51" />
    <line x1="742.74" y1="394.39" x2="527.62" y2="284.83" />
    <line x1="738.02" y1="407.98" x2="534.16" y2="278.65" />
    <line x1="732.34" y1="421.01" x2="541.56" y2="273.05" />
    <line x1="725.75" y1="433.38" x2="549.75" y2="268.14" />
    <line x1="718.35" y1="445.04" x2="558.67" y2="263.96" />
    <line x1="710.15" y1="455.9" x2="568.21" y2="260.61" />
    <line x1="701.27" y1="465.91" x2="578.35" y2="258.12" />
    <line x1="691.79" y1="475.01" x2="588.97" y2="256.57" />
    <line x1="681.79" y1="483.14" x2="599.98" y2="256" />
    <line x1="671.37" y1="490.27" x2="611.29" y2="256.43" />
    <line x1="660.63" y1="496.38" x2="622.82" y2="257.92" />
    <line x1="649.65" y1="501.43" x2="634.45" y2="260.47" />
    <line x1="638.55" y1="505.41" x2="646.09" y2="264.09" />
    <line x1="627.43" y1="508.33" x2="657.65" y2="268.79" />
    <line x1="616.37" y1="510.19" x2="668.99" y2="274.57" />
    <line x1="605.49" y1="511" x2="680.05" y2="281.36" />
  </svg>,
  'VexIcon',
)
