// eslint-disable-next-line import-x/no-internal-modules
import type { FirebaseError } from 'firebase/app'
// eslint-disable-next-line import-x/no-internal-modules
import type { Auth } from 'firebase/auth'
import {
  browserLocalPersistence, linkWithRedirect, setPersistence, signInWithRedirect,
// eslint-disable-next-line import-x/no-internal-modules
} from 'firebase/auth'
// eslint-disable-next-line import-x/no-internal-modules
import { GoogleAuthProvider } from 'firebase/auth/web-extension'

/**
 * Signs in a user with their email and password.
 * @param password The password of the user to sign in
 */
export const loginWithGoogle = async (auth: Auth) => {
  // TODO: Should we alert them that they are already logged in?
  // Are they trying to log in again? As someone else?
  try {
    await setPersistence(auth, browserLocalPersistence)
    await signInWithRedirect(auth, new GoogleAuthProvider())
    // await signInWithPopup(auth, new GoogleAuthProvider())
  } catch (e) {
    // TODO: Handle different cases (no account exists)
    const error = e as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message
    console.error(errorCode, errorMessage)
  }
}

/**
 * Signs in a user with their email and password.
 * @param password The password of the user to sign in
 */
export const linkWithGoogle = async (auth: Auth) => {
  // TODO: Should we alert them that they are already logged in?
  // Are they trying to log in again? As someone else?
  try {
    if (auth.currentUser) {
      await linkWithRedirect(auth.currentUser, new GoogleAuthProvider())
    }
  } catch (e) {
    // TODO: Handle different cases (no account exists)
    const error = e as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message
    console.error(errorCode, errorMessage)
  }
}
