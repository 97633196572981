// eslint-disable-next-line import-x/no-internal-modules
import { onAuthStateChanged, type User } from 'firebase/auth'
import type { ReactElement } from 'react'
import React, {
  useEffect, useMemo, useState,
} from 'react'

import { useAuth } from '../Auth/index.ts'
import { UserContext } from './Context.ts'

interface UserProviderProps {
  children?: ReactElement[] | ReactElement | undefined
}

/**
 * UserProvider component that will provide the user and loading state to its children
 */
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const auth = useAuth()
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Set up the listener for user changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // Update the state when the user changes
      setUser(currentUser)
      // Once we've resolved the first user state (this can even be
      // no user), set loading to false
      setLoading(false)
    })
    // Return a callback to make sure we unsubscribe from listening
    // to user changes when the component unmounts to prevent memory
    // leaks
    return () => unsubscribe()
  }, [])

  const value = useMemo(() => ({ user, loading }), [user, loading])
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}
