/* eslint-disable @stylistic/max-len */
import { createSvgIcon } from '@mui/material'
import React from 'react'

export const CassIcon = createSvgIcon(
  <svg id="CassIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" stroke="currentColor" fill="none">
    <path d="M414.09,390.58c5.09,4.71,2.91,12.75-4.87,17.95-7.78,5.2-18.22,5.6-23.31.88-5.09-4.71-2.91-12.75,4.87-17.95,7.78-5.2,18.22-5.6,23.31-.88Z" />
    <path d="M298.55,304.15c26.06,20.3,52.33,40.46,77.79,61.19,2.13,1.73-1.23,7.42-2.03,11.27-5.75-.36-14.22,1.06-16.79-1.39-12.55-11.99-23.63-24.95-35.04-37.68-9.24-10.31-18.2-20.78-27.29-31.18l3.36-2.22Z" />
    <path d="M444.05,427.37c19.94,22.29,39.87,44.59,59.81,66.88l-3.21,2.16c-9.86-7.47-19.78-14.9-29.57-22.42-14.91-11.46-30.38-22.54-44.16-34.78-3.46-3.08-1.37-9.95-1.83-15.08,6.36,1.19,12.71,2.38,19.07,3.56,0,0-.1-.32-.1-.32Z" />
    <path d="M249.06,500.17c31.9-23.82,63.59-47.8,95.99-71.21,3.52-2.55,11.12-1.78,16.83-2.55-1.18,4.28-.53,10.51-3.83,12.55-35.08,21.62-70.78,42.57-106.32,63.7-1.66-1.54-1.01-.94-2.67-2.48Z" />
    <path d="M552.67,301.43c-16.79,12.6-33.6,25.19-50.37,37.8-14.49,10.9-28.43,22.3-43.7,32.48-4.66,3.11-12.91,2.88-19.52,4.17,2.28-4.87,2.77-11.62,7.13-14.32,34.24-21.25,69.22-41.72,103.97-62.43l2.49,2.29Z" />
    <path d="M299.39,524.75c16.43-24.84,32.47-49.78,49.73-74.42,2.29-3.27,10.75-5.05,16.36-7.52.82,4.11,4.53,9.27,2.02,12.15-20.79,23.88-42.73,47.34-64.33,70.93l-3.78-1.14Z" />
    <path d="M429.5,519.6c-9.34-22.5-19-44.89-27.57-67.68-.88-2.34,4.66-7.1,7.22-10.73,4.14,1.1,11.5,1.27,11.94,3.46,4.89,24.31,8.7,48.94,12.81,73.48l-4.39,1.47Z" />
    <path d="M572.81,342.05c-33.83,8.74-67.72,17.17-101.44,26.59-4.47,1.25-8.07,7.53-12.08,11.48,5.02,1.3,10.9,5.01,14.93,3.52,33.33-12.28,66.31-25.53,99.4-38.48-.5-1.91-.32-1.21-.82-3.12Z" />
    <path d="M545.37,442.18c-26.74-10.88-53.28-21.98-80.5-32.31-2.79-1.06-9.66,2.47-14.64,3.87.72,3.39-.26,9.13,2.41,9.82,29.73,7.71,60.02,14.64,90.16,21.79l2.56-3.17Z" />
    <path d="M500.61,275.25c-16.43,24.84-32.47,49.78-49.73,74.42-2.29,3.27-10.75,5.05-16.36,7.52-.82-4.11-4.53-9.27-2.02-12.15,20.79-23.88,42.73-47.34,64.33-70.93,2.32.7,1.46.44,3.78,1.14Z" />
    <path d="M370.5,280.4c9.34,22.5,19,44.89,27.57,67.68.88,2.34-4.66,7.1-7.22,10.73-4.14-1.1-11.5-1.27-11.94-3.46-4.89-24.31-8.7-48.94-12.81-73.48l4.39-1.47Z" />
    <path d="M227.19,457.95c33.83-8.74,67.72-17.17,101.44-26.59,4.47-1.25,8.07-7.53,12.08-11.48-5.02-1.3-10.9-5.01-14.93-3.52-33.33,12.28-66.31,25.53-99.4,38.48l.82,3.12Z" />
    <path d="M254.63,357.82c26.74,10.88,53.28,21.98,80.5,32.31,2.79,1.06,9.66-2.47,14.64-3.87-.72-3.39.26-9.13-2.41-9.82-29.73-7.71-60.02-14.64-90.16-21.79-1.58,1.96-.98,1.21-2.56,3.17Z" />
    <path d="M414.86,390.61c5.08,5.14,2.54,13.52-5.66,18.7-8.2,5.18-18.97,5.22-24.05.07s-2.54-13.52,5.66-18.7c8.2-5.18,18.97-5.22,24.05-.07Z" />
    <path d="M298.93,295.44c26.12,22.32,52.46,44.48,77.95,67.23,2.13,1.9-1.53,7.76-2.5,11.79-5.91-.59-14.69.59-17.25-2.08-12.5-13.07-23.45-27.11-34.74-40.93-9.15-11.18-18.01-22.53-27-33.8l3.54-2.21Z" />
    <path d="M444.4,430.41c19.74,24.19,39.48,48.37,59.21,72.56l-3.39,2.15c-9.89-8.22-19.84-16.4-29.66-24.68-14.95-12.6-30.49-24.83-44.24-38.21-3.46-3.36-1.05-10.52-1.34-15.93,6.51,1.48,13.01,2.97,19.52,4.45,0,0-.09-.34-.09-.34Z" />
    <path d="M240.89,499.83c33.72-23.88,67.24-47.95,101.46-71.39,3.72-2.55,11.52-1.47,17.43-2.07-1.37,4.46-.93,11.04-4.4,13.06-36.92,21.45-74.46,42.18-111.83,63.11l-2.67-2.71Z" />
    <path d="M560.84,301.91c-17.75,12.64-35.52,25.26-53.25,37.92-15.32,10.94-30.09,22.42-46.19,32.56-4.91,3.1-13.41,2.55-20.26,3.67,2.53-5.04,3.28-12.12,7.86-14.81,36.04-21.09,72.81-41.34,109.36-61.85l2.48,2.5Z" />
    <path d="M291.86,527.54c17.82-25.53,35.24-51.18,53.92-76.47,2.48-3.36,11.26-4.92,17.12-7.31.7,4.35,4.33,9.92,1.64,12.86-22.28,24.36-45.73,48.23-68.83,72.25l-3.85-1.34Z" />
    <path d="M426.09,526.9c-8.82-24.01-17.96-47.92-25.97-72.21-.82-2.49,5.05-7.3,7.82-11.03,4.23,1.31,11.8,1.76,12.18,4.08,4.16,25.76,7.21,51.8,10.55,77.77l-4.58,1.39Z" />
    <path d="M580.14,345.38c-35.17,7.95-70.39,15.57-105.47,24.25-4.65,1.15-8.59,7.62-12.85,11.63,5.13,1.55,11.05,5.67,15.25,4.25,34.78-11.7,69.24-24.42,103.8-36.83-.45-2.03-.28-1.28-.73-3.31Z" />
    <path d="M548.26,449.71c-27.15-12.43-54.1-25.08-81.78-36.95-2.84-1.22-10.04,2.24-15.22,3.53.62,3.6-.6,9.59,2.13,10.42,30.35,9.21,61.31,17.61,92.11,26.23l2.75-3.24Z" />
    <path d="M508.14,272.46c-17.82,25.53-35.24,51.18-53.92,76.47-2.48,3.36-11.26,4.92-17.12,7.31-.7-4.35-4.33-9.92-1.64-12.86,22.28-24.36,45.73-48.23,68.83-72.25,2.36.82,1.49.52,3.85,1.34Z" />
    <path d="M373.91,273.1c8.82,24.01,17.96,47.92,25.97,72.21.82,2.49-5.05,7.3-7.82,11.03-4.23-1.31-11.8-1.76-12.18-4.08-4.16-25.76-7.21-51.8-10.55-77.77l4.58-1.39Z" />
    <path d="M219.86,454.62c35.17-7.95,70.39-15.57,105.47-24.25,4.65-1.15,8.59-7.62,12.85-11.63-5.13-1.55-11.05-5.67-15.25-4.25-34.78,11.7-69.24,24.42-103.8,36.83l.73,3.31Z" />
    <path d="M251.74,350.29c27.15,12.43,54.1,25.08,81.78,36.95,2.84,1.22,10.04-2.24,15.22-3.53-.62-3.6.6-9.59-2.13-10.42-30.35-9.21-61.31-17.61-92.11-26.23-1.7,2-1.05,1.24-2.75,3.24Z" />
    <path d="M415.64,390.67c5.05,5.6,2.13,14.31-6.51,19.46-8.64,5.15-19.73,4.79-24.78-.81-5.05-5.6-2.13-14.31,6.51-19.46,8.64-5.15,19.73-4.79,24.78.81Z" />
    <path d="M299.63,286.29c26.11,24.44,52.45,48.72,77.89,73.59,2.13,2.08-1.86,8.11-3,12.31-6.07-.84-15.16.08-17.69-2.82-12.41-14.21-23.18-29.39-34.32-44.33-9.02-12.1-17.74-24.36-26.6-36.55l3.72-2.2Z" />
    <path d="M444.65,433.63c19.47,26.17,38.93,52.34,58.4,78.5l-3.57,2.14c-9.9-9.01-19.85-17.98-29.67-27.05-14.95-13.81-30.52-27.24-44.21-41.82-3.44-3.66-.71-11.11-.81-16.81,6.65,1.8,13.3,3.6,19.95,5.4,0,0-.09-.36-.09-.36Z" />
    <path d="M232.47,499.17c35.6-23.89,71-47.97,107.11-71.37,3.93-2.54,11.93-1.12,18.04-1.54-1.58,4.64-1.35,11.58-5.01,13.58-38.81,21.21-78.23,41.64-117.49,62.28l-2.65-2.95Z" />
    <path d="M569.24,302.72c-18.74,12.64-37.51,25.27-56.23,37.93-16.18,10.94-31.81,22.48-48.76,32.56-5.17,3.08-13.9,2.19-21,3.12,2.78-5.21,3.81-12.63,8.63-15.29,37.89-20.87,76.51-40.82,114.9-61.05l2.46,2.73Z" />
    <path d="M283.99,530.2c19.28-26.2,38.15-52.54,58.3-78.46,2.67-3.44,11.78-4.76,17.9-7.06.56,4.6,4.1,10.59,1.23,13.59-23.83,24.81-48.85,49.06-73.51,73.48l-3.92-1.55Z" />
    <path d="M422.31,534.46c-8.22-25.59-16.78-51.07-24.16-76.91-.76-2.65,5.47-7.49,8.46-11.31,4.31,1.54,12.1,2.28,12.4,4.74,3.36,27.25,5.56,54.76,8.08,82.2l-4.77,1.29Z" />
    <path d="M587.56,349.16c-36.52,7.08-73.08,13.8-109.53,21.63-4.84,1.04-9.12,7.71-13.66,11.77,5.23,1.82,11.19,6.37,15.56,5.04,36.26-11.03,72.22-23.14,108.27-34.93-.39-2.15-.25-1.36-.64-3.51Z" />
    <path d="M550.96,457.74c-27.53-14.07-54.84-28.37-82.92-41.87-2.88-1.39-10.43,1.99-15.81,3.16.51,3.81-.96,10.07,1.82,11.04,30.94,10.8,62.54,20.78,93.96,30.98l2.95-3.31Z" />
    <path d="M516.01,269.8c-19.28,26.2-38.15,52.54-58.3,78.46-2.67,3.44-11.78,4.76-17.9,7.06-.56-4.6-4.1-10.59-1.23-13.59,23.83-24.81,48.85-49.06,73.51-73.48,2.4.95,1.52.6,3.92,1.55Z" />
    <path d="M377.69,265.54c8.22,25.59,16.78,51.07,24.16,76.91.76,2.65-5.47,7.49-8.46,11.31-4.31-1.54-12.1-2.28-12.4-4.74-3.36-27.25-5.56-54.76-8.08-82.2l4.77-1.29Z" />
    <path d="M212.44,450.84c36.52-7.08,73.08-13.8,109.53-21.63,4.84-1.04,9.12-7.71,13.66-11.77-5.23-1.82-11.19-6.37-15.56-5.04-36.26,11.03-72.22,23.14-108.27,34.93l.64,3.51Z" />
    <path d="M249.04,342.26c27.53,14.07,54.84,28.37,82.92,41.87,2.88,1.39,10.43-1.99,15.81-3.16-.51-3.81.96-10.07-1.82-11.04-30.94-10.8-62.54-20.78-93.96-30.98-1.82,2.05-1.13,1.26-2.95,3.31Z" />
    <path d="M416.45,390.76c5,6.07,1.68,15.13-7.4,20.24-9.09,5.1-20.5,4.32-25.5-1.76-5-6.07-1.68-15.13,7.4-20.24,9.09-5.1,20.5-4.32,25.5,1.76Z" />
    <path d="M300.68,276.69c26.02,26.67,52.29,53.18,77.6,80.28,2.12,2.27-2.2,8.46-3.53,12.84-6.22-1.11-15.62-.47-18.13-3.62-12.27-15.41-22.83-31.76-33.77-47.9-8.86-13.06-17.4-26.28-26.09-39.43l3.92-2.17Z" />
    <path d="M444.79,437.01c19.11,28.24,38.23,56.49,57.34,84.73l-3.75,2.12c-9.87-9.84-19.81-19.65-29.6-29.55-14.91-15.07-30.47-29.78-44.05-45.62-3.41-3.98-.33-11.71-.23-17.72,6.79,2.14,13.57,4.27,20.36,6.41,0,0-.08-.38-.08-.38Z" />
    <path d="M223.83,498.18c37.54-23.82,74.88-47.86,112.92-71.15,4.14-2.53,12.33-.74,18.64-.95-1.79,4.83-1.81,12.13-5.65,14.1-40.75,20.89-82.1,40.93-123.29,61.2l-2.62-3.2Z" />
    <path d="M577.87,303.88c-19.77,12.61-39.55,25.2-59.3,37.84-17.06,10.92-33.58,22.48-51.41,32.46-5.44,3.05-14.4,1.8-21.75,2.51,3.06-5.38,4.38-13.15,9.44-15.76,39.79-20.56,80.3-40.13,120.58-60l2.44,2.96Z" />
    <path d="M275.79,532.71c20.81-26.86,41.2-53.87,62.89-80.4,2.88-3.52,12.31-4.57,18.7-6.77.42,4.86,3.85,11.29.77,14.34-25.45,25.22-52.1,49.82-78.38,74.6l-3.98-1.77Z" />
    <path d="M418.15,542.27c-7.55-27.22-15.45-54.34-22.13-81.8-.69-2.82,5.91-7.68,9.12-11.59,4.39,1.78,12.38,2.84,12.61,5.44,2.48,28.79,3.76,57.81,5.36,86.77l-4.96,1.18Z" />
    <path d="M595.07,353.41c-37.89,6.1-75.79,11.83-113.63,18.73-5.02.92-9.68,7.77-14.5,11.88,5.32,2.11,11.3,7.11,15.85,5.87,37.75-10.27,75.24-21.69,112.81-32.77-.32-2.28-.2-1.44-.53-3.71Z" />
    <path d="M553.46,466.29c-27.86-15.81-55.48-31.86-83.93-47.09-2.92-1.56-10.81,1.71-16.4,2.74.39,4.02-1.35,10.56,1.48,11.68,31.49,12.5,63.69,24.16,95.69,36.04,1.95-2.09,1.21-1.29,3.16-3.37Z" />
    <path d="M524.21,267.29c-20.81,26.86-41.2,53.87-62.89,80.4-2.88,3.52-12.31,4.57-18.7,6.77-.42-4.86-3.85-11.29-.77-14.34,25.45-25.22,52.1-49.82,78.38-74.6,2.44,1.09,1.54.69,3.98,1.77Z" />
    <path d="M381.85,257.73c7.55,27.22,15.45,54.34,22.13,81.8.69,2.82-5.91,7.68-9.12,11.59-4.39-1.78-12.38-2.84-12.61-5.44-2.48-28.79-3.76-57.81-5.36-86.77l4.96-1.18Z" />
    <path d="M204.93,446.59c37.89-6.1,75.79-11.83,113.63-18.73,5.02-.92,9.68-7.77,14.5-11.88-5.32-2.11-11.3-7.11-15.85-5.87-37.75,10.27-75.24,21.69-112.81,32.77l.53,3.71Z" />
    <path d="M246.54,333.71c27.86,15.81,55.48,31.86,83.93,47.09,2.92,1.56,10.81-1.71,16.4-2.74-.39-4.02,1.35-10.56-1.48-11.68-31.49-12.5-63.69-24.16-95.69-36.04-1.95,2.09-1.21,1.29-3.16,3.37Z" />
    <path d="M417.28,390.88c4.93,6.57,1.19,15.98-8.36,21.02-9.55,5.03-21.28,3.79-26.21-2.79s-1.19-15.98,8.36-21.02c9.55-5.03,21.28-3.79,26.21,2.79Z" />
    <path d="M302.1,266.63c25.85,29.01,51.96,57.87,77.06,87.3,2.1,2.46-2.57,8.82-4.1,13.37-6.37-1.39-16.08-1.07-18.55-4.47-12.09-16.66-22.38-34.25-33.07-51.63-8.66-14.07-16.99-28.28-25.46-42.43l4.11-2.14Z" />
    <path d="M444.82,440.58c18.68,30.41,37.35,60.83,56.03,91.24l-3.95,2.09c-9.82-10.72-19.7-21.4-29.43-32.17-14.82-16.4-30.32-32.44-43.74-49.61-3.37-4.31.08-12.33.4-18.64,6.91,2.5,13.83,4.99,20.74,7.49,0,0-.06-.4-.06-.4Z" />
    <path d="M214.96,496.81c39.54-23.68,78.87-47.59,118.9-70.7,4.35-2.51,12.73-.33,19.24-.32-2.02,5.01-2.3,12.69-6.32,14.62-42.73,20.48-86.06,40.04-129.23,59.85l-2.59-3.46Z" />
    <path d="M586.72,305.42c-20.82,12.54-41.66,25.06-62.46,37.63-17.97,10.86-35.41,22.41-54.13,32.26-5.72,3.01-14.9,1.36-22.5,1.84,3.34-5.55,4.99-13.67,10.29-16.24,41.73-20.17,84.17-39.26,126.39-58.69l2.41,3.2Z" />
    <path d="M267.25,535.05c22.4-27.49,44.39-55.16,67.69-82.27,3.09-3.6,12.84-4.36,19.51-6.43.25,5.13,3.56,12.02.28,15.11-27.13,25.6-55.47,50.49-83.44,75.6l-4.04-2.01Z" />
    <path d="M413.58,550.33c-6.8-28.91-13.96-57.73-19.86-86.87-.6-2.99,6.36-7.86,9.81-11.86,4.46,2.03,12.65,3.45,12.79,6.18,1.52,30.38,1.79,60.95,2.4,91.48l-5.15,1.06Z" />
    <path d="M602.66,358.15c-39.25,5.03-78.52,9.66-117.75,15.53-5.21.78-10.25,7.82-15.37,11.96,5.4,2.41,11.38,7.89,16.12,6.76,39.26-9.42,78.3-20.06,117.4-30.33-.25-2.41-.16-1.52-.41-3.92Z" />
    <path d="M555.72,475.37c-28.13-17.66-56.01-35.55-84.78-52.63-2.95-1.75-11.2,1.4-17,2.28.25,4.25-1.77,11.06,1.11,12.35,31.99,14.3,64.75,27.75,97.29,41.43,2.09-2.12,1.29-1.31,3.37-3.43Z" />
    <path d="M532.75,264.95c-22.4,27.49-44.39,55.16-67.69,82.27-3.09,3.6-12.84,4.36-19.51,6.43-.25-5.13-3.56-12.02-.28-15.11,27.13-25.6,55.47-50.49,83.44-75.6,2.48,1.23,1.56.78,4.04,2.01Z" />
    <path d="M386.42,249.67c6.8,28.91,13.96,57.73,19.86,86.87.6,2.99-6.36,7.86-9.81,11.86-4.46-2.03-12.65-3.45-12.79-6.18-1.52-30.38-1.79-60.95-2.4-91.48l5.15-1.06Z" />
    <path d="M197.34,441.85c39.25-5.03,78.52-9.66,117.75-15.53,5.21-.78,10.25-7.82,15.37-11.96-5.4-2.41-11.38-7.89-16.12-6.76-39.26,9.42-78.3,20.06-117.4,30.33l.41,3.92Z" />
    <path d="M244.28,324.63c28.13,17.66,56.01,35.55,84.78,52.63,2.95,1.75,11.2-1.4,17-2.28-.25-4.25,1.77-11.06-1.11-12.35-31.99-14.3-64.75-27.75-97.29-41.43-2.09,2.12-1.29,1.31-3.37,3.43Z" />
    <path d="M418.14,391.05c4.84,7.1.65,16.86-9.37,21.8-10.02,4.95-22.06,3.2-26.91-3.89-4.84-7.1-.65-16.86,9.37-21.8,10.02-4.95,22.06-3.2,26.91,3.89Z" />
    <path d="M303.94,256.1c25.59,31.47,51.45,62.78,76.26,94.67,2.07,2.67-2.97,9.19-4.71,13.92-6.51-1.7-16.52-1.71-18.95-5.38-11.86-17.97-21.82-36.86-32.21-55.53-8.41-15.12-16.48-30.38-24.71-45.58l4.31-2.1Z" />
    <path d="M444.72,444.34c18.15,32.68,36.3,65.36,54.45,98.04l-4.14,2.06c-9.73-11.64-19.53-23.23-29.16-34.92-14.68-17.8-30.07-35.24-43.28-53.8-3.32-4.66.53-12.97,1.09-19.6,7.03,2.88,14.07,5.76,21.1,8.64,0,0-.05-.42-.05-.42Z" />
    <path d="M205.87,495.04c41.59-23.46,82.97-47.17,125.05-70,4.57-2.48,13.13.12,19.83.37-2.26,5.2-2.83,13.27-7.04,15.15-44.76,19.97-90.11,38.96-135.29,58.22l-2.54-3.74Z" />
    <path d="M595.78,307.36c-21.9,12.43-43.82,24.83-65.7,37.29-18.91,10.76-37.29,22.28-56.93,31.95-6,2.95-15.41.88-23.25,1.11,3.64-5.71,5.63-14.2,11.19-16.7,43.72-19.68,88.13-38.21,132.33-57.1l2.37,3.46Z" />
    <path d="M258.37,537.19c24.07-28.1,47.71-56.4,72.7-84.06,3.32-3.67,13.39-4.11,20.33-6.05.08,5.41,3.23,12.78-.25,15.91-28.87,25.93-58.97,51.08-88.69,76.46l-4.09-2.26Z" />
    <path d="M408.59,558.65c-5.97-30.66-12.31-61.25-17.33-92.11-.52-3.17,6.84-8.03,10.54-12.11,4.52,2.3,12.91,4.09,12.96,6.97.47,32.01-.35,64.19-.82,96.32l-5.35.93Z" />
    <path d="M610.3,363.42c-40.62,3.85-81.24,7.28-121.87,12.02-5.39.63-10.84,7.85-16.26,12.02,5.48,2.74,11.44,8.72,16.37,7.7,40.79-8.46,81.39-18.22,122.05-27.59-.17-2.54-.11-1.6-.28-4.14Z" />
    <path d="M557.73,485.01c-28.35-19.61-56.43-39.46-85.46-58.48-2.98-1.95-11.59,1.07-17.59,1.78.11,4.48-2.23,11.57.7,13.03,32.45,16.22,65.71,31.57,98.75,47.16,2.23-2.16,1.37-1.33,3.6-3.49Z" />
    <path d="M541.63,262.81c-24.07,28.1-47.71,56.4-72.7,84.06-3.32,3.67-13.39,4.11-20.33,6.05-.08-5.41-3.23-12.78.25-15.91,28.87-25.93,58.97-51.08,88.69-76.46,2.51,1.39,1.58.88,4.09,2.26Z" />
    <path d="M391.41,241.35c5.97,30.66,12.31,61.25,17.33,92.11.52,3.17-6.84,8.03-10.54,12.11-4.52-2.3-12.91-4.09-12.96-6.97-.47-32.01.35-64.19.82-96.32l5.35-.93Z" />
    <path d="M189.7,436.58c40.62-3.85,81.24-7.28,121.87-12.02,5.39-.63,10.84-7.85,16.26-12.02-5.48-2.74-11.44-8.72-16.37-7.7-40.79,8.46-81.39,18.22-122.05,27.59l.28,4.14Z" />
    <path d="M242.27,314.99c28.35,19.61,56.43,39.46,85.46,58.48,2.98,1.95,11.59-1.07,17.59-1.78-.11-4.48,2.23-11.57-.7-13.03-32.45-16.22-65.71-31.57-98.75-47.16-2.23,2.16-1.37,1.33-3.6,3.49Z" />
    <path d="M419.01,391.25c4.74,7.64.06,17.76-10.43,22.59-10.5,4.83-22.85,2.56-27.58-5.09-4.74-7.64-.06-17.76,10.43-22.59,10.5-4.83,22.85-2.56,27.58,5.09Z" />
    <path d="M306.2,245.09c25.23,34.04,50.75,67.94,75.16,102.39,2.04,2.88-3.39,9.56-5.35,14.47-6.65-2.03-16.96-2.41-19.33-6.36-11.57-19.34-21.16-39.58-31.19-59.6-8.12-16.21-15.89-32.56-23.81-48.85l4.52-2.06Z" />
    <path d="M444.48,448.29c17.52,35.05,35.05,70.09,52.57,105.14l-4.34,2.01c-9.6-12.6-19.28-25.16-28.79-37.81-14.48-19.27-29.72-38.18-42.65-58.18-3.25-5.03,1.01-13.62,1.82-20.58,7.14,3.29,14.29,6.58,21.43,9.87,0,0-.04-.45-.04-.45Z" />
    <path d="M196.57,492.85c43.69-23.15,87.18-46.57,131.35-69.05,4.8-2.44,13.52.61,20.42,1.12-2.52,5.38-3.39,13.86-7.8,15.68-46.84,19.36-94.24,37.67-141.49,56.27l-2.48-4.02Z" />
    <path d="M605.04,309.73c-23.01,12.27-46.04,24.52-69.03,36.81-19.87,10.63-39.22,22.07-59.81,31.52-6.29,2.88-15.9.36-24,.32,3.96-5.88,6.31-14.73,12.13-17.16,45.76-19.1,92.18-36.96,138.4-55.21l2.31,3.72Z" />
    <path d="M249.15,539.12c25.81-28.67,51.19-57.58,77.93-85.76,3.55-3.74,13.94-3.84,21.17-5.62-.11,5.69,2.87,13.56-.83,16.73-30.68,26.22-62.59,51.57-94.13,77.18l-4.13-2.53Z" />
    <path d="M403.14,567.21c-5.04-32.48-10.48-64.88-14.54-97.54-.42-3.35,7.33-8.2,11.29-12.36,4.57,2.58,13.16,4.78,13.1,7.81-.66,33.69-2.67,67.51-4.31,101.3l-5.54.78Z" />
    <path d="M617.99,369.25c-41.99,2.55-83.97,4.67-126,8.16-5.58.46-11.45,7.86-17.19,12.04,5.55,3.08,11.48,9.6,16.59,8.7,42.33-7.41,84.51-16.18,126.74-24.54-.09-2.68-.05-1.69-.14-4.37Z" />
    <path d="M559.45,495.22c-28.5-21.67-56.72-43.58-85.94-64.66-3-2.16-11.98.69-18.19,1.22-.05,4.71-2.71,12.09.25,13.73,32.85,18.26,66.57,35.63,100.05,53.24l3.84-3.54Z" />
    <path d="M550.85,260.88c-25.81,28.67-51.19,57.58-77.93,85.76-3.55,3.74-13.94,3.84-21.17,5.62.11-5.69-2.87-13.56.83-16.73,30.68-26.22,62.59-51.57,94.13-77.18,2.53,1.55,1.6.98,4.13,2.53Z" />
    <path d="M396.86,232.79c5.04,32.48,10.48,64.88,14.54,97.54.42,3.35-7.33,8.2-11.29,12.36-4.57-2.58-13.16-4.78-13.1-7.81.66-33.69,2.67-67.51,4.31-101.3l5.54-.78Z" />
    <path d="M182.01,430.75c41.99-2.55,83.97-4.67,126-8.16,5.58-.46,11.45-7.86,17.19-12.04-5.55-3.08-11.48-9.6-16.59-8.7-42.33,7.41-84.51,16.18-126.74,24.54l.14,4.37Z" />
    <path d="M240.55,304.78c28.5,21.67,56.72,43.58,85.94,64.66,3,2.16,11.98-.69,18.19-1.22.05-4.71,2.71-12.09-.25-13.73-32.85-18.26-66.57-35.63-100.05-53.24-2.37,2.19-1.46,1.35-3.84,3.54Z" />
    <path d="M419.9,391.49c4.6,8.21-.57,18.68-11.56,23.38-10.99,4.7-23.63,1.85-28.23-6.36-4.6-8.21.57-18.68,11.56-23.38,10.99-4.7,23.63-1.85,28.23,6.36Z" />
    <path d="M308.94,233.59c24.77,36.74,49.84,73.33,73.76,110.48,2,3.11-3.84,9.93-6.03,15.02-6.78-2.38-17.39-3.16-19.69-7.4-11.23-20.77-20.37-42.41-29.99-63.84-7.79-17.35-15.2-34.84-22.78-52.27l4.73-2Z" />
    <path d="M444.09,452.43c16.79,37.51,33.59,75.03,50.38,112.54l-4.54,1.96c-9.44-13.61-18.96-27.18-28.3-40.83-14.23-20.8-29.24-41.26-41.85-62.78-3.17-5.41,1.53-14.29,2.62-21.58,7.24,3.72,14.48,7.45,21.73,11.17,0,0-.02-.47-.02-.47Z" />
    <path d="M187.06,490.21c45.85-22.75,91.5-45.79,137.82-67.81,5.04-2.39,13.91,1.14,21,1.93-2.79,5.57-3.99,14.45-8.6,16.21-48.96,18.65-98.45,36.17-147.8,54l-2.41-4.32Z" />
    <path d="M614.5,312.57c-24.15,12.06-48.32,24.1-72.45,36.19-20.85,10.45-41.2,21.77-62.76,30.96-6.58,2.8-16.4-.2-24.73-.55,4.29-6.04,7.03-15.27,13.12-17.61,47.83-18.41,96.3-35.5,144.58-53,1.4,2.48.85,1.52,2.25,4Z" />
    <path d="M239.57,540.81c27.63-29.22,54.81-58.69,83.38-87.35,3.79-3.8,14.5-3.52,22.01-5.13-.32,5.98,2.47,14.37-1.46,17.56-32.55,26.46-66.34,51.95-99.76,77.74l-4.17-2.81Z" />
    <path d="M397.22,576.02c-4.03-34.35-8.46-68.64-11.47-103.14-.31-3.54,7.85-8.36,12.08-12.58,4.62,2.89,13.38,5.51,13.21,8.7-1.9,35.42-5.18,70.93-8.08,106.41l-5.74.62Z" />
    <path d="M625.7,375.66c-43.36,1.14-86.68,1.83-130.11,3.96-5.76.28-12.08,7.85-18.14,12.04,5.61,3.45,11.48,10.52,16.78,9.76,43.88-6.23,87.66-13.92,131.47-21.16,0-2.82,0-1.78.01-4.6Z" />
    <path d="M560.85,506.03c-28.59-23.85-56.87-47.93-86.22-71.17-3.01-2.39-12.37.29-18.79.62-.22,4.96-3.23,12.62-.24,14.45,33.19,20.41,67.3,39.93,101.16,59.68l4.08-3.58Z" />
    <path d="M560.43,259.19c-27.63,29.22-54.81,58.69-83.38,87.35-3.79,3.8-14.5,3.52-22.01,5.13.32-5.98-2.47-14.37,1.46-17.56,32.55-26.46,66.34-51.95,99.76-77.74,2.55,1.72,1.61,1.09,4.17,2.81Z" />
    <path d="M402.78,223.98c4.03,34.35,8.46,68.64,11.47,103.14.31,3.54-7.85,8.36-12.08,12.58-4.62-2.89-13.38-5.51-13.21-8.7,1.9-35.42,5.18-70.93,8.08-106.41l5.74-.62Z" />
    <path d="M174.3,424.34c43.36-1.14,86.68-1.83,130.11-3.96,5.76-.28,12.08-7.85,18.14-12.04-5.61-3.45-11.48-10.52-16.78-9.76-43.88,6.23-87.66,13.92-131.47,21.16,0,2.82,0,1.78-.01,4.6Z" />
    <path d="M239.15,293.97c28.59,23.85,56.87,47.93,86.22,71.17,3.01,2.39,12.37-.29,18.79-.62.22-4.96,3.23-12.62.24-14.45-33.19-20.41-67.3-39.93-101.16-59.68-2.52,2.21-1.56,1.37-4.08,3.58Z" />
    <path d="M420.81,391.78c4.45,8.81-1.26,19.63-12.75,24.18s-24.41,1.08-28.86-7.73c-4.45-8.81,1.26-19.63,12.75-24.18,11.49-4.54,24.41-1.08,28.86,7.73Z" />
    <path d="M312.16,221.59c24.2,39.56,48.71,78.98,72.02,118.93,1.95,3.34-4.31,10.3-6.76,15.58-6.9-2.75-17.8-3.96-20.02-8.51-10.82-22.26-19.46-45.36-28.6-68.26-7.4-18.54-14.41-37.21-21.59-55.82l4.95-1.93Z" />
    <path d="M443.54,456.78c15.95,40.08,31.9,80.16,47.86,120.24l-4.75,1.89c-9.24-14.66-18.56-29.29-27.69-44-13.91-22.4-28.64-44.48-40.86-67.58-3.07-5.8,2.09-14.98,3.47-22.61,7.33,4.18,14.66,8.36,21.98,12.55,0,0,0-.5,0-.5Z" />
    <path d="M177.37,487.08c48.05-22.25,95.92-44.81,144.43-66.28,5.27-2.33,14.29,1.71,21.56,2.8-3.07,5.76-4.63,15.06-9.44,16.73-51.11,17.82-102.74,34.43-154.22,51.38l-2.33-4.64Z" />
    <path d="M624.15,315.91c-25.32,11.8-50.65,23.58-75.95,35.41-21.86,10.23-43.24,21.39-65.77,30.26-6.88,2.71-16.89-.82-25.46-1.49,4.64-6.19,7.8-15.8,14.15-18.04,49.94-17.61,100.5-33.8,150.86-50.44l2.17,4.29Z" />
    <path d="M229.64,542.24c29.51-29.72,58.58-59.73,89.04-88.83,4.04-3.86,15.07-3.17,22.86-4.59-.55,6.28,2.03,15.21-2.14,18.42-34.49,26.64-70.22,52.21-105.58,78.11l-4.19-3.11Z" />
    <path d="M390.8,585.07c-2.91-36.28-6.25-72.52-8.11-108.93-.19-3.74,8.39-8.5,12.9-12.79,4.65,3.21,13.59,6.29,13.3,9.64-3.23,37.19-7.88,74.42-12.15,111.64l-5.93.44Z" />
    <path d="M633.42,382.68c-44.71-.39-89.37-1.26-134.2-.61-5.95.09-12.73,7.81-19.12,12,5.65,3.83,11.45,11.49,16.93,10.89,45.44-4.95,90.81-11.42,136.21-17.43.11-2.97.07-1.87.18-4.84Z" />
    <path d="M561.91,517.45c-28.6-26.14-56.87-52.52-86.27-78.04-3.02-2.62-12.76-.15-19.38-.04-.41,5.21-3.78,13.15-.77,15.2,33.46,22.69,67.9,44.48,102.08,66.5l4.33-3.62Z" />
    <path d="M570.36,257.76c-29.51,29.72-58.58,59.73-89.04,88.83-4.04,3.86-15.07,3.17-22.86,4.59.55-6.28-2.03-15.21,2.14-18.42,34.49-26.64,70.22-52.21,105.58-78.11,2.57,1.91,1.62,1.21,4.19,3.11Z" />
    <path d="M409.2,214.93c2.91,36.28,6.25,72.52,8.11,108.93.19,3.74-8.39,8.5-12.9,12.79-4.65-3.21-13.59-6.29-13.3-9.64,3.23-37.19,7.88-74.42,12.15-111.64l5.93-.44Z" />
    <path d="M166.58,417.32c44.71.39,89.37,1.26,134.2.61,5.95-.09,12.73-7.81,19.12-12-5.65-3.83-11.45-11.49-16.93-10.89-45.44,4.95-90.81,11.42-136.21,17.43-.11,2.97-.07,1.87-.18,4.84Z" />
    <path d="M238.09,282.55c28.6,26.14,56.87,52.52,86.27,78.04,3.02,2.62,12.76.15,19.38.04.41-5.21,3.78-13.15.77-15.2-33.46-22.69-67.9-44.48-102.08-66.5-2.68,2.24-1.65,1.38-4.33,3.62Z" />
    <path d="M421.73,392.11c4.27,9.43-2.01,20.61-14.01,24.96s-25.19.24-29.46-9.19c-4.27-9.43,2.01-20.61,14.01-24.96,12-4.35,25.19-.24,29.46,9.19Z" />
    <path d="M315.92,209.09c23.51,42.5,47.35,84.87,69.92,127.76,1.89,3.59-4.81,10.68-7.52,16.15-7.01-3.15-18.2-4.82-20.32-9.69-10.35-23.81-18.42-48.44-27.01-72.86-6.96-19.78-13.5-39.67-20.23-59.52l5.17-1.84Z" />
    <path d="M442.81,461.33c14.99,42.75,29.99,85.5,44.98,128.25l-4.96,1.82c-8.99-15.76-18.07-31.49-26.95-47.3-13.53-24.08-27.91-47.84-39.67-72.59-2.95-6.22,2.7-15.68,4.39-23.66,7.4,4.67,14.8,9.34,22.2,14.01,0,0,.01-.52.01-.52Z" />
    <path d="M167.49,483.42c50.31-21.64,100.44-43.62,151.19-64.42,5.52-2.26,14.66,2.33,22.12,3.74-3.37,5.95-5.31,15.67-10.33,17.26-53.3,16.87-107.09,32.45-160.74,48.38l-2.24-4.96Z" />
    <path d="M633.97,319.77c-26.51,11.48-53.03,22.94-79.52,34.46-22.89,9.96-45.32,20.92-68.85,29.42-7.19,2.6-17.37-1.48-26.18-2.5,5-6.35,8.6-16.34,15.22-18.46,52.09-16.69,104.76-31.87,157.25-47.52l2.08,4.6Z" />
    <path d="M219.36,543.38c31.48-30.18,62.51-60.68,94.94-90.18,4.3-3.91,15.64-2.79,23.72-3.99-.79,6.59,1.54,16.07-2.86,19.3-36.49,26.75-74.23,52.35-111.59,78.3l-4.21-3.43Z" />
    <path d="M383.86,594.35c-1.7-38.28-3.83-76.52-4.44-114.89-.06-3.94,8.95-8.64,13.75-12.99,4.68,3.54,13.78,7.12,13.36,10.63-4.66,39.01-10.8,78-16.54,117l-6.13.24Z" />
    <path d="M641.11,390.36c-46.05-2.05-92.03-4.61-138.24-5.58-6.13-.13-13.4,7.75-20.14,11.92,5.69,4.24,11.38,12.5,17.05,12.08,46.99-3.54,93.98-8.68,140.96-13.33.22-3.12.14-1.97.36-5.09Z" />
    <path d="M562.59,529.51c-28.52-28.55-56.7-57.34-86.08-85.27-3.01-2.87-13.14-.63-19.96-.75-.61,5.46-4.37,13.7-1.34,15.96,33.65,25.1,68.36,49.28,102.78,73.71l4.59-3.65Z" />
    <path d="M580.64,256.62c-31.48,30.18-62.51,60.68-94.94,90.18-4.3,3.91-15.64,2.79-23.72,3.99.79-6.59-1.54-16.07,2.86-19.3,36.49-26.75,74.23-52.35,111.59-78.3l4.21,3.43Z" />
    <path d="M416.14,205.65c1.7,38.28,3.83,76.52,4.44,114.89.06,3.94-8.95,8.64-13.75,12.99-4.68-3.54-13.78-7.12-13.36-10.63,4.66-39.01,10.8-78,16.54-117l6.13-.24Z" />
    <path d="M158.89,409.64c46.05,2.05,92.03,4.61,138.24,5.58,6.13.13,13.4-7.75,20.14-11.92-5.69-4.24-11.38-12.5-17.05-12.08-46.99,3.54-93.98,8.68-140.96,13.33l-.36,5.09Z" />
    <path d="M237.41,270.49c28.52,28.55,56.7,57.34,86.08,85.27,3.01,2.87,13.14.63,19.96.75.61-5.46,4.37-13.7,1.34-15.96-33.65-25.1-68.36-49.28-102.78-73.71-2.84,2.25-1.75,1.39-4.59,3.65Z" />
    <path d="M422.68,392.5c4.06,10.08-2.81,21.61-15.33,25.75s-25.96-.67-30.02-10.75c-4.06-10.08,2.81-21.61,15.33-25.75s25.96.67,30.02,10.75Z" />
    <path d="M320.24,196.08c22.69,45.58,45.73,91.03,67.44,136.97,1.82,3.84-5.34,11.06-8.33,16.71-7.11-3.57-18.58-5.74-20.58-10.94-9.8-25.43-17.24-51.63-25.21-77.64-6.46-21.06-12.49-42.23-18.71-63.35l5.39-1.75Z" />
    <path d="M441.9,466.09c13.91,45.52,27.82,91.05,41.73,136.57l-5.18,1.73c-8.7-16.91-17.48-33.79-26.06-50.75-13.07-25.83-27.04-51.35-38.26-77.82-2.82-6.65,3.34-16.4,5.38-24.72,7.46,5.18,14.91,10.37,22.37,15.55,0,0,.03-.55.03-.55Z" />
    <path d="M157.44,479.22c52.61-20.91,105.05-42.2,158.09-62.21,5.77-2.18,15.02,2.99,22.65,4.74-3.69,6.13-6.04,16.29-11.27,17.78-55.52,15.79-111.5,30.2-167.35,45l-2.13-5.3Z" />
    <path d="M643.94,324.19c-27.73,11.11-55.47,22.19-83.17,33.33-23.94,9.63-47.44,20.34-72,28.42-7.5,2.47-17.84-2.19-26.89-3.59,5.38-6.49,9.45-16.87,16.35-18.86,54.27-15.65,109.08-29.68,163.72-44.21,1.23,3.05.75,1.86,1.98,4.91Z" />
    <path d="M208.73,544.19c33.52-30.59,66.59-61.54,101.06-91.38,4.57-3.96,16.22-2.36,24.58-3.33-1.05,6.9,1.01,16.97-3.65,20.2-38.56,26.8-78.36,52.34-117.79,78.27l-4.21-3.76Z" />
    <path d="M376.38,603.87c-.37-40.33-1.19-80.64-.44-121.03.08-4.14,9.53-8.76,14.63-13.16,4.69,3.9,13.94,7.99,13.38,11.67-6.21,40.86-13.93,81.66-21.25,122.48l-6.32.03Z" />
    <path d="M648.77,398.71c-47.37-3.85-94.66-8.23-142.23-10.94-6.31-.36-14.08,7.66-21.17,11.8,5.71,4.67,11.28,13.57,17.14,13.33,48.54-1.99,97.14-5.68,145.72-8.85.34-3.27.21-2.07.55-5.34Z" />
    <path d="M562.86,542.21c-28.36-31.08-56.36-62.4-85.62-92.86-3-3.13-13.51-1.14-20.54-1.53-.82,5.72-4.99,14.25-1.95,16.74,33.77,27.65,68.66,54.36,103.24,81.32l4.86-3.67Z" />
    <path d="M591.27,255.81c-33.52,30.59-66.59,61.54-101.06,91.38-4.57,3.96-16.22,2.36-24.58,3.33,1.05-6.9-1.01-16.97,3.65-20.2,38.56-26.8,78.36-52.34,117.79-78.27l4.21,3.76Z" />
    <path d="M423.62,196.13c.37,40.33,1.19,80.64.44,121.03-.08,4.14-9.53,8.76-14.63,13.16-4.69-3.9-13.94-7.99-13.38-11.67,6.21-40.86,13.93-81.66,21.25-122.48l6.32-.03Z" />
    <path d="M151.23,401.29c47.37,3.85,94.66,8.23,142.23,10.94,6.31.36,14.08-7.66,21.17-11.8-5.71-4.67-11.28-13.57-17.14-13.33-48.54,1.99-97.14,5.68-145.72,8.85l-.55,5.34Z" />
    <path d="M237.14,257.79c28.36,31.08,56.36,62.4,85.62,92.86,3,3.13,13.51,1.14,20.54,1.53.82-5.72,4.99-14.25,1.95-16.74-33.77-27.65-68.66-54.36-103.24-81.32-3.01,2.27-1.86,1.4-4.86,3.67Z" />
    <path d="M423.63,392.95c3.82,10.75-3.67,22.63-16.72,26.52s-26.73-1.66-30.54-12.41c-3.82-10.75,3.67-22.63,16.72-26.52,13.05-3.9,26.73,1.66,30.54,12.41Z" />
    <path d="M325.16,182.54c21.74,48.78,43.84,97.44,64.56,146.57,1.73,4.11-5.9,11.44-9.19,17.27-7.19-4.01-18.94-6.72-20.81-12.26-9.19-27.12-15.9-54.95-23.18-82.6-5.89-22.39-11.35-44.89-16.99-67.34l5.62-1.64Z" />
    <path d="M440.8,471.07c12.69,48.4,25.39,96.8,38.08,145.2l-5.4,1.63c-8.35-18.11-16.8-36.19-25.03-54.34-12.54-27.65-26.01-55.02-36.62-83.27-2.67-7.1,4.03-17.13,6.43-25.81,7.49,5.73,14.99,11.45,22.48,17.18,0,0,.05-.58.05-.58Z" />
    <path d="M147.24,474.43c54.96-20.07,109.75-40.53,165.12-59.64,6.02-2.08,15.37,3.69,23.17,5.82-4.02,6.31-6.81,16.91-12.25,18.29-57.77,14.57-115.97,27.68-174.04,41.19-1.24-3.52-.76-2.14-2-5.66Z" />
    <path d="M654.06,329.21c-28.97,10.67-57.95,21.3-86.89,32.01-25.01,9.25-49.61,19.65-75.2,27.25-7.81,2.32-18.31-2.96-27.57-4.76,5.78-6.63,10.34-17.4,17.52-19.24,56.48-14.47,113.45-27.21,170.27-40.5,1.16,3.25.71,1.99,1.87,5.24Z" />
    <path d="M197.75,544.66c35.63-30.95,70.82-62.29,107.41-92.42,4.86-4,16.79-1.88,25.45-2.6-1.33,7.22.43,17.88-4.48,21.12-40.69,26.78-82.62,52.19-124.17,78.01l-4.2-4.11Z" />
    <path d="M368.33,613.6c1.07-42.44,1.67-84.87,3.9-127.33.23-4.36,10.13-8.86,15.55-13.3,4.69,4.28,14.07,8.92,13.37,12.77-7.87,42.76-17.29,85.4-26.3,128.06l-6.52-.2Z" />
    <path d="M656.35,407.79c-48.67-5.79-97.23-12.14-146.14-16.74-6.49-.61-14.78,7.54-22.24,11.64,5.71,5.12,11.13,14.69,17.18,14.65,50.08-.31,100.28-2.4,150.45-3.96.47-3.43.29-2.17.76-5.59Z" />
    <path d="M562.68,555.59c-28.1-33.73-55.82-67.71-84.87-100.84-2.98-3.4-13.88-1.7-21.11-2.36-1.05,5.99-5.65,14.81-2.61,17.54,33.8,30.32,68.78,59.7,103.45,89.34l5.14-3.68Z" />
    <path d="M602.25,255.34c-35.63,30.95-70.82,62.29-107.41,92.42-4.86,4-16.79,1.88-25.45,2.6,1.33-7.22-.43-17.88,4.48-21.12,40.69-26.78,82.62-52.19,124.17-78.01,2.58,2.52,1.63,1.59,4.2,4.11Z" />
    <path d="M431.67,186.4c-1.07,42.44-1.67,84.87-3.9,127.33-.23,4.36-10.13,8.86-15.55,13.3-4.69-4.28-14.07-8.92-13.37-12.77,7.87-42.76,17.29-85.4,26.3-128.06,4.03.12,2.49.08,6.52.2Z" />
    <path d="M143.65,392.21c48.67,5.79,97.23,12.14,146.14,16.74,6.49.61,14.78-7.54,22.24-11.64-5.71-5.12-11.13-14.69-17.18-14.65-50.08.31-100.28,2.4-150.45,3.96l-.76,5.59Z" />
    <path d="M237.32,244.41c28.1,33.73,55.82,67.71,84.87,100.84,2.98,3.4,13.88,1.7,21.11,2.36,1.05-5.99,5.65-14.81,2.61-17.54-33.8-30.32-68.78-59.7-103.45-89.34-3.18,2.27-1.96,1.4-5.14,3.68Z" />
    <path d="M424.6,393.45c3.54,11.45-4.6,23.67-18.19,27.29s-27.48-2.73-31.02-14.18c-3.54-11.45,4.6-23.67,18.19-27.29,13.59-3.62,27.48,2.73,31.02,14.18Z" />
    <path d="M330.72,168.49c20.65,52.12,41.66,104.11,61.24,156.56,1.64,4.39-6.49,11.82-10.08,17.83-7.27-4.49-19.27-7.76-21-13.66-8.49-28.86-14.41-58.39-20.91-87.75-5.27-23.77-10.07-47.64-15.09-71.46l5.84-1.52Z" />
    <path d="M439.47,476.26c11.34,51.38,22.67,102.77,34.01,154.15l-5.62,1.51c-7.95-19.36-16-38.69-23.83-58.09-11.92-29.55-24.82-58.83-34.73-88.95-2.49-7.57,4.77-17.87,7.55-26.92,7.52,6.3,15.03,12.6,22.55,18.9,0,0,.07-.61.07-.61Z" />
    <path d="M136.9,469.01c57.35-19.09,114.54-38.61,172.27-56.67,6.28-1.96,15.7,4.45,23.66,6.98-4.37,6.5-7.62,17.54-13.28,18.79-60.05,13.21-120.48,24.86-180.8,36.93l-1.86-6.02Z" />
    <path d="M664.31,334.87c-30.23,10.16-60.47,20.28-90.68,30.48-26.1,8.82-51.82,18.85-78.46,25.9-8.13,2.15-18.75-3.79-28.23-6.02,6.19-6.76,11.28-17.92,18.75-19.6,58.71-13.14,117.87-24.46,176.89-36.35,1.08,3.46.66,2.12,1.73,5.58Z" />
    <path d="M186.41,544.76c37.83-31.25,75.2-62.93,113.99-93.28,5.15-4.03,17.37-1.36,26.31-1.8-1.63,7.55-.2,18.83-5.38,22.05-42.89,26.68-87,51.87-130.74,77.5l-4.18-4.48Z" />
    <path d="M359.69,623.54c2.63-44.61,4.78-89.23,8.6-133.81.39-4.58,10.76-8.95,16.5-13.42,4.68,4.67,14.18,9.9,13.31,13.92-9.64,44.69-20.89,89.2-31.7,133.76l-6.71-.45Z" />
    <path d="M663.84,417.61c-49.94-7.88-99.74-16.34-149.97-22.98-6.67-.88-15.5,7.39-23.33,11.42,5.7,5.59,10.94,15.86,17.17,16.05,51.61,1.51,103.41,1.16,155.15,1.36.6-3.59.38-2.27.98-5.86Z" />
    <path d="M562.01,569.66c-27.74-36.52-55.08-73.28-83.81-109.2-2.95-3.69-14.24-2.29-21.66-3.26-1.3,6.26-6.36,15.37-3.32,18.35,33.73,33.14,68.72,65.33,103.37,97.78l5.43-3.68Z" />
    <path d="M613.59,255.24c-37.83,31.25-75.2,62.93-113.99,93.28-5.15,4.03-17.37,1.36-26.31,1.8,1.63-7.55.2-18.83,5.38-22.05,42.89-26.68,87-51.87,130.74-77.5,2.56,2.75,1.62,1.73,4.18,4.48Z" />
    <path d="M440.31,176.46c-2.63,44.61-4.78,89.23-8.6,133.81-.39,4.58-10.76,8.95-16.5,13.42-4.68-4.67-14.18-9.9-13.31-13.92,9.64-44.69,20.89-89.2,31.7-133.76,4.15.28,2.56.17,6.71.45Z" />
    <path d="M136.16,382.39c49.94,7.88,99.74,16.34,149.97,22.98,6.67.88,15.5-7.39,23.33-11.42-5.7-5.59-10.94-15.86-17.17-16.05-51.61-1.51-103.41-1.16-155.15-1.36l-.98,5.86Z" />
    <path d="M237.99,230.34c27.74,36.52,55.08,73.28,83.81,109.2,2.95,3.69,14.24,2.29,21.66,3.26,1.3-6.26,6.36-15.37,3.32-18.35-33.73-33.14-68.72-65.33-103.37-97.78-3.36,2.28-2.07,1.41-5.43,3.68Z" />
    <path d="M425.58,394.01c3.24,12.18-5.59,24.73-19.72,28.04-14.13,3.31-28.21-3.88-31.45-16.06s5.59-24.73,19.72-28.04c14.13-3.31,28.21,3.88,31.45,16.06Z" />
    <path d="M336.95,153.91c19.4,55.58,39.18,111.06,57.46,166.95,1.53,4.67-7.11,12.19-11.03,18.39-7.33-4.99-19.57-8.88-21.15-15.15-7.71-30.68-12.74-61.96-18.39-93.08-4.57-25.2-8.67-50.48-12.97-75.73l6.08-1.39Z" />
    <path d="M437.93,481.68c9.83,54.47,19.66,108.94,29.49,163.41l-5.84,1.39c-7.5-20.66-15.1-41.29-22.46-61.99-11.22-31.52-23.45-62.8-32.58-94.85-2.3-8.05,5.56-18.62,8.75-28.04,7.52,6.9,15.03,13.81,22.55,20.71,0,0,.1-.63.1-.63Z" />
    <path d="M126.45,462.93c59.77-17.98,119.4-36.41,179.53-53.29,6.54-1.84,16.01,5.26,24.13,8.21-4.73,6.67-8.49,18.18-14.35,19.28-62.34,11.69-125.02,21.72-187.61,32.21l-1.7-6.41Z" />
    <path d="M674.66,341.19c-31.51,9.58-63.03,19.11-94.52,28.74-27.21,8.31-54.07,17.93-81.77,24.37-8.46,1.97-19.19-4.68-28.87-7.38,6.62-6.89,12.27-18.44,20.02-19.93,60.96-11.67,122.32-21.4,183.56-31.74.98,3.68.6,2.25,1.59,5.93Z" />
    <path d="M174.73,544.44c40.1-31.49,79.75-63.44,120.8-93.94,5.45-4.05,17.94-.8,27.17-.92-1.95,7.88-.88,19.8-6.33,23-45.15,26.49-91.5,51.37-137.49,76.73-2.54-2.98-1.61-1.88-4.15-4.87Z" />
    <path d="M350.42,633.68c4.31-46.83,8.13-93.69,13.68-140.45.57-4.8,11.41-9.02,17.48-13.51,4.66,5.08,14.25,10.94,13.21,15.14-11.54,46.66-24.73,93.07-37.48,139.55l-6.89-.72Z" />
    <path d="M671.2,428.22c-51.17-10.12-102.17-20.85-153.69-29.68-6.84-1.17-16.24,7.21-24.45,11.16,5.67,6.09,10.7,17.09,17.11,17.51,53.12,3.49,106.5,5.01,159.8,7.14.75-3.75.47-2.37,1.22-6.13Z" />
    <path d="M560.81,584.43c-27.26-39.44-54.11-79.12-82.42-117.95-2.91-3.99-14.59-2.94-22.2-4.22-1.56,6.54-7.1,15.93-4.08,19.19,33.56,36.1,68.45,71.26,102.98,106.66,3.54-2.27,2.19-1.4,5.73-3.67Z" />
    <path d="M625.27,255.56c-40.1,31.49-79.75,63.44-120.8,93.94-5.45,4.05-17.94.8-27.17.92,1.95-7.88.88-19.8,6.33-23,45.15-26.49,91.5-51.37,137.49-76.73,2.54,2.98,1.61,1.88,4.15,4.87Z" />
    <path d="M449.58,166.32c-4.31,46.83-8.13,93.69-13.68,140.45-.57,4.8-11.41,9.02-17.48,13.51-4.66-5.08-14.25-10.94-13.21-15.14,11.54-46.66,24.73-93.07,37.48-139.55l6.89.72Z" />
    <path d="M128.8,371.78c51.17,10.12,102.17,20.85,153.69,29.68,6.84,1.17,16.24-7.21,24.45-11.16-5.67-6.09-10.7-17.09-17.11-17.51-53.12-3.49-106.5-5.01-159.8-7.14l-1.22,6.13Z" />
    <path d="M239.19,215.57c27.26,39.44,54.11,79.12,82.42,117.95,2.91,3.99,14.59,2.94,22.2,4.22,1.56-6.54,7.1-15.93,4.08-19.19-33.56-36.1-68.45-71.26-102.98-106.66-3.54,2.27-2.19,1.4-5.73,3.67Z" />
    <path d="M426.57,394.64c2.9,12.93-6.65,25.81-21.32,28.77-14.68,2.96-28.93-5.12-31.82-18.05-2.9-12.93,6.65-25.81,21.32-28.77,14.68-2.96,28.93,5.12,31.82,18.05Z" />
    <path d="M343.89,138.8c17.98,59.19,36.37,118.27,53.19,177.74,1.41,4.97-7.77,12.56-12.03,18.94-7.37-5.51-19.85-10.06-21.24-16.71-6.84-32.55-10.9-65.64-15.6-98.59-3.8-26.68-7.11-53.43-10.64-80.14l6.31-1.24Z" />
    <path d="M436.14,487.32c8.17,57.66,16.34,115.33,24.51,172.99-3.77.77-2.3.47-6.07,1.24-6.98-22.01-14.07-43.99-20.91-66.03-10.43-33.57-21.9-66.93-30.16-100.98-2.08-8.56,6.4-19.39,10.02-29.18,7.5,7.54,14.99,15.08,22.49,22.62,0,0,.12-.66.12-.66Z" />
    <path d="M115.9,456.16c62.23-16.72,124.32-33.91,186.89-49.47,6.8-1.69,16.31,6.12,24.56,9.52-5.12,6.85-9.4,18.81-15.48,19.75-64.65,10-129.59,18.26-194.45,27l-1.52-6.8Z" />
    <path d="M685.09,348.22c-32.81,8.92-65.63,17.79-98.42,26.76-28.33,7.75-56.35,16.87-85.12,22.63-8.78,1.76-19.6-5.62-29.48-8.82,7.07-7,13.3-18.95,21.34-20.23,63.23-10.04,126.8-18.02,190.26-26.65.88,3.91.54,2.39,1.42,6.3Z" />
    <path d="M162.71,543.67c42.44-31.65,84.45-63.81,127.84-94.38,5.76-4.06,18.52-.18,28.03.03-2.3,8.22-1.62,20.8-7.35,23.96-47.47,26.21-96.12,50.68-144.42,75.67-2.51-3.23-1.59-2.04-4.1-5.27Z" />
    <path d="M340.51,644.01c6.13-49.11,11.75-98.26,19.14-147.25.76-5.03,12.08-9.07,18.5-13.57,4.62,5.52,14.29,12.03,13.07,16.41-13.57,48.66-28.83,97-43.63,145.42l-7.08-1.01Z" />
    <path d="M678.4,439.65c-52.35-12.53-104.52-25.69-157.28-36.87-7-1.48-16.99,6.98-25.59,10.84,5.62,6.62,10.41,18.37,17,19.05,54.61,5.62,109.55,9.19,164.39,13.38.91-3.92.57-2.48,1.48-6.4Z" />
    <path d="M559.05,599.92c-26.67-42.49-52.9-85.22-80.68-127.11-2.85-4.3-14.93-3.63-22.72-5.26-1.85,6.82-7.89,16.5-4.9,20.03,33.28,39.21,67.96,77.47,102.26,115.99l6.03-3.65Z" />
    <path d="M637.29,256.33c-42.44,31.65-84.45,63.81-127.84,94.38-5.76,4.06-18.52.18-28.03-.03,2.3-8.22,1.62-20.8,7.35-23.96,47.47-26.21,96.12-50.68,144.42-75.67l4.1,5.27Z" />
    <path d="M459.49,155.99c-6.13,49.11-11.75,98.26-19.14,147.25-.76,5.03-12.08,9.07-18.5,13.57-4.62-5.52-14.29-12.03-13.07-16.41,13.57-48.66,28.83-97,43.63-145.42l7.08,1.01Z" />
    <path d="M121.6,360.35c52.35,12.53,104.52,25.69,157.28,36.87,7,1.48,16.99-6.98,25.59-10.84-5.62-6.62-10.41-18.37-17-19.05-54.61-5.62-109.55-9.19-164.39-13.38-.91,3.92-.57,2.48-1.48,6.4Z" />
    <path d="M240.95,200.08c26.67,42.49,52.9,85.22,80.68,127.11,2.85,4.3,14.93,3.63,22.72,5.26,1.85-6.82,7.89-16.5,4.9-20.03-33.28-39.21-67.96-77.47-102.26-115.99-3.73,2.26-2.3,1.39-6.03,3.65Z" />
    <ellipse cx="400" cy="400" rx="27.98" ry="25.22" transform="translate(-39.59 43.96) rotate(-6)" />
    <path d="M351.59,123.16c16.4,62.92,33.22,125.76,48.41,188.94,1.27,5.28-8.46,12.93-13.07,19.48-7.39-6.07-20.08-11.31-21.29-18.36-5.87-34.5-8.87-69.46-12.52-104.29-2.96-28.21-5.4-56.47-8.08-84.7l6.55-1.07Z" />
    <path d="M434.09,493.18c6.34,60.96,12.68,121.92,19.03,182.89-3.91.67-2.39.41-6.3,1.08-6.4-23.41-12.91-46.79-19.17-70.23-9.53-35.7-20.16-71.22-27.44-107.34-1.83-9.08,7.29-20.16,11.38-30.32,7.45,8.21,14.9,16.41,22.36,24.62,0,0,.15-.69.15-.69Z" />
    <path d="M105.27,448.64c64.72-15.3,129.31-31.11,194.33-45.17,7.07-1.53,16.58,7.04,24.96,10.92-5.52,7.01-10.36,19.44-16.66,20.21-66.97,8.15-134.17,14.45-201.31,21.26l-1.32-7.21Z" />
    <path d="M695.6,356c-34.12,8.17-68.26,16.31-102.36,24.53-29.47,7.11-58.67,15.68-88.52,20.68-9.11,1.53-19.99-6.64-30.06-10.36,7.54-7.11,14.39-19.45,22.72-20.49,65.5-8.24,131.29-14.3,196.99-21.04.77,4.15.47,2.53,1.24,6.68Z" />
    <path d="M150.35,542.42c44.87-31.74,89.3-64.02,135.11-94.59,6.08-4.06,19.08.49,28.88,1.06-2.66,8.56-2.42,21.82-8.44,24.94-49.85,25.83-100.86,49.78-151.51,74.3-2.47-3.49-1.56-2.21-4.03-5.7Z" />
    <path d="M329.93,654.51c8.08-51.44,15.64-102.94,25.02-154.2.96-5.26,12.77-9.1,19.55-13.6,4.56,5.98,14.29,13.18,12.88,17.74-15.73,50.7-33.19,100.98-50.19,151.38l-7.26-1.32Z" />
    <path d="M685.41,451.93c-53.49-15.1-106.76-30.86-160.71-44.57-7.16-1.82-17.76,6.72-26.76,10.47,5.56,7.17,10.06,19.71,16.83,20.67,56.06,7.92,112.54,13.69,168.89,20.11,1.08-4.09.68-2.59,1.76-6.68Z" />
    <path d="M556.68,616.16c-25.95-45.68-51.44-91.59-78.55-136.69-2.78-4.63-15.25-4.36-23.22-6.37-2.15,7.11-8.73,17.07-5.77,20.9,32.88,42.47,67.23,84,101.19,125.78l6.35-3.62Z" />
    <path d="M649.65,257.58c-44.87,31.74-89.3,64.02-135.11,94.59-6.08,4.06-19.08-.49-28.88-1.06,2.66-8.56,2.42-21.82,8.44-24.94,49.85-25.83,100.86-49.78,151.51-74.3,2.47,3.49,1.56,2.21,4.03,5.7Z" />
    <path d="M470.07,145.49c-8.08,51.44-15.64,102.94-25.02,154.2-.96,5.26-12.77,9.1-19.55,13.6-4.56-5.98-14.29-13.18-12.88-17.74,15.73-50.7,33.19-100.98,50.19-151.38l7.26,1.32Z" />
    <path d="M114.59,348.07c53.49,15.1,106.76,30.86,160.71,44.57,7.16,1.82,17.76-6.72,26.76-10.47-5.56-7.17-10.06-19.71-16.83-20.67-56.06-7.92-112.54-13.69-168.89-20.11l-1.76,6.68Z" />
    <path d="M243.32,183.84c25.95,45.68,51.44,91.59,78.55,136.69,2.78,4.63,15.25,4.36,23.22,6.37,2.15-7.11,8.73-17.07,5.77-20.9-32.88-42.47-67.23-84-101.19-125.78l-6.35,3.62Z" />
    <ellipse cx="400" cy="400" rx="28.85" ry="26.55" transform="translate(-33.32 36.36) rotate(-5)" />
    <path d="M360.08,106.98c14.63,66.8,29.7,133.52,43.08,200.54,1.12,5.61-9.18,13.29-14.17,20.02-7.4-6.66-20.29-12.63-21.27-20.1-4.81-36.51-6.64-73.4-9.15-110.18-2.03-29.78-3.54-59.6-5.28-89.4l6.78-.88Z" />
    <path d="M431.77,499.28c4.34,64.36,8.68,128.73,13.02,193.09l-6.53.91c-5.75-24.86-11.62-49.7-17.22-74.59-8.54-37.91-18.21-75.66-24.41-113.92-1.56-9.62,8.24-20.94,12.81-31.48,7.38,8.91,14.77,17.81,22.15,26.72,0,0,.18-.72.18-.72Z" />
    <path d="M94.59,440.34c67.23-13.72,134.35-27.97,201.84-40.38,7.34-1.35,16.83,8.01,25.33,12.4-5.94,7.18-11.37,20.07-17.89,20.65-69.29,6.11-138.76,10.27-208.18,14.97l-1.1-7.63Z" />
    <path d="M706.14,364.58c-35.45,7.35-70.91,14.65-106.35,22.05-30.62,6.4-61.01,14.34-91.94,18.51-9.44,1.27-20.36-7.72-30.6-12,8.02-7.2,15.52-19.93,24.14-20.72,67.78-6.26,135.78-10.22,203.71-14.9.64,4.39.39,2.68,1.03,7.07Z" />
    <path d="M137.67,540.66c47.37-31.74,94.31-64.07,142.61-94.55,6.41-4.04,19.64,1.22,29.72,2.17-3.05,8.91-3.28,22.87-9.59,25.93-52.29,25.34-105.7,48.66-158.78,72.59-2.42-3.76-1.53-2.38-3.95-6.14Z" />
    <path d="M318.65,665.17c10.18-53.81,19.82-107.72,31.33-161.3,1.18-5.5,13.49-9.1,20.63-13.59,4.48,6.45,14.25,14.39,12.63,19.14-18.03,52.75-37.83,105.01-57.16,157.41l-7.43-1.66Z" />
    <path d="M692.19,465.12c-54.57-17.85-108.88-36.39-163.98-52.79-7.31-2.18-18.54,6.42-27.95,10.03,5.47,7.75,9.66,21.1,16.6,22.36,57.47,10.39,115.45,18.54,173.28,27.36,1.26-4.27.79-2.69,2.05-6.96Z" />
    <path d="M553.65,633.16c-25.09-49.01-49.7-98.25-76.01-146.68-2.7-4.97-15.55-5.15-23.7-7.55-2.47,7.4-9.6,17.64-6.69,21.77,32.35,45.89,66.25,90.84,99.73,136.04l6.67-3.58Z" />
    <path d="M662.33,259.34c-47.37,31.74-94.31,64.07-142.61,94.55-6.41,4.04-19.64-1.22-29.72-2.17,3.05-8.91,3.28-22.87,9.59-25.93,52.29-25.34,105.7-48.66,158.78-72.59l3.95,6.14Z" />
    <path d="M481.35,134.83c-10.18,53.81-19.82,107.72-31.33,161.3-1.18,5.5-13.49,9.1-20.63,13.59-4.48-6.45-14.25-14.39-12.63-19.14,18.03-52.75,37.83-105.01,57.16-157.41l7.43,1.66Z" />
    <path d="M107.81,334.88c54.57,17.85,108.88,36.39,163.98,52.79,7.31,2.18,18.54-6.42,27.95-10.03-5.47-7.75-9.66-21.1-16.6-22.36-57.47-10.39-115.45-18.54-173.28-27.36-1.26,4.27-.79,2.69-2.05,6.96Z" />
    <path d="M246.35,166.84c25.09,49.01,49.7,98.25,76.01,146.68,2.7,4.97,15.55,5.15,23.7,7.55,2.47-7.4,9.6-17.64,6.69-21.77-32.35-45.89-66.25-90.84-99.73-136.04l-6.67,3.58Z" />
    <ellipse cx="400" cy="400" rx="29.74" ry="27.94" transform="translate(-26.92 28.87) rotate(-4)" />
    <path d="M369.42,90.28c12.67,70.81,25.79,141.55,37.17,212.55.95,5.94-9.93,13.65-15.32,20.54-7.38-7.28-20.45-14.04-21.19-21.92-3.64-38.58-4.2-77.45-5.46-116.24-1.02-31.4-1.5-62.83-2.22-94.24l7.02-.68Z" />
    <path d="M429.16,505.61c2.16,67.87,4.31,135.74,6.47,203.61l-6.76.72c-5.03-26.37-10.18-52.71-15.06-79.1-7.43-40.19-16.04-80.26-21.05-120.74-1.26-10.17,9.24-21.73,14.34-32.65,7.29,9.64,14.57,19.28,21.86,28.92,0,0,.21-.76.21-.76Z" />
    <path d="M83.88,431.22c69.76-11.96,139.43-24.49,209.41-35.07,7.61-1.15,17.05,9.05,25.65,13.98-6.38,7.33-12.44,20.7-19.18,21.06-71.61,3.89-143.33,5.71-215.02,8.1l-.86-8.07Z" />
    <path d="M716.69,373.98c-36.79,6.42-73.59,12.8-110.36,19.29-31.78,5.6-63.37,12.85-95.4,16.09-9.78.99-20.7-8.86-31.09-13.75,8.52-7.28,16.71-20.39,25.62-20.92,70.06-4.09,140.26-5.77,210.42-8.19.5,4.64.31,2.84.81,7.48Z" />
    <path d="M124.66,538.33c49.95-31.65,99.47-63.94,150.33-94.23,6.75-4.02,20.19,2,30.54,3.38-3.46,9.26-4.2,23.93-10.82,26.92-54.79,24.74-110.66,47.31-166.2,70.54-2.36-4.05-1.49-2.56-3.85-6.61Z" />
    <path d="M306.64,675.96c12.42-56.24,24.3-112.59,38.08-168.54,1.41-5.74,14.22-9.08,21.74-13.54,4.38,6.95,14.16,15.66,12.32,20.6-20.48,54.83-42.76,109.08-64.55,163.49l-7.59-2.02Z" />
    <path d="M698.7,479.24c-55.58-20.79-110.87-42.28-167.05-61.55-7.45-2.56-19.33,6.07-29.15,9.52,5.35,8.35,9.19,22.56,16.3,24.13,58.84,13.04,118.28,23.74,177.55,35.15,1.45-4.44.91-2.81,2.36-7.25Z" />
    <path d="M549.91,650.92c-24.09-52.48-47.67-105.18-73.04-157.1-2.6-5.33-15.84-5.99-24.14-8.81-2.81,7.7-10.53,18.2-7.68,22.66,31.67,49.46,64.99,97.99,97.86,146.77l7-3.52Z" />
    <path d="M675.34,261.67c-49.95,31.65-99.47,63.94-150.33,94.23-6.75,4.02-20.19-2-30.54-3.38,3.46-9.26,4.2-23.93,10.82-26.92,54.79-24.74,110.66-47.31,166.2-70.54l3.85,6.61Z" />
    <path d="M493.36,124.04c-12.42,56.24-24.3,112.59-38.08,168.54-1.41,5.74-14.22,9.08-21.74,13.54-4.38-6.95-14.16-15.66-12.32-20.6,20.48-54.83,42.76-109.08,64.55-163.49l7.59,2.02Z" />
    <path d="M101.3,320.76c55.58,20.79,110.87,42.28,167.05,61.55,7.45,2.56,19.33-6.07,29.15-9.52-5.35-8.35-9.19-22.56-16.3-24.13-58.84-13.04-118.28-23.74-177.55-35.15l-2.36,7.25Z" />
    <path d="M250.09,149.08c24.09,52.48,47.67,105.18,73.04,157.1,2.6,5.33,15.84,5.99,24.14,8.81,2.81-7.7,10.53-18.2,7.68-22.66-31.67-49.46-64.99-97.99-97.86-146.77l-7,3.52Z" />
    <ellipse cx="400" cy="400" rx="30.66" ry="29.41" transform="translate(-20.38 21.48) rotate(-3)" />
    <path d="M379.63,73.06c10.51,74.95,21.48,149.85,30.65,224.97.77,6.28-10.73,13.99-16.52,21.04-7.35-7.93-20.56-15.52-21.04-23.84-2.36-40.72-1.54-81.64-1.44-122.48.08-33.07.72-66.15,1.1-99.22l7.26-.46Z" />
    <path d="M426.25,512.17c-.22,71.48-.44,142.96-.66,214.44l-6.99.51c-4.23-27.92-8.59-55.82-12.67-83.77-6.21-42.56-13.63-85.02-17.34-127.8-.93-10.75,10.3-22.52,15.94-33.82,7.16,10.41,14.32,20.82,21.48,31.23,0,0,.25-.79.25-.79Z" />
    <path d="M73.18,421.23c72.3-10.02,144.53-20.64,217.02-29.2,7.88-.93,17.25,10.14,25.92,15.65-6.83,7.48-13.56,21.32-20.52,21.45-73.92,1.46-147.88.74-221.83.62-.37-5.3-.22-3.23-.59-8.52Z" />
    <path d="M727.22,384.26c-38.13,5.41-76.28,10.77-114.4,16.24-32.94,4.73-65.76,11.19-98.86,13.42-10.11.68-21-10.09-31.54-15.61,9.04-7.35,17.95-20.84,27.15-21.06,72.33-1.73,144.72-.91,217.09-.89.35,4.9.21,2.99.57,7.9Z" />
    <path d="M111.34,535.41c52.6-31.46,104.79-63.61,158.27-93.6,7.1-3.98,20.73,2.85,31.34,4.67-3.9,9.61-5.19,25.02-12.11,27.92-57.34,24.01-115.71,45.7-173.78,68.1-2.29-4.35-1.44-2.75-3.73-7.09Z" />
    <path d="M293.89,686.88c14.82-58.7,29.08-117.55,45.3-175.9,1.66-5.99,14.98-9.03,22.89-13.44,4.27,7.48,14.03,16.99,11.95,22.12-23.07,56.93-47.98,113.18-72.39,169.62l-7.75-2.4Z" />
    <path d="M704.9,494.33c-56.51-23.91-112.71-48.55-169.9-70.89-7.59-2.96-20.13,5.68-30.38,8.95,5.21,8.98,8.66,24.07,15.92,25.98,60.15,15.88,121.01,29.32,181.66,43.5,1.65-4.62,1.04-2.92,2.7-7.54Z" />
    <path d="M545.43,669.47c-22.93-56.09-45.33-112.4-69.6-167.95-2.49-5.7-16.1-6.88-24.56-10.16-3.17,7.99-11.5,18.76-8.73,23.55,30.85,53.2,63.43,105.47,95.54,158l7.34-3.44Z" />
    <path d="M688.66,264.59c-52.6,31.46-104.79,63.61-158.27,93.6-7.1,3.98-20.73-2.85-31.34-4.67,3.9-9.61,5.19-25.02,12.11-27.92,57.34-24.01,115.71-45.7,173.78-68.1l3.73,7.09Z" />
    <path d="M506.11,113.12c-14.82,58.7-29.08,117.55-45.3,175.9-1.66,5.99-14.98,9.03-22.89,13.44-4.27-7.48-14.03-16.99-11.95-22.12,23.07-56.93,47.98-113.18,72.39-169.62l7.75,2.4Z" />
    <path d="M95.1,305.67c56.51,23.91,112.71,48.55,169.9,70.89,7.59,2.96,20.13-5.68,30.38-8.95-5.21-8.98-8.66-24.07-15.92-25.98-60.15-15.88-121.01-29.32-181.66-43.5l-2.7,7.54Z" />
    <path d="M254.57,130.53c22.93,56.09,45.33,112.4,69.6,167.95,2.49,5.7,16.1,6.88,24.56,10.16,3.17-7.99,11.5-18.76,8.73-23.55-30.85-53.2-63.43-105.47-95.54-158-4.54,2.13-2.8,1.31-7.34,3.44Z" />
    <ellipse cx="400" cy="400" rx="31.61" ry="30.96" transform="translate(-13.72 14.2) rotate(-2)" />
    <path d="M390.78,55.31c8.13,79.24,16.74,158.43,23.48,237.79.56,6.64-11.55,14.33-17.78,21.53-7.28-8.61-20.63-17.08-20.82-25.85-.97-42.92,1.35-85.94,2.92-128.9,1.27-34.79,3.12-69.56,4.71-104.34l7.49-.22Z" />
    <path d="M423.01,518.97c-2.8,75.19-5.6,150.37-8.4,225.56l-7.22.27c-3.36-29.53-6.85-59.04-10.04-88.59-4.87-45-10.99-89.94-13.27-135.08-.57-11.34,11.42-23.31,17.64-34.99,7,11.21,14,22.43,21.01,33.64,0,0,.28-.82.28-.82Z" />
    <path d="M62.51,410.33c74.85-7.89,149.65-16.41,224.64-22.74,8.15-.69,17.4,11.3,26.15,17.41-7.31,7.62-14.74,21.93-21.91,21.81-76.21-1.18-152.39-4.66-228.57-7.5l-.3-8.99Z" />
    <path d="M737.7,395.46c-39.48,4.29-78.98,8.52-118.45,12.88-34.11,3.76-68.15,9.35-102.34,10.49-10.44.35-21.28-11.38-31.93-17.58,9.58-7.4,19.25-21.27,28.73-21.16,74.59.83,149.14,4.36,223.7,7.04.19,5.17.11,3.16.3,8.33Z" />
    <path d="M97.72,531.84c55.33-31.16,110.25-63.06,166.44-92.65,7.46-3.93,21.26,3.76,32.12,6.07-4.36,9.97-6.25,26.14-13.48,28.93-59.94,23.15-120.86,43.83-181.49,65.25l-3.59-7.6Z" />
    <path d="M280.35,697.9c17.38-61.21,34.19-122.59,53-183.38,1.93-6.24,15.76-8.95,24.07-13.3,4.13,8.02,13.84,18.39,11.52,23.71-25.82,59.04-53.5,117.31-80.69,175.78l-7.9-2.81Z" />
    <path d="M710.74,510.44c-57.37-27.23-114.38-55.22-172.49-80.82-7.71-3.4-20.95,5.23-31.62,8.29,5.05,9.64,8.05,25.64,15.47,27.92,61.41,18.91,123.63,35.29,185.6,52.43,1.87-4.8,1.18-3.03,3.05-7.83Z" />
    <path d="M540.14,688.83c-21.61-59.85-42.66-119.91-65.66-179.24-2.36-6.09-16.34-7.83-24.94-11.59-3.56,8.29-12.53,19.31-9.84,24.46,29.88,57.1,61.56,113.29,92.75,169.72l7.69-3.35Z" />
    <path d="M702.28,268.16c-55.33,31.16-110.25,63.06-166.44,92.65-7.46,3.93-21.26-3.76-32.12-6.07,4.36-9.97,6.25-26.14,13.48-28.93,59.94-23.15,120.86-43.83,181.49-65.25l3.59,7.6Z" />
    <path d="M519.65,102.1c-17.38,61.21-34.19,122.59-53,183.38-1.93,6.24-15.76,8.95-24.07,13.3-4.13-8.02-13.84-18.39-11.52-23.71,25.82-59.04,53.5-117.31,80.69-175.78l7.9,2.81Z" />
    <path d="M89.26,289.56c57.37,27.23,114.38,55.22,172.49,80.82,7.71,3.4,20.95-5.23,31.62-8.29-5.05-9.64-8.05-25.64-15.47-27.92-61.41-18.91-123.63-35.29-185.6-52.43l-3.05,7.83Z" />
    <path d="M259.86,111.17c21.61,59.85,42.66,119.91,65.66,179.24,2.36,6.09,16.34,7.83,24.94,11.59,3.56-8.29,12.53-19.31,9.84-24.46-29.88-57.1-61.56-113.29-92.75-169.72l-7.69,3.35Z" />
    <circle cx="400" cy="400" r="32.59" />
    <path d="M402.9,37.05c5.53,83.66,11.55,167.29,15.64,251.01.34,7-12.42,14.65-19.09,21.99-7.19-9.32-20.64-18.72-20.53-27.96.54-45.19,4.48-90.35,7.65-135.5,2.56-36.55,5.71-73.06,8.6-109.59l7.73.05Z" />
    <path d="M419.42,526c-5.59,78.99-11.18,157.99-16.77,236.98l-7.45.02c-2.4-31.19-4.93-62.36-7.16-93.56-3.4-47.52-8.08-95.02-8.81-142.59-.18-11.95,12.61-24.1,19.44-36.16,6.81,12.05,13.62,24.11,20.43,36.16,0,0,.32-.85.32-.85Z" />
    <path d="M51.92,398.47c77.41-5.55,154.78-11.76,232.27-15.67,8.43-.42,17.52,12.53,26.31,19.28-7.81,7.74-15.97,22.52-23.36,22.14-78.48-4.04-156.84-10.5-235.23-16.29,0-5.88,0-3.58.01-9.47Z" />
    <path d="M748.09,407.63c-40.84,3.06-81.67,6.06-122.5,9.2-35.28,2.71-70.55,7.33-105.82,7.27-10.77-.02-21.51-12.75-32.27-19.67,10.14-7.43,20.59-21.66,30.36-21.2,76.82,3.62,153.5,10.06,230.23,15.63,0,5.45,0,3.33,0,8.77Z" />
    <path d="M83.82,527.59c58.12-30.75,115.86-62.29,174.81-91.36,7.82-3.86,21.77,4.74,32.88,7.56-4.85,10.33-7.38,27.26-14.93,29.94-62.59,22.15-126.1,41.67-189.34,61.98l-3.42-8.12Z" />
    <path d="M266.01,708.99c20.11-63.75,39.64-127.71,61.2-190.97,2.21-6.49,16.56-8.84,25.27-13.11,3.96,8.59,13.6,19.86,11.02,25.37-28.73,61.17-59.35,121.44-89.45,181.95l-8.04-3.24Z" />
    <path d="M716.18,527.59c-58.12-30.75-115.86-62.29-174.81-91.36-7.82-3.86-21.77,4.74-32.88,7.56,4.85,10.33,7.38,27.26,14.93,29.94,62.59,22.15,126.1,41.67,189.34,61.98,2.1-4.98,1.33-3.15,3.42-8.12Z" />
    <path d="M533.99,708.99c-20.11-63.75-39.64-127.71-61.2-190.97-2.21-6.49-16.56-8.84-25.27-13.11-3.96,8.59-13.6,19.86-11.02,25.37,28.73,61.17,59.35,121.44,89.45,181.95l8.04-3.24Z" />
    <path d="M716.18,272.41c-58.12,30.75-115.86,62.29-174.81,91.36-7.82,3.86-21.77-4.74-32.88-7.56,4.85-10.33,7.38-27.26,14.93-29.94,62.59-22.15,126.1-41.67,189.34-61.98l3.42,8.12Z" />
    <path d="M533.99,91.01c-20.11,63.75-39.64,127.71-61.2,190.97-2.21,6.49-16.56,8.84-25.27,13.11-3.96-8.59-13.6-19.86-11.02-25.37,28.73-61.17,59.35-121.44,89.45-181.95l8.04,3.24Z" />
    <path d="M83.82,272.41c58.12,30.75,115.86,62.29,174.81,91.36,7.82,3.86,21.77-4.74,32.88-7.56-4.85-10.33-7.38-27.26-14.93-29.94-62.59-22.15-126.1-41.67-189.34-61.98l-3.42,8.12Z" />
    <path d="M266.01,91.01c20.11,63.75,39.64,127.71,61.2,190.97,2.21,6.49,16.56,8.84,25.27,13.11,3.96-8.59,13.6-19.86,11.02-25.37-28.73-61.17-59.35-121.44-89.45-181.95l-8.04,3.24Z" />
  </svg>,
  'CassIcon',
)
