import {
  alpha, Box, Divider, Slide,
  useMediaQuery, useScrollTrigger, useTheme,
} from '@mui/material'
import type { AppBarExProps } from '@xylabs/react-appbar'
import { FlexGrowCol, FlexGrowRow } from '@xylabs/react-flexbox'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import type { To } from 'react-router-dom'

import { DataismAppBarEx, MobileNavDialog } from '../common/index.ts'
import { ContextToolbar } from './ContextToolbar.tsx'
import { LogoToolbar } from './Logo/index.ts'
import { NavbarData } from './NavData/index.ts'
import { SystemToolBar } from './SystemToolbar/index.ts'

interface DataismAppBarExProps extends AppBarExProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const DataismAppBar: React.FC<DataismAppBarExProps> = ({
  ctaLink, ctaText, customButton, style, contextToolbar, systemToolbar, ...props
}) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const trigger = useScrollTrigger({ target: window, threshold: 500 })
  const [isAtTop, setIsAtTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      if (scrollTop < 550) {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(true)
      } else {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Slide direction="down" in={!trigger}>
      {/* Slide needs component, so Box is used */}
      <Box>
        <DataismAppBarEx
          position="sticky"
          elevation={0}
          container="xl"
          systemToolbar={contextToolbar}
          contextToolbar={systemToolbar}
          style={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.getContrastText(theme.palette.background.default),
            ...style,
          }}
          onClick={event => event.stopPropagation()}
          {...props}
        >
          <FlexGrowCol alignItems="stretch">
            <FlexGrowRow>
              <LogoToolbar sx={{ justifyContent: 'flex-start' }} />
              <ContextToolbar sx={{ justifyContent: 'center' }} navBarData={NavbarData} />
              <SystemToolBar
                sx={{ justifyContent: 'flex-end', flexGrow: 1 }}
                disableGutters={false}
                ctaLink={ctaLink}
                ctaText={ctaText}
                customButton={customButton}
              >
                {isNavbarMobile
                  ? <MobileNavDialog navBarData={NavbarData} />
                  : null}
              </SystemToolBar>
            </FlexGrowRow>
            <Divider flexItem variant="fullWidth" />
          </FlexGrowCol>
        </DataismAppBarEx>
      </Box>
    </Slide>
  )
}
