import React from 'react'

import { DataismBasePage } from '../../../../common/index.ts'
import { InfoHero } from './Hero.tsx'
import { Quiz } from './Quiz.tsx'

export const QuizPage: React.FC = () => {
  return (
    <DataismBasePage title="Information">
      <InfoHero />
      <Quiz></Quiz>
    </DataismBasePage>
  )
}
