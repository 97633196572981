import {
  Box, Divider, Typography,
} from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow, useIsMobile } from '@xyo-network/react-shared'
import React from 'react'

import type { Persona } from '../../../../common/index.ts'
import {
  DataismBasePage, DataismBasicHero, personaIconsData,
} from '../../../../common/index.ts'
import { StrengthWeakness } from './StrengthWeakness.tsx'

interface DynamicPersonaPageProps {
  persona: Persona
}

export const DynamicPersonaPage: React.FC<DynamicPersonaPageProps> = ({ persona }) => {
  const isMobile = useIsMobile()

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <DataismBasePage title="Home">
      <style>
        {`
          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <DataismBasicHero
        title={capitalizeFirstLetter(persona.name)}
        desc={persona.description}
        button1To="/quiz/persona"
        button1Text="Discover Your Persona"
        overflow="hidden"
        heroComponent={(
          <Box
            style={{
              color: 'inherit',
              animation: 'rotation 40s infinite linear',
              bottom: isMobile ? 0 : -350,
              right: isMobile ? 0 : -330,
              position: 'absolute',
            }}
            sx={{ opacity: { xs: 0.05, md: 0.3 } }}
          >
            {personaIconsData[persona.name].iconLarge}
          </Box>
        )}
      />
      <Divider flexItem />
      <StrengthWeakness strengths={persona.strengths} weaknesses={persona.weaknesses} />
    </DataismBasePage>
  )
}
