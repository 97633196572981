import { NotFound } from '@xyo-network/react-shared'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { JourneyDetailPage } from './detail/index.ts'
import { JourneyListPage } from './list/index.ts'

export const JourneyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<JourneyListPage />} />
      <Route path="/:journeyId" element={<JourneyDetailPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
