import {
  Box,
  Card,
  CardContent,
  Container, Stack, Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

import { FullPageResponsiveBox, ThreePillarsData } from '../../../../common/index.ts'

export const ThreePillars: React.FC<BusyBoxProps> = ({ ...props }) => {
  return (
    <FullPageResponsiveBox id="three-pillars">
      <FlexCol
        alignItems="stretch"
        sx={{
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        {...props}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack flexDirection="column" alignItems="flex-start" gap={2}>
            <Typography variant="body1" gutterBottom>Pillars of Dataism</Typography>
            <Stack sx={{ flexDirection: { md: 'row', xs: 'column' } }} gap={0.5}>
              {ThreePillarsData.map((pillar, index) => {
                return (
                  <Card key={index} sx={{ minWidth: '300px' }}>
                    <CardContent sx={{ height: { md: '100%' } }}>
                      <FlexCol sx={{
                        alignItems: 'flex-start', justifyContent: 'space-between', height: { md: '100%' },
                      }}
                      >
                        <FlexCol sx={{ alignItems: 'flex-start' }}>
                          <Box height="100px"></Box>
                          <Typography variant="h5" fontWeight="bold">{pillar.title}</Typography>
                          <Typography variant="body1">
                            {pillar.desc}
                          </Typography>
                        </FlexCol>
                        <ButtonEx paddingLeft={0} variant="text">Learn More</ButtonEx>
                      </FlexCol>
                    </CardContent>
                  </Card>
                )
              })}
            </Stack>
          </Stack>
        </Container>
      </FlexCol>
    </FullPageResponsiveBox>
  )
}
