import { NotFound } from '@xyo-network/react-shared'
import React from 'react'
import {
  Navigate, Route, Routes,
} from 'react-router-dom'

import {
  DashboardHomePage, JourneyRoutes, ProfilePage, SettingsPage,
} from './pages/index.ts'

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="start" replace />} />
      <Route path="start" element={<DashboardHomePage />} />
      <Route path="journey/*" element={<JourneyRoutes />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
