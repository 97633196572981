// eslint-disable-next-line import-x/no-internal-modules
import type { Auth } from 'firebase/auth'
import {
  browserLocalPersistence, setPersistence, signOut,
// eslint-disable-next-line import-x/no-internal-modules
} from 'firebase/auth'

/**
 * Signs out the current user. Idempotent so it can be called
 * multiple times and even if no user is currently signed in.
 * @param auth The Auth context to use
 */
export const logout = async (auth: Auth) => {
  await setPersistence(auth, browserLocalPersistence)
  await signOut(auth)
}
