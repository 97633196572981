import { AddRounded, ArrowForwardRounded } from '@mui/icons-material'
import {
  Button,
  Card, CardContent, Container, Dialog, DialogContent, DialogTitle, Grid2, Typography, useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import {
  PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer,
} from 'recharts'

import type { Persona } from '../../../../common/index.ts'
import { DataismBasicHero, DataistPersonaData } from '../../../../common/index.ts'

// Props type to accept result data
type ResultDataProps = {
  resultData: {
    fullMark: number
    persona: string
    value: number
  }[]
}

// Component for displaying persona radar chart and persona cards
export const PersonaResults: React.FC<ResultDataProps> = ({ resultData }) => {
  const theme = useTheme()

  // Calculate total score and percentage for each persona
  const nonZeroResultData = resultData.filter(item => item.value > 0)
  const totalScore = nonZeroResultData.reduce((acc, item) => acc + item.value, 0)

  const percentageData = nonZeroResultData
    .map(item => ({
      ...item,
      percentage: ((item.value / totalScore) * 100).toFixed(2),
    }))
    .sort((a, b) => Number.parseFloat(b.percentage) - Number.parseFloat(a.percentage)) // Sort from greatest to least

  // Dialog state for persona details with Persona type
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(null) // Persona type

  const handleOpenDialog = (personaName: string) => {
    const persona = DataistPersonaData.find(p => p.name === personaName) as Persona
    setSelectedPersona(persona)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedPersona(null)
  }

  return (
    <div>
      {/* Radar chart for persona scores */}
      <DataismBasicHero
        title="Your Results"
        desc="Congrats! You've finished your first quiz and earned 10 data for your efforts!"
        extras={(
          <Typography>
            <AddRounded />
            {' '}
            10 Data
          </Typography>
        )}
        heroComponent={(
          <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={percentageData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="persona" />
                <PolarRadiusAxis angle={30} domain={[0, 20]} />
                <Radar
                  name="Your Persona Scores"
                  dataKey="value"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        )}
      />

      {/* Persona cards below the radar chart */}
      <Container>
        <Grid2 container spacing={2}>
          {percentageData.map((item, index) => (
            <Grid2
              size={{
                xs: 12, sm: 6, md: 4,
              }}
              key={index}
            >
              <Card
                onClick={() => handleOpenDialog(item.persona)}
                sx={{
                  'cursor': 'pointer',
                  'backgroundColor': theme.palette.background.paper,
                  '&:hover': { backgroundColor: theme.palette.grey[200] },
                }}
              >
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {item.persona}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {item.percentage}
                    % of your total
                  </Typography>
                  <ArrowForwardRounded />
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Container>

      {/* Dialog to show full persona description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPersona?.name}</DialogTitle>
        <DialogContent>
          {selectedPersona && (
            <>
              <Typography variant="h6">{selectedPersona.subname}</Typography>
              <Typography variant="body1" gutterBottom>
                {selectedPersona.description}
              </Typography>
              <Typography variant="body2">
                <strong>Strengths:</strong>
                {' '}
                {selectedPersona.strengths}
              </Typography>
              <Typography variant="body2">
                <strong>Weaknesses:</strong>
                {' '}
                {selectedPersona.weaknesses}
              </Typography>
            </>
          )}
        </DialogContent>
        <Button onClick={handleCloseDialog}>Close</Button>
      </Dialog>
    </div>
  )
}
