import { createSvgIcon } from '@mui/material'
import React from 'react'

export const MycaIcon = createSvgIcon(
  <svg id="MycaIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" stroke="currentColor" fill="none">
    <line x1="523.61" y1="384.81" x2="764.21" y2="415.19" />
    <line x1="592.26" y1="294.12" x2="695.51" y2="513.55" />
    <line x1="708.76" y1="305.28" x2="578.82" y2="510.04" />
    <line x1="764.91" y1="411.48" x2="522.39" y2="411.48" />
    <line x1="708.4" y1="517.69" x2="578.46" y2="312.93" />
    <line x1="591.53" y1="528.85" x2="694.78" y2="309.42" />
    <line x1="522.53" y1="438.15" x2="763.14" y2="407.76" />
    <line x1="565.15" y1="333.34" x2="719.73" y2="520.2" />
    <line x1="679.45" y1="315.25" x2="604.52" y2="545.9" />
    <line x1="758.93" y1="404.21" x2="524.03" y2="464.52" />
    <line x1="729.31" y1="521.16" x2="552.52" y2="355.15" />
    <line x1="617.56" y1="561.05" x2="663.01" y2="322.83" />
    <line x1="526.85" y1="490.34" x2="752.33" y2="401.07" />
    <line x1="540.74" y1="378.19" x2="736.95" y2="520.74" />
    <line x1="645.65" y1="332.19" x2="630.42" y2="574.22" />
    <line x1="743.43" y1="398.52" x2="530.92" y2="515.36" />
    <line x1="742.51" y1="519.07" x2="529.98" y2="402.24" />
    <line x1="642.88" y1="585.38" x2="627.66" y2="343.34" />
    <line x1="536.13" y1="539.32" x2="732.33" y2="396.78" />
    <line x1="520.38" y1="427.08" x2="745.87" y2="516.35" />
    <line x1="609.25" y1="356.26" x2="654.7" y2="594.48" />
    <line x1="719.16" y1="396" x2="542.36" y2="562.01" />
    <line x1="746.94" y1="512.78" x2="512.05" y2="452.46" />
    <line x1="665.64" y1="601.54" x2="590.7" y2="370.9" />
    <line x1="549.48" y1="583.22" x2="704.08" y2="396.36" />
    <line x1="505.04" y1="478.15" x2="745.64" y2="508.53" />
    <line x1="572.22" y1="387.15" x2="675.48" y2="606.58" />
    <line x1="687.27" y1="397.99" x2="557.33" y2="602.75" />
    <line x1="741.95" y1="503.85" x2="499.43" y2="503.85" />
    <line x1="684.01" y1="609.69" x2="554.07" y2="404.92" />
    <line x1="565.69" y1="620.45" x2="668.96" y2="401.02" />
    <line x1="495.25" y1="529.33" x2="735.86" y2="498.93" />
    <line x1="536.45" y1="424.07" x2="691.04" y2="610.93" />
    <line x1="649.35" y1="405.52" x2="574.4" y2="636.17" />
    <line x1="727.4" y1="494.01" x2="492.49" y2="554.32" />
    <line x1="696.36" y1="610.44" x2="519.57" y2="444.43" />
    <line x1="583.21" y1="649.8" x2="628.66" y2="411.58" />
    <line x1="491.13" y1="578.55" x2="716.61" y2="489.27" />
    <line x1="503.63" y1="465.83" x2="699.83" y2="608.36" />
    <line x1="607.17" y1="419.23" x2="591.94" y2="661.26" />
    <line x1="703.59" y1="484.95" x2="491.07" y2="601.77" />
    <line x1="701.31" y1="604.87" x2="488.8" y2="488.03" />
    <line x1="600.34" y1="670.51" x2="585.11" y2="428.47" />
    <line x1="492.25" y1="623.77" x2="688.46" y2="481.23" />
    <line x1="475.19" y1="510.83" x2="700.68" y2="600.11" />
    <line x1="562.75" y1="439.29" x2="608.19" y2="677.51" />
    <line x1="671.36" y1="478.29" x2="494.57" y2="644.3" />
    <line x1="697.85" y1="594.3" x2="462.96" y2="533.99" />
    <line x1="615.27" y1="682.29" x2="540.34" y2="451.64" />
    <line x1="497.87" y1="663.17" x2="652.46" y2="476.31" />
    <line x1="452.17" y1="557.27" x2="692.78" y2="587.67" />
    <line x1="518.11" y1="465.44" x2="621.37" y2="684.87" />
    <line x1="631.94" y1="475.42" x2="502" y2="680.18" />
    <line x1="685.42" y1="580.41" x2="442.9" y2="580.41" />
    <line x1="626.28" y1="685.34" x2="496.32" y2="480.57" />
    <line x1="506.78" y1="695.18" x2="610.04" y2="475.75" />
    <line x1="435.18" y1="603.12" x2="675.77" y2="572.74" />
    <line x1="475.21" y1="496.92" x2="629.79" y2="683.78" />
    <line x1="586.96" y1="477.41" x2="512.02" y2="708.04" />
    <line x1="663.9" y1="564.89" x2="429" y2="625.2" />
    <line x1="631.76" y1="680.32" x2="454.97" y2="514.31" />
    <line x1="517.52" y1="718.67" x2="562.97" y2="480.45" />
    <line x1="424.35" y1="646.37" x2="649.85" y2="557.09" />
    <line x1="435.81" y1="532.59" x2="632.01" y2="675.12" />
    <line x1="538.3" y1="484.92" x2="523.08" y2="726.96" />
    <line x1="633.71" y1="549.55" x2="421.19" y2="666.37" />
    <line x1="630.42" y1="668.35" x2="417.9" y2="551.53" />
    <line x1="528.46" y1="732.88" x2="513.23" y2="490.84" />
    <line x1="419.4" y1="685.01" x2="615.6" y2="542.47" />
    <line x1="401.39" y1="570.91" x2="626.88" y2="660.2" />
    <line x1="488.02" y1="498.22" x2="533.45" y2="736.44" />
    <line x1="595.7" y1="536.03" x2="418.91" y2="702.04" />
    <line x1="621.3" y1="650.85" x2="386.4" y2="590.54" />
    <line x1="537.84" y1="737.62" x2="462.9" y2="506.97" />
    <line x1="419.57" y1="717.27" x2="574.17" y2="530.41" />
    <line x1="373.04" y1="610.15" x2="613.64" y2="640.53" />
    <line x1="438.16" y1="517.06" x2="541.41" y2="736.49" />
    <line x1="551.18" y1="525.79" x2="421.24" y2="730.54" />
    <line x1="603.88" y1="629.48" x2="361.36" y2="629.48" />
    <line x1="543.98" y1="733.13" x2="414.04" y2="528.38" />
    <line x1="423.75" y1="741.68" x2="527" y2="522.25" />
    <line x1="351.41" y1="648.32" x2="592.02" y2="617.93" />
    <line x1="390.76" y1="540.8" x2="545.34" y2="727.65" />
    <line x1="501.83" y1="519.95" x2="426.89" y2="750.58" />
    <line x1="578.11" y1="606.09" x2="343.2" y2="666.4" />
    <line x1="545.33" y1="720.17" x2="368.54" y2="554.16" />
    <line x1="430.48" y1="757.13" x2="475.93" y2="518.93" />
    <line x1="336.72" y1="683.47" x2="562.21" y2="594.2" />
    <line x1="347.61" y1="568.31" x2="543.81" y2="710.86" />
    <line x1="449.55" y1="519.26" x2="434.31" y2="761.29" />
    <line x1="544.41" y1="582.48" x2="331.9" y2="699.32" />
    <line x1="540.63" y1="699.89" x2="328.11" y2="583.05" />
    <line x1="438.19" y1="763" x2="422.96" y2="520.96" />
    <line x1="328.67" y1="713.7" x2="524.87" y2="571.17" />
    <line x1="310.21" y1="598.19" x2="535.7" y2="687.46" />
    <line x1="396.42" y1="524.04" x2="441.86" y2="762.26" />
    <line x1="503.71" y1="560.42" x2="326.92" y2="726.43" />
    <line x1="528.94" y1="673.79" x2="294.04" y2="613.48" />
    <line x1="445.13" y1="759.11" x2="370.19" y2="528.46" />
    <line x1="326.53" y1="737.3" x2="481.12" y2="550.44" />
    <line x1="279.7" y1="628.71" x2="520.3" y2="659.1" />
    <line x1="344.54" y1="534.16" x2="447.79" y2="753.59" />
    <line x1="457.31" y1="541.41" x2="327.37" y2="746.16" />
    <line x1="509.77" y1="643.64" x2="267.26" y2="643.64" />
    <line x1="449.66" y1="745.8" x2="319.7" y2="541.05" />
    <line x1="329.24" y1="752.87" x2="432.49" y2="533.44" />
    <line x1="256.75" y1="658.02" x2="497.36" y2="627.63" />
    <line x1="295.94" y1="549" x2="450.52" y2="735.86" />
    <line x1="406.9" y1="526.66" x2="331.96" y2="757.3" />
    <line x1="483.08" y1="611.32" x2="248.19" y2="671.63" />
    <line x1="450.23" y1="723.91" x2="273.44" y2="557.9" />
    <line x1="335.34" y1="759.38" x2="380.79" y2="521.17" />
    <line x1="241.56" y1="684.23" x2="467.04" y2="594.94" />
    <line x1="252.44" y1="567.57" x2="448.64" y2="710.11" />
    <line x1="354.41" y1="517.02" x2="339.18" y2="759.04" />
    <line x1="449.33" y1="578.75" x2="236.8" y2="695.58" />
    <line x1="445.6" y1="694.66" x2="233.08" y2="577.83" />
    <line x1="343.25" y1="756.28" x2="328.03" y2="514.25" />
    <line x1="233.85" y1="705.49" x2="430.05" y2="562.94" />
    <line x1="215.53" y1="588.49" x2="441.02" y2="677.76" />
    <line x1="301.91" y1="512.87" x2="347.34" y2="751.07" />
    <line x1="409.38" y1="547.75" x2="232.59" y2="713.76" />
    <line x1="434.83" y1="659.64" x2="199.92" y2="599.33" />
    <line x1="351.26" y1="743.47" x2="276.32" y2="512.84" />
    <line x1="232.91" y1="720.2" x2="387.5" y2="533.35" />
    <line x1="186.36" y1="610.13" x2="426.96" y2="640.53" />
    <line x1="251.51" y1="514.13" x2="354.76" y2="733.56" />
    <line x1="364.6" y1="519.92" x2="234.66" y2="724.67" />
    <line x1="417.41" y1="620.7" x2="174.89" y2="620.69" />
    <line x1="357.67" y1="721.41" x2="227.71" y2="516.66" />
    <line x1="237.64" y1="727.04" x2="340.9" y2="507.61" />
    <line x1="165.57" y1="630.75" x2="406.17" y2="600.36" />
    <line x1="205.21" y1="520.31" x2="359.79" y2="707.17" />
    <line x1="316.62" y1="496.54" x2="241.68" y2="727.18" />
    <line x1="393.28" y1="579.79" x2="158.39" y2="640.09" />
    <line x1="360.95" y1="690.97" x2="184.16" y2="524.96" />
    <line x1="246.59" y1="725.05" x2="292.02" y2="486.83" />
    <line x1="153.34" y1="648.49" x2="378.83" y2="559.22" />
    <line x1="164.8" y1="530.45" x2="361" y2="673.01" />
    <line x1="267.37" y1="478.54" x2="252.13" y2="720.56" />
    <line x1="362.9" y1="538.91" x2="150.37" y2="655.74" />
    <line x1="359.8" y1="653.47" x2="147.29" y2="536.63" />
    <line x1="258.11" y1="713.75" x2="242.89" y2="471.71" />
    <line x1="149.4" y1="661.62" x2="345.6" y2="519.08" />
    <line x1="131.78" y1="543.29" x2="357.27" y2="632.57" />
    <line x1="218.88" y1="466.36" x2="264.32" y2="704.57" />
    <line x1="327.09" y1="499.96" x2="150.3" y2="665.95" />
    <line x1="353.29" y1="610.55" x2="118.4" y2="550.25" />
    <line x1="270.5" y1="693.12" x2="195.56" y2="462.48" />
    <line x1="152.97" y1="668.59" x2="307.55" y2="481.73" />
    <line x1="107.22" y1="557.27" x2="347.83" y2="587.67" />
    <line x1="173.22" y1="460.01" x2="276.47" y2="679.45" />
    <line x1="287.17" y1="464.59" x2="157.22" y2="669.34" />
    <line x1="340.85" y1="564.15" x2="98.33" y2="564.15" />
    <line x1="282.02" y1="663.68" x2="152.06" y2="458.91" />
    <line x1="162.9" y1="668.11" x2="266.16" y2="448.68" />
    <line x1="91.77" y1="570.66" x2="332.36" y2="540.28" />
    <line x1="132.35" y1="459.07" x2="286.94" y2="645.93" />
    <line x1="244.75" y1="434.17" x2="169.8" y2="664.81" />
    <line x1="322.39" y1="516.29" x2="87.5" y2="576.6" />
    <line x1="291.06" y1="626.37" x2="114.28" y2="460.36" />
    <line x1="177.72" y1="659.35" x2="223.17" y2="421.14" />
    <line x1="85.52" y1="581.73" x2="311.01" y2="492.46" />
    <line x1="98.04" y1="462.63" x2="294.24" y2="605.19" />
    <line x1="201.68" y1="409.67" x2="186.45" y2="651.71" />
    <line x1="298.3" y1="469.03" x2="85.77" y2="585.86" />
    <line x1="296.32" y1="582.58" x2="83.79" y2="465.74" />
    <line x1="195.74" y1="641.86" x2="180.52" y2="399.83" />
    <line x1="88.16" y1="588.77" x2="284.36" y2="446.23" />
    <line x1="71.7" y1="469.49" x2="297.18" y2="558.78" />
    <line x1="159.95" y1="391.62" x2="205.39" y2="629.84" />
    <line x1="269.36" y1="424.29" x2="92.56" y2="590.3" />
    <line x1="296.75" y1="534.01" x2="61.86" y2="473.69" />
    <line x1="215.17" y1="615.7" x2="140.23" y2="385.05" />
    <line x1="98.86" y1="590.29" x2="253.44" y2="403.44" />
    <line x1="54.36" y1="478.13" x2="294.95" y2="508.53" />
    <line x1="121.59" y1="380.07" x2="224.84" y2="599.5" />
    <line x1="236.82" y1="383.83" x2="106.86" y2="588.59" />
    <line x1="291.77" y1="482.62" x2="49.26" y2="482.62" />
    <line x1="234.21" y1="581.38" x2="104.27" y2="376.63" />
    <line x1="116.39" y1="585.09" x2="219.66" y2="365.66" />
    <line x1="46.57" y1="486.91" x2="287.17" y2="456.52" />
    <line x1="88.48" y1="374.62" x2="243.06" y2="561.48" />
    <line x1="202.21" y1="349.04" x2="127.27" y2="579.68" />
    <line x1="281.21" y1="430.51" x2="46.3" y2="490.82" />
    <line x1="251.21" y1="539.95" x2="74.44" y2="373.94" />
    <line x1="139.24" y1="572.31" x2="184.69" y2="334.1" />
    <line x1="48.41" y1="494.09" x2="273.9" y2="404.82" />
    <line x1="62.31" y1="374.42" x2="258.5" y2="516.98" />
    <line x1="167.34" y1="320.92" x2="152.1" y2="562.96" />
    <line x1="265.35" y1="379.75" x2="52.82" y2="496.57" />
    <line x1="264.78" y1="492.78" x2="52.27" y2="375.94" />
    <line x1="165.62" y1="551.58" x2="150.4" y2="309.55" />
    <line x1="59.46" y1="498.04" x2="255.66" y2="355.5" />
    <line x1="44.43" y1="378.31" x2="269.92" y2="467.6" />
    <line x1="134.12" y1="300.03" x2="179.56" y2="538.25" />
    <line x1="244.97" y1="332.31" x2="68.17" y2="498.31" />
    <line x1="273.81" y1="441.65" x2="38.91" y2="381.34" />
    <line x1="193.68" y1="522.98" x2="118.74" y2="292.33" />
    <line x1="78.82" y1="497.26" x2="233.42" y2="310.4" />
    <line x1="35.79" y1="384.81" x2="276.39" y2="415.19" />
    <line x1="104.49" y1="286.45" x2="207.74" y2="505.88" />
    <line x1="221.18" y1="289.96" x2="91.24" y2="494.72" />
    <line x1="277.61" y1="388.52" x2="35.09" y2="388.52" />
    <line x1="221.54" y1="487.07" x2="91.6" y2="282.3" />
    <line x1="105.22" y1="490.58" x2="208.47" y2="271.15" />
    <line x1="36.86" y1="392.24" x2="277.47" y2="361.85" />
    <line x1="80.27" y1="279.8" x2="234.85" y2="466.66" />
    <line x1="195.49" y1="254.12" x2="120.53" y2="484.75" />
    <line x1="275.97" y1="335.48" x2="41.07" y2="395.79" />
    <line x1="247.48" y1="444.85" x2="70.71" y2="278.84" />
    <line x1="136.99" y1="477.17" x2="182.44" y2="238.95" />
    <line x1="47.67" y1="398.93" x2="273.15" y2="309.66" />
    <line x1="63.05" y1="279.26" x2="259.26" y2="421.81" />
    <line x1="169.58" y1="225.78" x2="154.35" y2="467.81" />
    <line x1="269.08" y1="284.65" x2="56.57" y2="401.48" />
    <line x1="270.02" y1="397.76" x2="57.49" y2="280.93" />
    <line x1="172.34" y1="456.66" x2="157.12" y2="214.62" />
    <line x1="67.67" y1="403.22" x2="263.87" y2="260.68" />
    <line x1="54.13" y1="283.64" x2="279.62" y2="372.92" />
    <line x1="145.3" y1="205.52" x2="190.75" y2="443.74" />
    <line x1="257.64" y1="237.99" x2="80.84" y2="404" />
    <line x1="287.95" y1="347.54" x2="53.06" y2="287.22" />
    <line x1="209.3" y1="429.1" x2="134.36" y2="198.46" />
    <line x1="95.92" y1="403.64" x2="250.52" y2="216.78" />
    <line x1="54.36" y1="291.46" x2="294.95" y2="321.87" />
    <line x1="124.52" y1="193.42" x2="227.77" y2="412.85" />
    <line x1="242.67" y1="197.25" x2="112.73" y2="402.01" />
    <line x1="300.57" y1="296.15" x2="58.05" y2="296.15" />
    <line x1="245.93" y1="395.08" x2="115.99" y2="190.31" />
    <line x1="131.04" y1="398.98" x2="234.31" y2="179.55" />
    <line x1="64.14" y1="301.06" x2="304.75" y2="270.67" />
    <line x1="108.96" y1="189.07" x2="263.55" y2="375.93" />
    <line x1="225.61" y1="163.83" x2="150.65" y2="394.48" />
    <line x1="307.51" y1="245.68" x2="72.6" y2="305.99" />
    <line x1="280.43" y1="355.57" x2="103.64" y2="189.56" />
    <line x1="171.34" y1="388.42" x2="216.79" y2="150.2" />
    <line x1="83.39" y1="310.73" x2="308.87" y2="221.46" />
    <line x1="100.17" y1="191.64" x2="296.36" y2="334.17" />
    <line x1="208.06" y1="138.74" x2="192.83" y2="380.77" />
    <line x1="308.93" y1="198.23" x2="96.41" y2="315.05" />
    <line x1="311.2" y1="311.97" x2="98.69" y2="195.13" />
    <line x1="214.88" y1="371.53" x2="199.66" y2="129.49" />
    <line x1="111.54" y1="318.77" x2="307.75" y2="176.23" />
    <line x1="99.32" y1="199.89" x2="324.81" y2="289.17" />
    <line x1="191.81" y1="122.49" x2="237.25" y2="360.71" />
    <line x1="305.43" y1="155.7" x2="128.64" y2="321.71" />
    <line x1="337.04" y1="266.01" x2="102.15" y2="205.7" />
    <line x1="259.66" y1="348.36" x2="184.73" y2="117.71" />
    <line x1="147.54" y1="323.69" x2="302.13" y2="136.83" />
    <line x1="107.22" y1="212.33" x2="347.83" y2="242.73" />
    <line x1="178.63" y1="115.13" x2="281.89" y2="334.56" />
    <line x1="298" y1="119.82" x2="168.06" y2="324.58" />
    <line x1="357.1" y1="219.59" x2="114.58" y2="219.59" />
    <line x1="303.68" y1="319.43" x2="173.72" y2="114.66" />
    <line x1="189.96" y1="324.25" x2="293.22" y2="104.82" />
    <line x1="124.23" y1="227.26" x2="364.82" y2="196.88" />
    <line x1="170.21" y1="116.22" x2="324.79" y2="303.08" />
    <line x1="287.98" y1="91.96" x2="213.04" y2="322.59" />
    <line x1="371" y1="174.8" x2="136.1" y2="235.11" />
    <line x1="345.03" y1="285.69" x2="168.24" y2="119.68" />
    <line x1="237.03" y1="319.55" x2="282.48" y2="81.33" />
    <line x1="150.15" y1="242.91" x2="375.65" y2="153.63" />
    <line x1="167.99" y1="124.88" x2="364.19" y2="267.41" />
    <line x1="276.92" y1="73.04" x2="261.7" y2="315.08" />
    <line x1="378.81" y1="133.63" x2="166.29" y2="250.45" />
    <line x1="382.1" y1="248.47" x2="169.58" y2="131.65" />
    <line x1="286.77" y1="309.16" x2="271.54" y2="67.12" />
    <line x1="184.4" y1="257.53" x2="380.6" y2="114.99" />
    <line x1="173.12" y1="139.8" x2="398.61" y2="229.09" />
    <line x1="266.55" y1="63.56" x2="311.98" y2="301.78" />
    <line x1="381.09" y1="97.96" x2="204.3" y2="263.97" />
    <line x1="413.6" y1="209.46" x2="178.7" y2="149.15" />
    <line x1="337.1" y1="293.03" x2="262.16" y2="62.38" />
    <line x1="225.83" y1="269.59" x2="380.43" y2="82.73" />
    <line x1="186.36" y1="159.47" x2="426.96" y2="189.87" />
    <line x1="258.59" y1="63.51" x2="361.84" y2="282.94" />
    <line x1="378.76" y1="69.46" x2="248.8" y2="274.21" />
    <line x1="438.64" y1="170.52" x2="196.12" y2="170.51" />
    <line x1="385.96" y1="271.62" x2="256.02" y2="66.87" />
    <line x1="273" y1="277.74" x2="376.26" y2="58.32" />
    <line x1="207.98" y1="182.07" x2="448.59" y2="151.68" />
    <line x1="254.66" y1="72.35" x2="409.24" y2="259.2" />
    <line x1="373.11" y1="49.42" x2="298.17" y2="280.05" />
    <line x1="456.8" y1="133.6" x2="221.89" y2="193.91" />
    <line x1="431.46" y1="245.84" x2="254.67" y2="79.83" />
    <line x1="324.07" y1="281.07" x2="369.52" y2="42.87" />
    <line x1="237.79" y1="205.8" x2="463.28" y2="116.53" />
    <line x1="256.19" y1="89.14" x2="452.39" y2="231.69" />
    <line x1="365.69" y1="38.71" x2="350.45" y2="280.74" />
    <line x1="468.1" y1="100.68" x2="255.58" y2="217.52" />
    <line x1="471.89" y1="216.95" x2="259.37" y2="100.11" />
    <line x1="377.04" y1="279.04" x2="361.82" y2="37" />
    <line x1="275.13" y1="228.83" x2="471.33" y2="86.3" />
    <line x1="264.3" y1="112.54" x2="489.79" y2="201.82" />
    <line x1="358.14" y1="37.74" x2="403.58" y2="275.96" />
    <line x1="473.08" y1="73.57" x2="296.29" y2="239.58" />
    <line x1="505.96" y1="186.52" x2="271.06" y2="126.21" />
    <line x1="429.81" y1="271.54" x2="354.87" y2="40.89" />
    <line x1="318.88" y1="249.56" x2="473.47" y2="62.7" />
    <line x1="279.7" y1="140.9" x2="520.3" y2="171.29" />
    <line x1="352.21" y1="46.41" x2="455.46" y2="265.84" />
    <line x1="472.63" y1="53.84" x2="342.69" y2="258.59" />
    <line x1="532.74" y1="156.36" x2="290.23" y2="156.36" />
    <line x1="480.3" y1="258.95" x2="350.34" y2="54.2" />
    <line x1="367.51" y1="266.56" x2="470.76" y2="47.13" />
    <line x1="302.66" y1="172.37" x2="543.25" y2="141.98" />
    <line x1="349.48" y1="64.14" x2="504.06" y2="251" />
    <line x1="468.04" y1="42.7" x2="393.1" y2="273.34" />
    <line x1="551.81" y1="128.37" x2="316.92" y2="188.68" />
    <line x1="526.56" y1="242.1" x2="349.77" y2="76.09" />
    <line x1="419.22" y1="278.83" x2="464.66" y2="40.62" />
    <line x1="332.96" y1="205.06" x2="558.44" y2="115.77" />
    <line x1="351.36" y1="89.89" x2="547.56" y2="232.43" />
    <line x1="460.82" y1="40.96" x2="445.59" y2="282.98" />
    <line x1="563.2" y1="104.42" x2="350.67" y2="221.25" />
    <line x1="566.92" y1="222.17" x2="354.4" y2="105.35" />
    <line x1="471.97" y1="285.76" x2="456.74" y2="43.72" />
    <line x1="369.95" y1="237.06" x2="566.15" y2="94.51" />
    <line x1="358.98" y1="122.24" x2="584.47" y2="211.51" />
    <line x1="452.66" y1="48.93" x2="498.09" y2="287.13" />
    <line x1="567.41" y1="86.24" x2="390.62" y2="252.25" />
    <line x1="600.08" y1="200.67" x2="365.17" y2="140.36" />
    <line x1="523.68" y1="287.16" x2="448.74" y2="56.53" />
    <line x1="412.5" y1="266.65" x2="567.09" y2="79.79" />
    <line x1="373.04" y1="159.47" x2="613.64" y2="189.87" />
    <line x1="445.24" y1="66.44" x2="548.49" y2="285.87" />
    <line x1="565.34" y1="75.33" x2="435.4" y2="280.08" />
    <line x1="625.11" y1="179.31" x2="382.59" y2="179.31" />
    <line x1="572.29" y1="283.34" x2="442.33" y2="78.59" />
    <line x1="459.11" y1="292.39" x2="562.36" y2="72.96" />
    <line x1="393.83" y1="199.64" x2="634.43" y2="169.25" />
    <line x1="440.21" y1="92.83" x2="594.79" y2="279.69" />
    <line x1="558.32" y1="72.82" x2="483.38" y2="303.46" />
    <line x1="641.61" y1="159.9" x2="406.72" y2="220.22" />
    <line x1="615.84" y1="275.04" x2="439.05" y2="109.03" />
    <line x1="507.98" y1="313.17" x2="553.41" y2="74.95" />
    <line x1="421.17" y1="240.78" x2="646.66" y2="151.51" />
    <line x1="439" y1="126.99" x2="635.2" y2="269.55" />
    <line x1="547.87" y1="79.44" x2="532.63" y2="321.46" />
    <line x1="649.63" y1="144.26" x2="437.1" y2="261.09" />
    <line x1="652.71" y1="263.37" x2="440.2" y2="146.53" />
    <line x1="557.11" y1="328.29" x2="541.89" y2="86.25" />
    <line x1="454.4" y1="280.92" x2="650.6" y2="138.38" />
    <line x1="442.73" y1="167.43" x2="668.22" y2="256.71" />
    <line x1="535.68" y1="95.43" x2="581.12" y2="333.64" />
    <line x1="649.7" y1="134.05" x2="472.91" y2="300.05" />
    <line x1="681.6" y1="249.75" x2="446.71" y2="189.45" />
    <line x1="604.44" y1="337.52" x2="529.5" y2="106.88" />
    <line x1="492.45" y1="318.27" x2="647.03" y2="131.41" />
    <line x1="452.17" y1="212.33" x2="692.78" y2="242.73" />
    <line x1="523.53" y1="120.54" x2="626.78" y2="339.99" />
    <line x1="642.78" y1="130.66" x2="512.83" y2="335.41" />
    <line x1="701.67" y1="235.85" x2="459.15" y2="235.85" />
    <line x1="647.94" y1="341.09" x2="517.98" y2="136.32" />
    <line x1="533.84" y1="351.32" x2="637.1" y2="131.89" />
    <line x1="467.64" y1="259.72" x2="708.23" y2="229.34" />
    <line x1="513.06" y1="154.07" x2="667.65" y2="340.93" />
    <line x1="630.2" y1="135.19" x2="555.25" y2="365.83" />
    <line x1="712.5" y1="223.4" x2="477.61" y2="283.71" />
    <line x1="685.72" y1="339.64" x2="508.94" y2="173.63" />
    <line x1="576.83" y1="378.86" x2="622.28" y2="140.65" />
    <line x1="488.99" y1="307.54" x2="714.48" y2="218.27" />
    <line x1="505.76" y1="194.81" x2="701.96" y2="337.37" />
    <line x1="613.55" y1="148.29" x2="598.32" y2="390.33" />
    <line x1="714.23" y1="214.14" x2="501.7" y2="330.97" />
    <line x1="716.21" y1="334.26" x2="503.68" y2="217.42" />
    <line x1="619.48" y1="400.17" x2="604.26" y2="158.14" />
    <line x1="515.64" y1="353.77" x2="711.84" y2="211.23" />
    <line x1="502.82" y1="241.22" x2="728.3" y2="330.51" />
    <line x1="594.61" y1="170.16" x2="640.05" y2="408.38" />
    <line x1="707.44" y1="209.7" x2="530.64" y2="375.71" />
    <line x1="738.14" y1="326.31" x2="503.25" y2="265.99" />
    <line x1="659.77" y1="414.95" x2="584.83" y2="184.3" />
    <line x1="546.56" y1="396.56" x2="701.14" y2="209.71" />
    <line x1="505.05" y1="291.46" x2="745.64" y2="321.87" />
    <line x1="575.16" y1="200.5" x2="678.41" y2="419.93" />
    <line x1="693.14" y1="211.41" x2="563.18" y2="416.17" />
    <line x1="750.74" y1="317.38" x2="508.23" y2="317.38" />
    <line x1="695.73" y1="423.37" x2="565.79" y2="218.62" />
    <line x1="580.34" y1="434.34" x2="683.61" y2="214.91" />
    <line x1="512.83" y1="343.47" x2="753.43" y2="313.09" />
    <line x1="556.94" y1="238.52" x2="711.52" y2="425.38" />
    <line x1="672.73" y1="220.32" x2="597.79" y2="450.96" />
    <line x1="753.7" y1="309.2" x2="518.79" y2="369.49" />
    <line x1="725.57" y1="426.06" x2="548.77" y2="260.05" />
    <line x1="615.31" y1="465.9" x2="660.76" y2="227.69" />
    <line x1="526.1" y1="395.18" x2="751.58" y2="305.91" />
    <line x1="541.5" y1="283.02" x2="737.69" y2="425.58" />
    <line x1="647.9" y1="237.04" x2="632.66" y2="479.08" />
    <line x1="747.16" y1="303.43" x2="534.65" y2="420.27" />
    <line x1="747.73" y1="424.04" x2="535.22" y2="307.22" />
    <line x1="649.6" y1="490.45" x2="634.38" y2="248.42" />
    <line x1="544.34" y1="444.5" x2="740.54" y2="301.96" />
    <line x1="530.08" y1="332.4" x2="755.57" y2="421.69" />
    <line x1="620.44" y1="261.75" x2="665.88" y2="499.97" />
    <line x1="731.82" y1="301.69" x2="555.03" y2="467.69" />
    <line x1="761.09" y1="418.66" x2="526.19" y2="358.36" />
    <line x1="681.26" y1="507.67" x2="606.32" y2="277.02" />
    <line x1="566.58" y1="489.6" x2="721.17" y2="302.74" />
  </svg>,
  'MycaIcon',
)
