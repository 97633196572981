import {
  ArrowForwardRounded,
  OpenInNewRounded,
} from '@mui/icons-material'
import {
  Box,
  Card,
  Dialog,
  Fade,
  Slide, Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { FlexCol, FlexGrowCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import type { DropdownSectionDataProps } from '../../props/index.ts'
import { SlideInArrow } from '../AnimatedComponents/index.ts'
import { SectionAppBar } from './SectionAppBar.tsx'
import { SlideTransition } from './Transition.tsx'

export interface MobileNavSectionDialogProps extends DropdownSectionDataProps {
  closeAllDialogs: () => void
}

export const MobileNavSectionDialog: React.FC<MobileNavSectionDialogProps> = ({
  closeAllDialogs,
  colItems,
  cta,
  name,
  title,
  to,
}) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleClickOpen = () => {
    if (colItems && colItems.items.length > 0) {
      setOpen(true)
    } else if (to) {
      navigate(to)
      setOpen(false)
      handleAllClose()
    }
  }

  const handleGoBack = () => {
    setOpen(false)
  }

  const handleAllClose = () => {
    setOpen(false)
    closeAllDialogs()
  }

  return (
    <React.Fragment>
      <LinkEx component="button" color="inherit" onClick={handleClickOpen}>
        <Typography fontWeight={theme.typography.fontWeightMedium} variant="h3">{name}</Typography>
      </LinkEx>
      <Dialog
        fullScreen
        open={open}
        onClose={handleAllClose}
        TransitionComponent={SlideTransition}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.paper, backgroundImage: 'none', paddingLeft: 2, paddingRight: 2,
          },
        }}
      >
        <SectionAppBar handleGoBack={handleGoBack} handleAllClose={handleAllClose} name={name} />
        <Stack gap={3} flexDirection="column" alignItems="stretch">
          <Stack gap={3} flexDirection="column" alignItems="flex-start">
            <Typography fontWeight={theme.typography.fontWeightMedium} variant="h3">{title}</Typography>
            <FlexCol>
              {colItems?.title ? <Typography fontWeight={theme.typography.fontWeightMedium} variant="body2">{colItems?.title}</Typography> : null}
              <Stack gap={2} flexDirection="column">
                {colItems?.items.map((item, index) => {
                  const [hover, setHover] = React.useState(false)
                  return (
                    <Slide key={index} in timeout={index === 0 ? 600 : (index + 1) * 400} direction="right">
                      <LinkEx
                        to={item.to}
                        href={item.href}
                        color={hover ? 'secondary' : 'inherit'}
                        onTouchStart={() => setHover(true)}
                        onTouchEnd={() => setHover(false)}
                      >
                        <Stack flexDirection="row" gap={0.5} alignItems="center">
                          <Typography variant="body1" color={hover ? theme.palette.secondary.main : 'inherit'}>{item.linkText}</Typography>
                          {item.to ? <ArrowForwardRounded fontSize="small" /> : <OpenInNewRounded fontSize="small" />}
                        </Stack>
                      </LinkEx>
                    </Slide>
                  )
                })}
              </Stack>
            </FlexCol>
          </Stack>
          {cta && (
            <Fade in timeout={2000}>
              <LinkEx to={cta.ctaLink}>
                <Card
                  sx={{ bgcolor: theme.palette.primary.main }}
                >
                  <FlexGrowCol
                    padding={1}
                    height="200px"
                    overflow="hidden"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    bgcolor={theme.palette.primary.main}
                  >
                    <Fade in={true} timeout={1000}>
                      <Slide in={true} direction="right" timeout={1000}>
                        <Box maxHeight="100%">{cta?.ctaIcon}</Box>
                      </Slide>
                    </Fade>
                    <Typography variant="body2" color={theme.palette.primary.contrastText}>
                      {cta?.ctaTitle}
                    </Typography>
                    <Typography variant="caption" color={theme.palette.primary.contrastText} display="flex" alignItems="center">
                      <span>
                        {cta?.ctaText}
                        <SlideInArrow inTrigger={true} />
                      </span>
                    </Typography>
                  </FlexGrowCol>
                </Card>
              </LinkEx>
            </Fade>
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  )
}
