import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

import { DataismBasicHero } from '../../../../common/index.ts'

export const HomeHero: React.FC<BusyBoxProps> = ({ ...props }) => {
  return (
    <FlexCol
      alignItems="stretch"
      sx={{
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: { xs: '600px', md: '700px' },
      }}
      {...props}
    >
      <DataismBasicHero
        title="Reclaiming the Human Side of Data"
        desc="Your data is not just numbers. It's a part of you.
        Learn how to control, protect, and make the most of your data while staying true to who you are."
        button1To="/quiz"
        button1Text="Take the Quiz"
        button2To="#learn-more"
        button2Text="Learn More"
      />
    </FlexCol>
  )
}
