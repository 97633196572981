// eslint-disable-next-line import-x/no-internal-modules
import { initializeApp } from 'firebase/app'
import {
  connectAuthEmulator, getAuth,
// eslint-disable-next-line import-x/no-internal-modules
} from 'firebase/auth'

// NOTE: This is OK to include with the site as it's standard Firebase
// practice to include these config values in the client-side code
const firebaseConfig = {
  apiKey: 'AIzaSyAoy51aTcNEgq38I7w7gqZpWNPK4moCgRA',
  appId: '1:108377663731:web:4de3c50c2a300b755b6f86',
  authDomain: 'dataism-27ba0.firebaseapp.com',
  // authDomain: 'localhost:3000',
  measurementId: 'G-3RFG37B02F',
  messagingSenderId: '108377663731',
  projectId: 'dataism-27ba0',
  storageBucket: 'dataism-27ba0.appspot.com',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
// export const auth = initializeAuth(app, {
//   persistence: browserLocalPersistence,
//   popupRedirectResolver: undefined,
// })
// TODO: Remove this to connect to real Firebase Auth
connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })

export { auth }
