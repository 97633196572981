import { type DrawerProps } from '@mui/material'
import React from 'react'

import { BaseDrawer } from '../../../common/index.ts'
import { DashboardMenuList } from './MenuList.tsx'

export interface DashboardDrawerProps extends DrawerProps {}

export const DashboardDrawer: React.FC<DashboardDrawerProps> = ({ children, ...props }) => {
  return (
    <BaseDrawer {...props}>
      <DashboardMenuList sx={{ mt: 2 }} />
      {children}
    </BaseDrawer>
  )
}
