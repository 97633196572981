// eslint-disable-next-line import-x/no-internal-modules
import type { FirebaseError } from 'firebase/app'
// eslint-disable-next-line import-x/no-internal-modules
import type { Auth } from 'firebase/auth'
import {
  browserLocalPersistence, setPersistence, signInWithEmailAndPassword,
// eslint-disable-next-line import-x/no-internal-modules
} from 'firebase/auth'

/**
 * Signs in a user with their email and password.
 * @param auth The Auth context to use
 * @param email The email of the user to sign in
 * @param password The password of the user to sign in
 */
export const loginWithEmailAndPassword = async (auth: Auth, email: string, password: string) => {
  // TODO: Should we alert them that they are already logged in?
  // Are they trying to log in again? As someone else?
  try {
    await setPersistence(auth, browserLocalPersistence)
    await signInWithEmailAndPassword(auth, email, password)
  } catch (e) {
    // TODO: Handle different cases (no account exists)
    const error = e as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message
    console.error(errorCode, errorMessage)
  }
}
