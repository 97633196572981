import {
  Box, Container, Stack, TextField, Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol } from '@xylabs/react-flexbox'
import React, { useState } from 'react'

import { DataismBasePage, FullPageResponsiveBox } from '../../../../common/index.ts'
import { useAuth, useUser } from '../../../../contexts/index.ts'
import {
  createWithEmailAndPassword, loginWithEmailAndPassword, loginWithGoogle, logout,
} from './Users/index.ts'

export const AccountPage: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const auth = useAuth()
  const { user, loading } = useUser()

  if (loading) return <div />

  return (
    <DataismBasePage maxWidth="xl" title="Account">
      <FullPageResponsiveBox id="account">
        <FlexCol
          sx={{
            backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', alignItems: 'stretch',
          }}
        >
          <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack flexDirection="column" alignItems="flex-start" gap={2}>
              <Typography variant="body1">{user ? `Logged In: ${user.email}` : 'Logged Out'}</Typography>
              <Box
                component="form"
                sx={{
                  display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, maxWidth: 400, margin: '0 auto',
                }}
              >
                <TextField label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} required fullWidth />
                <TextField label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} required fullWidth />
                <ButtonEx variant="outlined" onClick={() => createWithEmailAndPassword(auth, email, password)}>
                  Create Account
                </ButtonEx>
                <ButtonEx variant="outlined" onClick={() => loginWithEmailAndPassword(auth, email, password)}>
                  Login
                </ButtonEx>
                <ButtonEx variant="outlined" onClick={() => loginWithGoogle(auth)}>
                  Google SSO
                </ButtonEx>
                <ButtonEx variant="outlined" onClick={() => logout(auth)}>
                  Log Out
                </ButtonEx>
              </Box>
            </Stack>
          </Container>
        </FlexCol>
      </FullPageResponsiveBox>
    </DataismBasePage>
  )
}
