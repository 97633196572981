import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { DataistPersonaData } from '../../common/index.ts'
import { Four04Page } from '../resources/index.ts'
import { DynamicPersonaPage } from './pages/index.ts'

export const PersonaRoutes: React.FC<RouteProps> = (props) => {
  return (
    <Routes {...props}>
      {DataistPersonaData.map((persona, index) => {
        return (
          <Route key={index} element={<DynamicPersonaPage persona={persona} />} path={`/${persona.name}`} />
        )
      })}
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}
