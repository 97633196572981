import { Typography } from '@mui/material'
import React from 'react'

import { DashboardPage, type DashboardPageProps } from '../../../layout/index.ts'

export const JourneyDetailPage: React.FC<DashboardPageProps> = ({ children, ...props }) => {
  return (
    <DashboardPage title="Journey - Sample" {...props}>
      <Typography variant="h1">JourneyDetailPage</Typography>
      {children}
    </DashboardPage>
  )
}
