import type { Theme, ToolbarProps } from '@mui/material'
import { Toolbar, useMediaQuery } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexRow } from '@xylabs/react-flexbox'
import { DarkModeIconButtonForColorScheme } from '@xylabs/react-invertible-theme'
import type { ReactNode } from 'react'
import React from 'react'
import type { To } from 'react-router-dom'

interface XYOToolbarProps extends ToolbarProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const SystemToolBar: React.FC<XYOToolbarProps> = ({
  ctaLink, ctaText, customButton, children, ...props
}) => {
  const isMedium = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'))
  const ctaLinkTypeTo: boolean = ctaLink?.toString().includes('https') ? false : true
  return (
    <Toolbar disableGutters {...props}>
      <FlexRow gap={1}>
        {customButton
        ?? (
          <ButtonEx
            flexWrap="nowrap"
            color="primary"
            variant="contained"
            sx={{ display: isMedium ? 'none' : 'flex' }}
            target={ctaLinkTypeTo ? undefined : '_blank'}
            href={ctaLinkTypeTo ? '/quiz' : ctaLink?.toString()}
            to={ctaLinkTypeTo ? ctaLink : '/quiz'}
            intent="Take the Quiz"
            placement="navbar-cta"
          >
            {ctaText ?? 'Take the Quiz'}
          </ButtonEx>
        )}
        {children}
        <DarkModeIconButtonForColorScheme size="small" />
      </FlexRow>
    </Toolbar>
  )
}
