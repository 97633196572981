import { createSvgIcon } from '@mui/material'
import React from 'react'

export const LuxIcon = createSvgIcon(
  <svg id="LuxIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" stroke="currentColor" fill="none">
    <line x1="646.25" y1="279.5" x2="638.68" y2="520.5" />
    <line x1="716.31" y1="308.56" x2="568.56" y2="499.06" />
    <line x1="758.1" y1="373.98" x2="526.59" y2="441.25" />
    <line x1="755.6" y1="452.26" x2="528.78" y2="370.58" />
    <line x1="709.74" y1="514.93" x2="574.23" y2="315.51" />
    <line x1="637.93" y1="539.53" x2="645.5" y2="298.52" />
    <line x1="567.51" y1="518.08" x2="715.27" y2="327.57" />
    <line x1="525.25" y1="460.25" x2="756.75" y2="392.97" />
    <line x1="527.13" y1="389.55" x2="753.97" y2="471.23" />
    <line x1="572.29" y1="334.46" x2="707.8" y2="533.87" />
    <line x1="643.27" y1="317.44" x2="635.69" y2="558.43" />
    <line x1="712.74" y1="346.43" x2="564.97" y2="536.95" />
    <line x1="753.93" y1="411.81" x2="522.41" y2="479.07" />
    <line x1="750.84" y1="490.02" x2="524.02" y2="408.34" />
    <line x1="704.38" y1="552.61" x2="568.87" y2="353.19" />
    <line x1="631.97" y1="577.11" x2="639.55" y2="336.12" />
    <line x1="560.97" y1="555.56" x2="708.73" y2="365.05" />
    <line x1="518.11" y1="497.62" x2="749.62" y2="430.36" />
    <line x1="519.42" y1="426.81" x2="746.25" y2="508.49" />
    <line x1="563.99" y1="371.59" x2="699.49" y2="571.01" />
    <line x1="634.39" y1="354.44" x2="626.81" y2="595.44" />
    <line x1="703.27" y1="383.29" x2="555.51" y2="573.81" />
    <line x1="743.88" y1="448.51" x2="512.38" y2="515.78" />
    <line x1="740.22" y1="526.56" x2="513.4" y2="444.88" />
    <line x1="693.19" y1="588.98" x2="557.68" y2="389.57" />
    <line x1="620.23" y1="613.3" x2="627.79" y2="372.3" />
    <line x1="548.64" y1="591.56" x2="696.4" y2="401.05" />
    <line x1="505.23" y1="533.43" x2="736.73" y2="466.15" />
    <line x1="505.98" y1="462.41" x2="732.8" y2="544.09" />
    <line x1="549.98" y1="406.97" x2="685.5" y2="606.4" />
    <line x1="619.82" y1="389.59" x2="612.25" y2="630.6" />
    <line x1="688.16" y1="418.21" x2="540.41" y2="608.73" />
    <line x1="728.23" y1="483.18" x2="496.72" y2="550.46" />
    <line x1="724.03" y1="560.99" x2="497.19" y2="479.3" />
    <line x1="676.45" y1="623.15" x2="540.95" y2="423.74" />
    <line x1="602.95" y1="647.2" x2="610.53" y2="406.21" />
    <line x1="530.83" y1="625.19" x2="678.6" y2="434.68" />
    <line x1="486.9" y1="566.77" x2="718.4" y2="499.51" />
    <line x1="487.12" y1="495.46" x2="713.95" y2="577.15" />
    <line x1="530.63" y1="439.73" x2="666.13" y2="639.15" />
    <line x1="599.94" y1="422.05" x2="592.36" y2="663.04" />
    <line x1="667.78" y1="450.34" x2="520.01" y2="640.86" />
    <line x1="707.33" y1="515" x2="475.83" y2="582.26" />
    <line x1="702.64" y1="592.46" x2="475.81" y2="510.79" />
    <line x1="654.57" y1="654.29" x2="519.07" y2="454.86" />
    <line x1="580.59" y1="677.99" x2="588.15" y2="437" />
    <line x1="508" y1="655.62" x2="655.75" y2="465.11" />
    <line x1="463.58" y1="596.84" x2="695.08" y2="529.58" />
    <line x1="463.33" y1="525.17" x2="690.17" y2="606.84" />
    <line x1="506.37" y1="469.05" x2="641.87" y2="668.47" />
    <line x1="575.24" y1="450.98" x2="567.66" y2="691.97" />
    <line x1="642.62" y1="478.89" x2="494.85" y2="669.41" />
    <line x1="681.73" y1="543.14" x2="450.22" y2="610.41" />
    <line x1="676.59" y1="620.2" x2="449.77" y2="538.52" />
    <line x1="628.1" y1="681.62" x2="492.59" y2="482.19" />
    <line x1="553.68" y1="704.9" x2="561.26" y2="463.89" />
    <line x1="480.68" y1="682.1" x2="628.43" y2="491.6" />
    <line x1="435.84" y1="622.89" x2="667.35" y2="555.61" />
    <line x1="435.19" y1="550.78" x2="662.03" y2="632.45" />
    <line x1="477.82" y1="494.21" x2="613.33" y2="693.63" />
    <line x1="546.31" y1="475.69" x2="538.73" y2="716.69" />
    <line x1="613.3" y1="503.14" x2="465.53" y2="693.66" />
    <line x1="652.04" y1="566.94" x2="420.53" y2="634.2" />
    <line x1="646.54" y1="643.52" x2="419.7" y2="561.85" />
    <line x1="597.67" y1="704.45" x2="462.17" y2="505.04" />
    <line x1="522.89" y1="727.26" x2="530.47" y2="486.26" />
    <line x1="449.55" y1="703.98" x2="597.31" y2="513.48" />
    <line x1="404.38" y1="644.27" x2="635.88" y2="577.01" />
    <line x1="403.39" y1="571.66" x2="630.22" y2="653.34" />
    <line x1="445.71" y1="514.6" x2="581.21" y2="714.03" />
    <line x1="513.86" y1="495.57" x2="506.3" y2="736.56" />
    <line x1="580.54" y1="522.51" x2="432.79" y2="713.03" />
    <line x1="618.99" y1="585.78" x2="387.49" y2="653.06" />
    <line x1="613.19" y1="661.86" x2="386.37" y2="580.18" />
    <line x1="564.04" y1="722.26" x2="428.54" y2="522.84" />
    <line x1="489" y1="744.54" x2="496.57" y2="503.54" />
    <line x1="415.38" y1="720.72" x2="563.13" y2="530.21" />
    <line x1="369.95" y1="660.47" x2="601.45" y2="593.21" />
    <line x1="368.72" y1="587.32" x2="595.55" y2="668.99" />
    <line x1="410.78" y1="529.72" x2="546.3" y2="729.13" />
    <line x1="478.71" y1="510.14" x2="471.15" y2="751.12" />
    <line x1="545.17" y1="536.52" x2="397.41" y2="727.04" />
    <line x1="583.4" y1="599.24" x2="351.9" y2="666.5" />
    <line x1="577.39" y1="674.74" x2="350.57" y2="593.07" />
    <line x1="528.05" y1="734.59" x2="392.55" y2="535.18" />
    <line x1="452.82" y1="756.29" x2="460.39" y2="515.3" />
    <line x1="379.01" y1="731.92" x2="526.77" y2="541.4" />
    <line x1="333.41" y1="671.1" x2="564.92" y2="603.82" />
    <line x1="332.03" y1="597.37" x2="558.85" y2="679.04" />
    <line x1="373.94" y1="539.18" x2="509.44" y2="738.6" />
    <line x1="441.72" y1="519.02" x2="434.14" y2="760.01" />
    <line x1="508.04" y1="544.82" x2="360.28" y2="735.34" />
    <line x1="546.14" y1="606.96" x2="314.64" y2="674.22" />
    <line x1="540.02" y1="681.87" x2="313.2" y2="600.19" />
    <line x1="490.57" y1="741.13" x2="355.07" y2="541.72" />
    <line x1="415.24" y1="762.25" x2="422.81" y2="521.25" />
    <line x1="341.34" y1="737.28" x2="489.1" y2="546.76" />
    <line x1="295.67" y1="675.87" x2="527.18" y2="608.59" />
    <line x1="294.2" y1="601.54" x2="521.02" y2="683.21" />
    <line x1="336.06" y1="542.76" x2="471.56" y2="742.17" />
    <line x1="403.78" y1="522" x2="396.22" y2="763" />
    <line x1="470.06" y1="547.22" x2="322.31" y2="737.72" />
    <line x1="508.14" y1="608.74" x2="276.63" y2="676.02" />
    <line x1="501.99" y1="683.07" x2="275.17" y2="601.38" />
    <line x1="452.52" y1="741.73" x2="317.02" y2="542.31" />
    <line x1="377.19" y1="762.25" x2="384.76" y2="521.25" />
    <line x1="303.31" y1="736.69" x2="451.06" y2="546.16" />
    <line x1="257.64" y1="674.66" x2="489.14" y2="607.4" />
    <line x1="256.2" y1="599.75" x2="483.02" y2="681.42" />
    <line x1="298.08" y1="540.37" x2="433.58" y2="739.79" />
    <line x1="365.86" y1="519.02" x2="358.28" y2="760.01" />
    <line x1="432.2" y1="543.63" x2="284.44" y2="734.15" />
    <line x1="470.31" y1="604.57" x2="238.81" y2="671.83" />
    <line x1="464.25" y1="678.31" x2="237.43" y2="596.62" />
    <line x1="414.87" y1="736.37" x2="279.35" y2="536.95" />
    <line x1="339.61" y1="756.29" x2="347.18" y2="515.3" />
    <line x1="265.83" y1="730.13" x2="413.58" y2="539.62" />
    <line x1="220.27" y1="667.54" x2="451.78" y2="600.28" />
    <line x1="218.94" y1="592.03" x2="445.76" y2="673.71" />
    <line x1="260.95" y1="532.07" x2="396.45" y2="731.49" />
    <line x1="328.87" y1="510.14" x2="321.29" y2="751.12" />
    <line x1="395.34" y1="534.16" x2="247.58" y2="724.69" />
    <line x1="433.63" y1="594.53" x2="202.12" y2="661.8" />
    <line x1="427.71" y1="667.68" x2="200.89" y2="586" />
    <line x1="378.5" y1="725.18" x2="242.99" y2="525.75" />
    <line x1="303.43" y1="744.54" x2="311" y2="503.54" />
    <line x1="229.83" y1="717.81" x2="377.58" y2="527.29" />
    <line x1="184.47" y1="654.65" x2="415.97" y2="587.38" />
    <line x1="183.35" y1="578.58" x2="410.18" y2="660.26" />
    <line x1="225.58" y1="518.06" x2="361.08" y2="717.48" />
    <line x1="293.72" y1="495.57" x2="286.14" y2="736.56" />
    <line x1="360.43" y1="519.06" x2="212.67" y2="709.57" />
    <line x1="398.95" y1="578.87" x2="167.44" y2="646.14" />
    <line x1="393.28" y1="651.48" x2="166.46" y2="569.8" />
    <line x1="344.33" y1="708.44" x2="208.83" y2="509.01" />
    <line x1="269.53" y1="727.26" x2="277.11" y2="486.26" />
    <line x1="196.21" y1="700.01" x2="343.96" y2="509.48" />
    <line x1="151.14" y1="636.32" x2="382.64" y2="569.04" />
    <line x1="150.3" y1="559.73" x2="377.13" y2="641.4" />
    <line x1="192.83" y1="498.69" x2="328.33" y2="698.11" />
    <line x1="261.27" y1="475.69" x2="253.69" y2="716.68" />
    <line x1="328.3" y1="498.66" x2="180.54" y2="689.18" />
    <line x1="367.15" y1="557.98" x2="135.65" y2="625.25" />
    <line x1="361.82" y1="630.1" x2="134.99" y2="548.41" />
    <line x1="313.2" y1="686.56" x2="177.7" y2="487.13" />
    <line x1="238.74" y1="704.9" x2="246.32" y2="463.89" />
    <line x1="165.78" y1="677.16" x2="313.53" y2="486.65" />
    <line x1="121.07" y1="613" x2="352.57" y2="545.72" />
    <line x1="120.61" y1="535.94" x2="347.43" y2="617.61" />
    <line x1="163.51" y1="474.44" x2="299.02" y2="673.86" />
    <line x1="232.34" y1="450.98" x2="224.76" y2="691.97" />
    <line x1="299.76" y1="473.5" x2="151.99" y2="664.02" />
    <line x1="339.01" y1="532.36" x2="107.49" y2="599.64" />
    <line x1="334.08" y1="604.05" x2="107.26" y2="522.37" />
    <line x1="285.88" y1="660.08" x2="150.37" y2="460.65" />
    <line x1="211.85" y1="677.99" x2="219.41" y2="437" />
    <line x1="139.3" y1="649.83" x2="287.05" y2="459.32" />
    <line x1="95.02" y1="585.26" x2="326.53" y2="517.99" />
    <line x1="95.01" y1="507.79" x2="321.83" y2="589.47" />
    <line x1="138.35" y1="445.89" x2="273.86" y2="645.31" />
    <line x1="207.64" y1="422.05" x2="200.06" y2="663.04" />
    <line x1="275.51" y1="444.18" x2="127.75" y2="634.7" />
    <line x1="315.22" y1="502.67" x2="83.72" y2="569.94" />
    <line x1="310.76" y1="573.98" x2="83.94" y2="492.3" />
    <line x1="263.03" y1="629.65" x2="127.53" y2="430.22" />
    <line x1="189.47" y1="647.2" x2="197.05" y2="406.21" />
    <line x1="117.43" y1="618.71" x2="265.18" y2="428.19" />
    <line x1="73.63" y1="553.79" x2="305.15" y2="486.51" />
    <line x1="74.12" y1="475.99" x2="300.94" y2="557.67" />
    <line x1="117.97" y1="413.76" x2="253.47" y2="613.18" />
    <line x1="187.75" y1="389.59" x2="180.18" y2="630.6" />
    <line x1="256.14" y1="411.43" x2="108.38" y2="601.94" />
    <line x1="296.36" y1="469.62" x2="64.86" y2="536.88" />
    <line x1="292.43" y1="540.64" x2="65.61" y2="458.95" />
    <line x1="245.23" y1="596.02" x2="109.72" y2="396.59" />
    <line x1="172.21" y1="613.3" x2="179.77" y2="372.3" />
    <line x1="100.69" y1="584.52" x2="248.44" y2="394.01" />
    <line x1="57.43" y1="519.35" x2="288.94" y2="452.09" />
    <line x1="58.46" y1="441.31" x2="285.28" y2="522.98" />
    <line x1="102.85" y1="378.84" x2="238.37" y2="578.26" />
    <line x1="173.19" y1="354.44" x2="165.61" y2="595.43" />
    <line x1="242.14" y1="376.04" x2="94.37" y2="566.56" />
    <line x1="282.91" y1="434.02" x2="51.41" y2="501.28" />
    <line x1="279.54" y1="504.83" x2="52.72" y2="423.16" />
    <line x1="232.91" y1="560.02" x2="97.41" y2="360.59" />
    <line x1="160.45" y1="577.11" x2="168.03" y2="336.12" />
    <line x1="89.49" y1="548.16" x2="237.26" y2="357.64" />
    <line x1="46.82" y1="482.81" x2="278.33" y2="415.55" />
    <line x1="48.41" y1="404.6" x2="275.25" y2="486.27" />
    <line x1="93.4" y1="341.98" x2="228.9" y2="541.4" />
    <line x1="164.31" y1="317.44" x2="156.73" y2="558.43" />
    <line x1="233.84" y1="338.9" x2="86.07" y2="529.43" />
    <line x1="275.21" y1="396.76" x2="43.69" y2="464.02" />
    <line x1="272.41" y1="467.44" x2="45.59" y2="385.77" />
    <line x1="226.37" y1="522.54" x2="90.87" y2="323.11" />
    <line x1="154.5" y1="539.53" x2="162.07" y2="298.52" />
    <line x1="84.13" y1="510.48" x2="231.9" y2="319.98" />
    <line x1="42.06" y1="445.06" x2="273.56" y2="377.79" />
    <line x1="44.24" y1="366.78" x2="271.07" y2="448.46" />
    <line x1="89.81" y1="304.1" x2="225.32" y2="503.51" />
    <line x1="161.32" y1="279.5" x2="153.75" y2="520.5" />
    <line x1="231.44" y1="300.94" x2="83.69" y2="491.44" />
    <line x1="273.41" y1="358.75" x2="41.9" y2="426.01" />
    <line x1="271.22" y1="429.42" x2="44.4" y2="347.74" />
    <line x1="225.77" y1="484.49" x2="90.26" y2="285.07" />
    <line x1="154.5" y1="501.48" x2="162.07" y2="260.47" />
    <line x1="84.73" y1="472.43" x2="232.49" y2="281.92" />
    <line x1="43.25" y1="407.03" x2="274.75" y2="339.75" />
    <line x1="46.03" y1="328.77" x2="272.87" y2="410.45" />
    <line x1="92.2" y1="266.13" x2="227.71" y2="465.54" />
    <line x1="164.31" y1="241.57" x2="156.73" y2="482.56" />
    <line x1="235.03" y1="263.05" x2="87.26" y2="453.57" />
    <line x1="277.59" y1="320.93" x2="46.07" y2="388.19" />
    <line x1="275.98" y1="391.66" x2="49.16" y2="309.98" />
    <line x1="231.12" y1="446.81" x2="95.62" y2="247.39" />
    <line x1="160.45" y1="463.88" x2="168.03" y2="222.89" />
    <line x1="91.27" y1="434.95" x2="239.03" y2="244.44" />
    <line x1="50.38" y1="369.64" x2="281.89" y2="302.38" />
    <line x1="53.75" y1="291.51" x2="280.57" y2="373.19" />
    <line x1="100.51" y1="228.99" x2="236.01" y2="428.41" />
    <line x1="173.19" y1="204.56" x2="165.61" y2="445.56" />
    <line x1="244.49" y1="226.19" x2="96.73" y2="416.71" />
    <line x1="287.62" y1="284.22" x2="56.12" y2="351.49" />
    <line x1="286.6" y1="355.12" x2="59.78" y2="273.44" />
    <line x1="242.32" y1="410.43" x2="106.81" y2="211.02" />
    <line x1="172.21" y1="427.7" x2="179.77" y2="186.7" />
    <line x1="103.6" y1="398.95" x2="251.36" y2="208.44" />
    <line x1="63.27" y1="333.85" x2="294.77" y2="266.57" />
    <line x1="67.2" y1="255.91" x2="294.02" y2="337.59" />
    <line x1="114.5" y1="193.6" x2="250.02" y2="393.03" />
    <line x1="187.75" y1="169.4" x2="180.18" y2="410.41" />
    <line x1="259.59" y1="191.27" x2="111.84" y2="381.79" />
    <line x1="303.28" y1="249.54" x2="71.77" y2="316.8" />
    <line x1="302.81" y1="320.7" x2="75.97" y2="239.01" />
    <line x1="259.05" y1="376.26" x2="123.55" y2="176.85" />
    <line x1="189.47" y1="393.79" x2="197.05" y2="152.8" />
    <line x1="121.4" y1="365.32" x2="269.17" y2="174.81" />
    <line x1="81.6" y1="300.49" x2="313.1" y2="233.23" />
    <line x1="86.04" y1="222.85" x2="312.88" y2="304.54" />
    <line x1="133.87" y1="160.85" x2="269.37" y2="360.27" />
    <line x1="207.64" y1="136.96" x2="200.06" y2="377.95" />
    <line x1="279.99" y1="159.14" x2="132.22" y2="349.66" />
    <line x1="324.17" y1="217.74" x2="92.67" y2="285" />
    <line x1="324.19" y1="289.21" x2="97.36" y2="207.54" />
    <line x1="280.93" y1="345.14" x2="145.43" y2="145.71" />
    <line x1="211.85" y1="363" x2="219.41" y2="122.01" />
    <line x1="144.25" y1="334.89" x2="292" y2="144.38" />
    <line x1="104.92" y1="270.42" x2="336.42" y2="203.16" />
    <line x1="109.83" y1="193.16" x2="336.67" y2="274.83" />
    <line x1="158.13" y1="131.53" x2="293.63" y2="330.95" />
    <line x1="232.34" y1="108.03" x2="224.76" y2="349.02" />
    <line x1="305.15" y1="130.6" x2="157.38" y2="321.11" />
    <line x1="349.78" y1="189.59" x2="118.27" y2="256.86" />
    <line x1="350.23" y1="261.48" x2="123.41" y2="179.8" />
    <line x1="307.41" y1="317.81" x2="171.9" y2="118.38" />
    <line x1="238.74" y1="336.11" x2="246.32" y2="95.1" />
    <line x1="171.57" y1="308.4" x2="319.32" y2="117.9" />
    <line x1="132.65" y1="244.39" x2="364.16" y2="177.11" />
    <line x1="137.97" y1="167.55" x2="364.81" y2="249.22" />
    <line x1="186.67" y1="106.37" x2="322.18" y2="305.79" />
    <line x1="261.27" y1="83.31" x2="253.69" y2="324.31" />
    <line x1="334.47" y1="106.34" x2="186.7" y2="296.86" />
    <line x1="379.47" y1="165.8" x2="147.96" y2="233.06" />
    <line x1="380.3" y1="238.16" x2="153.46" y2="156.48" />
    <line x1="337.83" y1="294.96" x2="202.33" y2="95.55" />
    <line x1="269.53" y1="313.73" x2="277.11" y2="72.74" />
    <line x1="202.69" y1="286.52" x2="350.45" y2="96.02" />
    <line x1="164.12" y1="222.99" x2="395.62" y2="155.73" />
    <line x1="169.78" y1="146.66" x2="396.61" y2="228.34" />
    <line x1="218.79" y1="85.97" x2="354.29" y2="285.4" />
    <line x1="293.72" y1="63.44" x2="286.14" y2="304.43" />
    <line x1="367.21" y1="86.97" x2="219.46" y2="277.49" />
    <line x1="412.51" y1="146.94" x2="181.01" y2="214.22" />
    <line x1="413.63" y1="219.83" x2="186.81" y2="138.14" />
    <line x1="371.46" y1="277.16" x2="235.96" y2="77.74" />
    <line x1="303.43" y1="296.46" x2="311.01" y2="55.46" />
    <line x1="236.86" y1="269.79" x2="384.62" y2="79.28" />
    <line x1="198.55" y1="206.79" x2="430.05" y2="139.53" />
    <line x1="204.45" y1="130.99" x2="431.28" y2="212.68" />
    <line x1="253.7" y1="70.87" x2="389.21" y2="270.28" />
    <line x1="328.87" y1="48.88" x2="321.29" y2="289.86" />
    <line x1="402.59" y1="72.96" x2="254.83" y2="263.48" />
    <line x1="448.1" y1="133.5" x2="216.6" y2="200.76" />
    <line x1="449.43" y1="206.93" x2="222.61" y2="125.26" />
    <line x1="407.45" y1="264.82" x2="271.95" y2="65.41" />
    <line x1="339.61" y1="284.7" x2="347.18" y2="43.71" />
    <line x1="273.23" y1="258.6" x2="420.99" y2="68.08" />
    <line x1="235.08" y1="196.18" x2="466.59" y2="128.91" />
    <line x1="241.15" y1="120.96" x2="467.97" y2="202.63" />
    <line x1="290.56" y1="61.4" x2="426.06" y2="260.82" />
    <line x1="365.86" y1="39.99" x2="358.28" y2="280.98" />
    <line x1="439.72" y1="64.66" x2="291.96" y2="255.18" />
    <line x1="485.36" y1="125.78" x2="253.86" y2="193.04" />
    <line x1="486.8" y1="199.81" x2="259.98" y2="118.12" />
    <line x1="444.93" y1="258.28" x2="309.43" y2="58.87" />
    <line x1="377.18" y1="278.75" x2="384.76" y2="37.75" />
    <line x1="310.9" y1="253.24" x2="458.66" y2="62.72" />
    <line x1="272.82" y1="191.4" x2="504.33" y2="124.13" />
    <line x1="278.98" y1="116.78" x2="505.8" y2="198.46" />
    <line x1="328.44" y1="57.83" x2="463.94" y2="257.24" />
    <line x1="403.78" y1="37" x2="396.22" y2="278" />
    <line x1="477.69" y1="62.28" x2="329.94" y2="252.8" />
    <line x1="523.37" y1="123.98" x2="291.86" y2="191.26" />
    <line x1="524.83" y1="198.62" x2="298.01" y2="116.93" />
    <line x1="482.98" y1="257.69" x2="347.48" y2="58.27" />
    <line x1="415.24" y1="278.75" x2="422.81" y2="37.75" />
    <line x1="348.94" y1="253.84" x2="496.69" y2="63.31" />
    <line x1="310.86" y1="192.6" x2="542.36" y2="125.34" />
    <line x1="316.98" y1="118.58" x2="543.8" y2="200.25" />
    <line x1="366.42" y1="60.21" x2="501.92" y2="259.63" />
    <line x1="441.72" y1="39.99" x2="434.14" y2="280.98" />
    <line x1="515.56" y1="65.85" x2="367.8" y2="256.37" />
    <line x1="561.19" y1="128.17" x2="329.69" y2="195.43" />
    <line x1="562.57" y1="203.38" x2="335.75" y2="121.71" />
    <line x1="520.65" y1="263.05" x2="385.13" y2="63.63" />
    <line x1="452.82" y1="284.7" x2="460.39" y2="43.71" />
    <line x1="386.42" y1="260.38" x2="534.17" y2="69.86" />
    <line x1="348.22" y1="199.72" x2="579.73" y2="132.46" />
    <line x1="354.24" y1="126.3" x2="581.06" y2="207.97" />
    <line x1="403.55" y1="68.51" x2="539.05" y2="267.93" />
    <line x1="478.71" y1="48.88" x2="471.13" y2="289.86" />
    <line x1="552.42" y1="75.31" x2="404.66" y2="265.84" />
    <line x1="597.88" y1="138.2" x2="366.37" y2="205.47" />
    <line x1="599.11" y1="214" x2="372.29" y2="132.32" />
    <line x1="557.01" y1="274.25" x2="421.5" y2="74.82" />
    <line x1="489" y1="296.46" x2="496.57" y2="55.46" />
    <line x1="422.42" y1="272.71" x2="570.17" y2="82.19" />
    <line x1="384.03" y1="212.62" x2="615.53" y2="145.35" />
    <line x1="389.83" y1="139.74" x2="616.65" y2="221.42" />
    <line x1="438.92" y1="82.52" x2="574.42" y2="281.94" />
    <line x1="513.86" y1="63.44" x2="506.28" y2="304.43" />
    <line x1="587.33" y1="90.43" x2="439.57" y2="280.94" />
    <line x1="632.56" y1="153.87" x2="401.05" y2="221.13" />
    <line x1="633.54" y1="230.2" x2="406.72" y2="148.52" />
    <line x1="591.17" y1="290.99" x2="455.67" y2="91.56" />
    <line x1="522.89" y1="313.73" x2="530.47" y2="72.74" />
    <line x1="456.04" y1="290.52" x2="603.79" y2="99.99" />
    <line x1="417.36" y1="230.96" x2="648.86" y2="163.68" />
    <line x1="422.87" y1="158.6" x2="649.7" y2="240.27" />
    <line x1="471.67" y1="101.89" x2="607.17" y2="301.31" />
    <line x1="546.31" y1="83.32" x2="538.73" y2="324.31" />
    <line x1="619.46" y1="110.82" x2="471.7" y2="301.34" />
    <line x1="664.35" y1="174.75" x2="432.85" y2="242.02" />
    <line x1="665.01" y1="251.59" x2="438.18" y2="169.9" />
    <line x1="622.3" y1="312.87" x2="486.8" y2="113.44" />
    <line x1="553.68" y1="336.11" x2="561.26" y2="95.1" />
    <line x1="486.47" y1="313.35" x2="634.22" y2="122.84" />
    <line x1="447.43" y1="254.27" x2="678.93" y2="187" />
    <line x1="452.57" y1="182.39" x2="679.39" y2="264.06" />
    <line x1="500.98" y1="126.14" x2="636.49" y2="325.56" />
    <line x1="575.24" y1="108.03" x2="567.66" y2="349.02" />
    <line x1="648.01" y1="135.98" x2="500.24" y2="326.5" />
    <line x1="692.51" y1="200.36" x2="460.99" y2="267.64" />
    <line x1="692.74" y1="277.63" x2="465.92" y2="195.95" />
    <line x1="649.63" y1="339.35" x2="514.12" y2="139.92" />
    <line x1="580.59" y1="363" x2="588.17" y2="122.01" />
    <line x1="512.95" y1="340.68" x2="660.7" y2="150.17" />
    <line x1="473.47" y1="282.01" x2="704.98" y2="214.74" />
    <line x1="478.17" y1="210.53" x2="704.99" y2="292.21" />
    <line x1="526.15" y1="154.69" x2="661.64" y2="354.11" />
    <line x1="599.94" y1="136.96" x2="592.36" y2="377.95" />
    <line x1="672.25" y1="165.3" x2="524.49" y2="355.82" />
    <line x1="716.3" y1="230.06" x2="484.78" y2="297.33" />
    <line x1="716.06" y1="307.7" x2="489.24" y2="226.02" />
    <line x1="672.47" y1="369.78" x2="536.97" y2="170.35" />
    <line x1="602.95" y1="393.79" x2="610.53" y2="152.8" />
    <line x1="534.82" y1="371.81" x2="682.57" y2="181.31" />
    <line x1="494.85" y1="313.48" x2="726.37" y2="246.23" />
    <line x1="499.06" y1="242.33" x2="725.88" y2="324.02" />
    <line x1="546.53" y1="186.82" x2="682.03" y2="386.24" />
    <line x1="619.82" y1="169.4" x2="612.25" y2="410.41" />
    <line x1="691.62" y1="198.06" x2="543.86" y2="388.57" />
    <line x1="735.14" y1="263.12" x2="503.64" y2="330.38" />
    <line x1="734.39" y1="341.05" x2="507.57" y2="259.36" />
    <line x1="690.28" y1="403.41" x2="554.77" y2="203.98" />
    <line x1="620.21" y1="427.7" x2="627.79" y2="186.7" />
    <line x1="551.56" y1="405.99" x2="699.32" y2="215.48" />
    <line x1="511.06" y1="347.91" x2="742.57" y2="280.65" />
    <line x1="514.72" y1="277.02" x2="741.54" y2="358.69" />
    <line x1="561.65" y1="221.74" x2="697.14" y2="421.16" />
    <line x1="634.39" y1="204.57" x2="626.81" y2="445.56" />
    <line x1="705.63" y1="233.44" x2="557.86" y2="423.96" />
    <line x1="748.59" y1="298.72" x2="517.09" y2="365.98" />
    <line x1="747.28" y1="376.86" x2="520.46" y2="295.17" />
    <line x1="702.59" y1="439.41" x2="567.09" y2="239.98" />
    <line x1="631.97" y1="463.88" x2="639.55" y2="222.89" />
    <line x1="562.74" y1="442.36" x2="710.51" y2="251.84" />
    <line x1="521.67" y1="384.45" x2="753.18" y2="317.19" />
    <line x1="524.75" y1="313.73" x2="751.59" y2="395.4" />
    <line x1="571.1" y1="258.6" x2="706.61" y2="458.02" />
    <line x1="643.27" y1="241.57" x2="635.69" y2="482.56" />
    <line x1="713.93" y1="270.57" x2="566.16" y2="461.1" />
    <line x1="756.31" y1="335.98" x2="524.79" y2="403.24" />
    <line x1="754.41" y1="414.23" x2="527.59" y2="332.56" />
    <line x1="709.13" y1="476.89" x2="573.63" y2="277.46" />
    <line x1="637.93" y1="501.48" x2="645.5" y2="260.47" />
    <line x1="568.1" y1="480.02" x2="715.87" y2="289.52" />
    <line x1="526.44" y1="422.21" x2="757.94" y2="354.94" />
    <line x1="528.93" y1="351.54" x2="755.76" y2="433.22" />
    <line x1="574.68" y1="296.47" x2="710.19" y2="495.9" />
  </svg>,
  'LuxIcon',
)
