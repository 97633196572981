import { Typography } from '@mui/material'
import React from 'react'

import { DashboardPage, type DashboardPageProps } from '../../../layout/index.ts'

export const JourneyListPage: React.FC<DashboardPageProps> = ({ children, ...props }) => {
  return (
    <DashboardPage title="Journeys" {...props}>
      <Typography variant="h1">JourneyListPage</Typography>
      {children}
    </DashboardPage>
  )
}
