import { Typography } from '@mui/material'
import React from 'react'

import { DashboardPage, type DashboardPageProps } from '../../layout/index.ts'

export const SettingsPage: React.FC<DashboardPageProps> = ({ children, ...props }) => {
  return (
    <DashboardPage title="Settings" {...props}>
      <Typography variant="h1">SettingsPage</Typography>
      {children}
    </DashboardPage>
  )
}
