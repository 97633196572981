import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import {
  ClickAwayListener,
  MenuList,
  Stack,
  Typography, useTheme,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import React from 'react'

import type { DropdownSectionProps } from '../../common/index.ts'
import { DropdownCta } from './DropdownCta.tsx'
import { NavBarMenuItem } from './NavBarMenuItem.tsx'
import { StyledDropdownSection } from './StyledDropdownSection.tsx'

export const DropdownSection: React.FC<DropdownSectionProps> = ({
  data,
  dropdownId,
  open,
  openController,
  placement,
}) => {
  const theme = useTheme()
  const {
    name, to, colItems, cta,
  } = data

  return (
    <ClickAwayListener onClickAway={() => openController([dropdownId, false])}>
      <div>
        <StyledDropdownSection
          role="menu" // Explicit role for the menu
          id={`${name}-menu`}
          aria-labelledby={`${name}button`}
          placement={placement}
          open={open}
          disableHoverListener
          disableTouchListener
          title={(
            <FlexGrowRow alignItems="stretch" justifyContent="stretch" overflow="hidden">
              {cta && (
                <DropdownCta {...cta} />
              )}
              <MenuList role="presentation">
                {' '}
                {colItems?.items?.map((item, index) => (
                  <NavBarMenuItem
                    key={index}
                    index={index}
                    item={item}
                    totalSectionItems={colItems.items}
                    navCategory={name}
                    openController={openController}
                  />
                ))}
              </MenuList>
            </FlexGrowRow>
          )}
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            <ButtonEx
              variant="text"
              endIcon={open ? <KeyboardArrowUpRounded fontSize="small" /> : <KeyboardArrowDownRounded fontSize="small" />}
              disableTouchRipple
              disableFocusRipple
              disableRipple
              size="small"
              aria-controls={`${name}-menu`}
              id={`${name}button`}
              aria-haspopup="menu"
              aria-expanded={open}
              name={`Nav Section ${name} Dropdown Opener`}
              placement={`Nav Section ${name} Dropdown Opener`}
              sx={{
                '&:hover': {
                  filter: 'none',
                  color: theme.palette.secondary.main,
                  cursor: to ? 'pointer' : 'default',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => openController([dropdownId, !open])}
            >
              <Typography
                variant="body1"
                color={theme.palette.text.primary}
                style={{
                  textDecoration: open ? `underline 1.5px ${theme.palette.secondary.main}` : 'none',
                  textUnderlineOffset: open ? '5px' : 'auto',
                }}
              >
                {name}
              </Typography>
            </ButtonEx>
          </Stack>
        </StyledDropdownSection>
      </div>
    </ClickAwayListener>
  )
}
