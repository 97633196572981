import { Typography } from '@mui/material'
import React from 'react'

import { DashboardPage, type DashboardPageProps } from '../../layout/index.ts'

export const ProfilePage: React.FC<DashboardPageProps> = ({ children, ...props }) => {
  return (
    <DashboardPage title="Profile" {...props}>
      <Typography variant="h1">ProfilePage</Typography>
      {children}
    </DashboardPage>
  )
}
