import {
  darken, Drawer, type DrawerProps, Typography, useTheme,
} from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { LinkEx } from '@xylabs/react-link'
import type { CSSProperties, ReactNode } from 'react'
import React, { useMemo } from 'react'

import { RotatingLogo } from '../../index.ts'
import { BottomMenuArea } from './BottomMenuArea.tsx'

export interface BaseDrawerProps extends DrawerProps {
  bottomMenuArea?: ReactNode | null
  drawerWidth?: CSSProperties['width']
}

export const BaseDrawer: React.FC<BaseDrawerProps> = ({
  bottomMenuArea, children, drawerWidth = '280px', ...props
}) => {
  const { sx, ...drawerProps } = props
  const theme = useTheme()
  const darkMode = useColorSchemeEx()
  const drawerColor = darkMode ? theme.palette.background.paper : darken(theme.palette.primary.main, 0.5)

  const bottomMenuAreaComponent = useMemo(() => {
    if (bottomMenuArea === null) {
      return null
    }
    return bottomMenuArea ?? <BottomMenuArea />
  }, [bottomMenuArea])

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          position: 'static', backgroundColor: drawerColor, borderRight: 'none', ...sx,
        },
      }}
      sx={{ width: drawerWidth }}
      {...drawerProps}
    >
      <FlexCol alignItems="stretch" justifyContent="space-between" height="100dvh">
        <FlexCol alignItems="stretch" justifyContent="flex-start" flexGrow={1} overflow="auto">
          <FlexRow justifyContent="start" sx={{ pt: 1, pl: 3 }}>
            <LinkEx
              to="/"
              tabIndex={0}
              sx={{
                display: 'inline-flex', alignItems: 'center', gap: 1,
              }}
            >
              <RotatingLogo logoStyle={{ height: theme.spacing(3), width: theme.spacing(3) }} />
              <Typography variant="h5">Dataism</Typography>
            </LinkEx>
          </FlexRow>
          {children}
        </FlexCol>
        {bottomMenuAreaComponent}
      </FlexCol>
    </Drawer>
  )
}
