import React from 'react'
import type { RoutesProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { AccountRoutes } from '../account/index.ts'
import { DashboardRoutes } from '../dashboard/index.ts'
import { HomeRoutes } from '../home/index.ts'
import { InfoRoutes } from '../info/index.ts'
import { MissionRoutes } from '../mission/index.ts'
import { PeopleRoutes } from '../people/index.ts'
import { PersonaRoutes } from '../personas/index.ts'
import { QuizRoutes } from '../quiz/index.ts'
import { Four04Page, ResourcesRoutes } from '../resources/index.ts'
import { ToolsRoutes } from '../tools/index.ts'

export const RootRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="/*" element={<HomeRoutes />} />
      <Route path="/account/*" element={<AccountRoutes />} />
      <Route path="/info/*" element={<InfoRoutes />} />
      <Route path="/mission/*" element={<MissionRoutes />} />
      <Route path="/people/*" element={<PeopleRoutes />} />
      <Route path="/persona/*" element={<PersonaRoutes />} />
      <Route path="/quiz/*" element={<QuizRoutes />} />
      <Route path="/resources/*" element={<ResourcesRoutes />} />
      <Route path="/tools/*" element={<ToolsRoutes />} />
      <Route path="/dashboard/*" element={<DashboardRoutes />} />
      <Route path="/people/*" element={<PeopleRoutes />} />
      <Route path="/resources/*" element={<ResourcesRoutes />} />
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}
