/* eslint-disable max-lines */
import { createSvgIcon } from '@mui/material'
import React from 'react'

export const NovaIcon = createSvgIcon(
  <svg id="NovaIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" stroke="currentColor" fill="none">
    <line x1="686.05" y1="511.77" x2="597.54" y2="288.23" />
    <path d="M684.12,512.53c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M684.12,512.53c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M595.62,288.99c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <path d="M595.62,288.99c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <line x1="671.57" y1="524.02" x2="611.79" y2="291.16" />
    <path d="M669.57,524.55c-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49Z" />
    <path d="M669.57,524.55c-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49Z" />
    <path d="M609.77,291.67c-.28-1.11.39-2.24,1.49-2.51,1.12-.29,2.24.37,2.53,1.49.29,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.53-1.49Z" />
    <path d="M609.77,291.67c-.28-1.11.39-2.24,1.49-2.51,1.12-.29,2.24.37,2.53,1.49.29,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.53-1.49Z" />
    <line x1="656.39" y1="534.44" x2="626.25" y2="295.92" />
    <path d="M654.33,534.7c-.14-1.13.66-2.17,1.8-2.32,1.13-.14,2.17.66,2.32,1.8.14,1.15-.66,2.18-1.8,2.32-1.15.14-2.18-.66-2.32-1.8Z" />
    <path d="M654.33,534.7c-.14-1.13.66-2.17,1.8-2.32,1.13-.14,2.17.66,2.32,1.8.14,1.15-.66,2.18-1.8,2.32-1.15.14-2.18-.66-2.32-1.8Z" />
    <path d="M624.19,296.18c-.14-1.13.66-2.17,1.8-2.31,1.13-.15,2.18.65,2.32,1.8.14,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.32-1.8Z" />
    <path d="M624.19,296.18c-.14-1.13.66-2.17,1.8-2.31,1.13-.15,2.18.65,2.32,1.8.14,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.32-1.8Z" />
    <line x1="640.72" y1="542.96" x2="640.72" y2="302.55" />
    <circle cx="640.72" cy="542.96" r="2.07" />
    <circle cx="640.72" cy="542.96" r="2.07" />
    <path d="M638.65,302.55c0-1.15.94-2.07,2.07-2.07s2.07.93,2.07,2.07-.93,2.07-2.07,2.07-2.07-.93-2.07-2.07Z" />
    <path d="M638.65,302.55c0-1.15.94-2.07,2.07-2.07s2.07.93,2.07,2.07-.93,2.07-2.07,2.07-2.07-.93-2.07-2.07Z" />
    <line x1="624.83" y1="549.57" x2="654.95" y2="311.05" />
    <path d="M622.77,549.31c.14-1.13,1.17-1.93,2.31-1.8,1.15.14,1.95,1.17,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32Z" />
    <path d="M622.77,549.31c.14-1.13,1.17-1.93,2.31-1.8,1.15.14,1.95,1.17,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32Z" />
    <path d="M652.91,310.78c.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.15-.15-1.95-1.19-1.8-2.32Z" />
    <path d="M652.91,310.78c.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.15-.15-1.95-1.19-1.8-2.32Z" />
    <line x1="608.93" y1="554.25" x2="668.71" y2="321.4" />
    <path d="M606.92,553.74c.28-1.11,1.41-1.78,2.51-1.49,1.11.29,1.78,1.41,1.49,2.53-.28,1.11-1.41,1.77-2.51,1.49-1.12-.29-1.78-1.42-1.49-2.53Z" />
    <path d="M606.92,553.74c.28-1.11,1.41-1.78,2.51-1.49,1.11.29,1.78,1.41,1.49,2.53-.28,1.11-1.41,1.77-2.51,1.49-1.12-.29-1.78-1.42-1.49-2.53Z" />
    <path d="M666.71,320.88c.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.11-1.41,1.78-2.51,1.49-1.11-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M666.71,320.88c.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.11-1.41,1.78-2.51,1.49-1.11-.28-1.78-1.41-1.49-2.51Z" />
    <line x1="593.26" y1="557.07" x2="681.77" y2="333.54" />
    <path d="M591.34,556.31c.41-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69Z" />
    <path d="M591.34,556.31c.41-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69Z" />
    <path d="M679.84,332.78c.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.62,1.16,2.68-.41,1.06-1.62,1.59-2.68,1.17-1.08-.43-1.59-1.63-1.17-2.69Z" />
    <path d="M679.84,332.78c.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.62,1.16,2.68-.41,1.06-1.62,1.59-2.68,1.17-1.08-.43-1.59-1.63-1.17-2.69Z" />
    <line x1="578.06" y1="558.08" x2="693.89" y2="347.41" />
    <path d="M576.25,557.09c.55-.99,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M576.25,557.09c.55-.99,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M692.06,346.4c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55,1.01-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <path d="M692.06,346.4c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55,1.01-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <line x1="563.54" y1="557.39" x2="704.86" y2="362.89" />
    <path d="M561.87,556.16c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M561.87,556.16c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M703.19,361.66c.66-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.46,2.9-.66.93-1.96,1.13-2.89.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M703.19,361.66c.66-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.46,2.9-.66.93-1.96,1.13-2.89.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <line x1="549.91" y1="555.08" x2="714.49" y2="379.83" />
    <path d="M548.39,553.67c.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93-.79.83-2.1.87-2.93.1-.84-.79-.87-2.1-.1-2.93Z" />
    <path d="M548.39,553.67c.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93-.79.83-2.1.87-2.93.1-.84-.79-.87-2.1-.1-2.93Z" />
    <path d="M712.97,378.42c.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93s-2.1.87-2.93.1c-.83-.79-.87-2.1-.1-2.93Z" />
    <path d="M712.97,378.42c.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93s-2.1.87-2.93.1c-.83-.79-.87-2.1-.1-2.93Z" />
    <line x1="537.34" y1="551.34" x2="722.59" y2="398.09" />
    <path d="M536.01,549.75c.88-.73,2.2-.61,2.93.28.72.88.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.18.28-2.92Z" />
    <path d="M536.01,549.75c.88-.73,2.2-.61,2.93.28.72.88.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.18.28-2.92Z" />
    <path d="M721.26,396.5c.88-.73,2.2-.61,2.93.28.72.87.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.2.28-2.92Z" />
    <path d="M721.26,396.5c.88-.73,2.2-.61,2.93.28.72.87.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.2.28-2.92Z" />
    <line x1="526.01" y1="546.32" x2="729" y2="417.49" />
    <path d="M524.89,544.57c.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86-.97.62-2.25.33-2.86-.64s-.33-2.25.64-2.86Z" />
    <path d="M524.89,544.57c.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86-.97.62-2.25.33-2.86-.64s-.33-2.25.64-2.86Z" />
    <path d="M727.89,415.75c.97-.62,2.24-.33,2.86.64.61.97.32,2.24-.65,2.86-.95.61-2.24.33-2.86-.64-.61-.97-.32-2.25.65-2.86Z" />
    <path d="M727.89,415.75c.97-.62,2.24-.33,2.86.64.61.97.32,2.24-.65,2.86-.95.61-2.24.33-2.86-.64-.61-.97-.32-2.25.65-2.86Z" />
    <line x1="516.05" y1="540.19" x2="733.58" y2="437.83" />
    <path d="M515.16,538.32c1.04-.5,2.28-.04,2.76.99s.04,2.27-.99,2.75c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.75Z" />
    <path d="M515.16,538.32c1.04-.5,2.28-.04,2.76.99s.04,2.27-.99,2.75c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.75Z" />
    <path d="M732.7,435.95c1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.76-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.28.99-2.76Z" />
    <path d="M732.7,435.95c1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.76-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.28.99-2.76Z" />
    <line x1="507.59" y1="533.18" x2="736.24" y2="458.89" />
    <path d="M506.94,531.21c1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61Z" />
    <path d="M506.94,531.21c1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61Z" />
    <path d="M735.6,456.91c1.08-.36,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61-1.09.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61Z" />
    <path d="M735.6,456.91c1.08-.36,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61-1.09.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61Z" />
    <line x1="500.71" y1="525.47" x2="736.86" y2="480.43" />
    <path d="M500.31,523.44c1.13-.22,2.21.53,2.43,1.64.21,1.13-.53,2.21-1.64,2.43-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.2,1.64-2.42Z" />
    <path d="M500.31,523.44c1.13-.22,2.21.53,2.43,1.64.21,1.13-.53,2.21-1.64,2.43-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.2,1.64-2.42Z" />
    <path d="M736.47,478.4c1.13-.22,2.21.51,2.43,1.64.21,1.12-.53,2.21-1.66,2.42-1.12.22-2.2-.53-2.42-1.64-.21-1.12.53-2.21,1.64-2.42Z" />
    <path d="M736.47,478.4c1.13-.22,2.21.51,2.43,1.64.21,1.12-.53,2.21-1.66,2.42-1.12.22-2.2-.53-2.42-1.64-.21-1.12.53-2.21,1.64-2.42Z" />
    <line x1="495.48" y1="517.32" x2="735.41" y2="502.23" />
    <path d="M495.34,515.25c1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2-1.13.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.21Z" />
    <path d="M495.34,515.25c1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2-1.13.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.21Z" />
    <path d="M735.28,500.16c1.15-.07,2.13.79,2.2,1.93.07,1.15-.79,2.13-1.93,2.2-1.15.07-2.13-.79-2.2-1.93-.07-1.15.79-2.13,1.93-2.2Z" />
    <path d="M735.28,500.16c1.15-.07,2.13.79,2.2,1.93.07,1.15-.79,2.13-1.93,2.2-1.15.07-2.13-.79-2.2-1.93-.07-1.15.79-2.13,1.93-2.2Z" />
    <line x1="491.92" y1="508.93" x2="731.86" y2="524.04" />
    <path d="M492.05,506.87c1.15.07,2,1.05,1.93,2.2-.07,1.13-1.05,2-2.2,1.93-1.15-.07-2-1.05-1.93-2.2s1.05-2.02,2.2-1.93Z" />
    <path d="M492.05,506.87c1.15.07,2,1.05,1.93,2.2-.07,1.13-1.05,2-2.2,1.93-1.15-.07-2-1.05-1.93-2.2s1.05-2.02,2.2-1.93Z" />
    <path d="M731.99,521.96c1.13.07,2,1.06,1.93,2.2-.07,1.15-1.06,2.02-2.2,1.95-1.15-.08-2.02-1.06-1.95-2.2.08-1.15,1.06-2.02,2.21-1.95Z" />
    <path d="M731.99,521.96c1.13.07,2,1.06,1.93,2.2-.07,1.15-1.06,2.02-2.2,1.95-1.15-.08-2.02-1.06-1.95-2.2.08-1.15,1.06-2.02,2.21-1.95Z" />
    <line x1="490.05" y1="500.56" x2="726.21" y2="545.6" />
    <path d="M490.43,498.53c1.13.21,1.87,1.3,1.64,2.42-.21,1.12-1.3,1.87-2.42,1.64-1.12-.21-1.87-1.3-1.64-2.42.21-1.12,1.3-1.87,2.42-1.64Z" />
    <path d="M490.43,498.53c1.13.21,1.87,1.3,1.64,2.42-.21,1.12-1.3,1.87-2.42,1.64-1.12-.21-1.87-1.3-1.64-2.42.21-1.12,1.3-1.87,2.42-1.64Z" />
    <path d="M726.59,543.57c1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64-1.13-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64Z" />
    <path d="M726.59,543.57c1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64-1.13-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64Z" />
    <line x1="489.84" y1="492.42" x2="718.48" y2="566.7" />
    <path d="M490.47,490.45c1.09.35,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M490.47,490.45c1.09.35,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M719.12,564.74c1.09.35,1.69,1.52,1.34,2.61-.36,1.09-1.53,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M719.12,564.74c1.09.35,1.69,1.52,1.34,2.61-.36,1.09-1.53,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.09,1.52-1.69,2.61-1.33Z" />
    <line x1="491.22" y1="484.73" x2="708.75" y2="587.1" />
    <path d="M492.1,482.85c1.04.5,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99-1.04-.48-1.48-1.73-.99-2.76s1.73-1.48,2.76-.99Z" />
    <path d="M492.1,482.85c1.04.5,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99-1.04-.48-1.48-1.73-.99-2.76s1.73-1.48,2.76-.99Z" />
    <path d="M709.64,585.22c1.04.48,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.98-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.73-1.48,2.76-.99Z" />
    <path d="M709.64,585.22c1.04.48,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.98-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.73-1.48,2.76-.99Z" />
    <line x1="494.12" y1="477.72" x2="697.11" y2="606.54" />
    <path d="M495.24,475.97c.95.61,1.24,1.89.64,2.86-.62.97-1.89,1.26-2.86.64-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <path d="M495.24,475.97c.95.61,1.24,1.89.64,2.86-.62.97-1.89,1.26-2.86.64-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <path d="M698.23,604.78c.97.62,1.24,1.89.64,2.86-.61.97-1.89,1.26-2.86.65-.97-.62-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65Z" />
    <path d="M698.23,604.78c.97.62,1.24,1.89.64,2.86-.61.97-1.89,1.26-2.86.65-.97-.62-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65Z" />
    <line x1="498.43" y1="471.57" x2="683.68" y2="624.82" />
    <path d="M499.76,469.98c.88.72,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28-.88-.73-1.01-2.04-.28-2.92.72-.88,2.03-1.01,2.92-.28Z" />
    <path d="M499.76,469.98c.88.72,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28-.88-.73-1.01-2.04-.28-2.92.72-.88,2.03-1.01,2.92-.28Z" />
    <path d="M685,623.23c.88.73,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28Z" />
    <path d="M685,623.23c.88.73,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28Z" />
    <line x1="504.01" y1="466.5" x2="668.6" y2="641.76" />
    <path d="M505.53,465.08c.77.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M505.53,465.08c.77.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M670.11,640.33c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M670.11,640.33c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <line x1="510.72" y1="462.65" x2="652.04" y2="657.15" />
    <path d="M512.4,461.43c.66.93.46,2.22-.47,2.9-.93.68-2.21.47-2.89-.46-.68-.93-.47-2.22.46-2.9.93-.68,2.22-.47,2.9.46Z" />
    <path d="M512.4,461.43c.66.93.46,2.22-.47,2.9-.93.68-2.21.47-2.89-.46-.68-.93-.47-2.22.46-2.9.93-.68,2.22-.47,2.9.46Z" />
    <path d="M653.71,655.93c.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46-.66-.93-.47-2.22.46-2.9.94-.68,2.22-.47,2.9.46Z" />
    <path d="M653.71,655.93c.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46-.66-.93-.47-2.22.46-2.9.94-.68,2.22-.47,2.9.46Z" />
    <line x1="518.36" y1="460.19" x2="634.17" y2="670.85" />
    <path d="M520.17,459.18c.55,1.01.19,2.27-.82,2.82-1.01.55-2.27.19-2.82-.82s-.18-2.27.82-2.82c1.01-.55,2.27-.18,2.82.82Z" />
    <path d="M520.17,459.18c.55,1.01.19,2.27-.82,2.82-1.01.55-2.27.19-2.82-.82s-.18-2.27.82-2.82c1.01-.55,2.27-.18,2.82.82Z" />
    <path d="M635.99,669.86c.55,1.01.18,2.27-.83,2.82-.99.55-2.25.18-2.8-.82-.55-1.01-.19-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82Z" />
    <path d="M635.99,669.86c.55,1.01.18,2.27-.83,2.82-.99.55-2.25.18-2.8-.82-.55-1.01-.19-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82Z" />
    <line x1="526.73" y1="459.21" x2="615.23" y2="682.74" />
    <path d="M528.65,458.45c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.69-1.16-.41-1.08.1-2.28,1.16-2.69,1.08-.43,2.28.1,2.69,1.16Z" />
    <path d="M528.65,458.45c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.69-1.16-.41-1.08.1-2.28,1.16-2.69,1.08-.43,2.28.1,2.69,1.16Z" />
    <path d="M617.15,681.98c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.68-1.16-.43-1.06.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.68,1.16Z" />
    <path d="M617.15,681.98c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.68-1.16-.43-1.06.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.68,1.16Z" />
    <line x1="535.63" y1="459.83" x2="595.41" y2="692.7" />
    <path d="M537.63,459.32c.29,1.11-.37,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49Z" />
    <path d="M537.63,459.32c.29,1.11-.37,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49Z" />
    <path d="M597.42,692.17c.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49-.29-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49Z" />
    <path d="M597.42,692.17c.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49-.29-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49Z" />
    <line x1="544.84" y1="462.12" x2="574.96" y2="700.63" />
    <path d="M546.89,461.86c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M546.89,461.86c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M577.02,700.38c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <path d="M577.02,700.38c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <line x1="554.13" y1="466.1" x2="554.13" y2="706.52" />
    <circle cx="554.13" cy="466.1" r="2.07" />
    <circle cx="554.13" cy="466.1" r="2.07" />
    <circle cx="554.13" cy="706.52" r="2.07" />
    <circle cx="554.13" cy="706.52" r="2.07" />
    <line x1="563.26" y1="471.79" x2="533.13" y2="710.32" />
    <path d="M565.32,472.06c-.14,1.13-1.17,1.95-2.31,1.8-1.15-.14-1.95-1.17-1.81-2.31.15-1.15,1.19-1.95,2.32-1.8,1.13.14,1.95,1.17,1.8,2.31Z" />
    <path d="M565.32,472.06c-.14,1.13-1.17,1.95-2.31,1.8-1.15-.14-1.95-1.17-1.81-2.31.15-1.15,1.19-1.95,2.32-1.8,1.13.14,1.95,1.17,1.8,2.31Z" />
    <path d="M535.19,710.58c-.14,1.13-1.17,1.93-2.31,1.8-1.15-.14-1.95-1.19-1.8-2.32.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32Z" />
    <path d="M535.19,710.58c-.14,1.13-1.17,1.93-2.31,1.8-1.15-.14-1.95-1.19-1.8-2.32.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32Z" />
    <line x1="572.02" y1="479.19" x2="512.24" y2="712.04" />
    <path d="M574.03,479.7c-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51.28-1.11,1.41-1.78,2.51-1.49,1.11.28,1.78,1.41,1.49,2.51Z" />
    <path d="M574.03,479.7c-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51.28-1.11,1.41-1.78,2.51-1.49,1.11.28,1.78,1.41,1.49,2.51Z" />
    <path d="M514.24,712.57c-.29,1.11-1.41,1.77-2.53,1.49-1.11-.29-1.77-1.42-1.49-2.53.29-1.11,1.42-1.77,2.53-1.49,1.11.29,1.78,1.42,1.49,2.53Z" />
    <path d="M514.24,712.57c-.29,1.11-1.41,1.77-2.53,1.49-1.11-.29-1.77-1.42-1.49-2.53.29-1.11,1.42-1.77,2.53-1.49,1.11.29,1.78,1.42,1.49,2.53Z" />
    <line x1="580.16" y1="488.22" x2="491.66" y2="711.75" />
    <path d="M582.09,488.98c-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68.41-1.06,1.62-1.59,2.68-1.17,1.06.43,1.59,1.63,1.17,2.69Z" />
    <path d="M582.09,488.98c-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68.41-1.06,1.62-1.59,2.68-1.17,1.06.43,1.59,1.63,1.17,2.69Z" />
    <path d="M493.58,712.51c-.41,1.06-1.62,1.59-2.68,1.16-1.06-.41-1.59-1.62-1.17-2.68.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69Z" />
    <path d="M493.58,712.51c-.41,1.06-1.62,1.59-2.68,1.16-1.06-.41-1.59-1.62-1.17-2.68.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69Z" />
    <line x1="587.47" y1="498.82" x2="471.65" y2="709.5" />
    <path d="M589.29,499.81c-.55,1.01-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.8.55-1.01,1.81-1.37,2.82-.83.99.55,1.37,1.82.82,2.82Z" />
    <path d="M589.29,499.81c-.55,1.01-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.8.55-1.01,1.81-1.37,2.82-.83.99.55,1.37,1.82.82,2.82Z" />
    <path d="M473.46,710.5c-.55.99-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.82.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82Z" />
    <path d="M473.46,710.5c-.55.99-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.82.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82Z" />
    <line x1="593.74" y1="510.88" x2="452.42" y2="705.37" />
    <path d="M595.41,512.1c-.66.93-1.96,1.13-2.89.46-.93-.68-1.13-1.98-.46-2.9.66-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M595.41,512.1c-.66.93-1.96,1.13-2.89.46-.93-.68-1.13-1.98-.46-2.9.66-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M454.11,706.6c-.68.93-1.98,1.12-2.9.46-.93-.68-1.13-1.98-.46-2.9.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M454.11,706.6c-.68.93-1.98,1.12-2.9.46-.93-.68-1.13-1.98-.46-2.9.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <line x1="598.77" y1="524.26" x2="434.2" y2="699.51" />
    <path d="M600.29,525.68c-.79.83-2.1.87-2.93.1-.84-.79-.88-2.1-.1-2.93.79-.84,2.1-.88,2.93-.1.83.79.87,2.1.1,2.93Z" />
    <path d="M600.29,525.68c-.79.83-2.1.87-2.93.1-.84-.79-.88-2.1-.1-2.93.79-.84,2.1-.88,2.93-.1.83.79.87,2.1.1,2.93Z" />
    <path d="M435.7,700.93c-.77.83-2.09.87-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.84,2.09-.88,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M435.7,700.93c-.77.83-2.09.87-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.84,2.09-.88,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <line x1="602.39" y1="538.82" x2="417.15" y2="692.06" />
    <path d="M603.72,540.42c-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93Z" />
    <path d="M603.72,540.42c-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93Z" />
    <path d="M418.47,693.67c-.88.73-2.18.61-2.92-.28s-.61-2.2.28-2.92c.88-.73,2.18-.61,2.92.28.73.87.61,2.18-.28,2.92Z" />
    <path d="M418.47,693.67c-.88.73-2.18.61-2.92-.28s-.61-2.2.28-2.92c.88-.73,2.18-.61,2.92.28.73.87.61,2.18-.28,2.92Z" />
    <line x1="604.45" y1="554.38" x2="401.45" y2="683.19" />
    <path d="M605.56,556.12c-.97.62-2.24.33-2.86-.64-.61-.97-.33-2.24.64-2.86.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86Z" />
    <path d="M605.56,556.12c-.97.62-2.24.33-2.86-.64-.61-.97-.33-2.24.64-2.86.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86Z" />
    <path d="M402.57,684.95c-.97.61-2.25.32-2.86-.64-.62-.97-.33-2.25.64-2.87.97-.61,2.25-.32,2.86.65.62.97.33,2.24-.64,2.86Z" />
    <path d="M402.57,684.95c-.97.61-2.25.32-2.86-.64-.62-.97-.33-2.25.64-2.87.97-.61,2.25-.32,2.86.65.62.97.33,2.24-.64,2.86Z" />
    <line x1="604.8" y1="570.72" x2="387.26" y2="673.09" />
    <path d="M605.68,572.6c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.75,1.04-.5,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.75Z" />
    <path d="M605.68,572.6c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.75,1.04-.5,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.75Z" />
    <path d="M388.15,674.97c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.76Z" />
    <path d="M388.15,674.97c-1.04.48-2.27.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.27-.99,2.76Z" />
    <line x1="603.33" y1="587.68" x2="374.69" y2="661.97" />
    <path d="M603.98,589.64c-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.25,1.33-2.61,1.09-.36,2.25.25,2.61,1.33.36,1.09-.23,2.27-1.33,2.61Z" />
    <path d="M603.98,589.64c-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.25,1.33-2.61,1.09-.36,2.25.25,2.61,1.33.36,1.09-.23,2.27-1.33,2.61Z" />
    <path d="M375.32,663.93c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <path d="M375.32,663.93c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <line x1="599.99" y1="604.98" x2="363.83" y2="650.03" />
    <path d="M600.37,607.02c-1.12.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.66-2.42,1.12-.22,2.21.53,2.42,1.64.22,1.12-.53,2.21-1.64,2.43Z" />
    <path d="M600.37,607.02c-1.12.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.66-2.42,1.12-.22,2.21.53,2.42,1.64.22,1.12-.53,2.21-1.64,2.43Z" />
    <path d="M364.21,652.06c-1.12.21-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43Z" />
    <path d="M364.21,652.06c-1.12.21-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43Z" />
    <line x1="594.7" y1="622.41" x2="354.75" y2="637.51" />
    <path d="M594.82,624.48c-1.15.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.2,1.15-.08,2.13.79,2.2,1.93.08,1.13-.79,2.13-1.93,2.2Z" />
    <path d="M594.82,624.48c-1.15.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.2,1.15-.08,2.13.79,2.2,1.93.08,1.13-.79,2.13-1.93,2.2Z" />
    <path d="M354.87,639.57c-1.13.08-2.13-.79-2.2-1.93s.8-2.13,1.95-2.2c1.13-.07,2.11.8,2.2,1.93.07,1.15-.8,2.13-1.95,2.2Z" />
    <path d="M354.87,639.57c-1.13.08-2.13-.79-2.2-1.93s.8-2.13,1.95-2.2c1.13-.07,2.11.8,2.2,1.93.07,1.15-.8,2.13-1.95,2.2Z" />
    <line x1="587.43" y1="639.74" x2="347.5" y2="624.65" />
    <path d="M587.3,641.81c-1.15-.07-2.02-1.05-1.95-2.2.08-1.15,1.06-2,2.21-1.93,1.13.07,2,1.05,1.93,2.2-.07,1.15-1.06,2-2.2,1.93Z" />
    <path d="M587.3,641.81c-1.15-.07-2.02-1.05-1.95-2.2.08-1.15,1.06-2,2.21-1.93,1.13.07,2,1.05,1.93,2.2-.07,1.15-1.06,2-2.2,1.93Z" />
    <path d="M347.36,626.72c-1.15-.08-2-1.06-1.93-2.21.07-1.13,1.05-2,2.2-1.93,1.15.07,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95Z" />
    <path d="M347.36,626.72c-1.15-.08-2-1.06-1.93-2.21.07-1.13,1.05-2,2.2-1.93,1.15.07,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95Z" />
    <line x1="578.21" y1="656.72" x2="342.05" y2="611.68" />
    <path d="M577.82,658.76c-1.13-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64,1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64Z" />
    <path d="M577.82,658.76c-1.13-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64,1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64Z" />
    <path d="M341.66,613.71c-1.12-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.64,1.13.21,1.87,1.3,1.66,2.42-.22,1.12-1.31,1.87-2.43,1.64Z" />
    <path d="M341.66,613.71c-1.12-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.64,1.13.21,1.87,1.3,1.66,2.42-.22,1.12-1.31,1.87-2.43,1.64Z" />
    <line x1="567.08" y1="673.12" x2="338.42" y2="598.83" />
    <path d="M566.43,675.1c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M566.43,675.1c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M337.78,600.8c-1.09-.36-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M337.78,600.8c-1.09-.36-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <line x1="554.07" y1="688.69" x2="336.54" y2="586.34" />
    <path d="M553.19,690.57c-1.04-.48-1.48-1.73-.99-2.76.5-1.02,1.73-1.48,2.76-.98,1.04.48,1.48,1.71.99,2.75-.48,1.04-1.73,1.48-2.76.99Z" />
    <path d="M553.19,690.57c-1.04-.48-1.48-1.73-.99-2.76.5-1.02,1.73-1.48,2.76-.98,1.04.48,1.48,1.71.99,2.75-.48,1.04-1.73,1.48-2.76.99Z" />
    <path d="M335.65,588.2c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.48,2.76-.99s1.48,1.73.99,2.76c-.48,1.04-1.73,1.48-2.76.98Z" />
    <path d="M335.65,588.2c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.48,2.76-.99s1.48,1.73.99,2.76c-.48,1.04-1.73,1.48-2.76.98Z" />
    <line x1="539.32" y1="703.23" x2="336.33" y2="574.41" />
    <path d="M538.21,704.98c-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64.97.61,1.24,1.89.64,2.86-.62.97-1.89,1.24-2.86.64Z" />
    <path d="M538.21,704.98c-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64.97.61,1.24,1.89.64,2.86-.62.97-1.89,1.24-2.86.64Z" />
    <path d="M335.23,576.15c-.97-.61-1.26-1.89-.65-2.86.62-.95,1.89-1.24,2.86-.64.97.62,1.26,1.89.65,2.86-.62.97-1.89,1.26-2.86.64Z" />
    <path d="M335.23,576.15c-.97-.61-1.26-1.89-.65-2.86.62-.95,1.89-1.24,2.86-.64.97.62,1.26,1.89.65,2.86-.62.97-1.89,1.26-2.86.64Z" />
    <line x1="522.93" y1="716.52" x2="337.69" y2="563.26" />
    <path d="M521.6,718.11c-.88-.73-1.01-2.03-.28-2.92s2.04-1.01,2.92-.28c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28Z" />
    <path d="M521.6,718.11c-.88-.73-1.01-2.03-.28-2.92s2.04-1.01,2.92-.28c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28Z" />
    <path d="M336.36,564.86c-.88-.73-1.01-2.03-.28-2.92s2.04-1.01,2.92-.28c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28Z" />
    <path d="M336.36,564.86c-.88-.73-1.01-2.03-.28-2.92s2.04-1.01,2.92-.28c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28Z" />
    <line x1="505.04" y1="728.35" x2="340.46" y2="553.09" />
    <path d="M503.53,729.77c-.79-.83-.75-2.14.1-2.93.83-.79,2.14-.75,2.93.1.77.83.75,2.14-.1,2.93-.83.77-2.14.73-2.93-.1Z" />
    <path d="M503.53,729.77c-.79-.83-.75-2.14.1-2.93.83-.79,2.14-.75,2.93.1.77.83.75,2.14-.1,2.93-.83.77-2.14.73-2.93-.1Z" />
    <path d="M338.96,554.52c-.79-.83-.75-2.14.1-2.93.83-.79,2.14-.75,2.93.1.77.83.73,2.14-.1,2.93-.83.77-2.14.73-2.93-.1Z" />
    <path d="M338.96,554.52c-.79-.83-.75-2.14.1-2.93.83-.79,2.14-.75,2.93.1.77.83.73,2.14-.1,2.93-.83.77-2.14.73-2.93-.1Z" />
    <line x1="485.84" y1="738.57" x2="344.52" y2="544.07" />
    <path d="M484.16,739.79c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.9.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <path d="M484.16,739.79c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.9.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <path d="M342.85,545.29c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.89.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.89-.46Z" />
    <path d="M342.85,545.29c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.89.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.89-.46Z" />
    <line x1="465.51" y1="747.01" x2="349.68" y2="536.33" />
    <path d="M463.68,748.02c-.54-1.01-.18-2.27.83-2.82.99-.55,2.25-.19,2.8.82s.19,2.27-.82,2.82c-.99.55-2.27.18-2.82-.82Z" />
    <path d="M463.68,748.02c-.54-1.01-.18-2.27.83-2.82.99-.55,2.25-.19,2.8.82s.19,2.27-.82,2.82c-.99.55-2.27.18-2.82-.82Z" />
    <path d="M347.87,537.34c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82Z" />
    <path d="M347.87,537.34c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82Z" />
    <line x1="444.26" y1="753.56" x2="355.74" y2="530.03" />
    <path d="M442.32,754.32c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M442.32,754.32c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M353.82,530.79c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <path d="M353.82,530.79c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <line x1="422.3" y1="758.11" x2="362.51" y2="525.25" />
    <path d="M420.3,758.62c-.29-1.11.37-2.24,1.49-2.51,1.11-.29,2.24.39,2.51,1.49.29,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.51-1.49Z" />
    <path d="M420.3,758.62c-.29-1.11.37-2.24,1.49-2.51,1.11-.29,2.24.39,2.51,1.49.29,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.51-1.49Z" />
    <path d="M360.5,525.76c-.28-1.11.39-2.24,1.49-2.53,1.12-.28,2.24.39,2.53,1.49.29,1.12-.39,2.24-1.49,2.53-1.11.29-2.24-.39-2.53-1.49Z" />
    <path d="M360.5,525.76c-.28-1.11.39-2.24,1.49-2.53,1.12-.28,2.24.39,2.53,1.49.29,1.12-.39,2.24-1.49,2.53-1.11.29-2.24-.39-2.53-1.49Z" />
    <line x1="399.89" y1="760.58" x2="369.75" y2="522.06" />
    <path d="M397.83,760.84c-.15-1.15.66-2.18,1.8-2.32,1.13-.14,2.17.66,2.31,1.8.15,1.13-.65,2.17-1.8,2.32-1.13.14-2.17-.66-2.31-1.8Z" />
    <path d="M397.83,760.84c-.15-1.15.66-2.18,1.8-2.32,1.13-.14,2.17.66,2.31,1.8.15,1.13-.65,2.17-1.8,2.32-1.13.14-2.17-.66-2.31-1.8Z" />
    <path d="M367.7,522.32c-.14-1.13.66-2.17,1.8-2.32,1.13-.14,2.17.66,2.32,1.8.14,1.13-.66,2.18-1.8,2.32-1.15.14-2.18-.66-2.32-1.8Z" />
    <path d="M367.7,522.32c-.14-1.13.66-2.17,1.8-2.32,1.13-.14,2.17.66,2.32,1.8.14,1.13-.66,2.18-1.8,2.32-1.15.14-2.18-.66-2.32-1.8Z" />
    <line x1="377.24" y1="760.93" x2="377.24" y2="520.51" />
    <circle cx="377.24" cy="760.93" r="2.07" />
    <circle cx="377.24" cy="760.93" r="2.07" />
    <circle cx="377.24" cy="520.51" r="2.07" />
    <circle cx="377.24" cy="520.51" r="2.07" />
    <line x1="354.62" y1="759.15" x2="384.76" y2="520.64" />
    <path d="M352.57,758.9c.15-1.15,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.14,1.13-1.17,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M352.57,758.9c.15-1.15,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.14,1.13-1.17,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M382.7,520.37c.15-1.13,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.14,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M382.7,520.37c.15-1.13,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.14,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <line x1="332.28" y1="755.25" x2="392.07" y2="522.39" />
    <path d="M330.27,754.74c.29-1.11,1.42-1.78,2.53-1.49,1.11.29,1.78,1.41,1.49,2.53-.29,1.11-1.41,1.77-2.53,1.49-1.11-.29-1.77-1.42-1.49-2.53Z" />
    <path d="M330.27,754.74c.29-1.11,1.42-1.78,2.53-1.49,1.11.29,1.78,1.41,1.49,2.53-.29,1.11-1.41,1.77-2.53,1.49-1.11-.29-1.77-1.42-1.49-2.53Z" />
    <path d="M390.07,521.88c.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.11-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M390.07,521.88c.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.11-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <line x1="310.44" y1="749.28" x2="398.95" y2="525.75" />
    <path d="M308.52,748.52c.41-1.06,1.62-1.59,2.68-1.16,1.06.41,1.59,1.62,1.17,2.68-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69Z" />
    <path d="M308.52,748.52c.41-1.06,1.62-1.59,2.68-1.16,1.06.41,1.59,1.62,1.17,2.68-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69Z" />
    <path d="M397.02,524.99c.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.41,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.58-1.62-1.16-2.68Z" />
    <path d="M397.02,524.99c.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.41,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.58-1.62-1.16-2.68Z" />
    <line x1="289.34" y1="741.31" x2="405.17" y2="530.64" />
    <path d="M287.53,740.31c.55-.99,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M287.53,740.31c.55-.99,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M403.34,529.63c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55,1.01-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <path d="M403.34,529.63c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55,1.01-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <line x1="269.21" y1="731.46" x2="410.53" y2="536.95" />
    <path d="M267.54,730.23c.68-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.47,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M267.54,730.23c.68-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.47,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M408.84,535.74c.68-.93,1.98-1.13,2.9-.47.93.68,1.13,1.98.46,2.9-.68.93-1.96,1.13-2.89.46-.94-.68-1.13-1.96-.47-2.89Z" />
    <path d="M408.84,535.74c.68-.93,1.98-1.13,2.9-.47.93.68,1.13,1.98.46,2.9-.68.93-1.96,1.13-2.89.46-.94-.68-1.13-1.96-.47-2.89Z" />
    <line x1="250.25" y1="719.82" x2="414.83" y2="544.57" />
    <path d="M248.75,718.4c.77-.83,2.09-.87,2.93-.08.83.79.87,2.09.08,2.93-.77.83-2.09.87-2.93.08-.83-.77-.87-2.09-.08-2.93Z" />
    <path d="M248.75,718.4c.77-.83,2.09-.87,2.93-.08.83.79.87,2.09.08,2.93-.77.83-2.09.87-2.93.08-.83-.77-.87-2.09-.08-2.93Z" />
    <path d="M413.32,543.14c.79-.83,2.1-.87,2.93-.08.83.77.87,2.09.1,2.93-.79.83-2.1.87-2.93.08-.84-.77-.88-2.09-.1-2.93Z" />
    <path d="M413.32,543.14c.79-.83,2.1-.87,2.93-.08.83.77.87,2.09.1,2.93-.79.83-2.1.87-2.93.08-.84-.77-.88-2.09-.1-2.93Z" />
    <line x1="232.66" y1="706.59" x2="417.91" y2="553.34" />
    <path d="M231.34,704.98c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.87.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M231.34,704.98c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.87.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M416.58,551.74c.88-.72,2.18-.61,2.92.28.73.88.61,2.2-.28,2.92-.88.73-2.18.61-2.92-.26-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M416.58,551.74c.88-.72,2.18-.61,2.92.28.73.88.61,2.2-.28,2.92-.88.73-2.18.61-2.92-.26-.73-.88-.61-2.2.28-2.93Z" />
    <line x1="216.59" y1="691.91" x2="419.59" y2="563.1" />
    <path d="M215.49,690.16c.97-.61,2.25-.32,2.86.65.61.97.33,2.24-.64,2.86-.97.61-2.25.32-2.86-.65-.62-.95-.33-2.24.64-2.86Z" />
    <path d="M215.49,690.16c.97-.61,2.25-.32,2.86.65.61.97.33,2.24-.64,2.86-.97.61-2.25.32-2.86-.65-.62-.95-.33-2.24.64-2.86Z" />
    <path d="M418.47,561.34c.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86-.97.62-2.25.33-2.86-.64-.61-.97-.33-2.25.64-2.86Z" />
    <path d="M418.47,561.34c.97-.61,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86-.97.62-2.25.33-2.86-.64-.61-.97-.33-2.25.64-2.86Z" />
    <line x1="202.22" y1="675.99" x2="419.76" y2="573.64" />
    <path d="M201.34,674.13c1.04-.5,2.27-.04,2.76.98.48,1.04.04,2.28-.99,2.76-1.04.48-2.28.04-2.76-.99s-.04-2.27.99-2.75Z" />
    <path d="M201.34,674.13c1.04-.5,2.27-.04,2.76.98.48,1.04.04,2.28-.99,2.76-1.04.48-2.28.04-2.76-.99s-.04-2.27.99-2.75Z" />
    <path d="M418.87,571.76c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.76-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.28.99-2.76Z" />
    <path d="M418.87,571.76c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.76-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.28.99-2.76Z" />
    <line x1="189.65" y1="659.05" x2="418.29" y2="584.76" />
    <path d="M189,657.08c1.09-.35,2.27.25,2.61,1.34.36,1.09-.23,2.25-1.33,2.61-1.09.35-2.25-.25-2.61-1.34-.36-1.08.25-2.25,1.33-2.61Z" />
    <path d="M189,657.08c1.09-.35,2.27.25,2.61,1.34.36,1.09-.23,2.25-1.33,2.61-1.09.35-2.25-.25-2.61-1.34-.36-1.08.25-2.25,1.33-2.61Z" />
    <path d="M417.66,582.8c1.09-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61-1.08.36-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61Z" />
    <path d="M417.66,582.8c1.09-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61-1.08.36-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61Z" />
    <line x1="178.97" y1="641.31" x2="415.13" y2="596.26" />
    <path d="M178.58,639.27c1.12-.21,2.21.53,2.42,1.66.22,1.12-.53,2.2-1.64,2.42-1.12.22-2.21-.53-2.42-1.64-.22-1.13.51-2.21,1.64-2.43Z" />
    <path d="M178.58,639.27c1.12-.21,2.21.53,2.42,1.66.22,1.12-.53,2.2-1.64,2.42-1.12.22-2.21-.53-2.42-1.64-.22-1.13.51-2.21,1.64-2.43Z" />
    <path d="M414.74,594.23c1.12-.22,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.66-2.42Z" />
    <path d="M414.74,594.23c1.12-.22,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.66-2.42Z" />
    <line x1="170.25" y1="622.99" x2="410.2" y2="607.89" />
    <path d="M170.13,620.92c1.15-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.93,2.21-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2Z" />
    <path d="M170.13,620.92c1.15-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.93,2.21-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2Z" />
    <path d="M410.07,605.83c1.13-.08,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.95,2.2-1.13.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.95-2.2Z" />
    <path d="M410.07,605.83c1.13-.08,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.95,2.2-1.13.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.95-2.2Z" />
    <line x1="163.54" y1="604.34" x2="403.48" y2="619.44" />
    <path d="M163.67,602.27c1.15.08,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95s-2-1.06-1.93-2.2c.07-1.15,1.05-2.02,2.2-1.95Z" />
    <path d="M163.67,602.27c1.15.08,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95s-2-1.06-1.93-2.2c.07-1.15,1.05-2.02,2.2-1.95Z" />
    <path d="M403.62,617.37c1.13.07,2,1.05,1.93,2.2-.07,1.15-1.06,2.02-2.2,1.93-1.15-.07-2.02-1.05-1.95-2.2.08-1.13,1.06-2,2.21-1.93Z" />
    <path d="M403.62,617.37c1.13.07,2,1.05,1.93,2.2-.07,1.15-1.06,2.02-2.2,1.93-1.15-.07-2.02-1.05-1.95-2.2.08-1.13,1.06-2,2.21-1.93Z" />
    <line x1="158.84" y1="585.6" x2="395" y2="630.65" />
    <path d="M159.23,583.56c1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.87-2.42,1.64-1.13-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66Z" />
    <path d="M159.23,583.56c1.12.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.87-2.42,1.64-1.13-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66Z" />
    <path d="M395.39,628.62c1.12.21,1.87,1.3,1.64,2.42-.22,1.13-1.3,1.87-2.42,1.66-1.13-.22-1.87-1.31-1.66-2.43.22-1.12,1.3-1.87,2.43-1.64Z" />
    <path d="M395.39,628.62c1.12.21,1.87,1.3,1.64,2.42-.22,1.13-1.3,1.87-2.42,1.66-1.13-.22-1.87-1.31-1.66-2.43.22-1.12,1.3-1.87,2.43-1.64Z" />
    <line x1="156.12" y1="567.01" x2="384.76" y2="641.3" />
    <path d="M156.75,565.04c1.09.35,1.69,1.52,1.34,2.61-.36,1.08-1.53,1.69-2.63,1.33-1.08-.36-1.67-1.52-1.33-2.61.36-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M156.75,565.04c1.09.35,1.69,1.52,1.34,2.61-.36,1.08-1.53,1.69-2.63,1.33-1.08-.36-1.67-1.52-1.33-2.61.36-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M385.41,639.34c1.08.35,1.67,1.52,1.33,2.61-.36,1.08-1.52,1.67-2.61,1.33-1.09-.36-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M385.41,639.34c1.08.35,1.67,1.52,1.33,2.61-.36,1.08-1.52,1.67-2.61,1.33-1.09-.36-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <line x1="155.33" y1="548.81" x2="372.86" y2="651.17" />
    <path d="M156.2,546.93c1.04.48,1.49,1.73.99,2.75-.48,1.04-1.71,1.49-2.75.99-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.71-1.48,2.75-.99Z" />
    <path d="M156.2,546.93c1.04.48,1.49,1.73.99,2.75-.48,1.04-1.71,1.49-2.75.99-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.71-1.48,2.75-.99Z" />
    <path d="M373.73,649.29c1.04.5,1.49,1.73.99,2.76-.48,1.04-1.71,1.48-2.75.99-1.04-.48-1.48-1.73-.99-2.76.48-1.04,1.71-1.48,2.75-.99Z" />
    <path d="M373.73,649.29c1.04.5,1.49,1.73.99,2.76-.48,1.04-1.71,1.48-2.75.99-1.04-.48-1.48-1.73-.99-2.76.48-1.04,1.71-1.48,2.75-.99Z" />
    <line x1="156.38" y1="531.21" x2="359.36" y2="660.04" />
    <path d="M157.48,529.45c.97.62,1.26,1.91.65,2.86-.62.97-1.89,1.26-2.86.65-.97-.62-1.26-1.89-.65-2.86.62-.97,1.91-1.26,2.86-.65Z" />
    <path d="M157.48,529.45c.97.62,1.26,1.91.65,2.86-.62.97-1.89,1.26-2.86.65-.97-.62-1.26-1.89-.65-2.86.62-.97,1.91-1.26,2.86-.65Z" />
    <path d="M360.48,658.28c.97.61,1.24,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <path d="M360.48,658.28c.97.61,1.24,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.97-.61-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <line x1="159.18" y1="514.43" x2="344.43" y2="667.68" />
    <path d="M160.5,512.84c.88.72,1.01,2.03.28,2.92-.73.88-2.03.99-2.92.28-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.26Z" />
    <path d="M160.5,512.84c.88.72,1.01,2.03.28,2.92-.73.88-2.03.99-2.92.28-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.26Z" />
    <path d="M345.74,666.09c.88.73,1.01,2.03.28,2.92s-2.03,1.01-2.92.28c-.88-.73-1.01-2.03-.28-2.92s2.03-1.01,2.92-.28Z" />
    <path d="M345.74,666.09c.88.73,1.01,2.03.28,2.92s-2.03,1.01-2.92.28c-.88-.73-1.01-2.03-.28-2.92s2.03-1.01,2.92-.28Z" />
    <line x1="163.59" y1="498.68" x2="328.17" y2="673.94" />
    <path d="M165.1,497.26c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M165.1,497.26c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M329.67,672.51c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.77-.84-.73-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M329.67,672.51c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.77-.84-.73-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <line x1="169.44" y1="484.12" x2="310.76" y2="678.62" />
    <path d="M171.12,482.9c.66.93.47,2.22-.47,2.9-.93.68-2.21.47-2.89-.46-.68-.93-.47-2.22.46-2.9.93-.68,2.22-.47,2.9.46Z" />
    <path d="M171.12,482.9c.66.93.47,2.22-.47,2.9-.93.68-2.21.47-2.89-.46-.68-.93-.47-2.22.46-2.9.93-.68,2.22-.47,2.9.46Z" />
    <path d="M312.43,677.4c.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46Z" />
    <path d="M312.43,677.4c.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46Z" />
    <line x1="176.57" y1="470.92" x2="292.39" y2="681.6" />
    <path d="M178.39,469.93c.55.99.18,2.27-.83,2.82-.99.54-2.25.18-2.8-.83-.55-.99-.19-2.25.82-2.8,1.01-.55,2.27-.19,2.82.82Z" />
    <path d="M178.39,469.93c.55.99.18,2.27-.83,2.82-.99.54-2.25.18-2.8-.83-.55-.99-.19-2.25.82-2.8,1.01-.55,2.27-.19,2.82.82Z" />
    <path d="M294.2,680.61c.55.99.19,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.83Z" />
    <path d="M294.2,680.61c.55.99.19,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.83Z" />
    <line x1="184.77" y1="459.21" x2="273.27" y2="682.74" />
    <path d="M186.69,458.45c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.68-1.16-.43-1.08.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.68,1.16Z" />
    <path d="M186.69,458.45c.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.1-2.68-1.16-.43-1.08.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.68,1.16Z" />
    <path d="M275.2,681.98c.41,1.06-.1,2.27-1.16,2.69-1.08.41-2.28-.1-2.69-1.16-.43-1.06.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.69,1.16Z" />
    <path d="M275.2,681.98c.41,1.06-.1,2.27-1.16,2.69-1.08.41-2.28-.1-2.69-1.16-.43-1.06.1-2.28,1.16-2.69,1.06-.43,2.27.1,2.69,1.16Z" />
    <line x1="193.84" y1="449.09" x2="253.64" y2="681.95" />
    <path d="M195.85,448.58c.28,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.39-2.53-1.49-.28-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.53,1.49Z" />
    <path d="M195.85,448.58c.28,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.39-2.53-1.49-.28-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.53,1.49Z" />
    <path d="M255.64,681.44c.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.53-1.49-.28-1.12.39-2.24,1.49-2.53,1.12-.29,2.24.39,2.53,1.49Z" />
    <path d="M255.64,681.44c.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.53-1.49-.28-1.12.39-2.24,1.49-2.53,1.12-.29,2.24.39,2.53,1.49Z" />
    <line x1="203.56" y1="440.65" x2="233.68" y2="679.16" />
    <path d="M205.62,440.39c.14,1.13-.66,2.17-1.81,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.32,1.8Z" />
    <path d="M205.62,440.39c.14,1.13-.66,2.17-1.81,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.32,1.8Z" />
    <path d="M235.74,678.91c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <path d="M235.74,678.91c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <line x1="213.69" y1="433.92" x2="213.69" y2="674.34" />
    <circle cx="213.69" cy="433.92" r="2.07" />
    <circle cx="213.69" cy="433.92" r="2.07" />
    <circle cx="213.69" cy="674.34" r="2.07" />
    <circle cx="213.69" cy="674.34" r="2.07" />
    <line x1="224.01" y1="428.93" x2="193.88" y2="667.46" />
    <path d="M226.07,429.2c-.15,1.13-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.14-1.15,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <path d="M226.07,429.2c-.15,1.13-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.14-1.15,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <path d="M195.94,667.72c-.15,1.13-1.19,1.93-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.32.14-1.13,1.17-1.93,2.32-1.8,1.13.15,1.93,1.19,1.8,2.32Z" />
    <path d="M195.94,667.72c-.15,1.13-1.19,1.93-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.32.14-1.13,1.17-1.93,2.32-1.8,1.13.15,1.93,1.19,1.8,2.32Z" />
    <line x1="234.28" y1="425.7" x2="174.49" y2="658.56" />
    <path d="M236.28,426.21c-.28,1.11-1.41,1.77-2.51,1.49-1.12-.29-1.78-1.41-1.49-2.53.28-1.11,1.41-1.77,2.51-1.49,1.11.29,1.78,1.42,1.49,2.53Z" />
    <path d="M236.28,426.21c-.28,1.11-1.41,1.77-2.51,1.49-1.12-.29-1.78-1.41-1.49-2.53.28-1.11,1.41-1.77,2.51-1.49,1.11.29,1.78,1.42,1.49,2.53Z" />
    <path d="M176.5,659.07c-.29,1.11-1.41,1.78-2.53,1.49-1.11-.28-1.77-1.41-1.49-2.51.29-1.12,1.42-1.78,2.53-1.49,1.11.28,1.77,1.41,1.49,2.51Z" />
    <path d="M176.5,659.07c-.29,1.11-1.41,1.78-2.53,1.49-1.11-.28-1.77-1.41-1.49-2.51.29-1.12,1.42-1.78,2.53-1.49,1.11.28,1.77,1.41,1.49,2.51Z" />
    <line x1="244.27" y1="424.14" x2="155.76" y2="647.68" />
    <path d="M246.19,424.91c-.41,1.06-1.62,1.58-2.68,1.16-1.06-.43-1.59-1.63-1.17-2.69.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.63,1.16,2.69Z" />
    <path d="M246.19,424.91c-.41,1.06-1.62,1.58-2.68,1.16-1.06-.43-1.59-1.63-1.17-2.69.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.63,1.16,2.69Z" />
    <path d="M157.69,648.44c-.43,1.06-1.63,1.58-2.69,1.16-1.06-.43-1.59-1.63-1.16-2.69.41-1.06,1.63-1.58,2.69-1.16,1.06.43,1.58,1.63,1.16,2.69Z" />
    <path d="M157.69,648.44c-.43,1.06-1.63,1.58-2.69,1.16-1.06-.43-1.59-1.63-1.16-2.69.41-1.06,1.63-1.58,2.69-1.16,1.06.43,1.58,1.63,1.16,2.69Z" />
    <line x1="253.76" y1="424.22" x2="137.93" y2="634.9" />
    <path d="M255.57,425.22c-.55,1.01-1.81,1.37-2.82.83-.99-.55-1.37-1.82-.82-2.82.55-1.01,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.8Z" />
    <path d="M255.57,425.22c-.55,1.01-1.81,1.37-2.82.83-.99-.55-1.37-1.82-.82-2.82.55-1.01,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.8Z" />
    <path d="M139.74,635.9c-.54,1.01-1.81,1.37-2.8.82-1.01-.55-1.37-1.81-.82-2.8.54-1.01,1.81-1.38,2.8-.83,1.01.55,1.37,1.81.82,2.82Z" />
    <path d="M139.74,635.9c-.54,1.01-1.81,1.37-2.8.82-1.01-.55-1.37-1.81-.82-2.8.54-1.01,1.81-1.38,2.8-.83,1.01.55,1.37,1.81.82,2.82Z" />
    <line x1="262.53" y1="425.84" x2="121.21" y2="620.34" />
    <path d="M264.21,427.05c-.68.93-1.96,1.13-2.89.46-.93-.68-1.13-1.96-.47-2.89.68-.93,1.98-1.13,2.9-.47.93.68,1.13,1.98.46,2.9Z" />
    <path d="M264.21,427.05c-.68.93-1.96,1.13-2.89.46-.93-.68-1.13-1.96-.47-2.89.68-.93,1.98-1.13,2.9-.47.93.68,1.13,1.98.46,2.9Z" />
    <path d="M122.9,621.56c-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.96-.46-2.9.68-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.47,2.9Z" />
    <path d="M122.9,621.56c-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.96-.46-2.9.68-.93,1.96-1.12,2.89-.46.93.68,1.13,1.98.47,2.9Z" />
    <line x1="270.4" y1="428.86" x2="105.82" y2="604.12" />
    <path d="M271.92,430.27c-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93.79-.83,2.1-.87,2.93-.1.83.79.87,2.1.1,2.93Z" />
    <path d="M271.92,430.27c-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93.79-.83,2.1-.87,2.93-.1.83.79.87,2.1.1,2.93Z" />
    <path d="M107.33,605.53c-.77.84-2.09.88-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.83,2.09-.87,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M107.33,605.53c-.77.84-2.09.88-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.83,2.09-.87,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <line x1="277.18" y1="433.15" x2="91.94" y2="586.39" />
    <path d="M278.49,434.75c-.87.73-2.18.61-2.92-.28-.73-.88-.61-2.2.28-2.92.88-.73,2.2-.61,2.92.28.73.87.61,2.18-.28,2.92Z" />
    <path d="M278.49,434.75c-.87.73-2.18.61-2.92-.28-.73-.88-.61-2.2.28-2.92.88-.73,2.2-.61,2.92.28.73.87.61,2.18-.28,2.92Z" />
    <path d="M93.26,587.99c-.88.73-2.2.61-2.93-.28-.72-.88-.61-2.18.28-2.92s2.2-.61,2.92.28c.73.88.61,2.18-.26,2.92Z" />
    <path d="M93.26,587.99c-.88.73-2.2.61-2.93-.28-.72-.88-.61-2.18.28-2.92s2.2-.61,2.92.28c.73.88.61,2.18-.26,2.92Z" />
    <line x1="282.71" y1="438.54" x2="79.72" y2="567.37" />
    <path d="M283.83,440.29c-.97.62-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.33,2.25-.64,2.86Z" />
    <path d="M283.83,440.29c-.97.62-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.33,2.25-.64,2.86Z" />
    <path d="M80.83,569.11c-.97.62-2.24.33-2.86-.64-.61-.97-.32-2.24.64-2.86.97-.61,2.25-.33,2.87.64.61.97.32,2.25-.65,2.86Z" />
    <path d="M80.83,569.11c-.97.62-2.24.33-2.86-.64-.61-.97-.32-2.24.64-2.86.97-.61,2.25-.33,2.87.64.61.97.32,2.25-.65,2.86Z" />
    <line x1="286.85" y1="444.85" x2="69.32" y2="547.21" />
    <path d="M287.74,446.73c-1.04.48-2.27.04-2.75-.99-.5-1.04-.06-2.28.98-2.76s2.28-.04,2.76.99c.48,1.04.04,2.27-.99,2.76Z" />
    <path d="M287.74,446.73c-1.04.48-2.27.04-2.75-.99-.5-1.04-.06-2.28.98-2.76s2.28-.04,2.76.99c.48,1.04.04,2.27-.99,2.76Z" />
    <path d="M70.2,549.09c-1.04.48-2.27.04-2.75-.99-.5-1.04-.06-2.27.98-2.75,1.04-.5,2.28-.04,2.76.98.48,1.04.04,2.28-.99,2.76Z" />
    <path d="M70.2,549.09c-1.04.48-2.27.04-2.75-.99-.5-1.04-.06-2.27.98-2.75,1.04-.5,2.28-.04,2.76.98.48,1.04.04,2.28-.99,2.76Z" />
    <line x1="289.51" y1="451.87" x2="60.86" y2="526.16" />
    <path d="M290.14,453.83c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61,1.09-.35,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <path d="M290.14,453.83c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61,1.09-.35,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <path d="M61.5,528.12c-1.09.36-2.25-.23-2.61-1.33-.36-1.09.25-2.25,1.33-2.61,1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61Z" />
    <path d="M61.5,528.12c-1.09.36-2.25-.23-2.61-1.33-.36-1.09.25-2.25,1.33-2.61,1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61Z" />
    <line x1="290.57" y1="459.39" x2="54.42" y2="504.43" />
    <path d="M290.97,461.42c-1.13.22-2.21-.53-2.43-1.64-.21-1.13.53-2.21,1.64-2.43,1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.64,2.42Z" />
    <path d="M290.97,461.42c-1.13.22-2.21-.53-2.43-1.64-.21-1.13.53-2.21,1.64-2.43,1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.64,2.42Z" />
    <path d="M54.81,506.46c-1.13.22-2.21-.51-2.43-1.64-.21-1.12.53-2.21,1.66-2.42,1.12-.22,2.2.53,2.42,1.64.22,1.12-.53,2.21-1.64,2.42Z" />
    <path d="M54.81,506.46c-1.13.22-2.21-.51-2.43-1.64-.21-1.12.53-2.21,1.66-2.42,1.12-.22,2.2.53,2.42,1.64.22,1.12-.53,2.21-1.64,2.42Z" />
    <line x1="290" y1="467.18" x2="50.07" y2="482.27" />
    <path d="M290.14,469.24c-1.15.07-2.13-.79-2.2-1.93-.08-1.15.79-2.13,1.93-2.2,1.15-.07,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.93,2.2Z" />
    <path d="M290.14,469.24c-1.15.07-2.13-.79-2.2-1.93-.08-1.15.79-2.13,1.93-2.2,1.15-.07,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.93,2.2Z" />
    <path d="M50.2,484.34c-1.15.07-2.13-.8-2.2-1.95-.07-1.13.8-2.11,1.93-2.2,1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2Z" />
    <path d="M50.2,484.34c-1.15.07-2.13-.8-2.2-1.95-.07-1.13.8-2.11,1.93-2.2,1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2Z" />
    <line x1="287.78" y1="475.01" x2="47.83" y2="459.91" />
    <path d="M287.64,477.07c-1.13-.07-2-1.05-1.93-2.2.07-1.13,1.05-2,2.2-1.93,1.15.07,2.02,1.05,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.93Z" />
    <path d="M287.64,477.07c-1.13-.07-2-1.05-1.93-2.2.07-1.13,1.05-2,2.2-1.93,1.15.07,2.02,1.05,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.93Z" />
    <path d="M47.71,461.98c-1.15-.07-2.02-1.06-1.95-2.2.08-1.15,1.06-2.02,2.2-1.95,1.15.08,2.02,1.06,1.95,2.2-.07,1.15-1.06,2.02-2.2,1.95Z" />
    <path d="M47.71,461.98c-1.15-.07-2.02-1.06-1.95-2.2.08-1.15,1.06-2.02,2.2-1.95,1.15.08,2.02,1.06,1.95,2.2-.07,1.15-1.06,2.02-2.2,1.95Z" />
    <line x1="283.88" y1="482.65" x2="47.72" y2="437.61" />
    <path d="M283.5,484.7c-1.13-.22-1.87-1.3-1.66-2.43.22-1.12,1.3-1.87,2.43-1.64,1.12.22,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66Z" />
    <path d="M283.5,484.7c-1.13-.22-1.87-1.3-1.66-2.43.22-1.12,1.3-1.87,2.43-1.64,1.12.22,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66Z" />
    <path d="M47.34,439.64c-1.13-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66,1.12.22,1.87,1.31,1.64,2.43-.21,1.12-1.3,1.87-2.42,1.64Z" />
    <path d="M47.34,439.64c-1.13-.21-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66,1.12.22,1.87,1.31,1.64,2.43-.21,1.12-1.3,1.87-2.42,1.64Z" />
    <line x1="278.36" y1="489.89" x2="49.7" y2="415.6" />
    <path d="M277.71,491.87c-1.09-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M277.71,491.87c-1.09-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M49.06,417.58c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M49.06,417.58c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <line x1="271.25" y1="496.48" x2="53.72" y2="394.13" />
    <path d="M270.37,498.36c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.49,2.76-.99,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M270.37,498.36c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.49,2.76-.99,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M52.83,396.01c-1.04-.5-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M52.83,396.01c-1.04-.5-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99Z" />
    <line x1="262.67" y1="502.23" x2="59.69" y2="373.42" />
    <path d="M261.57,503.99c-.97-.61-1.26-1.89-.65-2.86.62-.97,1.91-1.26,2.86-.64.97.61,1.26,1.89.65,2.86-.62.97-1.89,1.24-2.86.64Z" />
    <path d="M261.57,503.99c-.97-.61-1.26-1.89-.65-2.86.62-.97,1.91-1.26,2.86-.64.97.61,1.26,1.89.65,2.86-.62.97-1.89,1.24-2.86.64Z" />
    <path d="M58.57,375.17c-.95-.62-1.24-1.91-.64-2.87.62-.95,1.89-1.24,2.86-.64.97.62,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.65Z" />
    <path d="M58.57,375.17c-.95-.62-1.24-1.91-.64-2.87.62-.95,1.89-1.24,2.86-.64.97.62,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.65Z" />
    <line x1="252.72" y1="506.93" x2="67.49" y2="353.69" />
    <path d="M251.41,508.53c-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.28.88.73,1.01,2.04.28,2.93-.73.87-2.03.99-2.92.28Z" />
    <path d="M251.41,508.53c-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.28.88.73,1.01,2.04.28,2.93-.73.87-2.03.99-2.92.28Z" />
    <path d="M66.17,355.27c-.88-.73-1.01-2.03-.28-2.92.73-.88,2.03-1.01,2.92-.28s1.01,2.03.28,2.92c-.73.88-2.03,1.01-2.92.28Z" />
    <path d="M66.17,355.27c-.88-.73-1.01-2.03-.28-2.92.73-.88,2.03-1.01,2.92-.28s1.01,2.03.28,2.92c-.73.88-2.03,1.01-2.92.28Z" />
    <line x1="241.56" y1="510.38" x2="76.99" y2="335.13" />
    <path d="M240.05,511.81c-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08.77.84.73,2.16-.1,2.93-.84.79-2.14.75-2.93-.08Z" />
    <path d="M240.05,511.81c-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08.77.84.73,2.16-.1,2.93-.84.79-2.14.75-2.93-.08Z" />
    <path d="M75.48,336.55c-.79-.84-.75-2.16.08-2.93.84-.79,2.16-.75,2.93.08.79.84.75,2.14-.08,2.93-.84.79-2.16.75-2.93-.08Z" />
    <path d="M75.48,336.55c-.79-.84-.75-2.16.08-2.93.84-.79,2.16-.75,2.93.08.79.84.75,2.14-.08,2.93-.84.79-2.16.75-2.93-.08Z" />
    <line x1="229.33" y1="512.43" x2="88.03" y2="317.93" />
    <path d="M227.66,513.64c-.68-.93-.47-2.21.46-2.89.93-.68,2.22-.47,2.9.46.66.93.46,2.22-.47,2.9-.93.66-2.21.46-2.89-.47Z" />
    <path d="M227.66,513.64c-.68-.93-.47-2.21.46-2.89.93-.68,2.22-.47,2.9.46.66.93.46,2.22-.47,2.9-.93.66-2.21.46-2.89-.47Z" />
    <path d="M86.34,319.16c-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46Z" />
    <path d="M86.34,319.16c-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46.68.93.47,2.22-.46,2.9-.93.66-2.22.47-2.9-.46Z" />
    <line x1="216.23" y1="512.94" x2="100.41" y2="302.26" />
    <path d="M214.41,513.93c-.54-1.01-.18-2.27.83-2.82.99-.55,2.25-.18,2.8.82.55,1.01.19,2.27-.82,2.82-.99.55-2.27.18-2.82-.82Z" />
    <path d="M214.41,513.93c-.54-1.01-.18-2.27.83-2.82.99-.55,2.25-.18,2.8.82.55,1.01.19,2.27-.82,2.82-.99.55-2.27.18-2.82-.82Z" />
    <path d="M98.6,303.25c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.83.55.99.18,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82Z" />
    <path d="M98.6,303.25c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.83.55.99.18,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82Z" />
    <line x1="202.46" y1="511.77" x2="113.95" y2="288.23" />
    <path d="M200.52,512.53c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M200.52,512.53c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M112.03,288.99c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <path d="M112.03,288.99c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <line x1="188.21" y1="508.84" x2="128.43" y2="275.98" />
    <path d="M186.21,509.35c-.29-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.51,1.49.29,1.11-.37,2.24-1.48,2.51-1.12.29-2.24-.37-2.53-1.49Z" />
    <path d="M186.21,509.35c-.29-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.51,1.49.29,1.11-.37,2.24-1.48,2.51-1.12.29-2.24-.37-2.53-1.49Z" />
    <path d="M126.42,276.49c-.29-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49Z" />
    <path d="M126.42,276.49c-.29-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49Z" />
    <line x1="173.75" y1="504.08" x2="143.61" y2="265.56" />
    <path d="M171.69,504.33c-.14-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.31-1.13.15-2.18-.65-2.32-1.8Z" />
    <path d="M171.69,504.33c-.14-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.31-1.13.15-2.18-.65-2.32-1.8Z" />
    <path d="M141.55,265.82c-.14-1.15.66-2.18,1.8-2.32,1.15-.14,2.18.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.32-1.13.14-2.17-.66-2.32-1.8Z" />
    <path d="M141.55,265.82c-.14-1.15.66-2.18,1.8-2.32,1.15-.14,2.18.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.32-1.13.14-2.17-.66-2.32-1.8Z" />
    <line x1="159.28" y1="497.45" x2="159.28" y2="257.04" />
    <path d="M157.21,497.45c0-1.15.93-2.07,2.07-2.07s2.07.93,2.07,2.07-.94,2.07-2.07,2.07-2.07-.93-2.07-2.07Z" />
    <path d="M157.21,497.45c0-1.15.93-2.07,2.07-2.07s2.07.93,2.07,2.07-.94,2.07-2.07,2.07-2.07-.93-2.07-2.07Z" />
    <circle cx="159.28" cy="257.04" r="2.07" />
    <circle cx="159.28" cy="257.04" r="2.07" />
    <line x1="145.05" y1="488.95" x2="175.17" y2="250.43" />
    <path d="M142.99,488.69c.14-1.13,1.17-1.93,2.31-1.8,1.15.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32Z" />
    <path d="M142.99,488.69c.14-1.13,1.17-1.93,2.31-1.8,1.15.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32Z" />
    <path d="M173.12,250.17c.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.15-.14-1.95-1.17-1.8-2.32Z" />
    <path d="M173.12,250.17c.14-1.13,1.17-1.93,2.31-1.8,1.13.15,1.95,1.19,1.8,2.32-.14,1.13-1.17,1.93-2.31,1.8-1.15-.14-1.95-1.17-1.8-2.32Z" />
    <line x1="131.29" y1="478.6" x2="191.07" y2="245.75" />
    <path d="M129.28,478.09c.28-1.11,1.41-1.78,2.51-1.49,1.11.28,1.78,1.41,1.49,2.51-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M129.28,478.09c.28-1.11,1.41-1.78,2.51-1.49,1.11.28,1.78,1.41,1.49,2.51-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M189.07,245.22c.28-1.11,1.41-1.77,2.51-1.49,1.12.29,1.78,1.42,1.49,2.53-.28,1.11-1.41,1.78-2.51,1.49s-1.78-1.41-1.49-2.53Z" />
    <path d="M189.07,245.22c.28-1.11,1.41-1.77,2.51-1.49,1.12.29,1.78,1.42,1.49,2.53-.28,1.11-1.41,1.78-2.51,1.49s-1.78-1.41-1.49-2.53Z" />
    <line x1="118.23" y1="466.46" x2="206.74" y2="242.93" />
    <path d="M116.31,465.7c.41-1.06,1.62-1.59,2.68-1.17,1.08.43,1.59,1.63,1.17,2.69-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68Z" />
    <path d="M116.31,465.7c.41-1.06,1.62-1.59,2.68-1.17,1.08.43,1.59,1.63,1.17,2.69-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68Z" />
    <path d="M204.81,242.17c.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69-.41,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.58-1.62-1.16-2.68Z" />
    <path d="M204.81,242.17c.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69-.41,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.58-1.62-1.16-2.68Z" />
    <line x1="106.11" y1="452.59" x2="221.94" y2="241.92" />
    <path d="M104.3,451.59c.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82s-1.37-1.81-.82-2.82Z" />
    <path d="M104.3,451.59c.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82s-1.37-1.81-.82-2.82Z" />
    <path d="M220.12,240.91c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.82Z" />
    <path d="M220.12,240.91c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.82Z" />
    <line x1="95.14" y1="437.11" x2="236.46" y2="242.61" />
    <path d="M93.47,435.9c.66-.93,1.96-1.13,2.89-.46.93.68,1.13,1.98.46,2.9-.66.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M93.47,435.9c.66-.93,1.96-1.13,2.89-.46.93.68,1.13,1.98.46,2.9-.66.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M234.78,241.39c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.12-1.98-.46-2.9Z" />
    <path d="M234.78,241.39c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9-.68.93-1.98,1.13-2.9.46-.93-.68-1.12-1.98-.46-2.9Z" />
    <line x1="85.51" y1="420.17" x2="250.09" y2="244.92" />
    <path d="M84.01,418.75c.79-.83,2.1-.87,2.93-.1.83.79.87,2.1.1,2.93-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93Z" />
    <path d="M84.01,418.75c.79-.83,2.1-.87,2.93-.1.83.79.87,2.1.1,2.93-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93Z" />
    <path d="M248.58,243.49c.79-.83,2.1-.87,2.93-.1.84.79.87,2.1.1,2.93-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93Z" />
    <path d="M248.58,243.49c.79-.83,2.1-.87,2.93-.1.84.79.87,2.1.1,2.93-.79.84-2.1.88-2.93.1-.84-.79-.88-2.1-.1-2.93Z" />
    <line x1="77.41" y1="401.91" x2="262.66" y2="248.66" />
    <path d="M76.09,400.3c.88-.73,2.2-.61,2.93.28.72.88.59,2.2-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.87-.59-2.18.28-2.92Z" />
    <path d="M76.09,400.3c.88-.73,2.2-.61,2.93.28.72.88.59,2.2-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.87-.59-2.18.28-2.92Z" />
    <path d="M261.33,247.06c.88-.73,2.2-.61,2.93.28.72.88.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.18.28-2.92Z" />
    <path d="M261.33,247.06c.88-.73,2.2-.61,2.93.28.72.88.59,2.18-.28,2.92-.88.73-2.2.61-2.93-.28-.72-.88-.59-2.18.28-2.92Z" />
    <line x1="71" y1="382.51" x2="273.99" y2="253.68" />
    <path d="M69.9,380.75c.95-.61,2.24-.33,2.86.64.61.97.32,2.25-.65,2.86-.97.62-2.24.33-2.86-.64-.61-.97-.32-2.25.65-2.86Z" />
    <path d="M69.9,380.75c.95-.61,2.24-.33,2.86.64.61.97.32,2.25-.65,2.86-.97.62-2.24.33-2.86-.64-.61-.97-.32-2.25.65-2.86Z" />
    <path d="M272.88,251.94c.97-.62,2.25-.33,2.86.64.61.97.33,2.25-.64,2.86-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86Z" />
    <path d="M272.88,251.94c.97-.62,2.25-.33,2.86.64.61.97.33,2.25-.64,2.86-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86Z" />
    <line x1="66.42" y1="362.17" x2="283.95" y2="259.81" />
    <path d="M65.53,360.29c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.28-.99,2.76-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76Z" />
    <path d="M65.53,360.29c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.28-.99,2.76-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76Z" />
    <path d="M283.07,257.93c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.75-1.04.5-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.75Z" />
    <path d="M283.07,257.93c1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.75-1.04.5-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.75Z" />
    <line x1="63.76" y1="341.11" x2="292.41" y2="266.82" />
    <path d="M63.13,339.15c1.09-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61-1.08.36-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61Z" />
    <path d="M63.13,339.15c1.09-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61-1.08.36-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61Z" />
    <path d="M291.77,264.86c1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61Z" />
    <path d="M291.77,264.86c1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.27-1.33,2.61-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61Z" />
    <line x1="63.14" y1="319.57" x2="299.29" y2="274.53" />
    <path d="M62.75,317.54c1.12-.22,2.2.53,2.42,1.64.21,1.12-.53,2.21-1.64,2.42-1.13.22-2.21-.51-2.43-1.64-.21-1.12.53-2.21,1.66-2.42Z" />
    <path d="M62.75,317.54c1.12-.22,2.2.53,2.42,1.64.21,1.12-.53,2.21-1.64,2.42-1.13.22-2.21-.51-2.43-1.64-.21-1.12.53-2.21,1.66-2.42Z" />
    <path d="M298.9,272.48c1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.2-1.64,2.42-1.13.22-2.21-.53-2.43-1.64-.21-1.13.53-2.21,1.64-2.43Z" />
    <path d="M298.9,272.48c1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.2-1.64,2.42-1.13.22-2.21-.53-2.43-1.64-.21-1.13.53-2.21,1.64-2.43Z" />
    <line x1="64.59" y1="297.77" x2="304.52" y2="282.68" />
    <path d="M64.45,295.71c1.15-.07,2.13.79,2.2,1.93.07,1.15-.79,2.13-1.93,2.2-1.15.07-2.13-.79-2.2-1.93-.07-1.15.79-2.13,1.93-2.2Z" />
    <path d="M64.45,295.71c1.15-.07,2.13.79,2.2,1.93.07,1.15-.79,2.13-1.93,2.2-1.15.07-2.13-.79-2.2-1.93-.07-1.15.79-2.13,1.93-2.2Z" />
    <path d="M304.4,280.61c1.13-.07,2.13.8,2.2,1.95.07,1.13-.8,2.11-1.93,2.2-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2Z" />
    <path d="M304.4,280.61c1.13-.07,2.13.8,2.2,1.95.07,1.13-.8,2.11-1.93,2.2-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2Z" />
    <line x1="68.14" y1="275.96" x2="308.08" y2="291.07" />
    <path d="M68.27,273.89c1.15.07,2.02,1.06,1.95,2.2-.08,1.15-1.06,2.02-2.21,1.95-1.13-.08-2-1.06-1.93-2.2.07-1.15,1.06-2.02,2.2-1.95Z" />
    <path d="M68.27,273.89c1.15.07,2.02,1.06,1.95,2.2-.08,1.15-1.06,2.02-2.21,1.95-1.13-.08-2-1.06-1.93-2.2.07-1.15,1.06-2.02,2.2-1.95Z" />
    <path d="M308.21,288.99c1.15.07,2,1.05,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.93-1.15-.07-2-1.05-1.93-2.2.07-1.13,1.05-2,2.2-1.93Z" />
    <path d="M308.21,288.99c1.15.07,2,1.05,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.93-1.15-.07-2-1.05-1.93-2.2.07-1.13,1.05-2,2.2-1.93Z" />
    <line x1="73.79" y1="254.4" x2="309.95" y2="299.44" />
    <path d="M74.18,252.35c1.13.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64Z" />
    <path d="M74.18,252.35c1.13.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64Z" />
    <path d="M310.34,297.41c1.12.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.64-1.13-.21-1.87-1.3-1.64-2.42.21-1.12,1.3-1.87,2.42-1.64Z" />
    <path d="M310.34,297.41c1.12.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.64-1.13-.21-1.87-1.3-1.64-2.42.21-1.12,1.3-1.87,2.42-1.64Z" />
    <line x1="81.52" y1="233.3" x2="310.16" y2="307.58" />
    <path d="M82.15,231.32c1.09.36,1.69,1.52,1.34,2.61-.36,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.09,1.53-1.69,2.61-1.33Z" />
    <path d="M82.15,231.32c1.09.36,1.69,1.52,1.34,2.61-.36,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.09,1.53-1.69,2.61-1.33Z" />
    <path d="M310.81,305.62c1.09.35,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <path d="M310.81,305.62c1.09.35,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.33-2.61.35-1.09,1.52-1.69,2.61-1.33Z" />
    <line x1="91.25" y1="212.9" x2="308.78" y2="315.27" />
    <path d="M92.13,211.04c1.04.48,1.48,1.71.99,2.75-.48,1.04-1.73,1.48-2.76.99-1.04-.48-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.98Z" />
    <path d="M92.13,211.04c1.04.48,1.48,1.71.99,2.75-.48,1.04-1.73,1.48-2.76.99-1.04-.48-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.98Z" />
    <path d="M309.66,313.4c1.04.48,1.48,1.73.99,2.76s-1.73,1.48-2.76.99c-1.04-.5-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99Z" />
    <path d="M309.66,313.4c1.04.48,1.48,1.73.99,2.76s-1.73,1.48-2.76.99c-1.04-.5-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99Z" />
    <line x1="102.89" y1="193.46" x2="305.88" y2="322.28" />
    <path d="M104,191.71c.97.62,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.65-.97-.62-1.24-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65Z" />
    <path d="M104,191.71c.97.62,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.65-.97-.62-1.24-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65Z" />
    <path d="M306.98,320.54c.97.61,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.95-.61-1.24-1.89-.64-2.86.62-.97,1.89-1.26,2.86-.64Z" />
    <path d="M306.98,320.54c.97.61,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.95-.61-1.24-1.89-.64-2.86.62-.97,1.89-1.26,2.86-.64Z" />
    <line x1="116.32" y1="175.18" x2="301.57" y2="328.43" />
    <path d="M117.64,173.58c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.73-.88,2.04-1.01,2.92-.28Z" />
    <path d="M117.64,173.58c.88.73,1.01,2.03.28,2.92-.72.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.73-.88,2.04-1.01,2.92-.28Z" />
    <path d="M302.88,326.83c.88.73,1.01,2.04.28,2.92-.72.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.73-.88,2.04-1.01,2.92-.28Z" />
    <path d="M302.88,326.83c.88.73,1.01,2.04.28,2.92-.72.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92.73-.88,2.04-1.01,2.92-.28Z" />
    <line x1="131.4" y1="158.24" x2="295.99" y2="333.5" />
    <path d="M132.92,156.82c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M132.92,156.82c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M297.49,332.08c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.77-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M297.49,332.08c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.77-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <line x1="147.96" y1="142.85" x2="289.28" y2="337.34" />
    <path d="M149.65,141.62c.66.93.47,2.22-.46,2.9-.94.68-2.22.47-2.9-.46-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46Z" />
    <path d="M149.65,141.62c.66.93.47,2.22-.46,2.9-.94.68-2.22.47-2.9-.46-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46Z" />
    <path d="M290.96,336.12c.68.93.47,2.22-.46,2.9-.93.68-2.22.47-2.9-.46-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46Z" />
    <path d="M290.96,336.12c.68.93.47,2.22-.46,2.9-.93.68-2.22.47-2.9-.46-.66-.93-.46-2.22.47-2.9.93-.68,2.21-.47,2.89.46Z" />
    <line x1="165.83" y1="129.15" x2="281.64" y2="339.81" />
    <path d="M167.64,128.14c.55,1.01.19,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82-.55-1.01-.18-2.27.83-2.82.99-.55,2.25-.18,2.8.82Z" />
    <path d="M167.64,128.14c.55,1.01.19,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82-.55-1.01-.18-2.27.83-2.82.99-.55,2.25-.18,2.8.82Z" />
    <path d="M283.47,338.82c.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82-.55-1.01-.19-2.27.82-2.82,1.01-.55,2.27-.19,2.82.82Z" />
    <path d="M283.47,338.82c.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82-.55-1.01-.19-2.27.82-2.82,1.01-.55,2.27-.19,2.82.82Z" />
    <line x1="184.77" y1="117.26" x2="273.27" y2="340.79" />
    <path d="M186.69,116.49c.43,1.06-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.68-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <path d="M186.69,116.49c.43,1.06-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.68-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <path d="M275.2,340.02c.41,1.06-.1,2.28-1.16,2.69-1.08.43-2.28-.1-2.69-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.69,1.16Z" />
    <path d="M275.2,340.02c.41,1.06-.1,2.28-1.16,2.69-1.08.43-2.28-.1-2.69-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.69,1.16Z" />
    <line x1="204.59" y1="107.3" x2="264.37" y2="340.17" />
    <path d="M206.59,106.79c.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49Z" />
    <path d="M206.59,106.79c.29,1.11-.39,2.24-1.49,2.53-1.11.28-2.24-.39-2.51-1.49-.29-1.12.37-2.24,1.49-2.53,1.11-.29,2.24.39,2.51,1.49Z" />
    <path d="M266.38,339.65c.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49-.29-1.11.37-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49Z" />
    <path d="M266.38,339.65c.29,1.12-.37,2.24-1.49,2.53-1.11.29-2.24-.39-2.51-1.49-.29-1.11.37-2.24,1.49-2.53,1.11-.28,2.24.39,2.51,1.49Z" />
    <line x1="225.04" y1="99.37" x2="255.16" y2="337.88" />
    <path d="M227.08,99.11c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M227.08,99.11c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M257.22,337.63c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <path d="M257.22,337.63c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.31-1.8-.15-1.13.65-2.17,1.8-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <line x1="245.87" y1="93.48" x2="245.87" y2="333.9" />
    <circle cx="245.87" cy="93.48" r="2.07" />
    <circle cx="245.87" cy="93.48" r="2.07" />
    <circle cx="245.87" cy="333.9" r="2.07" />
    <circle cx="245.87" cy="333.9" r="2.07" />
    <line x1="266.87" y1="89.68" x2="236.74" y2="328.21" />
    <path d="M268.92,89.95c-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32.14-1.13,1.17-1.93,2.31-1.8,1.15.14,1.95,1.19,1.8,2.32Z" />
    <path d="M268.92,89.95c-.14,1.13-1.17,1.93-2.31,1.8-1.13-.15-1.95-1.19-1.8-2.32.14-1.13,1.17-1.93,2.31-1.8,1.15.14,1.95,1.19,1.8,2.32Z" />
    <path d="M238.8,328.46c-.15,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.14-1.13,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <path d="M238.8,328.46c-.15,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.14-1.13,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <line x1="287.76" y1="87.96" x2="227.98" y2="320.81" />
    <path d="M289.78,88.47c-.29,1.11-1.42,1.77-2.53,1.49-1.11-.29-1.78-1.42-1.49-2.53.29-1.11,1.41-1.77,2.53-1.49,1.11.29,1.77,1.42,1.49,2.53Z" />
    <path d="M289.78,88.47c-.29,1.11-1.42,1.77-2.53,1.49-1.11-.29-1.78-1.42-1.49-2.53.29-1.11,1.41-1.77,2.53-1.49,1.11.29,1.77,1.42,1.49,2.53Z" />
    <path d="M229.98,321.33c-.28,1.11-1.41,1.78-2.51,1.49-1.11-.28-1.78-1.41-1.49-2.51.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51Z" />
    <path d="M229.98,321.33c-.28,1.11-1.41,1.78-2.51,1.49-1.11-.28-1.78-1.41-1.49-2.51.28-1.12,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51Z" />
    <line x1="308.34" y1="88.25" x2="219.84" y2="311.78" />
    <path d="M310.27,89.01c-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69.41-1.06,1.62-1.59,2.68-1.16,1.06.41,1.59,1.62,1.17,2.68Z" />
    <path d="M310.27,89.01c-.43,1.06-1.63,1.59-2.69,1.17-1.06-.43-1.59-1.63-1.16-2.69.41-1.06,1.62-1.59,2.68-1.16,1.06.41,1.59,1.62,1.17,2.68Z" />
    <path d="M221.76,312.54c-.41,1.06-1.62,1.59-2.68,1.17-1.06-.43-1.59-1.63-1.17-2.69.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.62,1.16,2.68Z" />
    <path d="M221.76,312.54c-.41,1.06-1.62,1.59-2.68,1.17-1.06-.43-1.59-1.63-1.17-2.69.43-1.06,1.63-1.59,2.69-1.16,1.06.41,1.59,1.62,1.16,2.68Z" />
    <line x1="328.35" y1="90.5" x2="212.53" y2="301.18" />
    <path d="M330.17,91.51c-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82.55-.99,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.82Z" />
    <path d="M330.17,91.51c-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82.55-.99,1.81-1.37,2.82-.82.99.55,1.37,1.81.82,2.82Z" />
    <path d="M214.34,302.18c-.55,1.01-1.81,1.37-2.82.83-.99-.55-1.37-1.82-.82-2.82.55-1.01,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.8Z" />
    <path d="M214.34,302.18c-.55,1.01-1.81,1.37-2.82.83-.99-.55-1.37-1.82-.82-2.82.55-1.01,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.8Z" />
    <line x1="347.58" y1="94.63" x2="206.26" y2="289.12" />
    <path d="M349.25,95.85c-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9.68-.93,1.98-1.12,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M349.25,95.85c-.68.93-1.98,1.13-2.9.46-.93-.68-1.13-1.98-.46-2.9.68-.93,1.98-1.12,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M207.93,290.35c-.66.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.46-2.9.66-.93,1.96-1.13,2.89-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M207.93,290.35c-.66.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.46-2.9.66-.93,1.96-1.13,2.89-.46.93.68,1.13,1.98.46,2.9Z" />
    <line x1="365.8" y1="100.49" x2="201.23" y2="275.74" />
    <path d="M367.31,101.9c-.77.84-2.09.88-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.83,2.09-.87,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M367.31,101.9c-.77.84-2.09.88-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.83,2.09-.87,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M202.73,277.15c-.79.84-2.1.88-2.93.1-.83-.79-.87-2.1-.1-2.93.79-.83,2.1-.87,2.93-.1.84.79.88,2.1.1,2.93Z" />
    <path d="M202.73,277.15c-.79.84-2.1.88-2.93.1-.83-.79-.87-2.1-.1-2.93.79-.83,2.1-.87,2.93-.1.84.79.88,2.1.1,2.93Z" />
    <line x1="382.85" y1="107.94" x2="197.61" y2="261.18" />
    <path d="M384.17,109.53c-.88.73-2.18.61-2.92-.28-.73-.87-.61-2.18.28-2.92.88-.73,2.18-.61,2.92.28.73.88.61,2.2-.28,2.92Z" />
    <path d="M384.17,109.53c-.88.73-2.18.61-2.92-.28-.73-.87-.61-2.18.28-2.92.88-.73,2.18-.61,2.92.28.73.88.61,2.2-.28,2.92Z" />
    <path d="M198.92,262.78c-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93Z" />
    <path d="M198.92,262.78c-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93Z" />
    <line x1="398.55" y1="116.81" x2="195.55" y2="245.62" />
    <path d="M399.65,118.56c-.97.61-2.25.32-2.86-.65-.62-.97-.33-2.24.64-2.86.97-.61,2.25-.32,2.86.64.62.97.33,2.25-.64,2.87Z" />
    <path d="M399.65,118.56c-.97.61-2.25.32-2.86-.65-.62-.97-.33-2.24.64-2.86.97-.61,2.25-.32,2.86.64.62.97.33,2.25-.64,2.87Z" />
    <path d="M196.67,247.38c-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.33,2.24-.64,2.86Z" />
    <path d="M196.67,247.38c-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.33,2.24-.64,2.86Z" />
    <line x1="412.74" y1="126.91" x2="195.2" y2="229.28" />
    <path d="M413.62,128.79c-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.76Z" />
    <path d="M413.62,128.79c-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.76Z" />
    <path d="M196.09,231.14c-1.04.5-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.75,1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.75Z" />
    <path d="M196.09,231.14c-1.04.5-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.75,1.04-.48,2.27-.04,2.76.99.48,1.04.04,2.27-.99,2.75Z" />
    <line x1="425.31" y1="138.03" x2="196.67" y2="212.32" />
    <path d="M425.95,140.01c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <path d="M425.95,140.01c-1.08.36-2.25-.23-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.27-1.34,2.61Z" />
    <path d="M197.3,214.3c-1.09.36-2.25-.25-2.61-1.33-.36-1.09.23-2.27,1.33-2.61,1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.25-1.33,2.61Z" />
    <path d="M197.3,214.3c-1.09.36-2.25-.25-2.61-1.33-.36-1.09.23-2.27,1.33-2.61,1.09-.36,2.27.23,2.61,1.33.36,1.09-.23,2.25-1.33,2.61Z" />
    <line x1="436.17" y1="149.97" x2="200.01" y2="195.02" />
    <path d="M436.56,152.01c-1.12.21-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43Z" />
    <path d="M436.56,152.01c-1.12.21-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.42,1.64.22,1.13-.53,2.21-1.64,2.43Z" />
    <path d="M200.4,197.06c-1.12.22-2.21-.53-2.42-1.64-.22-1.12.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.66,2.42Z" />
    <path d="M200.4,197.06c-1.12.22-2.21-.53-2.42-1.64-.22-1.12.53-2.21,1.64-2.43,1.12-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.66,2.42Z" />
    <line x1="445.25" y1="162.49" x2="205.3" y2="177.59" />
    <path d="M445.37,164.56c-1.13.07-2.11-.8-2.2-1.93-.07-1.15.8-2.13,1.95-2.2,1.13-.08,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.95,2.2Z" />
    <path d="M445.37,164.56c-1.13.07-2.11-.8-2.2-1.93-.07-1.15.8-2.13,1.95-2.2,1.13-.08,2.13.79,2.2,1.93.07,1.15-.8,2.13-1.95,2.2Z" />
    <path d="M205.44,179.65c-1.15.08-2.13-.79-2.2-1.93-.08-1.15.79-2.13,1.93-2.2,1.15-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.93,2.2Z" />
    <path d="M205.44,179.65c-1.15.08-2.13-.79-2.2-1.93-.08-1.15.79-2.13,1.93-2.2,1.15-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.93,2.2Z" />
    <line x1="452.5" y1="175.35" x2="212.57" y2="160.26" />
    <path d="M452.38,177.42c-1.15-.07-2-1.06-1.93-2.2.07-1.15,1.05-2.02,2.2-1.95,1.15.08,2,1.06,1.93,2.21-.07,1.13-1.05,2-2.2,1.93Z" />
    <path d="M452.38,177.42c-1.15-.07-2-1.06-1.93-2.2.07-1.15,1.05-2.02,2.2-1.95,1.15.08,2,1.06,1.93,2.21-.07,1.13-1.05,2-2.2,1.93Z" />
    <path d="M212.43,162.32c-1.13-.07-2-1.05-1.93-2.2.07-1.15,1.06-2,2.2-1.93,1.15.07,2.02,1.05,1.95,2.2-.08,1.15-1.06,2-2.21,1.93Z" />
    <path d="M212.43,162.32c-1.13-.07-2-1.05-1.93-2.2.07-1.15,1.06-2,2.2-1.93,1.15.07,2.02,1.05,1.95,2.2-.08,1.15-1.06,2-2.21,1.93Z" />
    <line x1="457.95" y1="188.32" x2="221.79" y2="143.28" />
    <path d="M457.56,190.35c-1.13-.21-1.87-1.3-1.66-2.42.22-1.12,1.31-1.87,2.43-1.64,1.12.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.64Z" />
    <path d="M457.56,190.35c-1.13-.21-1.87-1.3-1.66-2.42.22-1.12,1.31-1.87,2.43-1.64,1.12.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.64Z" />
    <path d="M221.4,145.31c-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64,1.13.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64Z" />
    <path d="M221.4,145.31c-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.85,2.42-1.64,1.13.22,1.87,1.3,1.64,2.43-.21,1.12-1.3,1.85-2.42,1.64Z" />
    <line x1="461.58" y1="201.17" x2="232.92" y2="126.88" />
    <path d="M460.93,203.15c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M460.93,203.15c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M232.29,128.86c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M232.29,128.86c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.08.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <line x1="463.46" y1="213.66" x2="245.93" y2="111.31" />
    <path d="M462.58,215.54c-1.04-.48-1.48-1.73-.99-2.76.48-1.04,1.73-1.48,2.76-.98,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M462.58,215.54c-1.04-.48-1.48-1.73-.99-2.76.48-1.04,1.73-1.48,2.76-.98,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M245.04,113.17c-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.73.99,2.76-.5,1.02-1.73,1.48-2.76.98Z" />
    <path d="M245.04,113.17c-1.04-.48-1.48-1.71-.99-2.75.48-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.73.99,2.76-.5,1.02-1.73,1.48-2.76.98Z" />
    <line x1="463.67" y1="225.59" x2="260.68" y2="96.77" />
    <path d="M462.56,227.34c-.97-.62-1.26-1.89-.65-2.86.62-.97,1.89-1.26,2.86-.64.97.61,1.26,1.89.65,2.86-.62.95-1.89,1.24-2.86.64Z" />
    <path d="M462.56,227.34c-.97-.62-1.26-1.89-.65-2.86.62-.97,1.89-1.26,2.86-.64.97.61,1.26,1.89.65,2.86-.62.95-1.89,1.24-2.86.64Z" />
    <path d="M259.56,98.51c-.97-.61-1.24-1.89-.64-2.86.62-.97,1.89-1.24,2.86-.64s1.26,1.89.64,2.86c-.61.97-1.89,1.26-2.86.64Z" />
    <path d="M259.56,98.51c-.97-.61-1.24-1.89-.64-2.86.62-.97,1.89-1.24,2.86-.64s1.26,1.89.64,2.86c-.61.97-1.89,1.26-2.86.64Z" />
    <line x1="462.31" y1="236.74" x2="277.07" y2="83.48" />
    <path d="M461,238.33c-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28.88.73,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28Z" />
    <path d="M461,238.33c-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28.88.73,1.01,2.03.28,2.92-.73.88-2.04,1.01-2.92.28Z" />
    <path d="M275.76,85.08c-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28.88.73,1.01,2.03.28,2.92s-2.04,1.01-2.92.28Z" />
    <path d="M275.76,85.08c-.88-.73-1.01-2.03-.28-2.92.72-.88,2.03-1.01,2.92-.28.88.73,1.01,2.03.28,2.92s-2.04,1.01-2.92.28Z" />
    <line x1="459.54" y1="246.91" x2="294.96" y2="71.65" />
    <path d="M458.02,248.32c-.77-.83-.73-2.14.1-2.93.83-.77,2.14-.73,2.93.1.79.83.75,2.14-.1,2.93-.83.79-2.14.75-2.93-.1Z" />
    <path d="M458.02,248.32c-.77-.83-.73-2.14.1-2.93.83-.77,2.14-.73,2.93.1.79.83.75,2.14-.1,2.93-.83.79-2.14.75-2.93-.1Z" />
    <path d="M293.44,73.06c-.77-.83-.73-2.14.1-2.93.83-.77,2.14-.73,2.93.1.79.83.75,2.14-.1,2.93-.83.79-2.14.75-2.93-.1Z" />
    <path d="M293.44,73.06c-.77-.83-.73-2.14.1-2.93.83-.77,2.14-.73,2.93.1.79.83.75,2.14-.1,2.93-.83.79-2.14.75-2.93-.1Z" />
    <line x1="455.48" y1="255.93" x2="314.16" y2="61.43" />
    <path d="M453.8,257.15c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.89.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.89-.46Z" />
    <path d="M453.8,257.15c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.89.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.89-.46Z" />
    <path d="M312.48,62.64c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.9.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <path d="M312.48,62.64c-.68-.93-.47-2.22.46-2.89.93-.68,2.22-.47,2.9.46.68.93.47,2.22-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <line x1="450.32" y1="263.67" x2="334.49" y2="52.99" />
    <path d="M448.5,264.66c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82Z" />
    <path d="M448.5,264.66c-.55-1.01-.18-2.27.82-2.82,1.01-.55,2.27-.18,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.82Z" />
    <path d="M332.68,53.98c-.55-1.01-.19-2.27.82-2.82.99-.55,2.27-.18,2.82.82.54,1.01.18,2.27-.83,2.82-.99.55-2.25.19-2.8-.82Z" />
    <path d="M332.68,53.98c-.55-1.01-.19-2.27.82-2.82.99-.55,2.27-.18,2.82.82.54,1.01.18,2.27-.83,2.82-.99.55-2.25.19-2.8-.82Z" />
    <line x1="444.26" y1="269.97" x2="355.74" y2="46.44" />
    <path d="M442.32,270.73c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M442.32,270.73c-.41-1.06.1-2.27,1.16-2.68,1.06-.43,2.28.1,2.69,1.16.43,1.06-.1,2.27-1.16,2.69-1.06.41-2.27-.11-2.69-1.17Z" />
    <path d="M353.82,47.2c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <path d="M353.82,47.2c-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.11,2.69,1.17.41,1.06-.1,2.27-1.16,2.68-1.06.43-2.28-.1-2.69-1.16Z" />
    <line x1="437.49" y1="274.75" x2="377.7" y2="41.89" />
    <path d="M435.48,275.27c-.29-1.12.39-2.24,1.49-2.53,1.11-.29,2.24.39,2.53,1.49.28,1.11-.39,2.24-1.49,2.53-1.12.28-2.24-.39-2.53-1.49Z" />
    <path d="M435.48,275.27c-.29-1.12.39-2.24,1.49-2.53,1.11-.29,2.24.39,2.53,1.49.28,1.11-.39,2.24-1.49,2.53-1.12.28-2.24-.39-2.53-1.49Z" />
    <path d="M375.7,42.4c-.29-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.51,1.49.29,1.11-.37,2.24-1.49,2.51-1.11.29-2.24-.39-2.51-1.49Z" />
    <path d="M375.7,42.4c-.29-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.37,2.51,1.49.29,1.11-.37,2.24-1.49,2.51-1.11.29-2.24-.39-2.51-1.49Z" />
    <line x1="430.25" y1="277.94" x2="400.11" y2="39.42" />
    <path d="M428.19,278.2c-.14-1.13.66-2.18,1.8-2.32,1.15-.14,2.18.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.32-1.13.14-2.17-.66-2.32-1.8Z" />
    <path d="M428.19,278.2c-.14-1.13.66-2.18,1.8-2.32,1.15-.14,2.18.66,2.32,1.8.14,1.13-.66,2.17-1.8,2.32-1.13.14-2.17-.66-2.32-1.8Z" />
    <path d="M398.07,39.68c-.15-1.13.65-2.17,1.8-2.32,1.13-.14,2.17.66,2.31,1.8.15,1.15-.66,2.18-1.8,2.32-1.13.14-2.17-.66-2.31-1.8Z" />
    <path d="M398.07,39.68c-.15-1.13.65-2.17,1.8-2.32,1.13-.14,2.17.66,2.31,1.8.15,1.15-.66,2.18-1.8,2.32-1.13.14-2.17-.66-2.31-1.8Z" />
    <line x1="422.76" y1="279.49" x2="422.76" y2="39.07" />
    <circle cx="422.76" cy="279.49" r="2.07" />
    <circle cx="422.76" cy="279.49" r="2.07" />
    <circle cx="422.76" cy="39.07" r="2.07" />
    <circle cx="422.76" cy="39.07" r="2.07" />
    <line x1="415.24" y1="279.36" x2="445.37" y2="40.85" />
    <path d="M413.18,279.12c.14-1.15,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.15,1.13-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M413.18,279.12c.14-1.15,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.15,1.13-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M443.32,40.59c.14-1.13,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.15,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <path d="M443.32,40.59c.14-1.13,1.17-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31-.15,1.15-1.19,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31Z" />
    <line x1="407.93" y1="277.61" x2="467.72" y2="44.75" />
    <path d="M405.93,277.1c.28-1.11,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M405.93,277.1c.28-1.11,1.41-1.78,2.51-1.49,1.12.28,1.78,1.41,1.49,2.51-.28,1.12-1.41,1.78-2.51,1.49-1.12-.28-1.78-1.41-1.49-2.51Z" />
    <path d="M465.71,44.23c.29-1.11,1.41-1.77,2.53-1.49,1.11.29,1.77,1.42,1.49,2.53-.29,1.11-1.42,1.78-2.53,1.49-1.11-.29-1.78-1.41-1.49-2.53Z" />
    <path d="M465.71,44.23c.29-1.11,1.41-1.77,2.53-1.49,1.11.29,1.77,1.42,1.49,2.53-.29,1.11-1.42,1.78-2.53,1.49-1.11-.29-1.78-1.41-1.49-2.53Z" />
    <line x1="401.05" y1="274.25" x2="489.56" y2="50.72" />
    <path d="M399.13,273.49c.41-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68Z" />
    <path d="M399.13,273.49c.41-1.06,1.63-1.59,2.69-1.16,1.06.41,1.58,1.62,1.16,2.68-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.62-1.16-2.68Z" />
    <path d="M487.63,49.96c.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69-.41,1.06-1.62,1.59-2.68,1.16-1.06-.41-1.59-1.62-1.17-2.68Z" />
    <path d="M487.63,49.96c.43-1.06,1.63-1.59,2.69-1.17,1.06.43,1.59,1.63,1.16,2.69-.41,1.06-1.62,1.59-2.68,1.16-1.06-.41-1.59-1.62-1.17-2.68Z" />
    <line x1="394.83" y1="269.36" x2="510.66" y2="58.69" />
    <path d="M393.02,268.37c.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M393.02,268.37c.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.38,1.81.83,2.82-.55.99-1.81,1.37-2.82.82-1.01-.55-1.37-1.81-.82-2.82Z" />
    <path d="M508.84,57.68c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <path d="M508.84,57.68c.55-.99,1.81-1.37,2.82-.82,1.01.55,1.37,1.81.82,2.82-.55.99-1.81,1.37-2.8.82-1.01-.55-1.38-1.81-.83-2.82Z" />
    <line x1="389.47" y1="263.05" x2="530.79" y2="68.54" />
    <path d="M387.8,261.83c.68-.93,1.96-1.13,2.89-.46.94.68,1.13,1.96.47,2.89-.68.93-1.98,1.13-2.9.47-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M387.8,261.83c.68-.93,1.96-1.13,2.89-.46.94.68,1.13,1.96.47,2.89-.68.93-1.98,1.13-2.9.47-.93-.68-1.13-1.98-.46-2.9Z" />
    <path d="M529.11,67.33c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9s-1.96,1.12-2.89.46c-.93-.68-1.13-1.98-.47-2.9Z" />
    <path d="M529.11,67.33c.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9s-1.96,1.12-2.89.46c-.93-.68-1.13-1.98-.47-2.9Z" />
    <line x1="385.17" y1="255.43" x2="549.75" y2="80.18" />
    <path d="M383.65,254.01c.79-.83,2.1-.87,2.93-.08.84.77.88,2.09.1,2.93-.79.83-2.1.87-2.93.08-.83-.79-.87-2.09-.1-2.93Z" />
    <path d="M383.65,254.01c.79-.83,2.1-.87,2.93-.08.84.77.88,2.09.1,2.93-.79.83-2.1.87-2.93.08-.83-.79-.87-2.09-.1-2.93Z" />
    <path d="M548.24,78.75c.77-.83,2.09-.87,2.93-.08.83.77.87,2.09.08,2.93-.77.83-2.09.87-2.93.08-.83-.79-.87-2.09-.08-2.93Z" />
    <path d="M548.24,78.75c.77-.83,2.09-.87,2.93-.08.83.77.87,2.09.08,2.93-.77.83-2.09.87-2.93.08-.83-.79-.87-2.09-.08-2.93Z" />
    <line x1="382.09" y1="246.66" x2="567.34" y2="93.41" />
    <path d="M380.78,245.06c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.88.72-2.18.61-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M380.78,245.06c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.88.72-2.18.61-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M566.02,91.81c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <path d="M566.02,91.81c.88-.72,2.18-.59,2.92.28.73.88.61,2.2-.28,2.93-.88.72-2.18.59-2.92-.28-.73-.88-.61-2.2.28-2.93Z" />
    <line x1="380.41" y1="236.9" x2="583.41" y2="108.09" />
    <path d="M379.3,235.16c.97-.62,2.25-.33,2.86.64.61.97.33,2.25-.64,2.86-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86Z" />
    <path d="M379.3,235.16c.97-.62,2.25-.33,2.86.64.61.97.33,2.25-.64,2.86-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86Z" />
    <path d="M582.29,106.33c.97-.61,2.25-.32,2.86.65.62.95.33,2.24-.64,2.86-.97.61-2.25.32-2.86-.65-.61-.97-.33-2.24.64-2.86Z" />
    <path d="M582.29,106.33c.97-.61,2.25-.32,2.86.65.62.95.33,2.24-.64,2.86-.97.61-2.25.32-2.86-.65-.61-.97-.33-2.24.64-2.86Z" />
    <line x1="380.24" y1="226.36" x2="597.78" y2="124.01" />
    <path d="M379.36,224.48c1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.28-.99,2.76s-2.27.04-2.76-.99c-.48-1.04-.04-2.27.99-2.76Z" />
    <path d="M379.36,224.48c1.04-.48,2.28-.04,2.76.99.48,1.04.04,2.28-.99,2.76s-2.27.04-2.76-.99c-.48-1.04-.04-2.27.99-2.76Z" />
    <path d="M596.89,122.13c1.04-.48,2.28-.04,2.76.99s.04,2.27-.99,2.75c-1.04.5-2.27.04-2.76-.98-.48-1.04-.04-2.28.99-2.76Z" />
    <path d="M596.89,122.13c1.04-.48,2.28-.04,2.76.99s.04,2.27-.99,2.75c-1.04.5-2.27.04-2.76-.98-.48-1.04-.04-2.28.99-2.76Z" />
    <line x1="381.71" y1="215.24" x2="610.35" y2="140.95" />
    <path d="M381.07,213.26c1.08-.36,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61-1.09.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61Z" />
    <path d="M381.07,213.26c1.08-.36,2.25.25,2.61,1.33.35,1.09-.25,2.27-1.34,2.61-1.09.36-2.25-.23-2.61-1.33-.35-1.09.25-2.25,1.34-2.61Z" />
    <path d="M609.71,138.97c1.09-.35,2.25.25,2.61,1.34.36,1.08-.25,2.25-1.33,2.61-1.09.35-2.27-.25-2.61-1.34-.36-1.09.23-2.25,1.33-2.61Z" />
    <path d="M609.71,138.97c1.09-.35,2.25.25,2.61,1.34.36,1.08-.25,2.25-1.33,2.61-1.09.35-2.27-.25-2.61-1.34-.36-1.09.23-2.25,1.33-2.61Z" />
    <line x1="384.87" y1="203.74" x2="621.03" y2="158.69" />
    <path d="M384.48,201.7c1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.66,2.42-1.12.22-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43Z" />
    <path d="M384.48,201.7c1.13-.21,2.21.53,2.43,1.66.21,1.12-.53,2.21-1.66,2.42-1.12.22-2.21-.53-2.42-1.64-.22-1.13.53-2.21,1.64-2.43Z" />
    <path d="M620.64,156.66c1.12-.22,2.21.53,2.42,1.64.22,1.13-.51,2.21-1.64,2.43-1.12.21-2.21-.53-2.42-1.66-.22-1.12.53-2.2,1.64-2.42Z" />
    <path d="M620.64,156.66c1.12-.22,2.21.53,2.42,1.64.22,1.13-.51,2.21-1.64,2.43-1.12.21-2.21-.53-2.42-1.66-.22-1.12.53-2.2,1.64-2.42Z" />
    <line x1="389.8" y1="192.11" x2="629.75" y2="177.01" />
    <path d="M389.68,190.04c1.13-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.95,2.2-1.13.08-2.13-.79-2.2-1.93-.07-1.15.8-2.13,1.95-2.2Z" />
    <path d="M389.68,190.04c1.13-.07,2.13.8,2.2,1.93.07,1.15-.8,2.13-1.95,2.2-1.13.08-2.13-.79-2.2-1.93-.07-1.15.8-2.13,1.95-2.2Z" />
    <path d="M629.61,174.93c1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2-1.15.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.21Z" />
    <path d="M629.61,174.93c1.15-.07,2.13.8,2.2,1.95.08,1.13-.79,2.13-1.93,2.2-1.15.07-2.13-.8-2.2-1.93-.07-1.15.8-2.13,1.93-2.21Z" />
    <line x1="396.52" y1="180.56" x2="636.46" y2="195.66" />
    <path d="M396.64,178.5c1.15.07,2.02,1.05,1.95,2.2-.08,1.13-1.06,2-2.21,1.93-1.13-.07-2-1.05-1.93-2.2.07-1.15,1.06-2.02,2.2-1.93Z" />
    <path d="M396.64,178.5c1.15.07,2.02,1.05,1.95,2.2-.08,1.13-1.06,2-2.21,1.93-1.13-.07-2-1.05-1.93-2.2.07-1.15,1.06-2.02,2.2-1.93Z" />
    <path d="M636.59,193.59c1.15.07,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95-1.15-.08-2-1.06-1.93-2.2.07-1.15,1.05-2.02,2.2-1.95Z" />
    <path d="M636.59,193.59c1.15.07,2,1.06,1.93,2.2-.07,1.15-1.05,2.02-2.2,1.95-1.15-.08-2-1.06-1.93-2.2.07-1.15,1.05-2.02,2.2-1.95Z" />
    <line x1="405" y1="169.35" x2="641.16" y2="214.4" />
    <path d="M405.39,167.31c1.13.22,1.87,1.3,1.66,2.43-.22,1.12-1.3,1.87-2.43,1.64-1.12-.21-1.87-1.3-1.64-2.42.22-1.13,1.3-1.87,2.42-1.66Z" />
    <path d="M405.39,167.31c1.13.22,1.87,1.3,1.66,2.43-.22,1.12-1.3,1.87-2.43,1.64-1.12-.21-1.87-1.3-1.64-2.42.22-1.13,1.3-1.87,2.42-1.66Z" />
    <path d="M641.55,212.37c1.13.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.87,2.42-1.64Z" />
    <path d="M641.55,212.37c1.13.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66-1.12-.22-1.87-1.3-1.64-2.43.21-1.12,1.3-1.87,2.42-1.64Z" />
    <line x1="415.24" y1="158.7" x2="643.88" y2="232.99" />
    <path d="M415.88,156.72c1.09.36,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.08-.35-1.67-1.52-1.33-2.61.36-1.08,1.52-1.67,2.61-1.33Z" />
    <path d="M415.88,156.72c1.09.36,1.69,1.52,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.33-1.08-.35-1.67-1.52-1.33-2.61.36-1.08,1.52-1.67,2.61-1.33Z" />
    <path d="M644.53,231.02c1.08.36,1.67,1.52,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.08,1.53-1.69,2.63-1.33Z" />
    <path d="M644.53,231.02c1.08.36,1.67,1.52,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.33-1.09-.35-1.69-1.52-1.34-2.61.36-1.08,1.53-1.69,2.63-1.33Z" />
    <line x1="427.14" y1="148.83" x2="644.67" y2="251.19" />
    <path d="M428.02,146.96c1.04.48,1.48,1.73.99,2.76-.48,1.04-1.71,1.48-2.75.99-1.04-.5-1.49-1.73-.99-2.76.48-1.04,1.71-1.48,2.75-.99Z" />
    <path d="M428.02,146.96c1.04.48,1.48,1.73.99,2.76-.48,1.04-1.71,1.48-2.75.99-1.04-.5-1.49-1.73-.99-2.76.48-1.04,1.71-1.48,2.75-.99Z" />
    <path d="M645.56,249.33c1.04.48,1.48,1.71.99,2.75s-1.71,1.48-2.75.99c-1.04-.48-1.49-1.73-.99-2.75.48-1.04,1.71-1.49,2.75-.99Z" />
    <path d="M645.56,249.33c1.04.48,1.48,1.71.99,2.75s-1.71,1.48-2.75.99c-1.04-.48-1.49-1.73-.99-2.75.48-1.04,1.71-1.49,2.75-.99Z" />
    <line x1="440.64" y1="139.96" x2="643.62" y2="268.79" />
    <path d="M441.74,138.22c.97.61,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.97-.61-1.24-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <path d="M441.74,138.22c.97.61,1.26,1.89.64,2.86-.61.97-1.89,1.26-2.86.64-.97-.61-1.24-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.64Z" />
    <path d="M644.73,267.04c.97.62,1.26,1.89.65,2.86-.62.97-1.91,1.26-2.86.65-.97-.62-1.26-1.89-.65-2.86.62-.97,1.89-1.26,2.86-.65Z" />
    <path d="M644.73,267.04c.97.62,1.26,1.89.65,2.86-.62.97-1.91,1.26-2.86.65-.97-.62-1.26-1.89-.65-2.86.62-.97,1.89-1.26,2.86-.65Z" />
    <line x1="455.57" y1="132.32" x2="640.82" y2="285.57" />
    <path d="M456.9,130.72c.88.73,1.01,2.03.28,2.92-.73.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92s2.03-1.01,2.92-.28Z" />
    <path d="M456.9,130.72c.88.73,1.01,2.03.28,2.92-.73.88-2.03,1.01-2.92.28-.88-.73-1.01-2.03-.28-2.92s2.03-1.01,2.92-.28Z" />
    <path d="M642.14,283.96c.88.73,1.01,2.04.28,2.93-.73.87-2.03.99-2.92.26-.88-.72-1.01-2.03-.28-2.92.73-.88,2.03-.99,2.92-.28Z" />
    <path d="M642.14,283.96c.88.73,1.01,2.04.28,2.93-.73.87-2.03.99-2.92.26-.88-.72-1.01-2.03-.28-2.92.73-.88,2.03-.99,2.92-.28Z" />
    <line x1="471.83" y1="126.06" x2="636.41" y2="301.32" />
    <path d="M473.35,124.64c.77.84.73,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M473.35,124.64c.77.84.73,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M637.93,299.9c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <path d="M637.93,299.9c.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08-.79-.84-.75-2.16.1-2.93.83-.79,2.14-.75,2.93.08Z" />
    <line x1="489.24" y1="121.38" x2="630.56" y2="315.88" />
    <path d="M490.93,120.15c.66.93.46,2.22-.47,2.9s-2.21.47-2.89-.46c-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46Z" />
    <path d="M490.93,120.15c.66.93.46,2.22-.47,2.9s-2.21.47-2.89-.46c-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46Z" />
    <path d="M632.24,314.65c.68.93.47,2.22-.46,2.9-.93.68-2.22.47-2.9-.46-.66-.93-.47-2.22.46-2.9.94-.68,2.22-.47,2.9.46Z" />
    <path d="M632.24,314.65c.68.93.47,2.22-.46,2.9-.93.68-2.22.47-2.9-.46-.66-.93-.47-2.22.46-2.9.94-.68,2.22-.47,2.9.46Z" />
    <line x1="507.61" y1="118.4" x2="623.43" y2="329.08" />
    <path d="M509.43,117.4c.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.83-.55-.99-.19-2.25.82-2.8s2.27-.19,2.82.82Z" />
    <path d="M509.43,117.4c.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.83-.55-.99-.19-2.25.82-2.8s2.27-.19,2.82.82Z" />
    <path d="M625.24,328.08c.55.99.19,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82-.55-.99-.18-2.27.83-2.82.99-.54,2.25-.18,2.8.83Z" />
    <path d="M625.24,328.08c.55.99.19,2.25-.82,2.8-1.01.55-2.27.19-2.82-.82-.55-.99-.18-2.27.83-2.82.99-.54,2.25-.18,2.8.83Z" />
    <line x1="526.73" y1="117.26" x2="615.23" y2="340.79" />
    <path d="M528.65,116.49c.43,1.06-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.69-1.16-.41-1.06.1-2.27,1.17-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <path d="M528.65,116.49c.43,1.06-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.69-1.16-.41-1.06.1-2.27,1.17-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <path d="M617.15,340.02c.43,1.08-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.68-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <path d="M617.15,340.02c.43,1.08-.1,2.28-1.16,2.69-1.06.43-2.27-.1-2.68-1.16-.43-1.06.1-2.27,1.16-2.69,1.06-.41,2.27.1,2.68,1.16Z" />
    <line x1="546.36" y1="118.05" x2="606.16" y2="350.91" />
    <path d="M548.38,117.53c.28,1.12-.39,2.24-1.49,2.53-1.12.29-2.24-.39-2.53-1.49-.28-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.53,1.49Z" />
    <path d="M548.38,117.53c.28,1.12-.39,2.24-1.49,2.53-1.12.29-2.24-.39-2.53-1.49-.28-1.11.39-2.24,1.49-2.53,1.11-.28,2.24.39,2.53,1.49Z" />
    <path d="M608.17,350.4c.28,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.53-1.49-.28-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.39,2.53,1.49Z" />
    <path d="M608.17,350.4c.28,1.11-.39,2.24-1.49,2.51-1.11.29-2.24-.37-2.53-1.49-.28-1.11.39-2.24,1.49-2.51,1.11-.29,2.24.39,2.53,1.49Z" />
    <line x1="566.32" y1="120.84" x2="596.44" y2="359.35" />
    <path d="M568.36,120.58c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M568.36,120.58c.15,1.13-.65,2.17-1.8,2.31-1.13.15-2.17-.65-2.31-1.8-.15-1.13.66-2.17,1.8-2.31,1.13-.15,2.17.66,2.31,1.8Z" />
    <path d="M598.49,359.1c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.32-1.8-.14-1.13.66-2.17,1.81-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <path d="M598.49,359.1c.15,1.13-.66,2.17-1.8,2.31-1.13.15-2.17-.66-2.32-1.8-.14-1.13.66-2.17,1.81-2.31,1.13-.15,2.17.65,2.31,1.8Z" />
    <line x1="586.31" y1="125.66" x2="586.31" y2="366.08" />
    <circle cx="586.31" cy="125.66" r="2.07" />
    <circle cx="586.31" cy="125.66" r="2.07" />
    <circle cx="586.31" cy="366.08" r="2.07" />
    <circle cx="586.31" cy="366.08" r="2.07" />
    <line x1="606.12" y1="132.54" x2="575.99" y2="371.07" />
    <path d="M608.18,132.81c-.14,1.13-1.17,1.93-2.32,1.8-1.13-.15-1.93-1.19-1.8-2.32.15-1.13,1.19-1.93,2.32-1.8,1.13.14,1.93,1.17,1.8,2.32Z" />
    <path d="M608.18,132.81c-.14,1.13-1.17,1.93-2.32,1.8-1.13-.15-1.93-1.19-1.8-2.32.15-1.13,1.19-1.93,2.32-1.8,1.13.14,1.93,1.17,1.8,2.32Z" />
    <path d="M578.05,371.32c-.14,1.15-1.17,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.15-1.13,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <path d="M578.05,371.32c-.14,1.15-1.17,1.95-2.32,1.8-1.13-.14-1.93-1.17-1.8-2.31.15-1.13,1.19-1.95,2.32-1.8,1.13.14,1.93,1.17,1.8,2.31Z" />
    <line x1="625.51" y1="141.44" x2="565.72" y2="374.3" />
    <path d="M627.52,141.95c-.29,1.12-1.42,1.78-2.53,1.49-1.11-.28-1.77-1.41-1.49-2.51.29-1.11,1.42-1.78,2.53-1.49,1.11.28,1.77,1.41,1.49,2.51Z" />
    <path d="M627.52,141.95c-.29,1.12-1.42,1.78-2.53,1.49-1.11-.28-1.77-1.41-1.49-2.51.29-1.11,1.42-1.78,2.53-1.49,1.11.28,1.77,1.41,1.49,2.51Z" />
    <path d="M567.72,374.83c-.28,1.11-1.41,1.77-2.51,1.49-1.11-.29-1.78-1.42-1.49-2.53.28-1.11,1.41-1.77,2.51-1.49,1.12.29,1.78,1.41,1.49,2.53Z" />
    <path d="M567.72,374.83c-.28,1.11-1.41,1.77-2.51,1.49-1.11-.29-1.78-1.42-1.49-2.53.28-1.11,1.41-1.77,2.51-1.49,1.12.29,1.78,1.41,1.49,2.53Z" />
    <line x1="644.24" y1="152.33" x2="555.73" y2="375.86" />
    <path d="M646.16,153.09c-.41,1.06-1.63,1.58-2.69,1.16-1.06-.43-1.58-1.63-1.16-2.69.43-1.06,1.63-1.58,2.69-1.16,1.06.43,1.59,1.63,1.16,2.69Z" />
    <path d="M646.16,153.09c-.41,1.06-1.63,1.58-2.69,1.16-1.06-.43-1.58-1.63-1.16-2.69.43-1.06,1.63-1.58,2.69-1.16,1.06.43,1.59,1.63,1.16,2.69Z" />
    <path d="M557.67,376.62c-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.63-1.16-2.69.41-1.06,1.62-1.58,2.68-1.16,1.06.43,1.59,1.63,1.17,2.69Z" />
    <path d="M557.67,376.62c-.43,1.06-1.63,1.59-2.69,1.16-1.06-.41-1.59-1.63-1.16-2.69.41-1.06,1.62-1.58,2.68-1.16,1.06.43,1.59,1.63,1.17,2.69Z" />
    <line x1="662.07" y1="165.1" x2="546.24" y2="375.78" />
    <path d="M663.88,166.09c-.54,1.01-1.81,1.38-2.8.83-1.01-.55-1.37-1.81-.82-2.82.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.37,1.81.82,2.8Z" />
    <path d="M663.88,166.09c-.54,1.01-1.81,1.38-2.8.83-1.01-.55-1.37-1.81-.82-2.82.55-1.01,1.81-1.37,2.8-.82,1.01.55,1.37,1.81.82,2.8Z" />
    <path d="M548.06,376.77c-.55,1.01-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.8.55-1.01,1.81-1.37,2.82-.83.99.55,1.37,1.82.82,2.82Z" />
    <path d="M548.06,376.77c-.55,1.01-1.81,1.37-2.82.82-.99-.55-1.37-1.81-.82-2.8.55-1.01,1.81-1.37,2.82-.83.99.55,1.37,1.82.82,2.82Z" />
    <line x1="678.79" y1="179.66" x2="537.47" y2="374.16" />
    <path d="M680.46,180.89c-.68.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.47-2.9.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M680.46,180.89c-.68.93-1.96,1.12-2.89.46-.93-.68-1.13-1.98-.47-2.9.68-.93,1.98-1.13,2.9-.46.93.68,1.13,1.98.46,2.9Z" />
    <path d="M539.15,375.38c-.68.93-1.98,1.13-2.9.47-.93-.68-1.13-1.98-.46-2.9.68-.93,1.96-1.13,2.89-.46.93.68,1.13,1.96.47,2.89Z" />
    <path d="M539.15,375.38c-.68.93-1.98,1.13-2.9.47-.93-.68-1.13-1.98-.46-2.9.68-.93,1.96-1.13,2.89-.46.93.68,1.13,1.96.47,2.89Z" />
    <line x1="694.18" y1="195.88" x2="529.6" y2="371.14" />
    <path d="M695.68,197.3c-.77.83-2.09.87-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.84,2.09-.88,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M695.68,197.3c-.77.83-2.09.87-2.93.1-.83-.79-.87-2.1-.08-2.93.77-.84,2.09-.88,2.93-.1.83.79.87,2.1.08,2.93Z" />
    <path d="M531.11,372.56c-.79.83-2.1.87-2.93.1-.83-.79-.87-2.1-.1-2.93.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93Z" />
    <path d="M531.11,372.56c-.79.83-2.1.87-2.93.1-.83-.79-.87-2.1-.1-2.93.79-.84,2.1-.88,2.93-.1.84.79.88,2.1.1,2.93Z" />
    <line x1="708.06" y1="213.61" x2="522.82" y2="366.85" />
    <path d="M709.39,215.2c-.88.73-2.2.61-2.92-.28-.73-.88-.61-2.18.28-2.92.87-.73,2.18-.61,2.92.28.72.88.61,2.18-.28,2.92Z" />
    <path d="M709.39,215.2c-.88.73-2.2.61-2.92-.28-.73-.88-.61-2.18.28-2.92.87-.73,2.18-.61,2.92.28.72.88.61,2.18-.28,2.92Z" />
    <path d="M524.15,368.44c-.88.73-2.2.61-2.92-.28-.73-.87-.61-2.18.28-2.92.87-.73,2.18-.61,2.92.28.73.88.61,2.18-.28,2.92Z" />
    <path d="M524.15,368.44c-.88.73-2.2.61-2.92-.28-.73-.87-.61-2.18.28-2.92.87-.73,2.18-.61,2.92.28.73.88.61,2.18-.28,2.92Z" />
    <line x1="720.28" y1="232.63" x2="517.29" y2="361.46" />
    <path d="M721.4,234.39c-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.32,2.24-.64,2.86Z" />
    <path d="M721.4,234.39c-.97.61-2.25.33-2.86-.64-.62-.97-.33-2.25.64-2.86.97-.62,2.24-.33,2.86.64.61.97.32,2.24-.64,2.86Z" />
    <path d="M518.4,363.21c-.97.62-2.24.33-2.86-.64-.61-.97-.33-2.25.64-2.86.97-.62,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86Z" />
    <path d="M518.4,363.21c-.97.62-2.24.33-2.86-.64-.61-.97-.33-2.25.64-2.86.97-.62,2.25-.33,2.86.64.62.97.33,2.25-.64,2.86Z" />
    <line x1="730.68" y1="252.79" x2="513.15" y2="355.15" />
    <path d="M731.57,254.66c-1.04.5-2.28.06-2.76-.98-.48-1.04-.04-2.28.99-2.76s2.27-.04,2.75.99c.5,1.04.06,2.27-.98,2.75Z" />
    <path d="M731.57,254.66c-1.04.5-2.28.06-2.76-.98-.48-1.04-.04-2.28.99-2.76s2.27-.04,2.75.99c.5,1.04.06,2.27-.98,2.75Z" />
    <path d="M514.03,357.03c-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.27-.04,2.75.99.5,1.04.06,2.28-.98,2.76Z" />
    <path d="M514.03,357.03c-1.04.48-2.28.04-2.76-.99-.48-1.04-.04-2.27.99-2.76,1.04-.48,2.27-.04,2.75.99.5,1.04.06,2.28-.98,2.76Z" />
    <line x1="739.14" y1="273.84" x2="510.49" y2="348.13" />
    <path d="M739.79,275.81c-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61,1.09-.36,2.25.23,2.61,1.33.36,1.09-.25,2.25-1.33,2.61Z" />
    <path d="M739.79,275.81c-1.09.36-2.27-.23-2.61-1.33-.36-1.09.23-2.27,1.33-2.61,1.09-.36,2.25.23,2.61,1.33.36,1.09-.25,2.25-1.33,2.61Z" />
    <path d="M511.13,350.11c-1.09.35-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61Z" />
    <path d="M511.13,350.11c-1.09.35-2.25-.25-2.61-1.33-.35-1.09.25-2.27,1.34-2.61,1.08-.36,2.25.23,2.61,1.33.35,1.09-.25,2.25-1.34,2.61Z" />
    <line x1="745.58" y1="295.57" x2="509.43" y2="340.61" />
    <path d="M745.96,297.6c-1.12.22-2.2-.53-2.42-1.64-.22-1.12.53-2.21,1.64-2.42,1.13-.22,2.21.51,2.43,1.64.21,1.12-.53,2.21-1.66,2.42Z" />
    <path d="M745.96,297.6c-1.12.22-2.2-.53-2.42-1.64-.22-1.12.53-2.21,1.64-2.42,1.13-.22,2.21.51,2.43,1.64.21,1.12-.53,2.21-1.66,2.42Z" />
    <path d="M509.82,342.66c-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.64-2.42,1.13-.22,2.21.53,2.43,1.64.21,1.13-.53,2.21-1.64,2.43Z" />
    <path d="M509.82,342.66c-1.13.21-2.21-.53-2.43-1.66-.21-1.12.53-2.21,1.64-2.42,1.13-.22,2.21.53,2.43,1.64.21,1.13-.53,2.21-1.64,2.43Z" />
    <line x1="749.93" y1="317.73" x2="510" y2="332.82" />
    <path d="M750.07,319.81c-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2,1.15-.07,2.13.8,2.2,1.95.07,1.13-.8,2.11-1.93,2.2Z" />
    <path d="M750.07,319.81c-1.15.07-2.13-.8-2.2-1.95-.08-1.13.79-2.13,1.93-2.2,1.15-.07,2.13.8,2.2,1.95.07,1.13-.8,2.11-1.93,2.2Z" />
    <path d="M510.12,334.89c-1.15.07-2.13-.79-2.2-1.93-.07-1.15.8-2.13,1.93-2.2,1.15-.07,2.13.79,2.2,1.93.08,1.15-.79,2.13-1.93,2.2Z" />
    <path d="M510.12,334.89c-1.15.07-2.13-.79-2.2-1.93-.07-1.15.8-2.13,1.93-2.2,1.15-.07,2.13.79,2.2,1.93.08,1.15-.79,2.13-1.93,2.2Z" />
    <line x1="752.17" y1="340.09" x2="512.22" y2="324.99" />
    <path d="M752.04,342.16c-1.15-.08-2.02-1.06-1.95-2.2.07-1.15,1.06-2.02,2.2-1.95,1.15.07,2.02,1.06,1.95,2.2-.08,1.15-1.06,2.02-2.2,1.95Z" />
    <path d="M752.04,342.16c-1.15-.08-2.02-1.06-1.95-2.2.07-1.15,1.06-2.02,2.2-1.95,1.15.07,2.02,1.06,1.95,2.2-.08,1.15-1.06,2.02-2.2,1.95Z" />
    <path d="M512.1,327.06c-1.15-.07-2.02-1.05-1.93-2.2.07-1.15,1.05-2.02,2.2-1.93,1.13.07,2,1.05,1.93,2.2-.07,1.13-1.05,2-2.2,1.93Z" />
    <path d="M512.1,327.06c-1.15-.07-2.02-1.05-1.93-2.2.07-1.15,1.05-2.02,2.2-1.93,1.13.07,2,1.05,1.93,2.2-.07,1.13-1.05,2-2.2,1.93Z" />
    <line x1="752.28" y1="362.39" x2="516.12" y2="317.35" />
    <path d="M751.89,364.44c-1.12-.22-1.87-1.31-1.64-2.43.21-1.12,1.3-1.87,2.42-1.64,1.13.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66Z" />
    <path d="M751.89,364.44c-1.12-.22-1.87-1.31-1.64-2.43.21-1.12,1.3-1.87,2.42-1.64,1.13.21,1.87,1.3,1.64,2.42-.21,1.13-1.3,1.87-2.42,1.66Z" />
    <path d="M515.73,319.38c-1.12-.22-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66,1.13.22,1.87,1.3,1.66,2.43-.22,1.12-1.3,1.87-2.43,1.64Z" />
    <path d="M515.73,319.38c-1.12-.22-1.87-1.3-1.64-2.42.21-1.13,1.3-1.87,2.42-1.66,1.13.22,1.87,1.3,1.66,2.43-.22,1.12-1.3,1.87-2.43,1.64Z" />
    <line x1="750.3" y1="384.4" x2="521.64" y2="310.11" />
    <path d="M749.65,386.38c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M749.65,386.38c-1.08-.36-1.69-1.53-1.33-2.61.36-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.35,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M521.01,312.08c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <path d="M521.01,312.08c-1.09-.36-1.69-1.53-1.33-2.61.35-1.09,1.52-1.69,2.61-1.34,1.09.36,1.69,1.53,1.33,2.61-.36,1.09-1.52,1.69-2.61,1.34Z" />
    <line x1="746.28" y1="405.87" x2="528.75" y2="303.52" />
    <path d="M745.4,407.75c-1.04-.48-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99,1.04.5,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M745.4,407.75c-1.04-.48-1.48-1.73-.99-2.76.5-1.04,1.73-1.48,2.76-.99,1.04.5,1.48,1.73.99,2.76-.5,1.04-1.73,1.48-2.76.99Z" />
    <path d="M527.86,305.38c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.49-2.76.99Z" />
    <path d="M527.86,305.38c-1.04-.48-1.48-1.71-.99-2.75.5-1.04,1.73-1.48,2.76-.99,1.04.48,1.48,1.71.99,2.75-.5,1.04-1.73,1.49-2.76.99Z" />
    <line x1="740.31" y1="426.58" x2="537.33" y2="297.77" />
    <path d="M739.21,428.34c-.97-.62-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65.97.62,1.24,1.91.64,2.86-.62.97-1.89,1.26-2.86.65Z" />
    <path d="M739.21,428.34c-.97-.62-1.26-1.89-.64-2.86.61-.97,1.89-1.26,2.86-.65.97.62,1.24,1.91.64,2.86-.62.97-1.89,1.26-2.86.65Z" />
    <path d="M536.22,299.51c-.97-.61-1.26-1.89-.65-2.86.62-.97,1.89-1.24,2.86-.64s1.26,1.89.65,2.86c-.62.97-1.91,1.26-2.86.64Z" />
    <path d="M536.22,299.51c-.97-.61-1.26-1.89-.65-2.86.62-.97,1.89-1.24,2.86-.64s1.26,1.89.65,2.86c-.62.97-1.91,1.26-2.86.64Z" />
    <line x1="732.51" y1="446.31" x2="547.28" y2="293.07" />
    <path d="M731.19,447.92c-.88-.73-1.01-2.04-.28-2.92.73-.88,2.03-1.01,2.92-.28s1.01,2.03.28,2.92c-.73.88-2.03,1.01-2.92.28Z" />
    <path d="M731.19,447.92c-.88-.73-1.01-2.04-.28-2.92.73-.88,2.03-1.01,2.92-.28s1.01,2.03.28,2.92c-.73.88-2.03,1.01-2.92.28Z" />
    <path d="M545.95,294.67c-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.28.88.73,1.01,2.04.28,2.93-.73.88-2.03.99-2.92.28Z" />
    <path d="M545.95,294.67c-.88-.73-1.01-2.04-.28-2.93.73-.87,2.03-.99,2.92-.28.88.73,1.01,2.04.28,2.93-.73.88-2.03.99-2.92.28Z" />
    <line x1="723.01" y1="464.87" x2="558.44" y2="289.62" />
    <path d="M721.51,466.29c-.79-.84-.75-2.14.08-2.93.84-.79,2.16-.75,2.93.08.79.84.75,2.16-.08,2.93-.84.79-2.16.75-2.93-.08Z" />
    <path d="M721.51,466.29c-.79-.84-.75-2.14.08-2.93.84-.79,2.16-.75,2.93.08.79.84.75,2.16-.08,2.93-.84.79-2.16.75-2.93-.08Z" />
    <path d="M556.92,291.04c-.77-.84-.73-2.16.1-2.93.83-.79,2.14-.75,2.93.08.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08Z" />
    <path d="M556.92,291.04c-.77-.84-.73-2.16.1-2.93.83-.79,2.14-.75,2.93.08.79.84.75,2.16-.1,2.93-.83.79-2.14.75-2.93-.08Z" />
    <line x1="711.97" y1="482.07" x2="570.67" y2="287.57" />
    <path d="M710.3,483.29c-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46.66.93.46,2.22-.47,2.9-.93.68-2.21.47-2.89-.46Z" />
    <path d="M710.3,483.29c-.68-.93-.47-2.22.46-2.9.93-.66,2.22-.47,2.9.46.66.93.46,2.22-.47,2.9-.93.68-2.21.47-2.89-.46Z" />
    <path d="M568.98,288.79c-.66-.93-.46-2.22.47-2.9.93-.66,2.21-.46,2.89.47.68.93.47,2.21-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <path d="M568.98,288.79c-.66-.93-.46-2.22.47-2.9.93-.66,2.21-.46,2.89.47.68.93.47,2.21-.46,2.89-.93.68-2.22.47-2.9-.46Z" />
    <line x1="699.59" y1="497.74" x2="583.77" y2="287.06" />
    <path d="M697.77,498.74c-.55-.99-.18-2.25.82-2.8,1.01-.55,2.27-.19,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.83Z" />
    <path d="M697.77,498.74c-.55-.99-.18-2.25.82-2.8,1.01-.55,2.27-.19,2.82.82.55,1.01.18,2.27-.82,2.82-1.01.55-2.27.18-2.82-.83Z" />
    <path d="M581.96,288.07c-.55-1.01-.19-2.27.82-2.82.99-.55,2.27-.18,2.82.82.54,1.01.18,2.27-.83,2.82-.99.55-2.25.18-2.8-.82Z" />
    <path d="M581.96,288.07c-.55-1.01-.19-2.27.82-2.82.99-.55,2.27-.18,2.82.82.54,1.01.18,2.27-.83,2.82-.99.55-2.25.18-2.8-.82Z" />
  </svg>,
  'NovaIcon',
)
