import { assertEx } from '@xylabs/sdk-js'
import React from 'react'
// eslint-disable-next-line import-x/no-internal-modules
import { createRoot } from 'react-dom/client'

import { App } from './app/index.ts'
import * as serviceWorker from './serviceWorker.ts'
const root = createRoot(assertEx(document.querySelector('#root')))

root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
