import { ArrowForwardRounded } from '@mui/icons-material'
import {
  Card, CardContent, Container, Grid2, Stack, Typography, useTheme,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import {
  FlexCol, FlexGrowCol, FlexRow,
} from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import type { PersonaName } from '../../../../common/data/persona/index.ts'
import { DataistPersonaData, personaIconsData } from '../../../../common/index.ts'

export const DataistPersonas: React.FC<BusyBoxProps> = ({ ...props }) => {
  const theme = useTheme()
  const [rotationAngles, setRotationAngles] = useState<number[]>(() => DataistPersonaData.map(() => 0))
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null)

  useEffect(() => {
    let interval: number | null = null

    if (hoveredCardIndex !== null) {
      interval = window.setInterval(() => {
        setRotationAngles((prev) => {
          const updatedAngles = [...prev]
          updatedAngles[hoveredCardIndex] = (updatedAngles[hoveredCardIndex] + 0.4) % 360
          return updatedAngles
        })
      }, 50)
    }

    return () => {
      if (interval !== null) {
        window.clearInterval(interval)
      }
    }
  }, [hoveredCardIndex])

  const handleMouseEnter = (index: number) => setHoveredCardIndex(index)
  const handleMouseLeave = () => setHoveredCardIndex(null)

  const getCardStyles = (index: number, personaName: PersonaName) => ({
    backgroundColor: hoveredCardIndex === index
      ? theme.palette.personas[personaName].main
      : theme.palette.background.paper,
    cursor: 'pointer',
    textDecoration: 'none',
    height: '100%',
  })

  const getTextColor = (index: number, personaName: PersonaName) => (
    hoveredCardIndex === index ? theme.palette.personas[personaName].contrastText : 'inherit'
  )

  const getIconStyles = (index: number, personaName: PersonaName) => ({
    color: hoveredCardIndex === index
      ? theme.palette.personas[personaName].dark
      : 'inherit',
    transform: `rotate(${rotationAngles[index]}deg)`,
    transition: 'transform 0.1s linear',
    display: 'inline-block',
  })

  return (
    <SectionSpacingRow id="personas">
      <FlexCol alignItems="stretch" sx={{ backgroundSize: 'cover', backgroundPosition: 'center bottom' }} {...props}>
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack flexDirection="column" gap={2}>
            <Typography variant="body1">Dataist Personas</Typography>
            <Grid2 container spacing={0.5}>
              {DataistPersonaData.map((persona, index) => (
                <Grid2
                  key={index}
                  size={{
                    lg: 3, md: 6, xs: 12,
                  }}
                >
                  <Card
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    sx={getCardStyles(index, persona.name as PersonaName)}
                    component={Link}
                    to={`/persona/${persona.name}`}
                  >
                    <CardContent sx={{ height: '100%' }}>
                      <FlexGrowCol sx={{
                        height: '100%', alignItems: 'flex-start', justifyContent: 'space-between',
                      }}
                      >
                        <span style={getIconStyles(index, persona.name as PersonaName)}>
                          {personaIconsData[persona.name as PersonaName].icon}
                        </span>
                        <FlexGrowCol alignItems="flex-start">
                          <Typography variant="h6" fontWeight="bold" textTransform="capitalize" color={getTextColor(index, persona.name as PersonaName)}>
                            {persona.name}
                          </Typography>
                          <Typography variant="body1" gutterBottom color={getTextColor(index, persona.name as PersonaName)}>
                            {persona.subname}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom paddingBottom={1} color={getTextColor(index, persona.name as PersonaName)}>
                            {persona.summary}
                          </Typography>
                        </FlexGrowCol>
                        <FlexRow gap={0.5} alignItems="center" sx={{ color: getTextColor(index, persona.name as PersonaName) }}>
                          <Typography variant="body2">Learn More</Typography>
                          <ArrowForwardRounded fontSize="small" />
                        </FlexRow>
                      </FlexGrowCol>
                    </CardContent>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Stack>
        </Container>
      </FlexCol>
    </SectionSpacingRow>
  )
}
