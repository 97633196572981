import { useTheme } from '@mui/material'
import type { DefaultColorScheme } from '@mui/material/styles/createThemeWithVars.js'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { InvertibleMuiThemeProvider } from '@xylabs/react-invertible-theme'
import React, { useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { RootRoutes } from '../../modules/index.ts'
import { AppAccessGatePage } from '../AccessGatePage.tsx'
import { DataismTheme } from './Theme.ts'

const AppBody: React.FC = () => {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <FlexGrowCol
        width="100vw"
        minHeight="100vh"
        justifyContent="flex-start"
        alignContent="stretch"
        bgcolor={theme.palette.background.default}
        color={theme.palette.text.primary}
      >
        <AppAccessGatePage>
          <RootRoutes />
        </AppAccessGatePage>
      </FlexGrowCol>
    </BrowserRouter>
  )
}

export const AppThemeBody: React.FC = () => {
  const initialValue: DefaultColorScheme | 'system' = useMemo(() => {
    const validValues: DefaultColorScheme[] = ['light', 'dark']
    const savedValue = localStorage.getItem('mui-mode')
    if (savedValue && validValues.includes(savedValue as DefaultColorScheme)) return savedValue as DefaultColorScheme
    return 'system'
  }, [])
  return (
    <InvertibleMuiThemeProvider
      defaultMode={initialValue}
      theme={DataismTheme}
    >
      <AppBody />
    </InvertibleMuiThemeProvider>
  )
}
