import {
  Box,
  Card,
  CardContent,
  Container,
  Grid2,
  Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

interface StrengthWeaknessProps {
  strengths: string
  weaknesses: string
}

const truncateText = (text: string, maxLength = 200) =>
  text.length <= maxLength ? text : text.slice(0, maxLength) + '...'

const StrengthWeaknessCard = ({ title, content }: { content: string; title: string }) => (
  <Card>
    <CardContent>
      <FlexCol sx={{
        alignItems: 'flex-start', justifyContent: 'space-between', height: { md: '100%' },
      }}
      >
        <FlexCol sx={{ alignItems: 'flex-start' }}>
          <Box height="100px"></Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>{title}</Typography>
          <Typography
            textOverflow="ellipsis"
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
            variant="body1"
            gutterBottom
          >
            {content}
          </Typography>
        </FlexCol>
        <ButtonEx paddingLeft={0} variant="text" alignItems="flex-start" to="/quiz" disableRipple>
          <Typography
            textAlign="left"
            variant="button"
          >
            {' '}
            Take the Dataist Persona Quiz to learn more
          </Typography>
        </ButtonEx>
      </FlexCol>
    </CardContent>
  </Card>
)

export const StrengthWeakness: React.FC<StrengthWeaknessProps> = ({ strengths, weaknesses }) => (
  <SectionSpacingRow>
    <Container>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <StrengthWeaknessCard title="Strengths" content={truncateText(strengths)} />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <StrengthWeaknessCard title="Weaknesses" content={truncateText(weaknesses)} />
        </Grid2>
      </Grid2>
    </Container>
  </SectionSpacingRow>
)
