import {
  Slide, Typography, useTheme,
} from '@mui/material'
import { AppBarEx } from '@xylabs/react-appbar'
import { FlexRow } from '@xylabs/react-flexbox'
import { DarkModeIconButtonForColorScheme } from '@xylabs/react-invertible-theme'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

import { MobileNavDialog, RotatingLogo } from '../../../common/index.ts'
import { DashboardMobileMenuItems } from '../lib/index.ts'

export interface DashboardMobileAppBarProps {
  active?: boolean
}

export const DashboardMobileAppBarEx: React.FC<DashboardMobileAppBarProps> = ({ active }) => {
  const theme = useTheme()
  return (
    <Slide direction="down" in={active}>
      <FlexRow width="100%">
        <AppBarEx
          sx={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.getContrastText(theme.palette.background.default),
          }}
          contextToolbar={(
            <LinkEx
              to="/"
              tabIndex={0}
              sx={{
                display: 'inline-flex', alignItems: 'center', gap: 1, p: 2,
              }}
            >
              <RotatingLogo logoStyle={{ height: theme.spacing(3), width: theme.spacing(3) }} />
              <Typography variant="h5">Dataism</Typography>
            </LinkEx>
          )}
          systemToolbar={(
            <FlexRow sx={{ pr: 2 }}>
              <DarkModeIconButtonForColorScheme />
              <MobileNavDialog navBarData={DashboardMobileMenuItems} />
            </FlexRow>
          )}
        />
      </FlexRow>
    </Slide>
  )
}
