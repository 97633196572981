// eslint-disable-next-line import-x/no-internal-modules
import type { FirebaseError } from 'firebase/app'
// eslint-disable-next-line import-x/no-internal-modules
import type { Auth } from 'firebase/auth'
import {
  browserLocalPersistence, createUserWithEmailAndPassword, setPersistence,
// eslint-disable-next-line import-x/no-internal-modules
} from 'firebase/auth'

/**
 * Creates a user using their email and password.
 * @param auth The Auth context to use
 * @param email The email of the user to create.
 * @param password The password of the user to create.
 */
export const createWithEmailAndPassword = async (auth: Auth, email: string, password: string) => {
  try {
    await setPersistence(auth, browserLocalPersistence)
    await createUserWithEmailAndPassword(auth, email, password)
  } catch (e) {
    const error = e as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message
    console.error(errorCode, errorMessage)
    // TODO: Handle different cases (account already exists, password length, etc.)
    if (error.code === 'auth/email-already-in-use') {
      // Alert user they already have an account
    }
  }
}
