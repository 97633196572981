import {
  alpha, List, ListItem, ListItemIcon, ListItemText, type ListProps,
  useTheme,
} from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import type { DappNavItem } from '@xyo-network/os-model'
import { StyledListItemButton, StyledMenuIconWrapSpan } from '@xyo-network/os-react-runtime'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface BaseMenuListProps extends ListProps {
  menuItems?: DappNavItem[]
}

export const BaseMenuList: React.FC<BaseMenuListProps> = (props) => {
  const {
    menuItems, sx, ...listProps
  } = props

  const navigate = useNavigate()
  const theme = useTheme()
  const activeColor = theme.palette.primary.light

  const { pathname } = useLocation()

  const handleChange = (path: string) => {
    navigate(path)
  }

  return (
    <List
      sx={{
        p: 0, overflow: 'auto', ...sx,
      }}
      {...listProps}
    >
      {menuItems?.map(({
        svgIcon: icon, primaryText, path, matchType,
      }) => (
        <ListItem
          key={primaryText}
          disablePadding
          sx={{ paddingBottom: 1 }}
        >
          <StyledListItemButton
            onClick={() => handleChange(path)}
            active={matchType === 'exact' ? pathname === `/dashboard/${path}` : pathname.startsWith(`/dashboard/${path}`)}
            disableRipple
            disableTouchRipple
          >
            <FlexRow
              flexGrow={1}
              paddingX={0.5}
              paddingY={0.5}
              sx={{ '&:hover': { backgroundColor: alpha('#ffffff', 0.05) }, 'borderRadius': '10px' }}
            >
              <ListItemIcon
                sx={{ color: path === path ? activeColor : alpha('#fff', 0.3), justifyContent: 'center' }}
              >
                <StyledMenuIconWrapSpan
                  ref={(ref: HTMLSpanElement) => {
                    if (ref) {
                      ref.innerHTML = icon ?? ''
                    }
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: path === path ? 'bold' : 'inherit',
                  color: path === path ? 'inherit' : alpha(theme.palette.text.primary, 0.5),
                }}
                primary={primaryText}
                style={{ opacity: path === path ? 1 : 0.5 }}
              />
            </FlexRow>
          </StyledListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
