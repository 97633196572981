import { type ListProps } from '@mui/material'
import React from 'react'

import { BaseMenuList } from '../../../common/index.ts'
import { DashboardMenuItems } from '../lib/index.ts'

export const DashboardMenuList: React.FC<ListProps> = (props) => {
  return (
    <BaseMenuList menuItems={DashboardMenuItems} {...props} />
  )
}
