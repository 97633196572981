// eslint-disable-next-line import-x/no-internal-modules
import type { User } from 'firebase/auth'
import { createContext } from 'react'

/**
 * The UserContextType
 */
export interface UserContextType {
  /**
   * Whether the user is loading
   */
  loading: boolean
  /**
   * The user
   */
  user: User | null
}

/**
 * The UserContext
 */
export const UserContext = createContext<UserContextType | undefined>({ loading: true, user: null })
