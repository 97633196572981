import React from 'react'

import { DataismBasePage } from '../../../../common/index.ts'
import { InfoHero } from './Hero.tsx'

export const InfoPage: React.FC = () => {
  return (
    <DataismBasePage title="Information">
      <InfoHero />
    </DataismBasePage>
  )
}
