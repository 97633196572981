import type { BasePageProps } from '@xylabs/react-common'
import { FlexCol } from '@xylabs/react-flexbox'
import { AccessCodeGateFlexbox, useAccessCodes } from '@xyo-network/react-sdk'
import React from 'react'

import { DataismBasePage } from '../common/index.ts'

const LOCAL_STORAGE_KEY = 'DataismAppSettings|accessCodes'
const VALID_ACCESS_CODES = ['481951', '772144', '951459', '681337']

export const AppAccessGatePage: React.FC<BasePageProps> = ({ children }) => {
  const {
    validated, userAccessCodes, onAccessCodeSuccess, onCodeInputChange,
  } = useAccessCodes(LOCAL_STORAGE_KEY)

  return validated
    ? children
    : (
        <DataismBasePage>
          <FlexCol gap={2} sx={{ height: '100dvh', width: '100dvw' }}>
            <AccessCodeGateFlexbox
              sx={{ flexGrow: 0, alignItems: 'start' }}
              userAccessCodes={userAccessCodes}
              onAccessCodeSuccess={onAccessCodeSuccess}
              validAccessCodes={VALID_ACCESS_CODES}
              validateFunction={onCodeInputChange}
            />
          </FlexCol>
        </DataismBasePage>
      )
}
