import {
  Container, Stack, Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

import { FullPageResponsiveBox } from '../../../../common/index.ts'

export const WhatIsDataism: React.FC<BusyBoxProps> = ({ ...props }) => {
  return (
    <FullPageResponsiveBox id="learn-more">
      <FlexCol
        sx={{
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          alignItems: 'stretch',
        }}
        {...props}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack flexDirection="column" alignItems="flex-start" gap={2}>
            <Typography variant="body1">What is Dataism</Typography>
            <Typography variant="h5">
              Dataism is more than just a philosophy—it&apos;s a way of seeing the world.
              Like roots that bind the soil, data connects our choices, actions, and relationships,
              forming an intricate web of truth that grows with us. It&apos;s not cold or
              mechanical—it&apos;s organic, alive, and essential. Dataism embraces the power
              of this interconnectedness, ensuring that data is used with intention and care,
              always honoring the individuals who create it. In this way, Dataism invites us to
              see data as something natural, part of the very fabric that holds the world together.
            </Typography>
            <ButtonEx
              variant="outlined"
              onClick={() => {
                const section = document.querySelector('#three-pillars')
                section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }}
            >
              Learn More
            </ButtonEx>
          </Stack>
        </Container>
      </FlexCol>
    </FullPageResponsiveBox>
  )
}
