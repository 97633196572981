import React from 'react'

import { DataismBasePage } from '../../../../common/index.ts'
import { DataistPersonas } from './DataistPersonas.tsx'
import { HomeHero } from './Hero.tsx'
import { ThreePillars } from './ThreePillars.tsx'
import { WhatIsDataism } from './WhatIsDataism.tsx'

export const HomePage: React.FC = () => {
  return (
    <DataismBasePage title="Home">
      <HomeHero />
      <WhatIsDataism />
      <ThreePillars />
      <DataistPersonas />
    </DataismBasePage>
  )
}
