import { Typography } from '@mui/material'
import type { BasePageProps } from '@xylabs/react-common'
import { BasePage, RedirectWithQuery } from '@xylabs/react-common'
import { CookieConsent } from '@xylabs/react-cookie-consent'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { ScrollToTop } from '@xylabs/react-scroll-to-top'
import { isLocalhost } from '@xylabs/react-shared'
import React from 'react'
import { Helmet } from 'react-helmet'

import { useSettings } from '../contexts/index.ts'
import { DataismAppBar } from '../Header/index.ts'

interface DataismBasePageProps extends BasePageProps {
  colorfulBackground?: boolean
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  extraAppBarSpace?: boolean
  localAsDev?: boolean
  name?: string
}

export const DataismBasePage: React.FC<DataismBasePageProps> = ({
  devOnly = false,
  devOnlyTo = false,
  extraAppBarSpace = false,
  localAsDev = false,
  children,
  title,
  colorfulBackground = false,
  ...props
}) => {
  const { developerMode } = useSettings()

  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)

  return (
    <BasePage
      title={title}
      appBar={<DataismAppBar position="fixed" style={{ left: 0, right: 0 }} />}
      appFooter={(
        <FlexRow alignItems="stretch" justifyContent="center">
          <footer>{`Copyright © ${new Date().getFullYear()} Dataism.org`}</footer>
        </FlexRow>
      )}
      cookieConsent={<CookieConsent />}
      {...props}
    >
      <FlexCol justifyContent="flex-start" alignItems="stretch" minHeight="calc(100vh - 40px)" sx={{ mt: extraAppBarSpace ? '65px' : 0 }}>
        <ScrollToTop />
        {/* This is here to provide spacing under fixed header */}
        <Helmet>
          <title>{`Dataism: ${title ?? 'Home'}`}</title>
        </Helmet>
        {devOnly && devRequirementPassed
          ? (
              <FlexRow margin={1}>
                <Typography variant="body1">
                  Important: This page is a Developer Only page. It is possible that some information may not be correct.
                </Typography>
              </FlexRow>
            )
          : null}
        <FlexRow>{devOnly && !devRequirementPassed ? <RedirectWithQuery to="/" /> : null}</FlexRow>
        {colorfulBackground
          ? (
              <FlexCol
                width="100%"
                alignItems="stretch"
                sx={{
                  backgroundPosition: 'top',
                  backgroundRepeat: 'repeat-y',
                  backgroundSize: 'cover',
                }}
              >
                {children}
              </FlexCol>
            )
          : (
              children
            )}
      </FlexCol>
    </BasePage>
  )
}
