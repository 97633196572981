import {
  Box, Button, Container, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { FullPageResponsiveBox } from '../../../../common/index.ts'
import { PersonaResults } from './PersonaResults.tsx'
import type { PersonaWeights } from './QuizData.tsx'
import { quizData } from './QuizData.tsx' // Importing the quiz data from QuizData

export const Quiz: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [selectedAnswers, setSelectedAnswers] = useState<PersonaWeights[]>([])
  const [selectedAnswer, setSelectedAnswer] = useState<PersonaWeights | null>(null)
  const [result, setResult] = useState<PersonaWeights | null>(null)

  // Function to handle when an answer is selected (but not submitted yet)
  const handleAnswerSelect = (answerWeights: PersonaWeights) => {
    setSelectedAnswer(answerWeights) // Store the selected answer in state
  }

  // Function to handle the submission of the selected answer
  const handleNextQuestion = () => {
    if (selectedAnswer) {
      const updatedAnswers = [...selectedAnswers]
      updatedAnswers[currentQuestionIndex] = selectedAnswer // Store the selected answer for the current question
      setSelectedAnswers(updatedAnswers)
      setSelectedAnswer(null) // Reset the selected answer

      // Move to the next question or calculate result if the quiz is complete
      if (currentQuestionIndex + 1 < quizData.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1)
      } else {
        calculateResult(updatedAnswers)
      }
    }
  }

  // Function to handle going back to the previous question
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
      setSelectedAnswer(selectedAnswers[currentQuestionIndex - 1] || null) // Restore the previous answer
    }
  }

  // Function to calculate the final result after the quiz
  const calculateResult = (finalAnswers: PersonaWeights[]) => {
    const totalWeights: PersonaWeights = {
      vex: 0,
      nova: 0,
      rook: 0,
      aegis: 0,
      lux: 0,
      echo: 0,
      myca: 0,
      cass: 0,
    }

    // Accumulate weights
    for (const answerWeights of finalAnswers) {
      totalWeights.vex += answerWeights.vex
      totalWeights.nova += answerWeights.nova
      totalWeights.rook += answerWeights.rook
      totalWeights.aegis += answerWeights.aegis
      totalWeights.lux += answerWeights.lux
      totalWeights.echo += answerWeights.echo
      totalWeights.myca += answerWeights.myca
    }

    setResult(totalWeights) // Set the calculated result in state
  }

  const currentQuestion = quizData[currentQuestionIndex]

  // Display the radar chart if the result is calculated
  if (result !== null) {
    const quizResultArray = Object.keys(result).map(key => ({
      persona: key.charAt(0).toUpperCase() + key.slice(1),
      value: result[key as keyof PersonaWeights],
      fullMark: 100,
    }))
    return <PersonaResults resultData={quizResultArray} />
  }

  // Display the current quiz question and answers
  return (
    <Container>
      <FullPageResponsiveBox>
        <Box>
          <Typography variant="h5">{currentQuestion.question}</Typography>
          <RadioGroup>
            {currentQuestion.answers.map((answer, index) => (
              <FormControlLabel
                key={index}
                control={<Radio />}
                label={answer.text}
                checked={selectedAnswer === answer.weights} // Check the radio button if it's the selected answer
                onChange={() => handleAnswerSelect(answer.weights)} // Handle selecting the answer
              />
            ))}
          </RadioGroup>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', marginTop: 2,
          }}
          >
            <Button
              variant="outlined"
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0} // Disable "Go Back" if on the first question
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNextQuestion}
              disabled={!selectedAnswer} // Disable the "Next" button until an answer is selected
            >
              {currentQuestionIndex + 1 < quizData.length ? 'Next' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </FullPageResponsiveBox>
    </Container>
  )
}
