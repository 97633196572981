import type { Theme } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import type { BasePageProps } from '@xylabs/react-common'
import { FlexGrowCol, FlexGrowRow } from '@xylabs/react-flexbox'
import React from 'react'

import { DataismBasePage } from '../../../common/index.ts'
import { DashboardMobileAppBarEx } from './DashboardMobileAppBarEx.tsx'
import { DashboardDrawer } from './Drawer.tsx'

export interface DashboardPageProps extends BasePageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = ({ children, ...props }) => {
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  return (
    <DataismBasePage hideAppBar hideFooter {...props}>
      <FlexGrowRow flexDirection={isMedium ? 'row' : 'column'} justifyContent="start" alignItems="start">
        {isMedium ? <DashboardDrawer /> : <DashboardMobileAppBarEx active={!isMedium} />}
        <FlexGrowCol justifyContent="start" alignItems="start" sx={{ mt: 2, ml: 2 }}>
          {children}
        </FlexGrowCol>
      </FlexGrowRow>
    </DataismBasePage>
  )
}
