import type { DropdownSectionDataProps } from '../../common/index.ts'

export const NavbarData: Array<DropdownSectionDataProps> = [
  {
    title: 'The Road Ahead',
    colItems: {
      title: '',
      items: [
        {
          desc: 'Why should Dataism matter to you?',
          linkText: 'Why Dataism?',
          to: '/mission/why',
        },
        {
          desc: 'Guidance on joining the Dataism philosophy by becoming a Dataist',
          linkText: 'How to be a Dataist',
          to: '/mission/how',
        },
      ],
    },
    name: 'Mission',
    to: '/mission',
  },
  {
    name: 'Info',
    colItems: {
      items: [
        {
          desc: 'Rights that pertain to people that create or own data',
          linkText: 'Dataism Bill of Rights',
          to: '/info/dataism-bill-of-rights',
        },
        {
          desc: 'Pledge that invokes the primary mantras of Dataism',
          linkText: 'Dataism Pledge',
          to: '/info/dataism-pledge',
        },
      ],
    },
    to: '/info',
  },
  {
    name: 'Tools',
    colItems: {
      items: [
        {
          desc: 'Personal Data Exploration Tool (built on XYO)',
          href: 'https://foreventory.com',
          linkText: 'Foreventory',
        },
        {
          desc: 'Blockchain Project that focuses on Sovereignty, Provenance, and Permanence of Data',
          href: 'https://xyo-network',
          linkText: 'XYO Network',
        },
        {
          desc: 'Interplanetary File System',
          href: 'https://ipfs.tech',
          linkText: 'IPFS',
        },
        {
          desc: 'Generative Pre-trained Transformer (GPT) model developed by OpenAI',
          href: 'https://chat.openai.com/',
          linkText: 'ChatGPT',
        },
      ],
    },
    to: '/tools',
  },
  {
    name: 'Resources',
    colItems: {
      items: [
        {
          desc: 'Movies and television shows that explore Dataism',
          linkText: 'Movies & Television',
          to: '/resources/movies',
        },
        {
          desc: 'Books that explore Dataism',
          linkText: 'Books',
          to: '/resources/books',
        },
        {
          desc: 'Dataism thought leaders',
          linkText: 'People',
          to: '/resources/people',
        },
      ],
    },
    to: '/resources',
  },
  {
    name: 'People',
    colItems: {
      items: [
        {
          desc: 'Historian, philosopher, and the bestselling author',
          href: 'https://www.ynharari.com/',
          linkText: 'Yuval Noah Harari',
        },
        {
          desc: 'Poet, Internet philosopher and was a co-founder of the Electronic Frontier Foundation',
          href: 'https://www.eff.org/john-perry-barlow',
          linkText: 'John Perry Barlow (1947-2018)',
        },
        {
          desc: 'Entrepreneur, Technologist, Futurist, Dataist, Co-Founder of XYO',
          href: 'https://arietrouw.com',
          linkText: 'Arie Trouw',
        },
        {
          desc: 'Computer scientist, composer, artist, and author',
          href: 'http://www.jaronlanier.com',
          linkText: 'Jaron Lanier',
        },
        {
          desc: 'The inventor of the World Wide Web',
          href: 'https://www.w3.org/People/Berners-Lee/',
          linkText: 'Sir Tim Berners-Lee',
        },
        {
          desc: 'Data scientist and the author of "Weapons of Math Destruction"',
          href: 'https://mathbabe.org/',
          linkText: "Cathy O'Neil",
        },
        {
          desc: 'Professor of Internet Governance and Regulation, Oxford Internet Institute; Fellow of Keble College',
          href: 'https://www.ox.ac.uk/news-and-events/find-an-expert/professor-viktor-mayer-sch%C3%B6nberger',
          linkText: 'Viktor Mayer-Schönberger',
        },
        {
          desc: 'Zuboff is a professor emerita at Harvard Business School and the author of "The Age of Surveillance Capitalism"',
          href: 'https://shoshanazuboff.com/',
          linkText: 'Shoshana Zuboff',
        },
      ],
    },
    to: '/people',
  },
]
