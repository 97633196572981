export type PersonaWeights = {
  aegis: number
  cass: number
  echo: number
  lux: number
  myca: number
  nova: number
  rook: number
  vex: number
}

export type Answer = {
  text: string
  weights: PersonaWeights
}

export type Question = {
  answers: Answer[]
  question: string
}

export const quizData: Question[] = [
  {
    question: 'How do you feel about using new technology?',
    answers: [
      {
        text: 'I love trying out new tech.',
        weights: {
          vex: 3, nova: 1, rook: 0, aegis: 0, lux: 2, echo: 1, myca: 1, cass: 0,
        },
      },
      {
        text: "I'm interested, but I hesitate before trying.",
        weights: {
          nova: 3, vex: 1, rook: 1, aegis: 1, lux: 1, echo: 0, myca: 1, cass: 1,
        },
      },
      {
        text: 'I prefer to stick with what I know.',
        weights: {
          rook: 3, aegis: 2, lux: 0, vex: 0, nova: 1, echo: 1, myca: 1, cass: 3,
        },
      },
      {
        text: 'I try it only if it benefits the team.',
        weights: {
          myca: 3, lux: 1, vex: 0, rook: 1, echo: 1, nova: 0, aegis: 0, cass: 0,
        },
      },
      {
        text: 'I rarely try new tech unless necessary.',
        weights: {
          cass: 3, aegis: 2, rook: 1, vex: 0, echo: 0, lux: 0, nova: 0, myca: 0,
        },
      },
    ],
  },
  {
    question: 'How do you approach learning new skills?',
    answers: [
      {
        text: 'I love exploring and trying new things.',
        weights: {
          nova: 3, vex: 2, echo: 1, lux: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I stick to proven methods that work.',
        weights: {
          cass: 3, rook: 2, aegis: 2, vex: 0, nova: 0, echo: 0, lux: 0, myca: 0,
        },
      },
      {
        text: 'I enjoy learning in collaboration with others.',
        weights: {
          myca: 3, nova: 1, lux: 1, rook: 0, echo: 0, aegis: 0, vex: 0, cass: 0,
        },
      },
      {
        text: 'I focus on how the skills can help long-term.',
        weights: {
          lux: 3, nova: 1, echo: 0, rook: 0, aegis: 0, vex: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I am hesitant to learn anything new unless needed.',
        weights: {
          cass: 2, aegis: 2, rook: 1, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
        },
      },
    ],
  },
  {
    question: 'How do you organize tasks?',
    answers: [
      {
        text: 'I love making to-do lists.',
        weights: {
          rook: 3, aegis: 1, lux: 1, vex: 0, nova: 1, echo: 0, myca: 0, cass: 1,
        },
      },
      {
        text: 'I jump between tasks based on what’s urgent.',
        weights: {
          vex: 3, nova: 2, echo: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I try to prioritize but find it hard sometimes.',
        weights: {
          lux: 2, rook: 2, nova: 1, echo: 1, aegis: 0, myca: 0, vex: 0, cass: 1,
        },
      },
      {
        text: 'I rely on team collaboration to stay organized.',
        weights: {
          myca: 3, vex: 1, lux: 0, rook: 0, nova: 0, echo: 0, aegis: 1, cass: 0,
        },
      },
      {
        text: 'I focus on maintaining steady progress.',
        weights: {
          cass: 3, rook: 1, aegis: 2, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
        },
      },
    ],
  },
  {
    question: 'How do you handle mistakes?',
    answers: [
      {
        text: 'I see mistakes as part of the learning process.',
        weights: {
          nova: 3, vex: 1, lux: 1, echo: 2, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I avoid mistakes at all costs.',
        weights: {
          aegis: 3, rook: 2, myca: 1, lux: 1, vex: 0, echo: 0, nova: 0, cass: 2,
        },
      },
      {
        text: 'I feel frustrated and try to fix it immediately.',
        weights: {
          echo: 3, vex: 2, nova: 1, rook: 0, aegis: 0, lux: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I prefer to step back and reassess.',
        weights: {
          rook: 2, lux: 2, nova: 1, aegis: 1, myca: 0, vex: 0, echo: 0, cass: 1,
        },
      },
      {
        text: 'I learn best by reflecting with others.',
        weights: {
          myca: 3, nova: 1, echo: 0, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
        },
      },
    ],
  },
  {
    question: 'How do you approach decision-making?',
    answers: [
      {
        text: 'I go with my gut instinct.',
        weights: {
          vex: 3, nova: 2, echo: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I rely on data to make decisions.',
        weights: {
          rook: 3, lux: 1, aegis: 1, echo: 0, nova: 1, vex: 0, myca: 0, cass: 2,
        },
      },
      {
        text: 'I gather feedback from others first.',
        weights: {
          myca: 3, lux: 1, rook: 0, vex: 0, nova: 0, echo: 0, aegis: 0, cass: 0,
        },
      },
      {
        text: 'I think about the long-term impact.',
        weights: {
          lux: 3, rook: 1, aegis: 1, nova: 0, vex: 0, echo: 0, myca: 0, cass: 1,
        },
      },
      {
        text: 'I evaluate slowly and methodically.',
        weights: {
          cass: 3, aegis: 1, rook: 1, lux: 0, vex: 0, nova: 0, echo: 0, myca: 0,
        },
      },
    ],
  },
  {
    question: 'How do you handle change?',
    answers: [
      {
        text: 'I embrace change and see it as an opportunity.',
        weights: {
          vex: 3, nova: 1, lux: 2, echo: 2, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I cautiously evaluate whether change is beneficial.',
        weights: {
          cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I trust change only if it directly benefits my team.',
        weights: {
          myca: 3, lux: 1, rook: 0, aegis: 0, vex: 0, nova: 0, echo: 0, cass: 1,
        },
      },
      {
        text: "I don't welcome change and prefer stability.",
        weights: {
          cass: 2, rook: 2, aegis: 1, nova: 0, vex: 0, lux: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I evaluate change for long-term outcomes.',
        weights: {
          lux: 3, rook: 1, nova: 1, aegis: 1, vex: 0, echo: 0, myca: 0, cass: 0,
        },
      },
    ],
  },
  {
    question: 'How do you respond to feedback?',
    answers: [
      {
        text: 'I appreciate and implement changes immediately.',
        weights: {
          nova: 3, myca: 2, vex: 1, echo: 1, lux: 0, rook: 0, aegis: 0, cass: 0,
        },
      },
      {
        text: 'I consider feedback carefully but don’t always act.',
        weights: {
          cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I consult with my team before deciding on changes.',
        weights: {
          myca: 3, rook: 1, nova: 0, vex: 0, lux: 0, echo: 0, aegis: 0, cass: 1,
        },
      },
      {
        text: 'I tend to trust my own judgment.',
        weights: {
          vex: 3, cass: 2, nova: 0, echo: 0, lux: 0, rook: 0, myca: 0, aegis: 1,
        },
      },
      {
        text: 'I balance feedback with my long-term strategy.',
        weights: {
          lux: 3, nova: 1, rook: 1, echo: 0, vex: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
    ],
  },
  {
    question: 'How do you react under pressure?',
    answers: [
      {
        text: 'I thrive under pressure and enjoy challenges.',
        weights: {
          vex: 3, nova: 1, echo: 2, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I plan ahead to avoid high-pressure situations.',
        weights: {
          rook: 3, aegis: 2, lux: 1, nova: 0, vex: 0, echo: 0, myca: 0, cass: 2,
        },
      },
      {
        text: 'I stay calm and work through issues methodically.',
        weights: {
          cass: 3, lux: 2, rook: 1, aegis: 1, nova: 0, vex: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I seek support from my team to manage stress.',
        weights: {
          myca: 3, nova: 1, echo: 0, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
        },
      },
      {
        text: 'I prefer to handle stress by experimenting with solutions.',
        weights: {
          echo: 3, vex: 1, nova: 1, rook: 0, aegis: 0, lux: 0, myca: 0, cass: 0,
        },
      },
    ],
  },
  {
    question: 'How do you approach team projects?',
    answers: [
      {
        text: 'I like to lead and make decisions quickly.',
        weights: {
          vex: 3, nova: 1, lux: 2, echo: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I prefer to plan and structure the project.',
        weights: {
          rook: 3, lux: 1, aegis: 2, nova: 0, vex: 0, echo: 0, myca: 0, cass: 1,
        },
      },
      {
        text: 'I enjoy collaborating and fostering teamwork.',
        weights: {
          myca: 3, nova: 1, echo: 1, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
        },
      },
      {
        text: 'I experiment and find creative solutions.',
        weights: {
          echo: 3, nova: 1, vex: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I prefer to stay in a supportive role, avoiding leadership.',
        weights: {
          cass: 3, aegis: 2, rook: 1, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
        },
      },
    ],
  },
  {
    question: 'How do you handle uncertainty in work or life?',
    answers: [
      {
        text: 'I embrace uncertainty and look for new opportunities.',
        weights: {
          vex: 3, nova: 2, echo: 2, lux: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
      {
        text: 'I cautiously assess risks before moving forward.',
        weights: {
          cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I consult with my team to figure out the best approach.',
        weights: {
          myca: 3, lux: 1, rook: 0, vex: 0, nova: 0, echo: 0, aegis: 0, cass: 0,
        },
      },
      {
        text: 'I prefer to wait for clarity before making any decisions.',
        weights: {
          rook: 3, aegis: 1, cass: 1, nova: 0, lux: 0, vex: 0, echo: 0, myca: 0,
        },
      },
      {
        text: 'I innovate and experiment to adapt to uncertain situations.',
        weights: {
          echo: 3, nova: 1, vex: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
        },
      },
    ],
  },
]
