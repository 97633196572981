import { createSvgIcon } from '@mui/material'
import React from 'react'

export const AegisIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 800"
    stroke="currentColor"
    fill="none"
    id="AegisIcon"
  >
    <line x1="753.03" y1="451.54" x2="533.97" y2="348.46" />
    <line x1="717.67" y1="499.47" x2="569.27" y2="308.18" />
    <line x1="666.07" y1="526.55" x2="620.69" y2="288.74" />
    <line x1="609.46" y1="527.72" x2="676.99" y2="295.22" />
    <line x1="560.15" y1="503.54" x2="725.88" y2="327.03" />
    <line x1="528.86" y1="460.12" x2="756.63" y2="378.08" />
    <line x1="522.32" y1="407.75" x2="762.52" y2="438.08" />
    <line x1="541.9" y1="358.69" x2="742.15" y2="494.76" />
    <line x1="583.25" y1="324.44" x2="699.91" y2="536.6" />
    <line x1="637.25" y1="313.31" x2="644.88" y2="555.3" />
    <line x1="692.03" y1="328.55" x2="588.97" y2="547.63" />
    <line x1="735.51" y1="367.66" x2="544.22" y2="516.07" />
    <line x1="758.1" y1="422.93" x2="520.27" y2="468.32" />
    <line x1="754.69" y1="483.14" x2="522.19" y2="415.61" />
    <line x1="725.88" y1="535.99" x2="549.38" y2="370.25" />
    <line x1="677.78" y1="570.74" x2="595.76" y2="342.95" />
    <line x1="620.68" y1="580.67" x2="651.01" y2="340.46" />
    <line x1="566.83" y1="564.39" x2="702.9" y2="364.13" />
    <line x1="527.76" y1="526.26" x2="739.91" y2="409.61" />
    <line x1="511.74" y1="475.42" x2="753.73" y2="467.79" />
    <line x1="522.04" y1="423.71" x2="741.12" y2="526.77" />
    <line x1="556.17" y1="383.23" x2="704.57" y2="574.51" />
    <line x1="606.42" y1="363.57" x2="651.79" y2="601.4" />
    <line x1="661.54" y1="369.82" x2="594.02" y2="602.33" />
    <line x1="709.26" y1="401.39" x2="543.54" y2="577.9" />
    <line x1="738.85" y1="452.17" x2="511.06" y2="534.2" />
    <line x1="743.57" y1="511.87" x2="503.37" y2="481.54" />
    <line x1="722.06" y1="568.24" x2="521.8" y2="432.17" />
    <line x1="678.65" y1="609.76" x2="562" y2="397.61" />
    <line x1="622.47" y1="628.13" x2="614.86" y2="386.13" />
    <line x1="565.43" y1="620.08" x2="668.49" y2="401.02" />
    <line x1="519.55" y1="588.15" x2="710.84" y2="439.74" />
    <line x1="494.48" y1="540.01" x2="732.28" y2="494.62" />
    <line x1="495.26" y1="486.88" x2="727.76" y2="554.41" />
    <line x1="521.35" y1="441.09" x2="697.83" y2="606.81" />
    <line x1="566.61" y1="413.33" x2="648.63" y2="641.12" />
    <line x1="620.76" y1="410.37" x2="590.43" y2="650.57" />
    <line x1="671.55" y1="433.56" x2="535.48" y2="633.82" />
    <line x1="707.46" y1="478.55" x2="495.31" y2="595.19" />
    <line x1="720.21" y1="536.21" x2="478.23" y2="543.83" />
    <line x1="706.53" y1="594.66" x2="487.46" y2="491.6" />
    <line x1="668.92" y1="641.85" x2="520.52" y2="450.55" />
    <line x1="615.1" y1="668.16" x2="569.71" y2="430.33" />
    <line x1="556.27" y1="668.49" x2="623.8" y2="436" />
    <line x1="504.76" y1="643.46" x2="670.48" y2="466.96" />
    <line x1="471.27" y1="599.16" x2="699.06" y2="517.13" />
    <line x1="462.54" y1="545.87" x2="702.74" y2="576.2" />
    <line x1="479.98" y1="495.84" x2="680.23" y2="631.91" />
    <line x1="519.17" y1="460.61" x2="635.83" y2="672.76" />
    <line x1="571.05" y1="448.46" x2="578.67" y2="690.45" />
    <line x1="623.71" y1="462.64" x2="520.65" y2="681.73" />
    <line x1="665.09" y1="500.66" x2="473.81" y2="649.07" />
    <line x1="685.59" y1="554.81" x2="447.78" y2="600.2" />
    <line x1="680.12" y1="613.86" x2="447.63" y2="546.34" />
    <line x1="649.27" y1="665.52" x2="472.79" y2="499.79" />
    <line x1="599.15" y1="699.06" x2="517.13" y2="471.27" />
    <line x1="540.05" y1="707.72" x2="570.38" y2="467.53" />
    <line x1="484.21" y1="690.16" x2="620.28" y2="489.92" />
    <line x1="443.16" y1="650.74" x2="655.31" y2="534.08" />
    <line x1="425.21" y1="598.53" x2="667.19" y2="590.92" />
    <line x1="433.59" y1="545.46" x2="652.67" y2="648.53" />
    <line x1="465.81" y1="503.58" x2="614.21" y2="694.87" />
    <line x1="514.18" y1="482.48" x2="559.55" y2="720.31" />
    <line x1="567.46" y1="487.27" x2="499.92" y2="719.77" />
    <line x1="613.33" y1="517.34" x2="447.61" y2="693.85" />
    <line x1="641.12" y1="566.61" x2="413.33" y2="648.64" />
    <line x1="644.05" y1="624.76" x2="403.85" y2="594.43" />
    <line x1="620.76" y1="679.55" x2="420.52" y2="543.47" />
    <line x1="575.62" y1="719.46" x2="458.98" y2="507.31" />
    <line x1="517.76" y1="736.2" x2="510.13" y2="494.2" />
    <line x1="459.01" y1="726.5" x2="562.09" y2="507.42" />
    <line x1="411.48" y1="692.87" x2="602.77" y2="544.47" />
    <line x1="384.76" y1="643.02" x2="622.58" y2="597.64" />
    <line x1="383.96" y1="588.16" x2="616.45" y2="655.69" />
    <line x1="408.46" y1="540.62" x2="584.96" y2="706.34" />
    <line x1="452.16" y1="511.07" x2="534.2" y2="738.86" />
    <line x1="504.8" y1="506.31" x2="474.47" y2="746.51" />
    <line x1="554.1" y1="527.65" x2="418.03" y2="727.91" />
    <line x1="588.55" y1="570.79" x2="376.4" y2="687.43" />
    <line x1="599.86" y1="626.57" x2="357.88" y2="634.18" />
    <line x1="584.78" y1="683.12" x2="365.72" y2="580.05" />
    <line x1="545.79" y1="728.38" x2="397.4" y2="537.09" />
    <line x1="490.62" y1="752.74" x2="445.23" y2="514.92" />
    <line x1="430.49" y1="751.12" x2="498.01" y2="518.62" />
    <line x1="377.7" y1="724.11" x2="543.41" y2="547.6" />
    <line x1="342.95" y1="677.79" x2="570.74" y2="595.76" />
    <line x1="333.02" y1="622.48" x2="573.22" y2="652.8" />
    <line x1="349.26" y1="570.41" x2="549.51" y2="706.48" />
    <line x1="387.3" y1="533.11" x2="503.95" y2="745.27" />
    <line x1="438.06" y1="518.88" x2="445.68" y2="760.87" />
    <line x1="489.62" y1="530.96" x2="386.56" y2="750.05" />
    <line x1="529.96" y1="566.88" x2="338.67" y2="715.27" />
    <line x1="549.42" y1="618.89" x2="311.62" y2="664.28" />
    <line x1="542.97" y1="675.8" x2="310.48" y2="608.27" />
    <line x1="511.16" y1="725.28" x2="334.66" y2="559.56" />
    <line x1="460.12" y1="756.65" x2="378.09" y2="528.86" />
    <line x1="400.13" y1="763.12" x2="430.46" y2="522.92" />
    <line x1="343.44" y1="743.37" x2="479.51" y2="543.11" />
    <line x1="301.57" y1="701.71" x2="513.72" y2="585.06" />
    <line x1="282.83" y1="647.29" x2="524.82" y2="639.66" />
    <line x1="290.47" y1="591.96" x2="509.53" y2="695.04" />
    <line x1="321.97" y1="547.83" x2="470.38" y2="739.12" />
    <line x1="369.65" y1="524.48" x2="415.04" y2="762.29" />
    <line x1="422.29" y1="526.98" x2="354.77" y2="759.48" />
    <line x1="467.57" y1="554.77" x2="301.85" y2="731.27" />
    <line x1="494.78" y1="601.73" x2="267.01" y2="683.77" />
    <line x1="497.19" y1="657.58" x2="256.99" y2="627.26" />
    <line x1="473.41" y1="710.07" x2="273.16" y2="574" />
    <line x1="427.8" y1="747.67" x2="311.16" y2="535.5" />
    <line x1="369.5" y1="762.07" x2="361.88" y2="520.08" />
    <line x1="310.38" y1="750.05" x2="413.44" y2="530.96" />
    <line x1="262.49" y1="714.08" x2="453.78" y2="565.68" />
    <line x1="235.46" y1="661.88" x2="473.29" y2="616.49" />
    <line x1="234.37" y1="604.68" x2="466.87" y2="672.21" />
    <line x1="258.63" y1="554.77" x2="435.13" y2="720.51" />
    <line x1="302.14" y1="522.88" x2="384.17" y2="750.67" />
    <line x1="354.61" y1="515.75" x2="324.28" y2="755.96" />
    <line x1="403.78" y1="534.75" x2="267.72" y2="735" />
    <line x1="438.14" y1="575.5" x2="225.99" y2="692.16" />
    <line x1="449.4" y1="628.93" x2="207.41" y2="636.55" />
    <line x1="434.28" y1="683.12" x2="215.22" y2="580.05" />
    <line x1="395.32" y1="726.02" x2="246.93" y2="534.73" />
    <line x1="340.21" y1="748.01" x2="294.82" y2="510.2" />
    <line x1="280.17" y1="744.03" x2="347.7" y2="511.54" />
    <line x1="227.51" y1="714.66" x2="393.22" y2="538.16" />
    <line x1="192.93" y1="665.97" x2="420.7" y2="583.95" />
    <line x1="183.19" y1="608.31" x2="423.39" y2="638.63" />
    <line x1="199.68" y1="553.89" x2="399.92" y2="689.96" />
    <line x1="238" y1="514.25" x2="354.65" y2="726.4" />
    <line x1="289.06" y1="497.68" x2="296.69" y2="739.67" />
    <line x1="340.99" y1="507.42" x2="237.91" y2="726.5" />
    <line x1="381.7" y1="540.99" x2="190.41" y2="689.41" />
    <line x1="401.6" y1="590.7" x2="163.79" y2="636.07" />
    <line x1="395.6" y1="645.28" x2="163.11" y2="577.74" />
    <line x1="364.3" y1="692.45" x2="187.8" y2="526.73" />
    <line x1="313.79" y1="721.52" x2="231.76" y2="493.73" />
    <line x1="254.36" y1="725.7" x2="284.69" y2="485.49" />
    <line x1="198.27" y1="703.65" x2="334.35" y2="503.4" />
    <line x1="157.06" y1="659.73" x2="369.21" y2="543.07" />
    <line x1="139" y1="603.04" x2="380.98" y2="595.41" />
    <line x1="147.33" y1="545.46" x2="366.41" y2="648.53" />
    <line x1="179.6" y1="499.08" x2="327.99" y2="690.37" />
    <line x1="228.06" y1="473.5" x2="273.45" y2="711.32" />
    <line x1="281.52" y1="473.79" x2="214" y2="706.28" />
    <line x1="327.65" y1="499.38" x2="161.93" y2="675.87" />
    <line x1="355.75" y1="544.15" x2="127.96" y2="626.18" />
    <line x1="359.08" y1="597.82" x2="118.88" y2="567.49" />
    <line x1="336.25" y1="648.14" x2="135.99" y2="512.07" />
    <line x1="291.64" y1="683.59" x2="174.99" y2="471.42" />
    <line x1="234.35" y1="695.86" x2="226.74" y2="453.88" />
    <line x1="176.29" y1="681.73" x2="279.35" y2="462.64" />
    <line x1="129.49" y1="643.66" x2="320.79" y2="495.26" />
    <line x1="103.59" y1="589.38" x2="341.41" y2="544" />
    <line x1="103.66" y1="530.11" x2="336.15" y2="597.64" />
    <line x1="129.11" y1="478.17" x2="305.59" y2="643.89" />
    <line x1="173.83" y1="444.25" x2="255.85" y2="672.04" />
    <line x1="227.55" y1="435.11" x2="197.21" y2="675.31" />
    <line x1="278" y1="452.12" x2="141.93" y2="652.38" />
    <line x1="313.68" y1="490.92" x2="101.53" y2="607.58" />
    <line x1="326.27" y1="542.39" x2="84.29" y2="550.01" />
    <line x1="312.54" y1="594.66" x2="93.47" y2="491.6" />
    <line x1="274.98" y1="635.67" x2="126.59" y2="444.37" />
    <line x1="221.3" y1="655.78" x2="175.91" y2="417.96" />
    <line x1="162.72" y1="649.94" x2="230.25" y2="417.43" />
    <line x1="111.55" y1="618.72" x2="277.26" y2="442.23" />
    <line x1="78.49" y1="568.24" x2="306.27" y2="486.21" />
    <line x1="70.32" y1="508.78" x2="310.5" y2="539.12" />
    <line x1="88.36" y1="452.61" x2="288.62" y2="588.68" />
    <line x1="128.3" y1="411.23" x2="244.94" y2="623.38" />
    <line x1="180.99" y1="392.95" x2="188.62" y2="634.93" />
    <line x1="234.57" y1="401.02" x2="131.51" y2="620.08" />
    <line x1="276.98" y1="432.92" x2="85.69" y2="581.33" />
    <line x1="298.58" y1="480.98" x2="60.77" y2="526.37" />
    <line x1="294.32" y1="533.97" x2="61.83" y2="466.44" />
    <line x1="264.78" y1="579.57" x2="88.28" y2="413.84" />
    <line x1="216.05" y1="607.07" x2="134.02" y2="379.3" />
    <line x1="158.44" y1="609.75" x2="188.77" y2="369.54" />
    <line x1="104.18" y1="586.2" x2="240.25" y2="385.95" />
    <line x1="64.82" y1="540.81" x2="276.97" y2="424.16" />
    <line x1="48.64" y1="482.68" x2="290.62" y2="475.07" />
    <line x1="58.88" y1="423.71" x2="277.96" y2="526.79" />
    <line x1="93.07" y1="375.96" x2="241.46" y2="567.25" />
    <line x1="143.48" y1="349.03" x2="188.87" y2="586.84" />
    <line x1="198.9" y1="348" x2="131.37" y2="580.51" />
    <line x1="247.01" y1="372.31" x2="81.3" y2="548.81" />
    <line x1="277.12" y1="415.83" x2="49.34" y2="497.86" />
    <line x1="282.47" y1="468.28" x2="42.27" y2="437.96" />
    <line x1="261.69" y1="517.41" x2="61.44" y2="381.34" />
    <line x1="219.14" y1="551.71" x2="102.49" y2="339.56" />
    <line x1="163.93" y1="562.85" x2="156.32" y2="320.87" />
    <line x1="107.97" y1="547.63" x2="211.03" y2="328.55" />
    <line x1="63.29" y1="508.52" x2="254.58" y2="360.11" />
    <line x1="39.51" y1="453.21" x2="277.33" y2="407.82" />
    <line x1="41.72" y1="392.96" x2="274.22" y2="460.49" />
    <line x1="69.33" y1="340.06" x2="245.83" y2="505.78" />
    <line x1="116.23" y1="305.21" x2="198.27" y2="532.99" />
    <line x1="172.14" y1="295.18" x2="141.82" y2="535.39" />
    <line x1="224.81" y1="311.34" x2="88.74" y2="511.59" />
    <line x1="262.69" y1="349.32" x2="50.55" y2="465.97" />
    <line x1="277.53" y1="400.01" x2="35.53" y2="407.64" />
    <line x1="266.03" y1="451.54" x2="46.97" y2="348.46" />
    <line x1="230.73" y1="491.82" x2="82.33" y2="300.53" />
    <line x1="179.31" y1="511.26" x2="133.93" y2="273.45" />
    <line x1="123.01" y1="504.78" x2="190.54" y2="272.28" />
    <line x1="74.12" y1="472.97" x2="239.85" y2="296.46" />
    <line x1="43.35" y1="421.92" x2="271.14" y2="339.88" />
    <line x1="37.48" y1="361.92" x2="277.68" y2="392.25" />
    <line x1="57.85" y1="305.24" x2="258.1" y2="441.31" />
    <line x1="100.09" y1="263.4" x2="216.75" y2="475.56" />
    <line x1="155.12" y1="244.7" x2="162.74" y2="486.69" />
    <line x1="211.03" y1="252.37" x2="107.97" y2="471.45" />
    <line x1="255.78" y1="283.93" x2="64.49" y2="432.34" />
    <line x1="279.73" y1="331.68" x2="41.9" y2="377.07" />
    <line x1="277.81" y1="384.39" x2="45.31" y2="316.86" />
    <line x1="250.62" y1="429.75" x2="74.12" y2="264.01" />
    <line x1="204.24" y1="457.05" x2="122.22" y2="229.26" />
    <line x1="148.99" y1="459.54" x2="179.32" y2="219.33" />
    <line x1="97.1" y1="435.87" x2="233.17" y2="235.61" />
    <line x1="60.09" y1="390.39" x2="272.24" y2="273.74" />
    <line x1="46.27" y1="332.21" x2="288.26" y2="324.58" />
    <line x1="58.88" y1="273.23" x2="277.96" y2="376.29" />
    <line x1="95.43" y1="225.47" x2="243.83" y2="416.77" />
    <line x1="148.21" y1="198.6" x2="193.58" y2="436.43" />
    <line x1="205.98" y1="197.67" x2="138.46" y2="430.18" />
    <line x1="256.46" y1="222.1" x2="90.74" y2="398.61" />
    <line x1="288.94" y1="265.8" x2="61.15" y2="347.83" />
    <line x1="296.63" y1="318.46" x2="56.43" y2="288.13" />
    <line x1="278.2" y1="367.83" x2="77.94" y2="231.76" />
    <line x1="238" y1="402.39" x2="121.35" y2="190.24" />
    <line x1="185.14" y1="413.87" x2="177.53" y2="171.87" />
    <line x1="131.51" y1="398.98" x2="234.57" y2="179.92" />
    <line x1="89.16" y1="360.26" x2="280.45" y2="211.86" />
    <line x1="67.72" y1="305.38" x2="305.52" y2="260.01" />
    <line x1="72.24" y1="245.59" x2="304.74" y2="313.12" />
    <line x1="102.17" y1="193.19" x2="278.65" y2="358.91" />
    <line x1="151.37" y1="158.88" x2="233.39" y2="386.67" />
    <line x1="209.57" y1="149.43" x2="179.24" y2="389.63" />
    <line x1="264.52" y1="166.18" x2="128.45" y2="366.44" />
    <line x1="304.69" y1="204.81" x2="92.54" y2="321.45" />
    <line x1="321.77" y1="256.17" x2="79.79" y2="263.79" />
    <line x1="312.54" y1="308.4" x2="93.47" y2="205.34" />
    <line x1="279.48" y1="349.45" x2="131.08" y2="158.15" />
    <line x1="230.29" y1="369.67" x2="184.91" y2="131.84" />
    <line x1="176.2" y1="364" x2="243.73" y2="131.51" />
    <line x1="129.52" y1="333.04" x2="295.24" y2="156.54" />
    <line x1="100.94" y1="282.87" x2="328.73" y2="200.84" />
    <line x1="97.26" y1="223.8" x2="337.46" y2="254.13" />
    <line x1="119.77" y1="168.09" x2="320.02" y2="304.16" />
    <line x1="164.17" y1="127.24" x2="280.83" y2="339.39" />
    <line x1="221.33" y1="109.55" x2="228.95" y2="351.54" />
    <line x1="279.35" y1="118.27" x2="176.29" y2="337.36" />
    <line x1="326.2" y1="150.93" x2="134.91" y2="299.34" />
    <line x1="352.22" y1="199.8" x2="114.41" y2="245.19" />
    <line x1="352.37" y1="253.66" x2="119.88" y2="186.14" />
    <line x1="327.21" y1="300.21" x2="150.73" y2="134.48" />
    <line x1="282.87" y1="328.73" x2="200.85" y2="100.94" />
    <line x1="229.62" y1="332.47" x2="259.95" y2="92.28" />
    <line x1="179.72" y1="310.08" x2="315.79" y2="109.84" />
    <line x1="144.69" y1="265.92" x2="356.84" y2="149.28" />
    <line x1="132.81" y1="209.08" x2="374.79" y2="201.47" />
    <line x1="147.35" y1="151.46" x2="366.41" y2="254.54" />
    <line x1="185.79" y1="105.13" x2="334.19" y2="296.42" />
    <line x1="240.45" y1="79.69" x2="285.82" y2="317.52" />
    <line x1="300.08" y1="80.23" x2="232.54" y2="312.73" />
    <line x1="352.39" y1="106.15" x2="186.67" y2="282.66" />
    <line x1="386.67" y1="151.36" x2="158.88" y2="233.39" />
    <line x1="396.15" y1="205.57" x2="155.95" y2="175.24" />
    <line x1="379.48" y1="256.53" x2="179.24" y2="120.45" />
    <line x1="341.02" y1="292.69" x2="224.38" y2="80.54" />
    <line x1="289.87" y1="305.8" x2="282.24" y2="63.8" />
    <line x1="237.91" y1="292.58" x2="340.99" y2="73.5" />
    <line x1="197.23" y1="255.53" x2="388.52" y2="107.13" />
    <line x1="177.42" y1="202.36" x2="415.24" y2="156.98" />
    <line x1="183.55" y1="144.31" x2="416.04" y2="211.84" />
    <line x1="215.04" y1="93.66" x2="391.54" y2="259.38" />
    <line x1="265.81" y1="61.14" x2="347.84" y2="288.93" />
    <line x1="325.53" y1="53.49" x2="295.2" y2="293.69" />
    <line x1="381.97" y1="72.09" x2="245.9" y2="272.35" />
    <line x1="423.6" y1="112.57" x2="211.45" y2="229.21" />
    <line x1="442.12" y1="165.82" x2="200.14" y2="173.43" />
    <line x1="434.28" y1="219.95" x2="215.22" y2="116.88" />
    <line x1="402.6" y1="262.91" x2="254.21" y2="71.62" />
    <line x1="354.77" y1="285.08" x2="309.38" y2="47.26" />
    <line x1="301.99" y1="281.38" x2="369.51" y2="48.88" />
    <line x1="256.59" y1="252.4" x2="422.3" y2="75.89" />
    <line x1="229.26" y1="204.24" x2="457.05" y2="122.21" />
    <line x1="226.78" y1="147.2" x2="466.98" y2="177.52" />
    <line x1="250.49" y1="93.52" x2="450.74" y2="229.59" />
    <line x1="296.05" y1="54.73" x2="412.7" y2="266.89" />
    <line x1="354.32" y1="39.13" x2="361.94" y2="281.12" />
    <line x1="413.44" y1="49.95" x2="310.38" y2="269.04" />
    <line x1="461.33" y1="84.73" x2="270.04" y2="233.12" />
    <line x1="488.38" y1="135.72" x2="250.58" y2="181.11" />
    <line x1="489.52" y1="191.73" x2="257.03" y2="124.2" />
    <line x1="465.34" y1="240.44" x2="288.84" y2="74.72" />
    <line x1="421.91" y1="271.14" x2="339.88" y2="43.35" />
    <line x1="369.54" y1="277.08" x2="399.87" y2="36.88" />
    <line x1="320.49" y1="256.89" x2="456.56" y2="56.63" />
    <line x1="286.28" y1="214.94" x2="498.43" y2="98.29" />
    <line x1="275.18" y1="160.34" x2="517.17" y2="152.71" />
    <line x1="290.47" y1="104.96" x2="509.53" y2="208.04" />
    <line x1="329.62" y1="60.88" x2="478.03" y2="252.17" />
    <line x1="384.96" y1="37.71" x2="430.35" y2="275.52" />
    <line x1="445.23" y1="40.52" x2="377.71" y2="273.02" />
    <line x1="498.15" y1="68.73" x2="332.43" y2="245.23" />
    <line x1="532.99" y1="116.23" x2="305.22" y2="198.27" />
    <line x1="543.01" y1="172.74" x2="302.81" y2="142.42" />
    <line x1="526.84" y1="226" x2="326.59" y2="89.93" />
    <line x1="488.84" y1="264.5" x2="372.2" y2="52.33" />
    <line x1="438.12" y1="279.92" x2="430.5" y2="37.93" />
    <line x1="386.56" y1="269.04" x2="489.62" y2="49.95" />
    <line x1="346.22" y1="234.32" x2="537.51" y2="85.92" />
    <line x1="326.71" y1="183.51" x2="564.54" y2="138.12" />
    <line x1="333.13" y1="127.79" x2="565.63" y2="195.32" />
    <line x1="364.87" y1="79.49" x2="541.37" y2="245.23" />
    <line x1="415.83" y1="49.33" x2="497.86" y2="277.12" />
    <line x1="475.72" y1="44.04" x2="445.39" y2="284.25" />
    <line x1="532.28" y1="65" x2="396.22" y2="265.25" />
    <line x1="574.01" y1="107.84" x2="361.86" y2="224.5" />
    <line x1="592.59" y1="163.45" x2="350.6" y2="171.08" />
    <line x1="584.78" y1="219.95" x2="365.72" y2="116.88" />
    <line x1="553.07" y1="265.27" x2="404.68" y2="73.98" />
    <line x1="505.18" y1="289.8" x2="459.79" y2="51.99" />
    <line x1="452.3" y1="288.46" x2="519.83" y2="55.97" />
    <line x1="406.78" y1="261.84" x2="572.49" y2="85.34" />
    <line x1="379.3" y1="216.05" x2="607.07" y2="134.03" />
    <line x1="376.61" y1="161.37" x2="616.81" y2="191.69" />
    <line x1="400.08" y1="110.04" x2="600.32" y2="246.11" />
    <line x1="445.35" y1="73.6" x2="562" y2="285.75" />
    <line x1="503.31" y1="60.33" x2="510.94" y2="302.32" />
    <line x1="562.09" y1="73.5" x2="459.01" y2="292.58" />
    <line x1="609.59" y1="110.59" x2="418.3" y2="259.01" />
    <line x1="636.21" y1="163.93" x2="398.4" y2="209.3" />
    <line x1="636.89" y1="222.26" x2="404.4" y2="154.72" />
    <line x1="612.21" y1="273.27" x2="435.7" y2="107.55" />
    <line x1="568.24" y1="306.27" x2="486.21" y2="78.48" />
    <line x1="515.31" y1="314.51" x2="545.64" y2="74.3" />
    <line x1="465.65" y1="296.6" x2="601.71" y2="96.35" />
    <line x1="430.79" y1="256.93" x2="642.94" y2="140.27" />
    <line x1="419.02" y1="204.59" x2="661" y2="196.97" />
    <line x1="433.59" y1="151.46" x2="652.65" y2="254.54" />
    <line x1="472.01" y1="109.63" x2="620.4" y2="300.93" />
    <line x1="526.55" y1="88.69" x2="571.94" y2="326.5" />
    <line x1="586" y1="93.72" x2="518.48" y2="326.21" />
    <line x1="638.07" y1="124.13" x2="472.35" y2="300.62" />
    <line x1="672.04" y1="173.82" x2="444.25" y2="255.86" />
    <line x1="681.12" y1="232.51" x2="440.92" y2="202.18" />
    <line x1="664.01" y1="287.93" x2="463.75" y2="151.86" />
    <line x1="625.01" y1="328.58" x2="508.36" y2="116.41" />
    <line x1="573.26" y1="346.12" x2="565.65" y2="104.14" />
    <line x1="520.65" y1="337.36" x2="623.71" y2="118.27" />
    <line x1="479.21" y1="304.74" x2="670.51" y2="156.34" />
    <line x1="458.59" y1="256" x2="696.41" y2="210.62" />
    <line x1="463.85" y1="202.36" x2="696.34" y2="269.89" />
    <line x1="494.39" y1="156.11" x2="670.89" y2="321.83" />
    <line x1="544.15" y1="127.96" x2="626.17" y2="355.75" />
    <line x1="602.79" y1="124.69" x2="572.45" y2="364.89" />
    <line x1="658.07" y1="147.62" x2="522" y2="347.88" />
    <line x1="698.47" y1="192.42" x2="486.32" y2="309.08" />
    <line x1="715.71" y1="249.99" x2="473.73" y2="257.61" />
    <line x1="706.53" y1="308.4" x2="487.46" y2="205.34" />
    <line x1="673.41" y1="355.63" x2="525.02" y2="164.33" />
    <line x1="624.09" y1="382.04" x2="578.7" y2="144.22" />
    <line x1="569.75" y1="382.57" x2="637.28" y2="150.05" />
    <line x1="522.74" y1="357.77" x2="688.45" y2="181.28" />
    <line x1="493.73" y1="313.79" x2="721.51" y2="231.76" />
    <line x1="489.5" y1="260.88" x2="729.68" y2="291.22" />
    <line x1="511.38" y1="211.32" x2="711.64" y2="347.39" />
    <line x1="555.06" y1="176.62" x2="671.7" y2="388.77" />
    <line x1="611.38" y1="165.07" x2="619.01" y2="407.05" />
    <line x1="668.49" y1="179.92" x2="565.43" y2="398.98" />
    <line x1="714.32" y1="218.67" x2="523.02" y2="367.08" />
    <line x1="739.23" y1="273.63" x2="501.42" y2="319.02" />
    <line x1="738.17" y1="333.56" x2="505.68" y2="266.03" />
    <line x1="711.72" y1="386.16" x2="535.22" y2="220.43" />
    <line x1="665.97" y1="420.72" x2="583.95" y2="192.93" />
    <line x1="611.23" y1="430.46" x2="641.56" y2="190.25" />
    <line x1="559.75" y1="414.05" x2="695.82" y2="213.8" />
    <line x1="523.03" y1="375.84" x2="735.18" y2="259.19" />
    <line x1="509.38" y1="324.93" x2="751.36" y2="317.32" />
    <line x1="522.04" y1="273.21" x2="741.12" y2="376.29" />
    <line x1="558.54" y1="232.75" x2="706.93" y2="424.04" />
    <line x1="611.13" y1="213.16" x2="656.52" y2="450.97" />
    <line x1="668.63" y1="219.5" x2="601.1" y2="452" />
    <line x1="718.7" y1="251.2" x2="552.99" y2="427.69" />
    <line x1="750.66" y1="302.14" x2="522.88" y2="384.17" />
    <line x1="757.73" y1="362.04" x2="517.53" y2="331.72" />
    <line x1="738.56" y1="418.66" x2="538.31" y2="282.59" />
    <line x1="697.51" y1="460.44" x2="580.86" y2="248.29" />
    <line x1="643.68" y1="479.13" x2="636.07" y2="237.15" />
    <line x1="588.97" y1="471.45" x2="692.03" y2="252.37" />
    <line x1="545.42" y1="439.89" x2="736.71" y2="291.48" />
    <line x1="522.67" y1="392.18" x2="760.49" y2="346.79" />
    <line x1="525.78" y1="339.51" x2="758.28" y2="407.04" />
    <line x1="554.17" y1="294.22" x2="730.67" y2="459.94" />
    <line x1="601.73" y1="267.01" x2="683.77" y2="494.79" />
    <line x1="658.18" y1="264.61" x2="627.86" y2="504.82" />
    <line x1="711.26" y1="288.41" x2="575.19" y2="488.66" />
    <line x1="749.45" y1="334.02" x2="537.31" y2="450.68" />
    <line x1="764.47" y1="392.36" x2="522.47" y2="399.99" />
  </svg>,
  'AegisIcon',
)
